import { Reducer } from "redux";
import { WAREHOUSE_CARD_ID } from "./types";

const initialState: { id: number[] } = {
  id: [1, 2, 3]
};

const warehouseIdReducer: Reducer<typeof initialState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case WAREHOUSE_CARD_ID:
      return {
        ...state.id,
        ...action.payload
      };
    default:
      return {
        state
      };
  }
};

export default warehouseIdReducer;
