import { Checkbox, Input, Select } from "antd";
import React from "react";
import { customCurrency } from "../../../../helper/CustomCurrency";
import { ConversionDataElementType } from "../../../../models/conversion/ConversionModel";
import {
  useExpenseAll,
  usePaymentAll,
  useValutaAll
} from "../../../../queries/queries";
import SelectIcon from "../../../../svg/SelectIcon";
import WalletIcon from "../../../../svg/WalletIcon";

import styles from "../createConversion/conversion_card.module.scss";

const { Option } = Select;

interface Props extends ConversionDataElementType {
  type: boolean;
}

const ViewConversionCard: React.FC<Props> = ({
  type,
  amount,
  expense_type_id,
  payment_type_id,
  valuta_history_price,
  valuta_id
}) => {
  const { data: valuta } = useValutaAll();
  const { data: payments } = usePaymentAll();
  const { data: expences } = useExpenseAll();

  return (
    <div className={styles.conversion_card}>
      <div className={styles.top}>
        <div className={styles.icon}>
          <WalletIcon />
        </div>
        {valuta_id > 1 && (
          <div className={styles.payment}>
            <span className={styles.text}>1 $ = </span>
            <Input
              suffix="so'm"
              disabled
              value={customCurrency(String(valuta_history_price))}
            />
          </div>
        )}
      </div>
      <div className={styles.center}>
        <Select
          placeholder="Kassa turi"
          suffixIcon={<SelectIcon />}
          value={payment_type_id ?? 1}
          disabled
        >
          {payments?.map((item, index) => (
            <Option key={index} value={item.id!}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.bottom}>
        <Input
          placeholder="Summa"
          disabled
          value={customCurrency(String(amount)) ?? undefined}
        />
        <Select placeholder="UZS" suffixIcon={<SelectIcon />} value={valuta_id}>
          {valuta?.map((item, index) => (
            <Option value={item.id!} key={index}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      {!type && expense_type_id! > 0 && (
        <div className={styles.expense}>
          <Checkbox
            className={styles.check}
            disabled
            checked={!!expense_type_id}
          >
            <span className={styles.text}>Xarajat</span>
          </Checkbox>
          <Select
            placeholder="Xarajat turi"
            className={styles.payment_type}
            suffixIcon={<SelectIcon />}
            value={expense_type_id!}
          >
            {expences?.map((item, index) => (
              <Option key={index} value={item.id!}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};

export default ViewConversionCard;
