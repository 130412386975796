import { Button } from "antd";
import React from "react";
import ReactToPrint from "react-to-print";
import PrinterIcon from "../../../svg/PrinterIcon";

type Props = {
  printElemClassName?: string;
  headerClassName?: string;
};

const PrintTable: React.FC<Props> = ({
  printElemClassName,
  headerClassName
}) => {
  // after print
  const handleAfterPrint = () => {
    document.querySelectorAll(".printerButton").forEach((item: any) => {
      item.style.display = "flex";
    });
    const header: any = document.querySelector(`.${headerClassName}`);
    header.style.height = 0;
    header.style.opacity = 0;
  };

  // before print
  const handleBeforePrint = () => {
    document.querySelectorAll(".printerButton").forEach((item: any) => {
      item.style.display = "none";
    });
    const header: any = document.querySelector(`.${headerClassName}`);
    header.style.height = "max-content";
    header.style.opacity = 1;
  };

  // trigger
  const triggerElements = () => {
    return (
      <Button type="primary" className="printerButton">
        <PrinterIcon />
      </Button>
    );
  };

  // content
  const content = () => {
    const printElem = document.querySelector(`.${printElemClassName}`);
    return printElem;
  };
  return (
    <ReactToPrint
      onBeforeGetContent={handleBeforePrint}
      onAfterPrint={handleAfterPrint}
      onPrintError={handleAfterPrint}
      trigger={triggerElements}
      content={content}
    />
  );
};

export default PrintTable;
