import React from 'react'
import IconInterface from '../models/interfaces/IconInterface'

export const Plus: React.FC<IconInterface> = ({ onClick, className }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1875 8.5H11.8125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 3.6875V13.3125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
