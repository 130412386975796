import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentInterface } from "../../../../models/interfaces/PaymentInterface";
import { usePaymentMutation } from "../../../../queries/mutations";
import { useCurrentPayment } from "../../../../queries/queries";
import { isOpenSettingEditModal } from "../../../../redux/actions";
import { RootState } from "../../../../redux/rootReducer";

const { useForm } = Form;
const { Option } = Select;
const colors = [
  { name: "Qizil", value: "#F74753" },
  { name: "Yashil", value: "#4BB152" },
  { name: "Sariq", value: "#ECCA38" },
  { name: "Ko'k", value: "#2291CC" }
];
const CreateAndEditModal: FC<{
  currentPaymentId: number;
  setPaymentId: (id: number) => void;
  currentPage: number;
}> = ({ currentPaymentId, setPaymentId, currentPage }) => {
  ///////////////////////////////////////////
  const cu = usePaymentMutation(currentPage);
  const { data: currentPaymentData, isLoading: isCurrentLoading } =
    useCurrentPayment(currentPaymentId);

  const dispatch = useDispatch();
  const [form] = useForm();
  const isModalOpen = useSelector(
    (state: RootState) => state.settings.openCreateModal
  );

  const cancelFunc = () => {
    dispatch(isOpenSettingEditModal(false));
    setPaymentId(0);
    form.resetFields();
  };

  const onFinish = async (values: PaymentInterface) => {
    if (currentPaymentId) {
      await cu.mutateAsync({ ...values, id: currentPaymentId });
    } else {
      await cu.mutateAsync(values);
    }
    cancelFunc();
  };

  useEffect(() => {
    if (currentPaymentId > 0) {
      form.setFieldsValue({
        name: currentPaymentData?.[0]?.name,
        color: currentPaymentData?.[0]?.color
      });
    }
  }, [currentPaymentId, currentPaymentData, form]);

  const ModalTitle = (
    <>
      <span style={{ display: "inline-block", marginRight: "1rem" }}>
        To'lov yaratish
      </span>
      {isCurrentLoading && <LoadingOutlined />}
    </>
  );
  return (
    <Modal
      className={"editModal print-test"}
      visible={isModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
    >
      <Form
        form={form}
        name="paymentEdit"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[20, 8]}>
          <Col span={12}>
            <label htmlFor={"name"}>Nomi</label>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "To'lov nomini tanlang" }]}
            >
              <Input className={"input"} id={"name"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor={"color"}>Rangi</label>
            <Form.Item
              name="color"
              rules={[{ required: true, message: "To'lov rangini tanlang" }]}
            >
              <Select className={"select"} id={"color"}>
                {colors.map((color) => (
                  <Option value={color.value} key={color.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: color.value,
                          borderRadius: "50%",
                          marginRight: "5px"
                        }}
                      />
                      {color.name}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className={"footerCont"}>
          <Button
            onClick={cancelFunc}
            className={"modalFooterBack"}
            type="link"
          >
            Bekor qilish
          </Button>
          <Button
            disabled={isCurrentLoading}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit"
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAndEditModal;
