import { Button, Checkbox, Input, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { customCurrency } from "../../../../helper/CustomCurrency";
import { ConversionStateType } from "../../../../models/conversion/ConversionModel";
import {
  AMOUNT,
  EXPENSE_TYPE_ID,
  PAYMENT_TYPE_ID,
  VALUTA_HISTORY_PRICE,
  VALUTA_ID,
} from "../../../../models/interfaces/ConversionKeyNames";
import {
  useExpenseAll,
  usePaymentAll,
  useValutaAll,
} from "../../../../queries/queries";
import PlyusCircleIcon from "../../../../svg/PlyusCircleIcon";
import SelectIcon from "../../../../svg/SelectIcon";
import WalletIcon from "../../../../svg/WalletIcon";
import styles from "./conversion_card.module.scss";

const { Option } = Select;

type Props = {
  setConversions: React.Dispatch<React.SetStateAction<ConversionStateType>>;
  data: ConversionStateType;
  type: boolean;
  close: boolean;
  id: number;
};

const ConversionCard: React.FC<Props> = ({
  setConversions,
  data,
  type,
  close,
  id,
}) => {
  const [visibleElements, setVisibleElements] = useState<{
    valuta: boolean;
    expence: boolean;
  }>();
  const { data: valuta } = useValutaAll();
  const { data: payments } = usePaymentAll();
  const { data: expences } = useExpenseAll();
  const location = useLocation();

  useEffect(() => {
    setVisibleElements({ valuta: false, expence: false });
  }, [location.search]);

  const changeConversionState = (key: string, value: any) => {
    if (!type) {
      setConversions((prev) => {
        const formed_list = prev.formed_list.map((item) => {
          if (item.id === id)
            return {
              ...item,
              [key]: value,
            };
          return item;
        });
        return {
          ...prev,
          formed_list,
        };
      });
    } else {
      setConversions((prev) => {
        const spent_list = prev.spent_list.map((item) => {
          return {
            ...item,
            [key]: value,
          };
        });
        return {
          ...prev,
          spent_list,
        };
      });
    }
  };

  const deleteCard = () => {
    setConversions({
      ...data,
      formed_list: data?.formed_list?.filter((item) => item.id !== id),
    });
  };

  const changeValuta = (e: number) => {
    e === 2
      ? setVisibleElements({ ...visibleElements!, valuta: true })
      : setVisibleElements({ ...visibleElements!, valuta: false });

    changeConversionState(VALUTA_ID, e);
    changeConversionState(
      VALUTA_HISTORY_PRICE,
      valuta?.filter((item) => item.id! === e)[0].price
    );
  };

  const changeExpence = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setVisibleElements({ ...visibleElements!, expence: true });
    } else {
      setVisibleElements({ ...visibleElements!, expence: false });
      changeConversionState(EXPENSE_TYPE_ID, undefined);
    }
  };

  const changePayments = (e: number) => {
    changeConversionState(PAYMENT_TYPE_ID, e);
  };

  const changePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    changeConversionState(AMOUNT, Number(value.replace(/\s/g, "")));
  };

  const changeValutaHistory = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    changeConversionState(VALUTA_HISTORY_PRICE, value);
  };

  const changeExpenceType = (e: number) => {
    changeConversionState(EXPENSE_TYPE_ID, e);
  };

  const values = (keys: keyof typeof Object.keys) => {
    if (!type) {
      return data.formed_list?.filter((item) => item.id === id)[0][keys]!;
    } else {
      return data.spent_list[0][keys];
    }
  };

  return (
    <div className={styles.conversion_card}>
      <div className={styles.top}>
        <div className={styles.icon}>
          <WalletIcon />
        </div>
        {visibleElements?.valuta && (
          <div className={styles.payment}>
            <span className={styles.text}>1 $ = </span>
            <Input
              suffix="so'm"
              onChange={changeValutaHistory}
              value={values(VALUTA_HISTORY_PRICE as never)!}
            />
          </div>
        )}
        {close && (
          <Button className={styles.cancel} onClick={deleteCard}>
            <PlyusCircleIcon />
          </Button>
        )}
      </div>
      <div className={styles.center}>
        <Select
          placeholder="Kassa tanlash"
          onChange={changePayments}
          suffixIcon={<SelectIcon />}
          value={values(PAYMENT_TYPE_ID as never)}
          dropdownMatchSelectWidth={false}
        >
          {payments?.map((item, index) => (
            <Option key={index} value={item.id!} className={styles.option}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.bottom}>
        <Input
          placeholder="Summa"
          onChange={changePrice}
          value={customCurrency(String(values(AMOUNT as never))) ?? undefined}
        />
        <Select
          placeholder="UZS"
          suffixIcon={<SelectIcon />}
          onChange={changeValuta}
          value={values(VALUTA_ID as never)}
          dropdownMatchSelectWidth={false}
        >
          {valuta?.map((item, index) => (
            <Option value={item.id!} key={index} className={styles.option}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      {!type && (
        <div className={styles.expense}>
          <Checkbox
            className={styles.check}
            checked={visibleElements?.expence}
            onChange={changeExpence}
          >
            <span className={styles.text}>Xarajat</span>
          </Checkbox>
          {visibleElements?.expence && (
            <Select
              placeholder="Xarajat turi"
              className={styles.payment_type}
              suffixIcon={<SelectIcon />}
              onChange={changeExpenceType}
              value={values(EXPENSE_TYPE_ID as never)}
              dropdownMatchSelectWidth={false}
            >
              {expences?.map((item, index) => (
                <Option key={index} value={item.id!} className={styles.option}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
        </div>
      )}
    </div>
  );
};

export default ConversionCard;
