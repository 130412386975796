import { Button, Modal, Spin, Tabs } from 'antd'
import React, { useState } from 'react'
import { CreditDebitData } from '../../../../models/stats/credit-debit'
import { useCreditDebitExcel } from '../../../../queries/mutations'
import DownloadIcon from '../../../../svg/DownloadIcon'
import { BrokerSupplierTable } from './BrokerSupplierTable'
const { TabPane } = Tabs
interface CreditDebitModalProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  title: string
  data?: CreditDebitData
  isLoading: boolean
}

export const CreditDebitModal: React.FC<CreditDebitModalProps> = ({
  visible,
  setVisible,
  title,
  data,
  isLoading,
}) => {
  const [tabType, setTabType] = useState<number>(1)

  // cancel modal
  const onCancel = () => {
    setVisible(false)
  }

  // excel mutation
  const getExcel = useCreditDebitExcel()

  // handle excel
  const handleExcel = () => {
    if (title === 'Kredit') {
      getExcel.mutate({ type: 'KREDIT_SUPPLIERS' })
    } else if (title === 'Debit') {
      if (tabType === 1) {
        getExcel.mutate({ type: 'DEBIT_SUPPLIERS' })
      } else if (tabType === 2) {
        getExcel.mutate({ type: 'DEBIT_SUPPLIERS_BROKERS' })
      }
    }
  }

  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      title={title}
      className={'editModal ' + title}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      // onOk={() => setVisible(false)}
      okButtonProps={{ danger: title === 'Debit' }}
      centered
    >
      <Tabs
        color="white"
        defaultActiveKey="1"
        onChange={(tab) => setTabType(Number(tab))}
      >
        <TabPane tab="Ta'minotchilar" key="1">
          <BrokerSupplierTable
            isLoading={isLoading}
            data={data?.supplier}
            type="supplier"
            entryType={title.toLowerCase()}
          />
        </TabPane>
        {title === 'Debit' && (
          <TabPane tab="Vositachilar" key="2">
            <BrokerSupplierTable
              isLoading={isLoading}
              data={data?.broker}
              type="broker"
              entryType={title.toLowerCase()}
            />
          </TabPane>
        )}
      </Tabs>
      <div className="footer">
        <Button onClick={handleExcel}>
          <div className="btn excel">
            {getExcel.isLoading ? (
              <>
                <Spin size="small" />
                &nbsp;
              </>
            ) : (
              <DownloadIcon />
            )}
            <span>Excel</span>
          </div>
        </Button>
        <Button
          type="primary"
          danger={title === 'Debit'}
          onClick={() => setVisible(false)}
        >
          <div>
            <span>OK</span>
          </div>
        </Button>
      </div>
    </Modal>
  )
}
