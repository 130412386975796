import { LeftCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Empty, Popconfirm, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import query from "querystring";
import dayjs from "rc-picker/node_modules/dayjs";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { PAGE } from "../../../helper/paramNames";
import { paramsValue } from "../../../helper/paramsValues";
import {
  ConversionDataType,
  ConversionStateType,
} from "../../../models/conversion/ConversionModel";
import { ConversionIncome } from "../../../models/enums/ConversionEnums";
import { DATE_FORMAT_TIME } from "../../../models/settings/dateFormat";
import { useDeleteConversion } from "../../../queries/mutations";
import {
  useConversion,
  usePaymentAll,
  useValutaAll,
} from "../../../queries/queries";
import mainPaths from "../../../routes/main/mainPaths";
import { rootPaths } from "../../../routes/rootRoutes";
import ConversionIcon from "../../../svg/ConversionIcon";
import TrashIcon from "../../../svg/TrashIcon";
import PaginationComp from "../universialComponents/PaginationComp";
import styles from "./conversion.module.scss";
import CreateConversion from "./createConversion/CreateConversion";
import ConversionFilter from "./filter/ConversionFilter";
import ViewConversion from "./viewConversion/ViewConversion";

const Conversion: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const pageNumber = () => {
    const params = query.parse(
      location.search.slice(1, location.search.length)
    );
    if (params?.page) return params.page as string;
    return "1";
  };

  const { data, isLoading } = useConversion(pageNumber());
  const deleteConversion = useDeleteConversion();
  const { data: valuta, isLoading: isLoadingValuta } = useValutaAll();
  const { data: payments, isLoading: isLoadingPayments } = usePaymentAll();

  const defaultConversion = {
    description: "",
    created_at: dayjs().format(DATE_FORMAT_TIME),
    formed_list: [
      {
        id: 1,
        amount: undefined,
        expense_type_id: undefined,
        kirim: ConversionIncome.KIRIM,
        payment_type_id: undefined,
        valuta_history_price: 1,
        valuta_id: 1,
      },
    ],
    spent_list: [
      {
        amount: undefined,
        kirim: ConversionIncome.CHIQIM,
        payment_type_id: undefined,
        valuta_history_price: 1,
        valuta_id: 1,
      },
    ],
  };
  const [conversions, setConversions] =
    useState<ConversionStateType>(defaultConversion);

  const [conversionView, setConversionView] = useState<ConversionDataType>();

  // change pagination
  const changePagination = (e: number) => {
    const queryValue = new URLSearchParams(location.search);
    queryValue.set(PAGE, String(e));

    history.push({
      search: queryValue.toString(),
    });
  };

  // filter conversion income
  const filterIncome = (data: ConversionDataType) => {
    return data.elements.filter(
      (item) => item.kirim === ConversionIncome.KIRIM
    )[0];
  };

  // return valuta name
  const valutaName = (data: ConversionDataType) => {
    return valuta?.filter(
      (item) => filterIncome(data)?.valuta_id === item.id!
    )[0];
  };

  // return payment name
  const paymentName = (data: ConversionDataType) => {
    return payments?.filter(
      (item) => filterIncome(data)?.payment_type_id === item.id!
    )[0];
  };

  // handle delete conversion
  const changeDeleteConversion = (e: ConversionDataType) => {
    deleteConversion.mutate(e.id);
  };

  // table columns
  const columns: ColumnsType<ConversionDataType> = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "SANA",
      render: (e: ConversionDataType) => (
        <>{dayjs(e.created_at).format("DD.MM.YYYY HH:mm")}</>
      ),
    },
    {
      title: "XODIM",
      render: (e: ConversionDataType) => <>{e.user.name}</>,
    },
    {
      title: "SUMMA",
      render: (e: ConversionDataType) => (
        <>
          {filterIncome(e)?.amount.toLocaleString()}
          <span className={styles.valuta_name}>{valutaName(e)?.name}</span>
        </>
      ),
    },
    {
      title: "KASSA",
      render: (e: ConversionDataType) => <>{paymentName(e)?.name}</>,
    },
    {
      title: "IZOH",
      render: (e: ConversionDataType) => <>{e.description ?? ""}</>,
    },
    {
      title: "ACTION",
      render: (e: ConversionDataType) => (
        <Popconfirm
          placement="left"
          title="O'chirishni davom ettirasizmi ?"
          okText="Ha !"
          cancelText="Yo'q."
          okType="danger"
          onConfirm={() => {
            changeDeleteConversion(e);
          }}
        >
          <span className={styles.delete}>
            <Button danger>
              <TrashIcon />
            </Button>
          </span>
        </Popconfirm>
      ),
      onCell: () => {
        return {
          onClick: (e) => {
            e.stopPropagation();
          },
        };
      },
    },
  ];

  // table locale content
  const localeTable = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Ma'lumotlar mavjud emas!"
      />
    ),
  };

  // table loading content
  const loadingTable = {
    spinning:
      isLoading ||
      isLoadingValuta ||
      deleteConversion.isLoading ||
      isLoadingPayments,
    indicator: <LoadingOutlined />,
  };

  // handle open create conversion modal
  const visibleConversionModal = () => {
    const params = query.parse(
      location.search.slice(1, location.search.length)
    );
    params.visible = paramsValue.TRUE;
    history.push(`${location.pathname}?${query.stringify(params)}`);
  };

  const onRowTable = (e: ConversionDataType) => {
    return {
      onClick: () => {
        const params = query.parse(
          location.search.slice(1, location.search.length)
        );
        params.visibleView = paramsValue.TRUE;
        history.push(`${location.pathname}?${query.stringify(params)}`);
        setConversionView(e);
      },
    };
  };

  return (
    <section className={styles.conversion}>
      <div className={styles.top}>
        <h2>
          <Button
            onClick={() => history.push(rootPaths.MAIN + mainPaths.CHECKOUT)}
          >
            <LeftCircleOutlined />
          </Button>
          Konvertatsiya
        </h2>
        <ConversionFilter>
          <Button onClick={visibleConversionModal}>
            <ConversionIcon /> Konvertatsiya qilish
          </Button>
        </ConversionFilter>
      </div>
      <div className={styles.bottom}>
        <Table
          pagination={false}
          dataSource={data?.data}
          columns={columns}
          locale={localeTable}
          loading={loadingTable}
          className={styles.table}
          rowKey={(e) => e.id}
          onRow={onRowTable}
        />
      </div>
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: 10,
          onChange: changePagination,
        }}
      />
      <CreateConversion
        conversions={conversions}
        setConversions={setConversions}
      />
      <ViewConversion data={conversionView!} />
    </section>
  );
};

export default Conversion;
