import { Reducer } from 'redux'
import { HISTORY_SET_HISTORY, PRODUCT_GRAPH } from './types'

const initialState = {
  isOpen: false,
  id: 0,
  historyArr: ['initial'],
}

const productGraphReducer: Reducer<typeof initialState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PRODUCT_GRAPH:
      return { ...state, ...action.payload }

    case HISTORY_SET_HISTORY:
      return { ...state, historyArr: action.payload }

    default:
      return state
  }
}

export default productGraphReducer
