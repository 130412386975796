import React from "react";
import { Button, Modal } from "antd";
import dayjs from "rc-picker/node_modules/dayjs";

import { OneSupplierType } from "../all/SupplierAllTypes";

type Props = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  data: OneSupplierType;
};

const LastPaymentModal: React.FC<Props> = ({ setVisible, visible, data }) => {
  return (
    <Modal
      centered
      visible={visible}
      footer={false}
      onCancel={() => setVisible(false)}
      title="Qo'shimcha ma'lumotlar"
      className="last_payment"
    >
      <div className="last_payment_content">
        {data?.created_at && (
          <div className="last_payment_item">
            <p>Boshlangan sana</p>
            <h3>{dayjs(data?.created_at)?.format("DD.MM.YYYY")}</h3>
          </div>
        )}
        <div className="last_payment_item">
          <p>Balans</p>
          <h3>{data?.balans?.toLocaleString("ru")} so'm</h3>
        </div>
        <div className="last_payment_item">
          <p>Raqami</p>
          <h3>{data?.phone}</h3>
        </div>
        {data?.extra_phone && (
          <div className="last_payment_item">
            <p>Qo’shimcha raqam</p>
            <h3>{data?.extra_phone ? data?.extra_phone : "Mavjud emas"}</h3>
          </div>
        )}
        {data?.address && (
          <div className="last_payment_item">
            <p>Manzil</p>
            <h3>{data?.address}</h3>
          </div>
        )}
        <div className="last_payment_footer">
          <Button onClick={() => setVisible(false)}>Chiqish</Button>
        </div>
      </div>
    </Modal>
  );
};

export default LastPaymentModal;
