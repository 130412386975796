import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { orderIdAction } from "../../../redux/actions";
import mainPaths from "../../../routes/main/mainPaths";
import { rootPaths } from "../../../routes/rootRoutes";
import { ArrowsIcon } from "../../../svg/ArrowsIcon";
import { PlusDark } from "../../../svg/PlusDark";
// import SortIcon from "../../../svg/SortIcon";
import SearchHeader from "../universialComponents/SearchHeader";
import OrdersTable from "./OrdersTable";

interface OrderPageProps {}

const OrderPage: React.FC<OrderPageProps> = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  useDocumentTitle("Partiyalar");

  // const search = new URLSearchParams(location.search);

  return (
    <>
      <SearchHeader
        title="Partiyalar"
        buttons={
          <div style={{ display: "flex" }}>
            <Button
              className={"cOrder_headerBtn button"}
              icon={<ArrowsIcon />}
              style={{ marginRight: "1rem" }}
              onClick={() => push(`${rootPaths.MAIN}${mainPaths.TRANSFER}`)}
            >
              Transfer
            </Button>
            <Button
              onClick={() => {
                dispatch(orderIdAction(false));
                push(`${rootPaths.MAIN}${mainPaths.ORDERS}/new`);
              }}
              icon={<PlusDark />}
              className={"cOrder_headerBtn button"}
              style={{ marginRight: "1rem" }}
            >
              Kirim
            </Button>
            {/* <Popover
              trigger={["click"]}
              content={
                <div className={"cOrder_sortPopover"}>
                  <div style={{ marginBottom: ".5rem", fontWeight: 600 }}>
                    Nima bo'yicha
                  </div>
                  <Select
                    onChange={(value) => {
                      search.set("key", value);
                      push(`${location.pathname}?${search.toString()}`);
                    }}
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    value={search.get("key") || "id"}
                  >
                    <Select.Option value={"id"}>Id</Select.Option>
                    <Select.Option value={"date"}>Sana</Select.Option>
                    <Select.Option value={"rating"}>Reyting</Select.Option>
                    <Select.Option value={"price"}>Narx</Select.Option>
                  </Select>
                  <div style={{ marginBottom: ".5rem", fontWeight: 600 }}>
                    Tartib
                  </div>
                  <Radio.Group
                    onChange={(value) => {
                      search.set("sort", value.target.value);
                      push(`${location.pathname}?${search.toString()}`);
                    }}
                    value={search.get("sort") || "desc"}
                    buttonStyle="solid"
                  >
                    <Radio.Button type="primary" value="asc">
                      oshish
                    </Radio.Button>
                    <Radio.Button type="primary" value="desc">
                      kamayish
                    </Radio.Button>
                  </Radio.Group>
                </div>
              }
            >
              <Button
                icon={<SortIcon />}
                className={"cOrder_headerBtn button"}
                style={{ marginRight: "1rem" }}
              >
                Tartiblash
              </Button>
            </Popover> */}
          </div>
        }
        inputPlaceholder="#N, kirim vaqti, vositachi,  taminotchi, obyekt nomlari"
      />
      <OrdersTable />
    </>
  );
};

export default OrderPage;
