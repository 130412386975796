import React from "react";

function CalendarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V3C13.5 2.72386 13.2761 2.5 13 2.5Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 1.5V3.5"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 1.5V3.5"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5.5H13.5"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 7.99976H7.5L6.5 9.24976C6.66443 9.24976 6.82631 9.2903 6.97133 9.3678C7.11635 9.4453 7.24001 9.55736 7.33139 9.69406C7.42276 9.83076 7.47901 9.98788 7.49516 10.1515C7.51132 10.3151 7.48687 10.4802 7.424 10.6322C7.36112 10.7841 7.26175 10.9182 7.13469 11.0225C7.00763 11.1269 6.85679 11.1983 6.69554 11.2305C6.53429 11.2626 6.3676 11.2545 6.21023 11.2069C6.05286 11.1592 5.90966 11.0735 5.79332 10.9573"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.74988L10 7.99988V11.2499"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalendarIcon;
