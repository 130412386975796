import React from 'react'

function DownloadIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.03906 6.44604L7.5 8.90634L9.96094 6.44604"
        stroke="#273B42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 2.34375V8.90453"
        stroke="#273B42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6562 8.90625V12.1875C12.6562 12.3118 12.6069 12.431 12.519 12.519C12.431 12.6069 12.3118 12.6562 12.1875 12.6562H2.8125C2.68818 12.6562 2.56895 12.6069 2.48104 12.519C2.39314 12.431 2.34375 12.3118 2.34375 12.1875V8.90625"
        stroke="#273B42"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownloadIcon
