import React from 'react'
import IconInterface from '../models/interfaces/IconInterface'

export const Key: React.FC<IconInterface> = ({ onClick, className }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.82301 7.67705C5.43757 6.71565 5.3959 5.65066 5.70504 4.66209C6.01418 3.67352 6.65516 2.822 7.51965 2.25148C8.38414 1.68095 9.4191 1.4264 10.4496 1.53087C11.4801 1.63533 12.4429 2.09239 13.1753 2.8248C13.9077 3.55721 14.3648 4.52004 14.4693 5.55054C14.5737 6.58103 14.3192 7.616 13.7486 8.48048C13.1781 9.34497 12.3266 9.98595 11.338 10.2951C10.3494 10.6042 9.28446 10.5625 8.32307 10.1771L8.32311 10.177L7.50012 11H6.00012V12.5H4.50012V14H2.00012V11.5L5.82312 7.677L5.82301 7.67705Z"
        stroke="#C1C7D0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M11.25 5C11.3881 5 11.5 4.88807 11.5 4.75C11.5 4.61193 11.3881 4.5 11.25 4.5C11.1119 4.5 11 4.61193 11 4.75C11 4.88807 11.1119 5 11.25 5Z"
        stroke="#C1C7D0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6875 4.75C11.6875 4.99162 11.4916 5.1875 11.25 5.1875C11.0084 5.1875 10.8125 4.99162 10.8125 4.75C10.8125 4.50838 11.0084 4.3125 11.25 4.3125C11.4916 4.3125 11.6875 4.50838 11.6875 4.75Z"
        fill="#C1C7D0"
        stroke="#C1C7D0"
        strokeWidth="0.125"
      />
    </svg>
  )
}
