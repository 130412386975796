import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Login from '../components/loginPage/Login'
import { RootState } from '../redux/rootReducer'
import mainPaths from './main/mainPaths'
import { rootPaths, rootRoutes } from './rootRoutes'

interface RootRouterProps {}

const RootRouter: React.FC<RootRouterProps> = () => {
  const isLogged = useSelector((state: RootState) => !!state.user.token)

  return (
    <BrowserRouter>
      <Switch>
        <Route path={rootPaths.LOGIN} component={Login} />

        {isLogged ? (
          rootRoutes.map((route) => (
            <Route
              exact={route.exact}
              key={route.path}
              path={route.path}
              component={route.component}
            />
          ))
        ) : (
          <Redirect to={rootPaths.LOGIN} />
        )}
        <Redirect to={`${rootPaths.MAIN}${mainPaths.ORDERS}`} />
      </Switch>
    </BrowserRouter>
  )
}

export default RootRouter
