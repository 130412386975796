import { Button, Skeleton, Typography } from "antd";
import dayjs from "rc-picker/node_modules/dayjs";
import customParseFormat from "rc-picker/node_modules/dayjs/plugin/customParseFormat";
import timezone from "rc-picker/node_modules/dayjs/plugin/timezone";
import utc from "rc-picker/node_modules/dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLatestHistory } from "../../../queries/queries";
import { isOpenProductGraph } from "../../../redux/actions";
import { getTag } from "../../../service/getTag";
import { isElementInViewport } from "../../../service/Helpers";
import { TrendingIcon } from "../../../svg/TrendingIcon";

interface HistoryPaperProps {
  date: string;
  index: number;
}

const HistoryPaper: React.FC<HistoryPaperProps> = ({ date }) => {
  const elem = useRef<HTMLDivElement>(null);
  const [isOnView, setIsOnView] = useState(false);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  useEffect(() => {
    if (elem.current) {
      const listener = () => {
        const _isOnView = isElementInViewport(elem.current);
        // console.log('isonview', date, elem.current, _isOnView)
        setIsOnView(_isOnView);
      };
      if (!isOnView) {
        listener();
        window.addEventListener("scroll", listener);
      }
      return () => {
        window.removeEventListener("scroll", listener);
      };
    }
  }, [isOnView]);

  const { data, isLoading } = useLatestHistory(date, isOnView);
  if (date === "last") {
    return (
      <div className="historyPaper_nodata">
        Hozircha boshqa amallar tarixi yo'q
      </div>
    );
  }
  return (
    <div ref={elem} className="historyPaper">
      <div className="historyPaper__title">
        <Typography.Paragraph type="secondary">
          {isLoading
            ? "yuklanmoqda..."
            : dayjs(data?.current_day)?.format("DD.MM.YYYY")}
        </Typography.Paragraph>
      </div>
      <div className="historyPaper__content">
        {isLoading ? (
          <Skeleton paragraph={{ rows: 4, width: "100%" }} />
        ) : (
          data?.data.map((item, index) => {
            return (
              <div className="historyPaper__item" key={index}>
                <span>
                  {dayjs.tz(item.date, "Asia/Tashkent").format("HH:mm")}
                </span>
                {getTag(item.type)}
                <span>{item.comment}</span>
                <Button
                  onClick={() =>
                    dispatch(isOpenProductGraph(true, item.product_id))
                  }
                  icon={<TrendingIcon />}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default HistoryPaper;
