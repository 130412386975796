import { LoadingOutlined } from "@ant-design/icons";
import { Col, Empty, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React from "react";
import { ACTIVE } from "../../../helper/ExpenseType";
import { tableNumber } from "../../../helper/TableNumber";
import { WareHouseOneProduct } from "../../../models/warehouse/WarehouseModel";
import { useGetOneExpense } from "../../../queries/queries";
import PrintTable from "../common/PrintTable";

import styles from "./expense.module.scss";

type Props = {
  id: number;
  dataOne?: {
    building_name: string;
    date: string;
    id: number;
    price: number;
    status: string;
    user_name: string;
  };
};

const ProductTable: React.FC<Props> = ({ id, dataOne }) => {
  const { data, isLoading } = useGetOneExpense(Number(id));

  // products table columns
  const columnProduct: ColumnsType<WareHouseOneProduct> = [
    {
      title: "№",
      render: (_, __, index) => tableNumber(index, String(1))
    },
    {
      title: "NOMI",
      dataIndex: "product_name"
    },
    {
      title: "MIQDORI",
      dataIndex: "summa_count"
    },
    {
      title: "O'LCHOVI",
      dataIndex: "product_type_name"
    },
    {
      title: (
        <span
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          UMUMIY{" "}
          <PrintTable
            printElemClassName={`${styles.product}_${dataOne?.id}`}
            headerClassName={`oneExpenseRow_${dataOne?.id}`}
          />
        </span>
      ),
      render: (record) => (
        <span>{Number(record.summa_price).toLocaleString()} so'm </span>
      )
    }
  ];

  return (
    <div className={`${styles.product} ${styles.product}_${dataOne?.id}`}>
      <Row
        className={`oneExpenseRow oneExpenseRow_${dataOne?.id}`}
        style={{
          height: 0,
          opacity: 0,
          transition: "0.3s ease-in-out"
        }}
      >
        <Col span={2}>#{dataOne?.id}</Col>
        <Col span={5}>{dayjs(dataOne?.date).format("DD.MM.YYYY HH:mm")}</Col>
        <Col span={4}>{dataOne?.user_name}</Col>
        <Col span={4}>{dataOne?.building_name}</Col>
        <Col span={4}>{Number(dataOne?.price).toLocaleString()}</Col>
        <Col span={4}>
          {dataOne?.status === ACTIVE ? "Tasdiqlangan" : "Tasdiqlanmagan"}
        </Col>
      </Row>
      <Table
        loading={{
          spinning: isLoading,
          indicator: <LoadingOutlined />
        }}
        dataSource={data ? data[0].products : []}
        columns={columnProduct}
        pagination={false}
        rowKey={(record) => record.product_id}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Mahsulotlar mavjud emas!"
            />
          )
        }}
      />
    </div>
  );
};

export default ProductTable;
