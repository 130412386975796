import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8.5H12"
        stroke="#099E74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 5.5H14.5"
        stroke="#099E74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 11.5H9.5"
        stroke="#099E74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
