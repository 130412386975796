import { LoadingOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDeleteTransfer } from "../../../queries/mutations";
import { useTransfers } from "../../../queries/queries";
import BackOutlined from "../../../svg/BackOutlined";
import TableDownIcon from "../../../svg/TableDownIcon";
import PaginationComp from "../universialComponents/PaginationComp";
import TransferOrders from "./TransferOrders";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import dayjs from "rc-picker/node_modules/dayjs";

const { Column } = Table;

const TransfersTable = () => {
  const [page, setPage] = useState(1);
  const [deleteId, setDeleteId] = useState(0);

  const searchString = useSelector(
    (state: RootState) => state.searchHeader.searchString
  );

  const { data, isLoading, isFetching } = useTransfers(page, searchString);
  const deleteTransfer = useDeleteTransfer(setDeleteId, page);

  useEffect(() => {
    setPage(1);
  }, [searchString]);

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id);
      await deleteTransfer.mutateAsync(id);
      if (data?.data.length === 1 && data.current_page !== 1) setPage(page - 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Table
        pagination={false}
        expandable={{
          expandIconColumnIndex: 5,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (expanded)
              return (
                <span
                  onClick={(e) => onExpand(record, e)}
                  style={{
                    padding: "5px",
                    color: "red",
                    cursor: "pointer",
                  }}
                >
                  <TableDownIcon style={{ transform: "rotate(180deg)" }} />
                </span>
              );
            return (
              <span
                onClick={(e) => onExpand(record, e)}
                style={{ padding: "5px", color: "red", cursor: "pointer" }}
              >
                <TableDownIcon />
              </span>
            );
          },
          // expandIcon: (record) => ,
          expandedRowRender: (record, index, indent, expanded) => {
            if (expanded) return <TransferOrders id={record.id} />;
            else return null;
          },
        }}
        dataSource={data?.data || []}
        loading={isLoading}
      >
        <Column
          title="#"
          dataIndex="id"
          width={"5%"}
          key="id"
          render={(record) => `#${record}`}
        />
        <Column
          width={"14%"}
          title={
            <>
              <span
                style={{
                  display: "inline-block",
                  marginRight: "1rem",
                }}
              >
                SANA
              </span>
              {isFetching && <LoadingOutlined />}
            </>
          }
          key="date"
          dataIndex={"date"}
          render={(record) => {
            return <>{dayjs(record)?.format("DD.MM.YYYY")}</>;
          }}
        />
        <Column
          title={
            <>
              <span
                style={{
                  display: "inline-block",
                  opacity: 0.4,
                }}
              >
                OB’YEKT
              </span>
              DAN
            </>
          }
          dataIndex={"first_building_name"}
          key="first_building_name"
        />
        <Column
          title={
            <>
              <span
                style={{
                  display: "inline-block",
                  opacity: 0.4,
                }}
              >
                OB’YEKT
              </span>
              GA
            </>
          }
          dataIndex="second_building_name"
          key="second_building_name"
        />
        <Column
          key={"action"}
          width={"10%"}
          title={"ACTION"}
          align={"center"}
          render={(record) => (
            <>
              {deleteTransfer.isLoading && deleteId === record.id ? (
                <Spin />
              ) : (
                <Popconfirm
                  placement="left"
                  title="Transferni bekor qilishni xohlaysizmi?"
                  onConfirm={() => deleteFunc(record.id)}
                  okButtonProps={{
                    danger: true,
                    loading: deleteTransfer.isLoading,
                  }}
                  okText="Xa"
                  cancelText="Yoq"
                >
                  {/* <span
                    title={'Transferni bekor qilish'}
                    onClick={(e) => e.stopPropagation()}
                    className="table-edit"
                    style={{ cursor: 'pointer' }}
                  > */}
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "#FFF0EF",
                      color: "#FF3830",
                      border: "none",
                    }}
                    icon={<BackOutlined />}
                    type="primary"
                    danger
                  >
                    <span
                      style={{
                        color: "#FF3830",
                        display: "inline-block",
                        marginLeft: ".5rem",
                      }}
                    >
                      bekor qilish
                    </span>
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        />
      </Table>
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          onChange: (page) => setPage(page),
        }}
      />
    </>
  );
};

export default TransfersTable;
