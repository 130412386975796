import React from "react";

const BigFolderIcon = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.2C1 2.0799 1 1.51984 1.21799 1.09202C1.40973 0.715695 1.71569 0.409734 2.09202 0.217987C2.51984 0 3.0799 0 4.2 0H9.07346C9.73327 0 10.0632 0 10.3839 0.0524545C10.6686 0.099024 10.9475 0.17626 11.2156 0.282815C11.5175 0.402836 11.8004 0.572572 12.3662 0.912045L15.4799 2.78026C16.0457 3.11974 16.3286 3.28947 16.6306 3.40949C16.8987 3.51605 17.1775 3.59328 17.4623 3.63985C17.783 3.69231 18.1129 3.69231 18.7727 3.69231H29.16C30.5041 3.69231 31.1762 3.69231 31.6896 3.95389C32.1412 4.18399 32.5083 4.55114 32.7384 5.00273C33 5.51612 33 6.18818 33 7.53231V27.2C33 28.8802 33 29.7202 32.673 30.362C32.3854 30.9265 31.9265 31.3854 31.362 31.673C30.7202 32 29.8802 32 28.2 32H5.8C4.11984 32 3.27976 32 2.63803 31.673C2.07354 31.3854 1.6146 30.9265 1.32698 30.362C1 29.7202 1 28.8802 1 27.2L1 3.2Z"
        fill="url(#paint0_linear_1928_3349)"
      />
      <path
        d="M1.5 3.2C1.5 2.6317 1.50039 2.23554 1.52559 1.92712C1.55031 1.62454 1.5964 1.45069 1.66349 1.31901C1.8073 1.03677 2.03677 0.8073 2.31901 0.66349C2.45069 0.596397 2.62454 0.55031 2.92712 0.525588C3.23554 0.500389 3.6317 0.5 4.2 0.5H9.07346C9.74898 0.5 10.0318 0.501504 10.3032 0.545898C10.5523 0.586646 10.7963 0.654228 11.0309 0.747463C11.2865 0.849039 11.5297 0.993236 12.109 1.34079L15.2227 3.20901L15.2619 3.23255C15.7904 3.54971 16.1059 3.73899 16.4459 3.87414C16.7475 3.99401 17.0613 4.08091 17.3816 4.1333C17.7427 4.19237 18.1106 4.19234 18.727 4.19231L18.7727 4.19231H29.16C29.8403 4.19231 30.3205 4.1927 30.6956 4.22335C31.0649 4.25352 31.2881 4.3105 31.4626 4.3994C31.8201 4.58155 32.1108 4.87222 32.2929 5.22973C32.3818 5.40419 32.4388 5.62742 32.469 5.99671C32.4996 6.37183 32.5 6.85199 32.5 7.53231V27.2C32.5 28.0483 32.4996 28.6545 32.4608 29.1297C32.4224 29.599 32.3491 29.8963 32.2275 30.135C31.9878 30.6054 31.6054 30.9878 31.135 31.2275C30.8963 31.3491 30.599 31.4224 30.1297 31.4608C29.6545 31.4996 29.0483 31.5 28.2 31.5H5.8C4.95167 31.5 4.34549 31.4996 3.87032 31.4608C3.40099 31.4224 3.10366 31.3491 2.86502 31.2275C2.39462 30.9878 2.01217 30.6054 1.77248 30.135C1.65089 29.8963 1.57756 29.599 1.53921 29.1297C1.50039 28.6545 1.5 28.0483 1.5 27.2V3.2Z"
        stroke="#074163"
        strokeOpacity="0.22"
      />
      <path
        d="M12.1391 1.31732L11.9905 1.6887L12.1391 1.31732L12.1145 1.30749C11.7102 1.14575 11.4767 1.05233 11.234 0.986115C11.0185 0.927328 10.7989 0.885033 10.577 0.859593C10.3271 0.830939 10.0755 0.830945 9.64008 0.830957L9.61363 0.830957H4.2L4.1825 0.830957C3.63728 0.830954 3.20367 0.830951 2.85383 0.859534C2.49559 0.888803 2.19012 0.950028 1.91042 1.09254C1.45883 1.32264 1.09168 1.68979 0.861584 2.14138C0.719071 2.42108 0.657846 2.72655 0.628577 3.08479C0.599994 3.43462 0.599997 3.86824 0.6 4.41346L0.6 4.43096V27.2002L0.6 27.2182C0.599998 28.043 0.599996 28.6866 0.642201 29.2032C0.685095 29.7281 0.773567 30.1571 0.970578 30.5438C1.29655 31.1835 1.81668 31.7036 2.45643 32.0296C2.84309 32.2266 3.27204 32.3151 3.79703 32.358C4.3136 32.4002 4.95715 32.4002 5.78198 32.4002H5.8H28.2H28.218C29.0428 32.4002 29.6864 32.4002 30.203 32.358C30.728 32.3151 31.1569 32.2266 31.5436 32.0296C32.1833 31.7036 32.7035 31.1835 33.0294 30.5438C33.2264 30.1571 33.3149 29.7281 33.3578 29.2032C33.4 28.6866 33.4 28.043 33.4 27.2181V27.2002V7.71389V7.69638C33.4 7.04817 33.4 6.53736 33.3666 6.12589C33.3325 5.706 33.2617 5.35505 33.1005 5.03514C32.8369 4.51218 32.4161 4.085 31.8971 3.81372C31.5796 3.64777 31.2298 3.57173 30.8104 3.53139C30.3995 3.49186 29.8888 3.48427 29.2407 3.47462L29.2231 3.47436L18.189 3.31015C17.7386 3.30344 17.5428 3.29998 17.3517 3.27605C17.1774 3.25423 17.0051 3.2197 16.8359 3.17273C16.6503 3.1212 16.4683 3.04899 16.05 2.88167L12.1391 1.31732Z"
        fill="url(#paint1_linear_1928_3349)"
        stroke="#2880B4"
        strokeWidth="0.8"
      />
      <mask
        id="mask0_1928_3349"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="34"
        height="33"
      >
        <path
          d="M12.1391 1.31732L11.9905 1.6887L12.1391 1.31732L12.1145 1.30749C11.7102 1.14575 11.4767 1.05233 11.234 0.986115C11.0185 0.927328 10.7989 0.885033 10.577 0.859593C10.3271 0.830939 10.0755 0.830945 9.64008 0.830957L9.61363 0.830957H4.2L4.1825 0.830957C3.63728 0.830954 3.20367 0.830951 2.85383 0.859534C2.49559 0.888803 2.19012 0.950028 1.91042 1.09254C1.45883 1.32264 1.09168 1.68979 0.861584 2.14138C0.719071 2.42108 0.657846 2.72655 0.628577 3.08479C0.599994 3.43462 0.599997 3.86824 0.6 4.41346L0.6 4.43096V27.2002L0.6 27.2182C0.599998 28.043 0.599996 28.6866 0.642201 29.2032C0.685095 29.7281 0.773567 30.1571 0.970578 30.5438C1.29655 31.1835 1.81668 31.7036 2.45643 32.0296C2.84309 32.2266 3.27204 32.3151 3.79703 32.358C4.3136 32.4002 4.95715 32.4002 5.78198 32.4002H5.8H28.2H28.218C29.0428 32.4002 29.6864 32.4002 30.203 32.358C30.728 32.3151 31.1569 32.2266 31.5436 32.0296C32.1833 31.7036 32.7035 31.1835 33.0294 30.5438C33.2264 30.1571 33.3149 29.7281 33.3578 29.2032C33.4 28.6866 33.4 28.043 33.4 27.2181V27.2002V7.71389V7.69638C33.4 7.04817 33.4 6.53736 33.3666 6.12589C33.3325 5.706 33.2617 5.35505 33.1005 5.03514C32.8369 4.51218 32.4161 4.085 31.8971 3.81372C31.5796 3.64777 31.2298 3.57173 30.8104 3.53139C30.3995 3.49186 29.8888 3.48427 29.2407 3.47462L29.2231 3.47436L18.189 3.31015C17.7386 3.30344 17.5428 3.29998 17.3517 3.27605C17.1774 3.25423 17.0051 3.2197 16.8359 3.17273C16.6503 3.1212 16.4683 3.04899 16.05 2.88167L12.1391 1.31732Z"
          fill="white"
          stroke="white"
          strokeWidth="0.8"
        />
      </mask>
      <g mask="url(#mask0_1928_3349)">
        <g filter="url(#filter0_df_1928_3349)">
          <rect
            x="-0.230469"
            y="8.61548"
            width="34.4615"
            height="4.92308"
            rx="2.46154"
            fill="url(#paint2_linear_1928_3349)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12.2C1 11.0799 1 10.5198 1.21799 10.092C1.40973 9.71569 1.71569 9.40973 2.09202 9.21799C2.51984 9 3.0799 9 4.2 9H29.8C30.9201 9 31.4802 9 31.908 9.21799C32.2843 9.40973 32.5903 9.71569 32.782 10.092C33 10.5198 33 11.0799 33 12.2V27.5846C33 29.2648 33 30.1049 32.673 30.7466C32.3854 31.3111 31.9265 31.77 31.362 32.0576C30.7202 32.3846 29.8802 32.3846 28.2 32.3846H5.8C4.11984 32.3846 3.27976 32.3846 2.63803 32.0576C2.07354 31.77 1.6146 31.3111 1.32698 30.7466C1 30.1049 1 29.2648 1 27.5846V12.2Z"
          fill="#73D0FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12.2C1 11.0799 1 10.5198 1.21799 10.092C1.40973 9.71569 1.71569 9.40973 2.09202 9.21799C2.51984 9 3.0799 9 4.2 9H29.8C30.9201 9 31.4802 9 31.908 9.21799C32.2843 9.40973 32.5903 9.71569 32.782 10.092C33 10.5198 33 11.0799 33 12.2V27.5846C33 29.2648 33 30.1049 32.673 30.7466C32.3854 31.3111 31.9265 31.77 31.362 32.0576C30.7202 32.3846 29.8802 32.3846 28.2 32.3846H5.8C4.11984 32.3846 3.27976 32.3846 2.63803 32.0576C2.07354 31.77 1.6146 31.3111 1.32698 30.7466C1 30.1049 1 29.2648 1 27.5846V12.2Z"
          fill="url(#paint3_radial_1928_3349)"
          fillOpacity="0.55"
        />
        <path
          d="M1.25 12.2C1.25 11.6358 1.25019 11.2277 1.27642 10.9068C1.3024 10.5887 1.3527 10.3783 1.44074 10.2055C1.60852 9.87623 1.87623 9.60852 2.20552 9.44074C2.37831 9.3527 2.58872 9.3024 2.90676 9.27642C3.22772 9.25019 3.63582 9.25 4.2 9.25H29.8C30.3642 9.25 30.7723 9.25019 31.0932 9.27642C31.4113 9.3024 31.6217 9.3527 31.7945 9.44074C32.1238 9.60852 32.3915 9.87623 32.5593 10.2055C32.6473 10.3783 32.6976 10.5887 32.7236 10.9068C32.7498 11.2277 32.75 11.6358 32.75 12.2V27.5846C32.75 28.4288 32.7498 29.0469 32.71 29.5347C32.6703 30.0194 32.5928 30.3533 32.4503 30.6331C32.1866 31.1505 31.7659 31.5712 31.2485 31.8349C30.9687 31.9774 30.6348 32.055 30.15 32.0946C29.6623 32.1344 29.0442 32.1346 28.2 32.1346H5.8C4.9558 32.1346 4.33768 32.1344 3.84996 32.0946C3.36517 32.055 3.03128 31.9774 2.75153 31.8349C2.23408 31.5712 1.81338 31.1505 1.54973 30.6331C1.40719 30.3533 1.32965 30.0194 1.29004 29.5347C1.25019 29.0469 1.25 28.4288 1.25 27.5846V12.2Z"
          stroke="#B6E4FF"
          strokeOpacity="0.44"
          strokeWidth="0.5"
        />
        <path
          d="M11.0059 20.48V23.4733C11.0059 26.4666 12.2059 27.6666 15.1992 27.6666H18.7925C21.7859 27.6666 22.9859 26.4666 22.9859 23.4733V20.48"
          stroke="#3172A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.001 20.9999C18.221 20.9999 19.121 20.0066 19.001 18.7866L18.561 14.3333H15.4476L15.001 18.7866C14.881 20.0066 15.781 20.9999 17.001 20.9999Z"
          stroke="#3172A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2065 20.9999C22.5532 20.9999 23.5399 19.9066 23.4065 18.5666L23.2199 16.7333C22.9799 14.9999 22.3132 14.3333 20.5665 14.3333H18.5332L18.9999 19.0066C19.1132 20.1066 20.1065 20.9999 21.2065 20.9999Z"
          stroke="#3172A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7609 20.9999C13.8609 20.9999 14.8542 20.1066 14.9609 19.0066L15.1076 17.5333L15.4276 14.3333H13.3942C11.6476 14.3333 10.9809 14.9999 10.7409 16.7333L10.5609 18.5666C10.4276 19.9066 11.4142 20.9999 12.7609 20.9999Z"
          stroke="#3172A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0007 24.3333C15.8873 24.3333 15.334 24.8866 15.334 25.9999V27.6666H18.6673V25.9999C18.6673 24.8866 18.114 24.3333 17.0007 24.3333Z"
          stroke="#3172A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g opacity="0.7008" filter="url(#filter1_f_1928_3349)">
          <rect
            x="-0.230469"
            y="6.15381"
            width="2.46154"
            height="29.5385"
            fill="#73AED2"
          />
        </g>
        <g opacity="0.7008" filter="url(#filter2_f_1928_3349)">
          <rect
            x="34.2305"
            y="6.15381"
            width="2.46154"
            height="29.5385"
            fill="#73AED2"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_df_1928_3349"
          x="-5.66703"
          y="3.17892"
          width="45.3341"
          height="16.3597"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.243148 0 0 0 0 0.530446 0 0 0 0 0.784098 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1928_3349"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1928_3349"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.71828"
            result="effect2_foregroundBlur_1928_3349"
          />
        </filter>
        <filter
          id="filter1_f_1928_3349"
          x="-5.66703"
          y="0.717245"
          width="13.3341"
          height="40.4117"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.71828"
            result="effect1_foregroundBlur_1928_3349"
          />
        </filter>
        <filter
          id="filter2_f_1928_3349"
          x="28.7939"
          y="0.717245"
          width="13.3341"
          height="40.4117"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.71828"
            result="effect1_foregroundBlur_1928_3349"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1928_3349"
          x1="1"
          y1="0"
          x2="1"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.000158361" stopColor="#6298C9" />
          <stop offset="0.270858" stopColor="#4DABE2" />
          <stop offset="1" stopColor="#5E9CDE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1928_3349"
          x1="1"
          y1="1.23096"
          x2="1"
          y2="32.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.000158361" stopColor="#6298C9" />
          <stop offset="0.270858" stopColor="#4DABE2" />
          <stop offset="1" stopColor="#5E9CDE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1928_3349"
          x1="-0.230469"
          y1="8.61548"
          x2="-0.230469"
          y2="13.5386"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.000158361" stopColor="#1E6293" />
          <stop offset="1" stopColor="#17659D" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_1928_3349"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17 14.3378) rotate(90) scale(18.0468 24.6957)"
        >
          <stop stopColor="white" stopOpacity="0.278354" />
          <stop offset="1" stopColor="white" stopOpacity="0.01" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default BigFolderIcon;
