import { Empty, Input, Modal, Table } from "antd";
import React, { useState, useEffect } from "react";
import type { ColumnsType } from "antd/es/table";

import styles from "../expense.module.scss";
import CloseIcon from "../../../../svg/CloseIcon";
import { useDispatch } from "react-redux";
import { ToLocaleString } from "../../../../helper/ToLocaleString";
import { isOpenProductGraph } from "../../../../redux/actions";
import {
  useGetWarehouseProduct,
  useGetWarehouseUsedProduct
} from "../../../../queries/queries";
import {
  ProductDataModel,
  WarehouseDataModel
} from "../../../../models/warehouse/WarehouseModel";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import SearchIcon from "../../../../svg/SearchIcon";
import { tableNumber } from "../../../../helper/TableNumber";
import ArrowUpIcon from "../../../../svg/ArrayUpIcon";
import ArrowDownIcon from "../../../../svg/ArrowDownIcon";
import ArrowDownUpIcon from "../../../../svg/ArrayDownUpIcon";
import PaginationComp from "../../universialComponents/PaginationComp";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  warehouse: WarehouseDataModel;
  type?: number;
};

const ProductsWarehouseModal: React.FC<Props> = ({
  visible,
  setVisible,
  warehouse,
  type
}) => {
  const location = useLocation();
  const history = useHistory();
  const [sortKey, setSortKey] = useState<string>("Saralash");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    setSortKey("");
    history.push(location.pathname);
    setPageNumber(1);
  }, [visible]);

  const dispatch = useDispatch();

  const { data, isLoading } = useGetWarehouseProduct(
    warehouse.id,
    pageNumber,
    sortKey,
    location.search.split("=")[1],
    searchValue
  );

  const { data: dataUsed, isLoading: isLoadingUsed } =
    useGetWarehouseUsedProduct(
      warehouse.id,
      pageNumber,
      sortKey,
      location.search.split("=")[1],
      searchValue
    );

  const handleCancel = () => {
    setVisible(!visible);
  };

  // const tableHeaderIconStyle = (sortName: string) => {
  //   const sortType = location.search.split("=")[1];
  //   if (sortName === sortKey) {
  //     if (sortType === "desc") {
  //       return {
  //         transform: "rotate(180deg)"
  //       };
  //     } else {
  //       return {
  //         transform: "rotate(0deg)"
  //       };
  //     }
  //   } else {
  //     return {
  //       opacity: "0.3"
  //     };
  //   }
  // };

  const tableHeaderSortIcon = (sortName: string) => {
    const sortType = location.search.split("=")[1];
    if (sortName === sortKey) {
      if (sortType === "desc") return <ArrowUpIcon />;
      else return <ArrowDownIcon />;
    } else return <ArrowDownUpIcon />;
  };

  const columns: ColumnsType<ProductDataModel> = [
    {
      title: "№",
      render: (_, __, index) => <p>{tableNumber(index, `${pageNumber}`)}</p>,
      width: 60,
      onHeaderCell: () => {
        return {
          onClick: () => {
            setSortKey("product_id");
            history.push(
              location.search
                ? `${location.pathname}?sort=${
                    location.search.split("=")[1] === "asc" ? "desc" : "asc"
                  }`
                : `${location.pathname}?sort=asc`
            );
          }
        };
      }
    },
    {
      title: (
        <div className="table_header">
          NOMI {tableHeaderSortIcon("product_name")}
        </div>
      ),
      // dataIndex: "product_name",
      render: (e: ProductDataModel) => (
        <>
          {e.product_name.length > 20
            ? e.product_name.slice(0, 20) + "..."
            : e.product_name}
        </>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => {
            setSortKey("product_name");
            history.push(
              location.search
                ? `${location.pathname}?sort=${
                    location.search.split("=")[1] === "asc" ? "desc" : "asc"
                  }`
                : `${location.pathname}?sort=asc`
            );
          }
        };
      }
    },
    {
      title: (
        <div className="table_header">
          KATEGORIYA {tableHeaderSortIcon("category_name")}
        </div>
      ),
      dataIndex: "category_name",
      onHeaderCell: () => {
        return {
          onClick: () => {
            setSortKey("category_name");
            history.push(
              location.search
                ? `${location.pathname}?sort=${
                    location.search.split("=")[1] === "asc" ? "desc" : "asc"
                  }`
                : `${location.pathname}?sort=asc`
            );
          }
        };
      }
    },
    {
      title: (
        <div className="table_header">
          MIQDORI {tableHeaderSortIcon("summa_count")}
        </div>
      ),
      render: (e: ProductDataModel) => {
        return (
          <p>
            {e.summa_count} {e.product_type_name}
          </p>
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            setSortKey("summa_count");
            history.push(
              location.search
                ? `${location.pathname}?sort=${
                    location.search.split("=")[1] === "asc" ? "desc" : "asc"
                  }`
                : `${location.pathname}?sort=asc`
            );
          }
        };
      }
    },
    {
      title: (
        <div className="table_header">SUMMA {tableHeaderSortIcon("summa")}</div>
      ),
      render: (e: ProductDataModel) => {
        return <p>{ToLocaleString(e.summa, "so'm")}</p>;
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            setSortKey("summa");
            history.push(
              location.search
                ? `${location.pathname}?sort=${
                    location.search.split("=")[1] === "asc" ? "desc" : "asc"
                  }`
                : `${location.pathname}?sort=asc`
            );
          }
        };
      }
    }
  ];

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Modal
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <p>
            <span onClick={handleCancel}>
              {type === 1 || type === 3 ? "Skladlar" : "Tarixlar"}
            </span>{" "}
            - {warehouse.name}
          </p>
          <p
            style={{
              marginRight: "40px"
            }}
          >
            Jami: {Number(warehouse.summa).toLocaleString()} so'm
          </p>
        </div>
      }
      onCancel={handleCancel}
      visible={visible}
      cancelText="Yopish"
      centered
      className={`${styles.warehouse} ${styles.warehouse_product}`}
      closeIcon={<CloseIcon />}
    >
      <div className={styles.top}>
        <div className={styles.left}></div>
        <div className={styles.right}>
          <Input
            placeholder="Nomi bo'yicha qidirish..."
            className={styles.search}
            onChange={changeSearch}
            value={searchValue}
            suffix={<SearchIcon />}
          />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.warehouses}>
          <Table
            dataSource={type === 1 || type === 3 ? data?.data : dataUsed?.data}
            columns={columns}
            scroll={{
              y: 210
            }}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Mahsulotlar mavjud emas!"
                />
              )
            }}
            loading={{
              spinning: type === 1 || type === 3 ? isLoading : isLoadingUsed,
              indicator: <LoadingOutlined />
            }}
            rowKey={(record: ProductDataModel) => record.product_id}
            onRow={(record) => {
              return {
                onClick: () => {
                  dispatch(isOpenProductGraph(true, record.product_id));
                }
              };
            }}
          />
          <PaginationComp
            paginationProps={{
              onChange: (page) => setPageNumber(page),
              total: type === 1 || type === 3 ? data?.total : dataUsed?.total,
              current:
                type === 1 || type === 3
                  ? data?.current_page
                  : dataUsed?.current_page,
              pageSize: 10
            }}
          />
        </div>
        <div className={styles.total}>
          <p>
            Mahsulotlar:{" "}
            {type === 1 || type === 3 ? data?.total : dataUsed?.total}{" "}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ProductsWarehouseModal;
