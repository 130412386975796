import React from 'react'
import { Table } from 'antd'
import { BrokerInfo } from '../../../../models/stats/credit-debit'
import TableDownIcon from '../../../../svg/TableDownIcon'
import BrokersOfSupplier from './BrokersOfSupplier'
const { Column } = Table

interface BrokerSupplierTableProps {
  data?: BrokerInfo[]
  isLoading: boolean
  type: string
  entryType: string
}

export const BrokerSupplierTable: React.FC<BrokerSupplierTableProps> = ({
  data,
  isLoading,
  type,
  entryType,
}) => {
  return (
    <Table
      pagination={false}
      loading={isLoading}
      dataSource={data}
      rowKey="id"
      expandable={
        entryType === 'debit'
          ? {
              expandedRowRender: (record, _, __, expanded) => {
                if (expanded) {
                  return (
                    <BrokersOfSupplier
                      id={record.id}
                      type={type}
                      entryType={entryType}
                    />
                  )
                } else return null
              },
              expandIconColumnIndex: 3,
              expandRowByClick: true,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (expanded)
                  return (
                    <span
                      onClick={(e) => onExpand(record, e)}
                      style={{
                        padding: '5px',
                        color: 'red',
                        cursor: 'pointer',
                      }}
                    >
                      <TableDownIcon
                        style={{ transform: 'rotate(180deg)', transition: '0.3s' }}
                      />
                    </span>
                  )
                return (
                  <span
                    onClick={(e) => onExpand(record, e)}
                    style={{ padding: '5px', color: 'red', cursor: 'pointer' }}
                  >
                    <TableDownIcon style={{ transition: '0.3s' }} />
                  </span>
                )
              },
            }
          : undefined
      }
      scroll={{ y: '60vh' }}
    >
      <Column
        title="#"
        dataIndex="name"
        key="id"
        align="center"
        render={(_, __, index) => index + 1}
        width={50}
      />
      <Column title="Nomi" dataIndex="name" key="name" align="left" />
      <Column
        title="Balans"
        dataIndex="balans"
        key="name"
        align="right"
        render={(balans) => {
          return <span>{parseInt(balans).toLocaleString()} so'm</span>
        }}
      />
    </Table>
  )
}
