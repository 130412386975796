import React from "react";

const SmallPictogramIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 5.67992V2.65325C14.6667 1.71325 14.24 1.33325 13.18 1.33325H10.4867C9.42667 1.33325 9 1.71325 9 2.65325V5.67325C9 6.61992 9.42667 6.99325 10.4867 6.99325H13.18C14.24 6.99992 14.6667 6.61992 14.6667 5.67992Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 13.18V10.4867C14.6667 9.42667 14.24 9 13.18 9H10.4867C9.42667 9 9 9.42667 9 10.4867V13.18C9 14.24 9.42667 14.6667 10.4867 14.6667H13.18C14.24 14.6667 14.6667 14.24 14.6667 13.18Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00065 5.67992V2.65325C7.00065 1.71325 6.57398 1.33325 5.51398 1.33325H2.82065C1.76065 1.33325 1.33398 1.71325 1.33398 2.65325V5.67325C1.33398 6.61992 1.76065 6.99325 2.82065 6.99325H5.51398C6.57398 6.99992 7.00065 6.61992 7.00065 5.67992Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00065 13.18V10.4867C7.00065 9.42667 6.57398 9 5.51398 9H2.82065C1.76065 9 1.33398 9.42667 1.33398 10.4867V13.18C1.33398 14.24 1.76065 14.6667 2.82065 14.6667H5.51398C6.57398 14.6667 7.00065 14.24 7.00065 13.18Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmallPictogramIcon;
