import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row, Spin, SpinProps, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TableLocale } from "antd/lib/table/interface";
import dayjs from "dayjs";
import React from "react";
import ReactToPrint from "react-to-print";
import { tableNumber } from "../../../helper/TableNumber";
import { OrderInterface } from "../../../models/parts/OrderInterface";
import { PartsInterface } from "../../../models/parts/PartsInterface";
import { DATE_FORMAT_TIME } from "../../../models/settings/dateFormat";
import {
  useAllSuppliers,
  useGetMyData,
  useOneOrderDetails,
} from "../../../queries/queries";
import PrinterIcon from "../../../svg/PrinterIcon";
import { SupplierType } from "../supplier/all/SupplierAllTypes";

import styles from "./order_detailed.module.scss";
import { ProductListTableItem } from "../../../redux/productListTypes";

type Props = {
  dataOne: PartsInterface;
};

const OrderDetailedTable: React.FC<Props> = ({ dataOne }) => {
  const { data, isLoading } = useOneOrderDetails(dataOne?.id!);
  const { data: myData, isLoading: isLoadingMyData } = useGetMyData();
  const { data: supplier, isLoading: isLoadingSupplier } = useAllSuppliers(
    1,
    "",
    1,
    true
  );

  // calculate column sum
  const calculateSum = (count: number, price: number, nds?: number) => {
    return count * price * (nds ? nds / 100 + 1 : 1);
  };

  // get one supplier data
  const getSupplierData = () => {
    return supplier?.filter(
      (item: SupplierType) => item.id === data?.supplier_id
    )[0] as SupplierType;
  };

  // visible nds column
  const visibleNdsColumn = (): boolean => {
    let state: boolean = false;

    data?.products?.forEach((item) => {
      if (item.nds) {
        state = true;
      }
    });

    return state;
  };

  // calculate nds sum
  const calculateNds = (count: number, nds: number, price: number) => {
    return count * (nds / 100) * price;
  };

  // calculate all sum
  // const allSum = (): number => {
  //   if (visibleNdsColumn()) {
  //     return data?.products?.reduce(
  //       (first, second) =>
  //         first +
  //         (second.count as number) *
  //         (second.price as number) *
  //         (second?.nds! / 100 + 1),
  //       0
  //     )!;
  //   } else
  //     return data?.products?.reduce(
  //       (first, second) =>
  //         first + (second.count as number) * (second.price as number),
  //       0
  //     )!;
  // };

  // products table columns
  const columnOrder: ColumnsType<any> = [
    {
      title: "№",
      render: (_, __, index) => tableNumber(index, String(1)),
    },
    {
      title: "Hазвание товаров",
      dataIndex: "name",
    },
    {
      title: "Количество",
      render: (e) => <>{Number(e.count).toLocaleString("ru")}</>,
    },
    {
      title: "Единица измерения",
      dataIndex: "product_type_name",
    },
    {
      title: "Цена",
      render: (e) => <>{Number(e.price).toLocaleString()}</>,
    },
    {
      title: "Общая стоимость",
      render: (record: OrderInterface) => (
        <span>
          {calculateSum(record.count, record.price).toLocaleString("ru")}
        </span>
      ),
    },
    visibleNdsColumn()
      ? {
          title: "НДС",
          children: [
            {
              title: "Ставка",
              render: (record: OrderInterface) => `${record.nds} %`,
            },
            {
              title: "Сумма",
              render: (record: OrderInterface) =>
                Number(
                  calculateNds(record.count, record.nds!, record.price).toFixed(
                    2
                  )
                ).toLocaleString("ru"),
            },
          ],
        }
      : {
          className: "d_n",
        },
    visibleNdsColumn()
      ? {
          title: "Общая стоимость с учётом НДС",
          render: (record: OrderInterface) => (
            <span>
              {calculateSum(
                record.count,
                record.price,
                record.nds!
              ).toLocaleString("ru")}{" "}
              so'm
            </span>
          ),
        }
      : { className: "d_n" },
  ];

  const loading: boolean | SpinProps | undefined = {
    spinning: isLoading || isLoadingMyData || isLoadingSupplier,
    indicator: <LoadingOutlined />,
  };

  const tableLocale: TableLocale | undefined = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Mahsulotlar mavjud emas!"
      />
    ),
  };

  // trigger
  const triggerElements = () => {
    return (
      <Button type="primary" className="printerButton">
        <PrinterIcon />
      </Button>
    );
  };

  // content
  const content = () => {
    const printElem = document.querySelector(`.${styles.order_detailed}`);
    return printElem;
  };

  // before print
  const handleBeforePrint = () => {
    document.querySelectorAll(".printerButton").forEach((item: any) => {
      item.style.display = "none";
    });
  };

  // after print
  const handleAfterPrint = () => {
    document.querySelectorAll(".printerButton").forEach((item: any) => {
      item.style.display = "flex";
    });
  };

  // table summaryu
  const tableSummary = (data: readonly ProductListTableItem[]) => {
    const count = data?.reduce((sum, current) => sum + +current.count, 0);
    const allPrice = data?.reduce(
      (sum, current) => sum + +calculateSum(+current.count, +current.price),
      0
    );
    const nds = data?.reduce(
      (sum, current) =>
        sum + calculateNds(+current.count, current.nds!, +current.price),
      0
    );
    const allPriceNds = data?.reduce(
      (sum, current) =>
        sum + calculateSum(+current.count, +current.price, current.nds!),
      0
    );

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell
            className={styles.bold}
            index={0}
          ></Table.Summary.Cell>
          <Table.Summary.Cell className={styles.bold} index={1}>
            Итого:
          </Table.Summary.Cell>
          <Table.Summary.Cell className={styles.bold} index={2}>
            {count.toLocaleString("ru")}
          </Table.Summary.Cell>
          <Table.Summary.Cell className={styles.bold} index={3}>
            x
          </Table.Summary.Cell>
          <Table.Summary.Cell className={styles.bold} index={4}>
            x
          </Table.Summary.Cell>
          <Table.Summary.Cell className={styles.bold} index={5}>
            {allPrice.toLocaleString("ru")}
          </Table.Summary.Cell>
          {visibleNdsColumn() && (
            <>
              <Table.Summary.Cell className={styles.bold} index={6}>
                x
              </Table.Summary.Cell>
              <Table.Summary.Cell className={styles.bold} index={7}>
                {Number(nds.toFixed(2)).toLocaleString("ru")}
              </Table.Summary.Cell>
              <Table.Summary.Cell className={styles.bold} index={8}>
                {allPriceNds.toLocaleString("ru")} so'm
              </Table.Summary.Cell>
            </>
          )}
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  // table Footer
  // const tableFooter = (): JSX.Element => {
  //   return (
  //     <Row gutter={5}>
  //       <Col span={18}>
  //         <div className={`${styles.bold} ${styles.footer_text}`}>Итого:</div>
  //       </Col>
  //       <Col span={6}>
  //         <div className={`${styles.sum} ${styles.bold}`}>
  //           {allSum()?.toLocaleString('ru')} so'm
  //         </div>
  //       </Col>
  //     </Row>
  //   );
  // };

  return (
    <Spin {...loading}>
      <div className={styles.order_detailed}>
        <Row justify="space-between">
          <Col span={12}>
            <h4 className={styles.text}>
              <span className={styles.bold}>ID:</span>#{data && data.id}
            </h4>
          </Col>
          <Col span={1}>
            <ReactToPrint
              onBeforeGetContent={handleBeforePrint}
              onAfterPrint={handleAfterPrint}
              onPrintError={handleAfterPrint}
              trigger={triggerElements}
              content={content}
            />
          </Col>
        </Row>
        <div className={styles.top}>
          <h2>счёт-фактура</h2>
          {data?.number && (
            <h4>
              № <span className={styles.bold}>{data?.number}</span>
            </h4>
          )}
        </div>
        <Row gutter={20} className={styles.info} justify="center">
          <Col span={11}>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Поставщик:</span>
              <span>{myData?.COMPANY}</span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Адрес:</span>
              <span>{myData?.ADDRESS}</span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Номер поставщика (ИНН):</span>
              <span>{myData?.INN}</span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Код плательщика НДС:</span>
              <span>{myData?.NDS}</span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Расчётный счёт:</span>
              <span>{myData?.SHOT}</span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>МФО банка:</span>
              <span>{myData?.MFO}</span>
            </h4>
          </Col>
          <Col span={11}>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Поставщик:</span>
              <span>{getSupplierData()?.name}</span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Адрес:</span>
              <span>
                {getSupplierData()?.address ? getSupplierData()?.address : "-"}
              </span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Номер поставщика (ИНН):</span>
              <span>
                {getSupplierData()?.inn ? getSupplierData()?.inn : "-"}
              </span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Код плательщика НДС:</span>
              <span>
                {getSupplierData()?.nds ? getSupplierData()?.nds : "-"}
              </span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>Расчётный счёт:</span>
              <span>
                {getSupplierData()?.shot ? getSupplierData()?.shot : "-"}
              </span>
            </h4>
            <h4 className={`${styles.text} ${styles.text_flex}`}>
              <span className={styles.bold}>МФО банка:</span>
              <span>
                {getSupplierData()?.mfo ? getSupplierData()?.mfo : "-"}
              </span>
            </h4>
          </Col>
        </Row>
        <Table
          loading={loading}
          pagination={false}
          locale={tableLocale}
          // footer={tableFooter}
          columns={columnOrder}
          className={styles.table}
          dataSource={data?.products}
          rowKey={(record) => record.id}
          summary={tableSummary}
        />
        <div className={styles.result}></div>
        <Row className={styles.check}>
          <Col span={15}>
            <h4>
              <span className={styles.bold}>Руководитель:</span>
            </h4>
            <h4>
              <span className={styles.bold}>Главный бухгалтер:</span>
            </h4>
            <h4>
              <span className={styles.bold}>Товар отпустил:</span>
            </h4>
          </Col>
          <Col span={9}>
            <h4>
              <span className={styles.bold}>Руководитель:</span>
            </h4>
            <h4>
              <span className={styles.bold}>Главный бухгалтер:</span>
            </h4>
            <h4>
              <span className={styles.bold}>Получил:</span>
            </h4>
          </Col>
        </Row>
        <Row className={styles.owner} justify="space-between">
          <Col span={6}>
            <div className={styles.card}>
              <div className={styles.title}>
                <h4>№ {data?.id}</h4>
                <h4>{dayjs(data?.date).format(DATE_FORMAT_TIME)}</h4>
              </div>
              <h3 className={styles.submit}>ОТПРАВЛЕНО</h3>
              <h4 className={styles.text}>
                <span>оператор:</span>
                <span className={styles.bold}>uyqur.uz</span>
              </h4>
            </div>
          </Col>
          <Col span={6}>
            <div className={`${styles.card} ${styles.card_2}`}>
              <div className={styles.title}>
                <h4>№ {data?.id}</h4>
                <h4>{dayjs(data?.date).format(DATE_FORMAT_TIME)}</h4>
              </div>
              <h3 className={styles.submit}>ПОДТВЕРЖДЕН</h3>
              <h4 className={styles.text}>
                <span className={styles.bold}>{getSupplierData()?.name}</span>
              </h4>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default OrderDetailedTable;
