import React from "react";

const RowVerticalIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2673 9.5H2.73398C1.73398 9.5 1.33398 9.92667 1.33398 10.9867V13.68C1.33398 14.74 1.73398 15.1667 2.73398 15.1667H13.2673C14.2673 15.1667 14.6673 14.74 14.6673 13.68V10.9867C14.6673 9.92667 14.2673 9.5 13.2673 9.5Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2673 1.83337H2.73398C1.73398 1.83337 1.33398 2.26004 1.33398 3.32004V6.01337C1.33398 7.07337 1.73398 7.50004 2.73398 7.50004H13.2673C14.2673 7.50004 14.6673 7.07337 14.6673 6.01337V3.32004C14.6673 2.26004 14.2673 1.83337 13.2673 1.83337Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RowVerticalIcon;
