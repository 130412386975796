import { paymentAndValutaReducer } from "./partPaymentAndValutaReducer";
import { combineReducers } from "redux";
import settingsReducer from "./settingsReducer";
import searchHeaderReducer from "./searchHeaderReducer";
import { userReducer } from "./userReducer";
import { productListReducer } from "./productListReducer";
import productGraphReducer from "./productGraphReducer";
import warehouseIdReducer from "./warehouseIdReducer";
import allSelectWarehouseReducer from "./allSelectWarehouseReducer";
import pictureVisibleReducer from "./pictureVisibleReducer";
import orderIdReducer from "./orderIdReducer";
import expenceCopyReducer from "./expenceCopyReducer";

export const rootReducer = combineReducers({
  settings: settingsReducer,
  searchHeader: searchHeaderReducer,
  user: userReducer,
  productList: productListReducer,
  productGraph: productGraphReducer,
  warehouseId: warehouseIdReducer,
  allSelectWarehouse: allSelectWarehouseReducer,
  pictureVisible: pictureVisibleReducer,
  orderIdReducer: orderIdReducer,
  expenceCopyReducer: expenceCopyReducer,
  paymentAndValutaReducer: paymentAndValutaReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
