import {
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Rate,
  Row,
  Select,
  Switch,
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPaymentAndValuta,
  setProductList,
  setValuta,
} from "../../../redux/actions";
import { RootState } from "../../../redux/rootReducer";
import { selectFilter } from "../../../service/Helpers";
import { CreateOrderAllSelects, OneOrderDataType } from "./CreateOrderTypes";

type Props = {
  form: FormInstance;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data?: CreateOrderAllSelects;
  isLoading: boolean;
  orderData?: OneOrderDataType;
  isOrderDataLoading: boolean;
  orderId: string;
  nds: boolean;
  setNds: React.Dispatch<React.SetStateAction<boolean>>;
  ndsValue?: number;
  setNdsValue: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

const NdsModal: React.FC<Props> = ({
  open,
  setOpen,
  data,
  isLoading,
  orderData,
  isOrderDataLoading,
  orderId,
  nds,
  form,
  setNds,
  ndsValue,
  setNdsValue,
}) => {
  const dispatch = useDispatch();
  const orderIdRed = useSelector(
    (state: RootState) => state.orderIdReducer.copy
  );
  const products = useSelector(
    (state: RootState) => state.productList.products
  );
  const paymentAndValuta = useSelector(
    (state: RootState) => state.paymentAndValutaReducer
  );

  const changeNds = (e: boolean) => {
    if (e) {
      setNdsValue(15);
      dispatch(
        setProductList(
          products.map((item) => ({
            ...item,
            nds: 15,
          }))
        )
      );
    } else {
      dispatch(
        setProductList(products.map((item) => ({ ...item, nds: null })))
      );
    }
    setNds(e);
  };

  const changeNdsValue = (e: number) => {
    setNdsValue(e);
    dispatch(
      setProductList(
        products.map((item) => ({
          ...item,
          nds: e,
        }))
      )
    );
  };

  return (
    <Modal
      title="Qo’shimcha sozlamalar"
      visible={open}
      className="nds"
      onCancel={() => setOpen(false)}
      onOk={() => setOpen(false)}
      centered
      cancelText="Chiqish"
      okText="Saqlash"
    >
      <Row gutter={16} justify="space-between" align="middle">
        <Col span={12}>
          <Item
            label="To'lov turi"
            name={"payment_type_id"}
            rules={[{ required: true, message: "To'lov turini tanlang" }]}
          >
            <Select
              disabled={orderData?.transfers === "1" && !orderIdRed}
              placeholder="To'lov turini kiriting"
              showSearch
              filterOption={selectFilter}
              // value={paymentAndValuta.payment_type_id}
              loading={isLoading}
              onChange={(e) => {
                dispatch(
                  setPaymentAndValuta(paymentAndValuta.valuta_id, Number(e))
                );
              }}
            >
              {data?.payment_types.map((paymentType) => (
                <Option key={paymentType.id} value={paymentType.id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: paymentType.color,
                        borderRadius: "50%",
                        marginRight: "5px",
                      }}
                    />
                    {paymentType.name}
                  </div>
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Valyuta"
            name="valuta_id"
            rules={[{ required: true, message: "Valyutani tanlang" }]}
          >
            <Select
              disabled={orderData?.transfers === "1" && !orderIdRed}
              showSearch
              loading={isLoading}
              // value={paymentAndValuta.valuta_id}
              filterOption={selectFilter}
              placeholder="Valyuta turini kiriting"
              onChange={(value) => {
                const valuta = data?.valuties.find((item) => item.id === value);
                dispatch(
                  setValuta({
                    name: valuta?.name!,
                    id: valuta?.id!,
                    price: valuta?.price!,
                  })
                );
                dispatch(
                  setPaymentAndValuta(
                    Number(value),
                    paymentAndValuta.payment_type_id
                  )
                );
              }}
            >
              {data?.valuties.map((valuta) => (
                <Option key={valuta.id} value={valuta.id}>
                  {valuta.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={24}>
          <Item name="number" label="Yo'l varaqasi raqami">
            <Input placeholder="1234" />
          </Item>
        </Col>
        <Col>
          <Item initialValue={5} label="Partyani baholang" name="rating">
            <Rate style={{ margin: "0 auto" }} />
          </Item>
        </Col>
        <Col span={24}>
          <Item name="description">
            <TextArea placeholder="Izoh kiriting" />
          </Item>
        </Col>
        <Col span={24}>
          <div className="status">
            <h4>NDSni hisoblash</h4>
            <div className="d_f cg-10 ai_c">
              <Switch checked={nds} onChange={changeNds} />
              <Select
                value={ndsValue}
                onChange={changeNdsValue}
                placeholder="15%"
                disabled={!nds}
              >
                <Option value={15}>15 %</Option>
                <Option value={12}>12 %</Option>
                <Option value={0}>0 %</Option>
              </Select>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default NdsModal;
