import { Reducer } from "redux";
import { ProductInterface } from "../models/interfaces/ProductInterface";
import { ValutaInterface } from "../models/interfaces/ValutaInterface";
import { ProductListTableItem } from "./productListTypes";
import {
  PRODUCT_LIST_ADD,
  PRODUCT_LIST_ADD_CHECKLIST,
  PRODUCT_LIST_BUILDING_FOR_ALL,
  PRODUCT_LIST_COUNT_CHANGE,
  PRODUCT_LIST_DATE_FOR_ALL,
  PRODUCT_LIST_FILTER,
  PRODUCT_LIST_NDS,
  PRODUCT_LIST_PRICE_CHANGE,
  PRODUCT_LIST_REMOVE,
  PRODUCT_LIST_RESET,
  PRODUCT_LIST_SET_BUILDING,
  PRODUCT_LIST_SET_BUILDING_ID,
  PRODUCT_LIST_SET_CHECKED,
  PRODUCT_LIST_SET_CHECKLIST,
  PRODUCT_LIST_SET_COMMENT,
  PRODUCT_LIST_SET_PRODUCTS,
  PRODUCT_LIST_SET_VALUTA,
} from "./types";

type ProductListState = {
  products: ProductListTableItem[];
  checkList: ProductInterface[];
  unfilteredCheckList: ProductInterface[];
  valuta: ValutaInterface;
  currentBuildingId: number;
};
const initialState: ProductListState = {
  products: [],
  checkList: [],
  // need it for filtering product list and then set it back to initial state
  unfilteredCheckList: [],
  valuta: { name: "", price: 0, id: 0 },
  currentBuildingId: 0,
};

export const productListReducer: Reducer<ProductListState> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    ///////////////////////////////
    case PRODUCT_LIST_ADD:
      const copy = [...state.products];
      copy.push(payload.product);
      const checklistCopy = [...state.checkList];
      //
      for (let i = 0; i < checklistCopy.length; i++) {
        if (checklistCopy[i].id === payload.product.id) {
          checklistCopy[i].checked = true;
          break;
        }
      }
      // unfiltered and filtered lists are equal and does not need to iterate
      // throught both lists
      if (state.checkList.length === state.unfilteredCheckList.length) {
        return {
          ...state,
          products: copy,
          checkList: checklistCopy,
          unfilteredCheckList: checklistCopy,
        };
      }
      //
      const unfilteredCheckListCopy = [...state.unfilteredCheckList];
      for (let i = 0; i < unfilteredCheckListCopy.length; i++) {
        if (unfilteredCheckListCopy[i].id === payload.product.id) {
          unfilteredCheckListCopy[i].checked = true;
          break;
        }
      }

      return {
        ...state,
        products: copy,
        checkList: checklistCopy,
        unfilteredCheckList: unfilteredCheckListCopy,
      };
    ////////////////////////////////
    case PRODUCT_LIST_SET_PRODUCTS:
      const checkListCopy0 = [...state.checkList];
      // const unfilteredCheckListCopy0 = [...state.unfilteredCheckList]
      for (let i = 0; i < checkListCopy0.length; i++) {
        for (let j = 0; j < payload.products.length; j++) {
          if (checkListCopy0[i].id === payload.products[j].id) {
            checkListCopy0[i].checked = true;
          }
        }
      }

      return {
        ...state,
        products: [...payload.products],
        checkList: checkListCopy0,
        unfilteredCheckList: checkListCopy0,
      };

    ///////////////////////////////
    case PRODUCT_LIST_ADD_CHECKLIST:
      const checkListCopy = [...state.checkList];
      const unfilteredCheckListCopy3 = [...state.unfilteredCheckList];

      checkListCopy.unshift(payload.product);
      unfilteredCheckListCopy3.unshift(payload.product);

      return {
        ...state,
        checkList: checkListCopy,
        unfilteredCheckList: unfilteredCheckListCopy3,
      };

    ///////////////////////////////
    case PRODUCT_LIST_REMOVE:
      const copy2 = [...state.products];
      const newProducts = copy2.filter((item) => item.id !== payload);

      const checklistCopy2 = [...state.checkList];
      for (let i = 0; i < checklistCopy2.length; i++) {
        if (checklistCopy2[i].id === payload) {
          checklistCopy2[i].checked = false;
          break;
        }
      }
      // unfiltered and filtered lists are equal and does not need to iterate
      // throught both lists
      if (state.checkList.length === state.unfilteredCheckList.length) {
        return {
          ...state,
          products: newProducts,
          checkList: checklistCopy2,
          unfilteredCheckList: checklistCopy2,
        };
      }
      //
      const unfilteredCheckListCopy2 = [...state.unfilteredCheckList];
      for (let i = 0; i < unfilteredCheckListCopy2.length; i++) {
        if (unfilteredCheckListCopy2[i].id === payload) {
          unfilteredCheckListCopy2[i].checked = false;
          break;
        }
      }

      return {
        ...state,
        products: newProducts,
        checkList: checklistCopy2,
        unfilteredCheckList: unfilteredCheckListCopy2,
      };

    ///////////////////////////////
    case PRODUCT_LIST_SET_CHECKLIST:
      return { ...state, checkList: payload, unfilteredCheckList: payload };

    ///////////////////////////////
    case PRODUCT_LIST_FILTER:
      if (payload.length === 0) {
        return { ...state, checkList: state.unfilteredCheckList };
      }

      const filtered = state.unfilteredCheckList.filter((item) => {
        // console.log(item.name, payload)
        return item.name.toLowerCase().includes(payload.toLowerCase());
      });
      return { ...state, checkList: filtered };

    ///////////////////////////////
    case PRODUCT_LIST_COUNT_CHANGE:
      const copy3 = state.products.slice();
      if (isNaN(payload.count)) {
        return state;
      }
      if (payload.count === "") {
        copy3[payload.index].count = "";
        return { ...state, products: copy3 };
      }
      copy3[payload.index].count = payload.count;
      return { ...state, products: copy3 };

    case PRODUCT_LIST_SET_COMMENT:
      const copy8 = state.products.slice();

      copy8[payload.index].comment = payload?.comment;
      return { ...state, products: copy8 };

    case PRODUCT_LIST_NDS:
      const copy9 = state.products.slice();

      copy9[payload.index].nds = payload?.number;
      return { ...state, products: copy9 };

    ///////////////////////////////
    case PRODUCT_LIST_PRICE_CHANGE:
      const copy4 = state.products.slice();

      if (isNaN(payload.price)) {
        return state;
      }
      if (payload.price === "") {
        copy4[payload.index].price = "";
        return { ...state, products: copy4 };
      }

      copy4[payload.index].price = payload.price;
      return { ...state, products: copy4 };

    ///////////////////////////////
    case PRODUCT_LIST_SET_VALUTA:
      return { ...state, valuta: payload };
    ///////////////////////////////
    case PRODUCT_LIST_SET_BUILDING_ID:
      return { ...state, currentBuildingId: payload, products: [] };

    case PRODUCT_LIST_SET_BUILDING:
      const copy1 = [...state.products];
      copy1.splice(payload.index, 1, {
        ...copy1[payload.index],
        building_id: payload.building_id,
      });
      return { ...state, products: copy1 };

    case PRODUCT_LIST_SET_CHECKED:
      const copy5 = [...state.products];
      for (let i = 0; i < copy5.length; i++) {
        const element = copy5[i];
        if (element.id === payload.id) {
          copy5[i].check = payload.check;
          copy5[i].last_price = payload.last_price;
          copy5[i].building_name = payload.building_name;
        }
      }
      return { ...state };

    case PRODUCT_LIST_BUILDING_FOR_ALL:
      const copy6 = [...state.products];
      for (let i = 0; i < copy6.length; i++) {
        copy6[i].building_id = payload;
      }
      return { ...state, products: copy6 };

    case PRODUCT_LIST_DATE_FOR_ALL:
      const copy7 = [...state.products];
      for (let i = 0; i < copy7.length; i++) {
        copy7[i].date = payload;
      }
      return { ...state, products: copy7 };

    case PRODUCT_LIST_RESET:
      return { ...initialState };

    default:
      return state;
  }
};
