import { Checkbox, Modal, Select, Table } from "antd";
import React, { useState, useEffect } from "react";
import { tableNumber } from "../../../helper/TableNumber";
import {
  useCategoryAll,
  useOrderProductList,
  useProductSiAll,
} from "../../../queries/queries";
import { useProductMutation } from "../../../queries/mutations";
import { UnverifiedProduct } from "./CreateOrder";
import { ProductCreateInterface } from "../../../models/interfaces/ProductCreateInterface";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { addToList } from "../../../redux/actions";
import { FormInstance } from "antd/es/form/Form";
import { toTrim } from "../../../helper/toTrim";

type Props = {
  setState: React.Dispatch<React.SetStateAction<UnverifiedProduct | undefined>>;
  state: UnverifiedProduct | undefined;
  form: FormInstance;
};

const { Column } = Table;
const { Option } = Select;

interface ProductType extends ProductCreateInterface {
  checked?: boolean;
}

const UnverifiedFiles: React.FC<Props> = ({ state, setState, form }) => {
  const { data: category } = useCategoryAll();
  const { data: productSi } = useProductSiAll();
  const createProduct = useProductMutation();
  const [products, setProducts] = useState<ProductType[]>();
  const dispatch = useDispatch();
  const checkList = useSelector(
    (state: RootState) => state.productList.checkList
  );
  const allTableProducts = useSelector(
    (state: RootState) => state.productList.products
  );

  const { data: allProducts, isLoading } = useOrderProductList();

  const onFinish = () => {
    const filterRows: any[] = checkList
      .map((item) => {
        if (
          state?.data?.some(
            (obj: any) =>
              String(obj["Mahsulot nomi"]).toUpperCase() ===
              item?.name?.toUpperCase()
          ) &&
          !allTableProducts?.some(
            (obj: any) =>
              String(obj?.name).toUpperCase() === item?.name?.toUpperCase()
          )
        ) {
          return {
            ...item,
            check: null,
            last_price: null,
            date: form?.getFieldValue("date"),
            building_id: form?.getFieldValue("building_id"),
            count: state?.data?.filter(
              (row) => row["Mahsulot nomi"] === item?.name
            )[0]["Miqdori"],
            price: state?.data?.filter(
              (row) => row["Mahsulot nomi"] === item?.name
            )[0]["Birlik narxi"],
            maxCount: item.count,
          };
        }
      })
      .filter((item) => item);

    if (filterRows && filterRows?.length > 0) {
      filterRows.forEach((item) => {
        dispatch(
          addToList({
            ...item,
          })
        );
      });

      setState((all) => {
        const flData = all?.data
          ?.map((item: any) => {
            if (
              !products?.filter((pr) => pr?.name === item["Mahsulot nomi"])[0]
                ?.checked
            ) {
              return item;
            } else return undefined;
          })
          ?.filter((item) => item);
        return { isOpen: false, data: flData };
      });
    }
  };

  useEffect(() => {
    setProducts(
      state?.data?.map((item: any) => ({
        name: item["Mahsulot nomi"],
        category_id: 1,
        product_type_id: 1,
        checked: false,
      }))
    );
  }, [state]);

  const checkedProduct = (e: CheckboxChangeEvent, elem: ProductType) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      createProduct
        .mutateAsync(elem)
        .then(() => {
          setProducts((state) => {
            return state?.map((item) => {
              if (item?.name === elem.name) {
                return {
                  ...item,
                  checked: true,
                };
              } else return item;
            });
          });
        })
        .catch(() => {
          setProducts((state) => {
            return state?.map((item) => {
              if (item?.name === elem.name) {
                return {
                  ...item,
                  checked: false,
                };
              } else return item;
            });
          });
        });
    }
  };

  const selectValue = (
    name: string,
    key: "product_type_id" | "category_id"
  ) => {
    return products?.filter((item) => item.name === name)[0][key];
  };

  const changeSelect = (
    id: number,
    name: string,
    key: "product_type_id" | "category_id"
  ) => {
    setProducts((state) => {
      return state?.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            [key]: id,
          };
        }
        return item;
      });
    });
  };

  const disabledCheckbox = (name: string): boolean => {
    if (allProducts?.some((item) => item.name === name)) return true;
    else return false;
  };

  return (
    <Modal
      visible={state?.isOpen}
      centered
      onCancel={() => setState({ isOpen: false, data: state?.data })}
      onOk={onFinish}
      okText="Saqlash"
      cancelText="Chiqish"
      title="Tasdiqlanmagan mahsulotlar ro'yhati"
      className="unvModal"
    >
      <Table
        pagination={false}
        dataSource={products}
        rowKey={(row, index) => `${row.name}-${index}`}
        loading={createProduct.isLoading || isLoading}
      >
        <Column
          title="№"
          render={(_, __, index) => <>{tableNumber(index, "1")}</>}
        />
        <Column title="Mahsulot nomi" dataIndex="name" />
        <Column
          title="Kategoriyasi"
          render={(product) => (
            <Select
              onChange={(e) => changeSelect(e, product?.name, "category_id")}
              value={selectValue(product?.name, "category_id")}
              placeholder="Mahsulot kategoriyasi"
              disabled={disabledCheckbox(product?.name)}
            >
              {category?.map((item, index) => (
                <Option key={index} value={item?.id!}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          )}
        />
        <Column
          title="Birligi"
          render={(product) => (
            <Select
              onChange={(e) =>
                changeSelect(e, product?.name, "product_type_id")
              }
              value={selectValue(product?.name, "product_type_id")}
              placeholder="Mahsulot birligi"
              disabled={disabledCheckbox(product?.name)}
            >
              {productSi?.map((item, index) => (
                <Option key={index} value={item?.id!}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          )}
        />
        <Column
          title="Tasdiqlash"
          render={(product) => (
            <Checkbox
              checked={
                products?.filter((item) => item.name === product?.name)[0]
                  ?.checked
              }
              onChange={(e) => checkedProduct(e, product)}
              disabled={disabledCheckbox(product?.name)}
            />
          )}
        />
      </Table>
    </Modal>
  );
};

export default UnverifiedFiles;
