import React, { useEffect, useState } from "react";
import "./Settings.scss";
import SettingsMenu from "./SettingsMenu";
import Payment from "./payment/PaymentSetting";
import Expense from "./expense/Expense";
import User from "./user/User";
import Objects from "./object/Objects";
import Product from "./product/Product";
import Valuta from "./valuta/Valuta";
import { Button } from "antd";
import { isOpenSettingEditModal } from "../../../redux/actions";
import SearchHeader from "../universialComponents/SearchHeader";
import { useDispatch } from "react-redux";
import { Plus } from "../../../svg/Plus";
import OpenObjectHistory from "../objectHistory/OpenObjectHistory";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import Integration from "./integration/Integration";
import { useHistory } from "react-router-dom";
import Category from "./category/Category";
import HistoryPage from "../history/HistoryPage";

const Settings = () => {
  const [activePage, setActivePage] = useState(5);
  const dispatch = useDispatch();
  const history = useHistory();
  useDocumentTitle("Sozlamalar");

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    if (params.get("key")) {
      setActivePage(9);
    }
  }, []);

  const showActivePage = () => {
    switch (activePage) {
      case 1:
        return <Category />;
      case 2:
        return <Payment />;
      case 3:
        return <Expense />;
      case 4:
        return <User />;
      case 5:
        return <Objects />;
      case 6:
        return <Product />;
      case 7:
        return <Valuta />;
      case 8:
        return <Integration />;
      case 9:
        return <HistoryPage />;
      default:
        return <div>Ups something wrong :(</div>;
    }
  };

  const createButtonTitle = () => {
    switch (activePage) {
      case 1:
        return "Kategoriya";
      case 2:
        return "To’lov turi";
      case 3:
        return "Xarajat turi";
      case 4:
        return "Foydalanuvchi";
      case 5:
        return "Ob’yekt";
      case 6:
        return "Mahsulotlar";
      case 7:
        return "Valyuta";
      case 8:
        return "Integratsiya";
      default:
        return "Element qo'shish";
    }
  };

  const inputPlaceHolder = () => {
    switch (activePage) {
      case 1:
        return "Nomi bo'yicha qidirish";
      case 2:
        return "Nomi bo'yicha qidirish";
      case 3:
        return "Nomi bo'yicha qidirish";
      case 4:
        return "Nomi bo'yicha qidirish";
      case 5:
        return "Nomi bo'yicha qidirish";
      case 6:
        return "Nomi bo'yicha qidirish";
      case 7:
        return "Nomi bo'yicha qidirish";
      case 8:
        return "";
      case 9:
        return "";
      default:
        return "qidirish...";
    }
  };

  const createdBtn = () => {
    if (
      activePage === 8 ||
      activePage === 7 ||
      activePage === 6 ||
      activePage === 9
    )
      return null;
    return (
      <Button className={"buttonCont"} type={"primary"}>
        <div
          className={"button"}
          onClick={() => {
            dispatch(isOpenSettingEditModal(true));
          }}
        >
          <Plus className="icon" />
          <span>{createButtonTitle()}</span>
        </div>
      </Button>
    );
  };

  return (
    <>
      <div className={"settings"}>
        <SearchHeader
          title={"Sozlamalar"}
          buttons={
            <div
              style={{
                display: "flex",
              }}
            >
              {/*{activePage === 6 && <OpenButtonComp id={0} type="overall"/>}*/}
              {activePage === 5 && <OpenObjectHistory />}
              {createdBtn()}
            </div>
          }
          inputPlaceholder={inputPlaceHolder()}
        />
        <hr className={"beforeHr"} />
        <SettingsMenu activePage={activePage} setActivePage={setActivePage} />
        <hr className={"afterHr"} />
        {showActivePage()}
      </div>
    </>
  );
};

export default Settings;
