import { Col, Select } from "antd";
import dayjs from "rc-picker/node_modules/dayjs";
import React, { useState } from "react";
import { useBuildingStats } from "../../../../queries/queries";
import { DatePicker } from "../../../../service/DatePicker";
import { dateOptions, DateType } from "../expenseStats/ExpenseStats";
import ChartObjectExpense from "./ChartObjectExpense";
const { Option } = Select;
interface ObjectExpenseStatsProps {}

const options = [
  {
    value: "all",
    label: "Umumiy",
  },
  // {
  //   value: "payment",
  //   label: "To'langan"
  // },
  // {
  //   value: "no_payment",
  //   label: "To'lanmagan"
  // },
  {
    value: "expense",
    label: "Xarajat",
  },
  {
    value: "useds",
    label: "Mahsulot chiqimi",
  },
];

const ObjectExpenseStats: React.FC<ObjectExpenseStatsProps> = () => {
  const [dateType, setDateType] = useState<DateType>("day");
  const [date, setDate] = useState([
    dayjs().startOf("month").format("YYYY-MM-DD"),
    dayjs().endOf("month").format("YYYY-MM-DD"),
  ]);
  const [expenseType, setExpenseType] = useState(options[0].value);
  const { data: buildings, isLoading } = useBuildingStats(
    date,
    dateType,
    expenseType
  );

  // handle date
  const handleDate = (value: string) => {
    const today = dayjs();
    if (value === "month") {
      setDate([
        today.set("month", 0).format("YYYY-MM"),
        today.set("month", 11).format("YYYY-MM"),
      ]);
    } else if (value === "year") {
      setDate([today.format("YYYY"), today.format("YYYY")]);
    }
  };

  return (
    <Col span="12" className="stats_products cover ObjectStats">
      <p>Obyektdagi xarajatlar</p>
      <div className="cover_head objects">
        <Select
          style={{ width: "7rem", marginRight: "1rem" }}
          value={expenseType}
          onChange={(value) => setExpenseType(value)}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>

        <Select
          style={{ width: "7rem", marginRight: "1rem" }}
          value={dateType}
          onChange={(value) => setDateType(value)}
          onSelect={(value) => handleDate(value)}
        >
          {dateOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {dateType === "year" ? (
          <DatePicker.RangePicker
            value={[dayjs(date[0]), dayjs(date[1])]}
            onChange={(_m, date) => {
              setDate(date);
            }}
            picker="year"
            format="DD.MM.YYYY"
          />
        ) : dateType === "month" ? (
          <DatePicker.RangePicker
            value={[dayjs(date[0]), dayjs(date[1])]}
            onChange={(_m, date) => {
              setDate(date);
            }}
            picker="month"
            format="DD.MM.YYYY"
          />
        ) : (
          <DatePicker.RangePicker
            // defaultValue={dayjs()}
            onChange={(_m, date) => {
              setDate(date);
            }}
            picker="date"
            format="DD.MM.YYYY"
          />
        )}
      </div>
      <div className="ObjectStats_chart">
        <ChartObjectExpense isLoading={isLoading} data={buildings || []} />
      </div>
    </Col>
  );
};

export default ObjectExpenseStats;
//
