import {Button, Form, Input, Modal} from "antd";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import {isOpenSettingEditModal} from "../../../../redux/actions";
import {useValutaMutation} from "../../../../queries/mutations";
import {useCurrentValuta} from "../../../../queries/queries";
import {LoadingOutlined} from "@ant-design/icons";
import {ValutaInterface} from "../../../../models/interfaces/ValutaInterface";

interface CUInterface {
  currentValutaId: number
  setValutaId: React.Dispatch<React.SetStateAction<number>>
  page: number
}

const CreateAndEditModal: React.FC<CUInterface> = ({setValutaId, currentValutaId, page}) => {

  const cu = useValutaMutation(page)
  const {data, isLoading} = useCurrentValuta(currentValutaId)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const isModalOpen = useSelector(
    (state: RootState) => state.settings.openCreateModal
  )

  const cancelFunc = () => {
    dispatch(isOpenSettingEditModal(false))
    setValutaId(0)
    form.resetFields()
  }

  const onFinish = async (values: ValutaInterface) => {
    if (currentValutaId) {
      await cu.mutateAsync({
        id: currentValutaId,
        name: values.name,
        // @ts-ignore
        price: values?.price.replace(/\s/g, '') * 1
      })
    } else {
      await cu.mutateAsync({
        name: values.name,
        // @ts-ignore
        price: values?.price.replace(/\s/g, '') * 1
      })
    }
    cancelFunc()
  }

  useEffect(() => {
    if (currentValutaId > 0) {
      form.setFieldsValue({
        name: data?.name,
        price: data?.price.toLocaleString('ru'),
      })
    }
  }, [currentValutaId, data, form])

  const ModalTitle = (
    <>
      <span style={{display: 'inline-block', marginRight: '1rem'}}>
        Valyuta yaratish
      </span>
      {isLoading && <LoadingOutlined/>}
    </>
  )

  return (
    <Modal
      className={'editModal'}
      visible={isModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
    >
      <Form
        form={form}
        name="paymentEdit"
        onFinish={onFinish}
        autoComplete="off"
      >
        <label htmlFor={'name'}>
          Nomi
        </label>
        <Form.Item
          name="name"
          rules={[{required: true, message: ''}]}
        >
          <Input className={'input'} id={'name'}/>
        </Form.Item>
        <label htmlFor={'name'}>
          Qiymati
        </label>
        <Form.Item
          name="price"
          rules={[{required: true, message: ''}]}
        >
          <Input
            onChange={(e) => {
              let value = e.target.value
              value = value.replace(/[^0-9]/g, '')
              if (value) {
                form.setFieldsValue({
                  price: parseInt(value).toLocaleString('ru'),
                })
              } else {
                form.setFieldsValue({
                  price: '',
                })
              }
            }}
            className={'input'} id={'name'}/>
        </Form.Item>
        <div className={'footerCont'}>
          <Button
            onClick={cancelFunc}
            className={'modalFooterBack'}
            type="link">
            Bekor qilish
          </Button>
          <Button
            disabled={isLoading}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit">
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateAndEditModal