import { message } from "antd";
import { Location } from "history";
import dayjs from "rc-picker/node_modules/dayjs";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { HistoryItem } from "../components/main/history/historyTypes";
import {
  CreateOrderAllSelects,
  OneOrderDataType,
} from "../components/main/parts/CreateOrderTypes";
import { DateType } from "../components/main/statistics/expenseStats/ExpenseStats";
import { ObjectExpenseTypes } from "../components/main/statistics/objectExpense/ObjectExpenseTypes";
import { ProductStats } from "../components/main/statistics/productStats/ProductStatsTypes";
import {
  OneSupplierType,
  SupplierOrder,
  SupplierType,
  WithPagination,
} from "../components/main/supplier/all/SupplierAllTypes";
import { BrokerType } from "../components/main/supplier/one/BrokerListModal";
import { apis } from "../http/apis";
import $api from "../http/http";
import {
  ActReconciliationInterface,
  ActReconciliationResponseInterface,
} from "../models/act/actReconciliationInterface";
import {
  CheckoutPaymentsType,
  CheckoutViewType,
} from "../models/checkout/CheckoutPaymentModel";
import { ConversionType } from "../models/conversion/ConversionModel";
import { CategoryInterface } from "../models/interfaces/CategoryInterface";
import { ExpenseInterface } from "../models/interfaces/ExpenseInterface";
import { MyDataInterface } from "../models/interfaces/MyDataInterface";
import { ObjectInterface } from "../models/interfaces/ObjectInterface";
import { PaymentInterface } from "../models/interfaces/PaymentInterface";
import { ProductInterface } from "../models/interfaces/ProductInterface";
import { ProductSiInterface } from "../models/interfaces/ProductSiInterface";
import { UserInterface } from "../models/interfaces/UserInterface";
import { ValutaInterface } from "../models/interfaces/ValutaInterface";
import { OrderInterface } from "../models/parts/OrderInterface";
import { PartsTableInterface } from "../models/parts/PartsInterface";
import { PicturesViewFile } from "../models/PicturesModel";
import { CategoryTableInterface } from "../models/settings/CategoryTableInterface";
import { ExpenseTableInterface } from "../models/settings/ExpenseTableInterface";
import { ObjectHistory } from "../models/settings/ObjectHistory";
import { ObjectTableInterface } from "../models/settings/ObjectTableInterface";
import { PaymentTableInterface } from "../models/settings/PaymentTableInterface";
import { ProductSITableInterface } from "../models/settings/ProductSITableInterface";
import {
  ProductDetailsInterface,
  ProductHistoryInterface,
  ProductHistoryModalInterface,
  ProductTableInterface,
} from "../models/settings/ProductTableInterface";
import { UserTableInterface } from "../models/settings/UserTableInterface";
import { ValutaTableInterface } from "../models/settings/ValutaTableInterface";
import { CreditDebitInfo } from "../models/stats/credit-debit";
import {
  TransferInterface,
  TransferOrder,
  TransferTableInterface,
} from "../models/transfer/TransferInterface";
import {
  AllPaymentsModel,
  UysotPaymentView,
} from "../models/uysot/UysotPaymentViewModel";
import {
  OneExpenseModel,
  WarehouseModel,
} from "../models/warehouse/WarehouseModel";
import { setHistoryArr } from "../redux/actions";
import { queryNames } from "./queryNames";

////// payment

export function useSettingPayment(page: number, searchString: string) {
  let url = `?page=${page}`;
  if (searchString) url += `&name=${searchString}`;
  return useQuery<PaymentTableInterface>(
    [queryNames.SETTINGS_PAYMENTS, page, searchString],
    async () => {
      const res = await $api.get(apis.SETTINGS_PAYMENT + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    }
  );
}

export function useCurrentPayment(id: number) {
  return useQuery<PaymentInterface[]>(
    [queryNames.SETTINGS_PAYMENT, id],
    async () => {
      const { data } = await $api.get(`${apis.SETTINGS_PAYMENT}/${id}`);
      return data;
    },
    {
      enabled: id > 0,
    }
  );
}

export function usePaymentAll() {
  return useQuery<PaymentInterface[]>(
    [queryNames.SETTINGS_PAYMENT, "all"],
    async () => {
      const res = await $api.get(`${apis.SETTINGS_PAYMENT}?all=true`);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {}
  );
}

// create order selects
export function useOrderSelects() {
  return useQuery<CreateOrderAllSelects>(
    [queryNames.ORDER_SELECTS],
    async () => {
      const { data } = await $api.get(`${apis.ORDER}${apis.CREATE}`);
      return data;
    }
  );
}

//suppliers
export function useAllSuppliers(
  page: number,
  searchString: string,
  perPage: number,
  all?: boolean,
  params?: string | boolean
) {
  let url = `${apis.SUPPLIER}?page=${page}&per_page=${perPage}${
    searchString ? `&name=${searchString}` : ""
  }${all ? `&all=true` : ""}${params ? `&${params}` : ""}${
    params ? "" : `&key=id&sort=desc`
  }`;
  return useQuery<WithPagination<SupplierType[]> | any>(
    [queryNames.SUPPLIER, page, searchString, all, params, perPage],
    async () => {
      const { data } = await $api.get(url);
      return data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

// my data
export function useGetMyData() {
  return useQuery<MyDataInterface>(
    [queryNames.MY_DATA],
    async () => {
      const res = await $api.get(apis.MY_DATA);
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

// current supplier in table
export function useCurrentSupplier(id?: number) {
  return useQuery<SupplierType>(
    [queryNames.SUPPLIER, id],
    async () => {
      const { data } = await $api.get(`${apis.SUPPLIER}/${id}`);
      return data[0];
    },
    {
      enabled: !!id && id > 0,
    }
  );
}

// one supplier
export function useSupplier(id: string, page?: number) {
  return useQuery<OneSupplierType>(
    [queryNames.SUPPLIER_DETAILS, id, page],
    async () => {
      const res = await $api.get(
        `${apis.SUPPLIER_DETAILS}/${id}${page ? `?page=${page}` : ""}`
      );
      const orders: SupplierOrder[] = res.data[0].orders;
      const newOrders: SupplierOrder[] = [];
      orders.forEach((order, index) => {
        newOrders.push({
          ...order,
          key: index + 1,
        });
      });
      const newData = {
        ...res.data[0],
        orders: newOrders,
      };
      return newData || {};
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

//get one supplier
export function useBrokersOfSupplier(
  id: number,
  type: string,
  entryType: string
) {
  return useQuery([queryNames.BROKERS_OF_SUPPLIER, id], async () => {
    const url =
      type === "supplier" ? apis.BROKERS_OF_SUPPLIER : apis.SUPPLIERS_OF_BROKER;

    if (id && entryType === "debit") {
      const res = await $api.get(url + id);
      return res.data;
    }
  });
}

/// get brokers
export function useBrokers(id: string) {
  return useQuery<BrokerType[]>(
    [queryNames.SUPPLIER_BROKER, id],
    async () => {
      const res = await $api.get(`${apis.SUPPLIER_BROKER}/${id}`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

////// user

export function useUser(page: number, searchString: string, all?: boolean) {
  const url = `?page=${page}${searchString ? `&name=${searchString}` : ""}${
    all ? `&all=true` : ""
  }`;

  return useQuery<UserTableInterface | any>(
    [queryNames.SETTINGS_USERS, page, searchString, all],
    async () => {
      const res = await $api.get(apis.USER + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useCurrentUser(id: number) {
  return useQuery<UserInterface[]>(
    [queryNames.SETTINGS_USER, id],
    async () => {
      const { data } = await $api.get(`${apis.USER}/${id}`);
      return data;
    },
    {
      enabled: id > 0,
    }
  );
}

////// expense

export function useExpense(page: number, searchString: string, all?: boolean) {
  const url = `?page=${page}${searchString ? `&name=${searchString}` : ""}${
    all ? `&all=true` : ""
  }`;
  return useQuery<ExpenseTableInterface | any>(
    [queryNames.SETTINGS_EXPENSES, page, searchString, all],
    async () => {
      const res = await $api.get(apis.EXPENSE + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function useExpenseAll() {
  const url = `?&all=true`;
  return useQuery<ExpenseInterface[]>(
    [queryNames.SETTINGS_EXPENSES],
    async () => {
      const res = await $api.get(apis.EXPENSE + url);
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
}

export function useCurrentExpense(id: number) {
  return useQuery<ExpenseInterface[]>(
    [queryNames.SETTINGS_EXPENSE, id],
    async () => {
      const { data } = await $api.get(`${apis.EXPENSE}/${id}`);
      return data;
    },
    {
      enabled: id > 0,
    }
  );
}

///// category

export function useCategory(page: number, searchString: string) {
  let url = `?page=${page}`;
  if (searchString) url += `&name=${searchString}`;
  return useQuery<CategoryTableInterface>(
    [queryNames.SETTINGS_CATEGORIES, page, searchString],
    async () => {
      const res = await $api.get(apis.CATEGORY + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    }
  );
}

export function useCurrentCategory(id: number) {
  return useQuery<CategoryInterface[]>(
    [queryNames.SETTINGS_CATEGORY, id],
    async () => {
      const res = await $api.get(`${apis.CATEGORY}/${id}`);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {
      enabled: id > 0,
    }
  );
}

export function useCategoryAll() {
  return useQuery<CategoryInterface[]>(
    queryNames.ALL_CATEGORY,
    async () => {
      const res = await $api.get(apis.CATEGORY + "?all=true");
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

/// product SI

export function useProductSi(page: number, searchString: string) {
  let url = `?page=${page}`;
  if (searchString) url += `&name=${searchString}`;

  return useQuery<ProductSITableInterface>(
    [queryNames.SETTINGS_PRODUCTS_SI, page, searchString],
    async () => {
      const res = await $api.get(apis.PRODUCT_SI + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    }
  );
}

export function useProductSiAll() {
  return useQuery<ProductSiInterface[]>(queryNames.ALL_PRODUCT_SI, async () => {
    const res = await $api.get(apis.PRODUCT_SI + "?all=true");
    if (res?.data) {
      return res.data;
    }
    throw Promise.reject("error");
  });
}

export function useCurrentProductSI(id: number) {
  return useQuery<ProductSiInterface>(
    [queryNames.SETTINGS_PRODUCT_SI, id],
    async () => {
      const res = await $api.get(`${apis.PRODUCT_SI}/${id}`);
      if (res?.data) {
        return res.data[0];
      }
      throw Promise.reject("error");
    },
    {
      enabled: id > 0,
    }
  );
}

//// object
export function useObject<T = number>(page?: T, searchString?: string) {
  let url = "?all=true";
  if (page) {
    url = `?page=${page}`;
    if (searchString) url += `&name=${searchString}`;
  }
  return useQuery<
    typeof page extends undefined ? ObjectInterface[] : ObjectTableInterface
  >(
    [queryNames.SETTINGS_OBJECTS, page || "all", searchString],
    async () => {
      const res = await $api.get(apis.BUILDING + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {
      staleTime: Infinity,
    }
  );
}

export function useGetAllOBject() {
  return useQuery<ObjectInterface[]>(
    [queryNames.GET_ALL_OBJECTS],
    async () => {
      const res = await $api.get(`${apis.BUILDING}?all=true`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useCurrentObject(id: number) {
  return useQuery<ObjectInterface>(
    [queryNames.SETTINGS_OBJECT, id],
    async () => {
      const res = await $api.get(`${apis.BUILDING}/${id}`);
      if (res?.data) {
        return res.data[0];
      }
      throw Promise.reject("error");
    },
    {
      enabled: id > 0,
    }
  );
}

//get all objects
export function useAllOjects() {
  return useQuery([queryNames.ALL_OBJECTS], async () => {
    const res = await $api.get(apis.ALL_OBJECTS);
    return res.data;
  });
}

//// valuta
export function useValuta(page: number, searchString: string) {
  let url = `?page=${page}`;
  if (searchString) url += `&name=${searchString}`;

  return useQuery<ValutaTableInterface>(
    [queryNames.SETTINGS_VALUTA, page, searchString],
    async () => {
      const res = await $api.get(apis.VALUTA + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    }
  );
}

export function useCurrentValuta(id: number) {
  return useQuery<ValutaInterface>(
    [queryNames.SETTINGS_VALUTA_ONE, id],
    async () => {
      const res = await $api.get(`${apis.VALUTA}/${id}`);
      if (res?.data) {
        return res.data[0];
      }
      throw Promise.reject("error");
    },
    {
      enabled: id > 0,
    }
  );
}

export function useValutaAll() {
  return useQuery<ValutaInterface[]>(
    [queryNames.SETTINGS_VALUTA_ONE, "all"],
    async () => {
      const res = await $api.get(`${apis.VALUTA}?all=true`);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

//// product
export function useProduct(
  page: number,
  searchString: string,
  resource?: boolean
) {
  let url = `?page=${page}`;
  if (searchString) url += `&name=${searchString}`;
  if (resource) url += `&resource=all`;
  return useQuery<ProductTableInterface>(
    [queryNames.SETTINGS_PRODUCTS, page, searchString, resource],
    async () => {
      const res = await $api.get(apis.PRODUCT + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    }
  );
}

export function useCurrentProduct(id?: number) {
  return useQuery<ProductInterface>(
    [queryNames.SETTINGS_PRODUCT, id],
    async () => {
      const res = await $api.get(`${apis.PRODUCT}/${id}`);
      if (res?.data) {
        return res.data[0];
      }
      throw Promise.reject("error");
    },
    {
      enabled: !!id && id > 0,
    }
  );
}

// orders

export function useParts(
  page: number,
  searchString: string,
  location: Location,
  per_page: number
) {
  let url = `?page=${page}&per_page=${per_page}`;
  if (searchString) url += `&name=${searchString}`;
  const search = new URLSearchParams(location.search);
  if (search.get("sort")) url += `&sort=${search.get("sort")}`;
  if (search.get("key")) url += `&key=${search.get("key")}`;
  return useQuery<PartsTableInterface>(
    [queryNames.PARTS, page, searchString, location.search, per_page],
    async () => {
      const res = await $api.get(apis.ORDER + url);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

// order details

export function useOrderDetails(id: number) {
  return useQuery<OrderInterface[]>(
    [queryNames.ORDER_DETAILS, id],
    async () => {
      const res = await $api.get(`${apis.ORDER_DETAILS}/${id}`);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("eror");
    },
    {
      onError: () => {
        message.error("Xatolik mavjud");
      },
    }
  );
}

export function useOneOrderDetails(id: number | string) {
  return useQuery<OneOrderDataType>(
    [queryNames.ONE_ORDER_DETAILS, id],
    async () => {
      const res = await $api.get(`${apis.ORDER}/${id}`);
      return res.data[0];
    },
    {
      enabled: id !== "new",
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
}

// transfer table
export function useTransfers(page: number, searchString: string) {
  let url = `${apis.TRANSFER}?page=${page}`;
  if (searchString) url += `&name=${searchString}`;

  return useQuery<TransferTableInterface>(
    [queryNames.TRANSFERS, page, searchString],
    async () => {
      const res = await $api.get(url);
      const transfers: TransferInterface[] = res.data?.data;
      const newTransfers: TransferInterface[] = [];
      transfers.forEach((transfer, index) => {
        newTransfers.push({
          ...transfer,
          key: index + 1,
        });
      });
      const newData = {
        ...res.data,
        data: newTransfers,
      };
      return newData || {};
    }
  );
}

export function useTransferOrders(id: number) {
  return useQuery<TransferOrder[]>(
    [queryNames.TRANSFER_DETAILS, id],
    async () => {
      const res = await $api.get(`${apis.TRANSFER_DETAILS}/${id}`);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    }
  );
}

export function useProductDetails(id: number) {
  return useQuery(
    [queryNames.SETTINGS_PRODUCT_DETAILS, id],
    async () => {
      const res = await $api.get(`${apis.PRODUCT_DETAILS}/${id}`);
      if (res?.data) {
        return res.data[0] as ProductDetailsInterface;
      }
      throw Promise.reject("error");
    },
    {
      enabled: id !== 0,
    }
  );
}

export interface CheckProductParams {
  product_id: number;
  building_id?: number;
  date?: string;
  name?: string;
  comment?: string;
}

export interface CheckProductResponse {
  check: number;
  last_price: number;
  building_name?: string;
  last_check?: number;
  valuta_name?: string;
  last_date?: string;
  comment?: string;
}

export const useCheckProduct = (values: CheckProductParams) => {
  return useQuery<CheckProductResponse>(
    [
      queryNames.CHECK_PRODUCT,
      values.building_id,
      values.product_id,
      values.date,
    ],
    async () => {
      const res = await $api.post(apis.CHECK_PRODUCT, {
        ...values,
        date: dayjs(values.date).format("YYYY-MM-DD"),
      });
      return res.data;
    },
    {
      enabled: !Object.values(values).find((v) => !v),
      refetchOnWindowFocus: false,
    }
  );
};

///////////////////////////////
//order product list
export function useOrderProductList(id?: number) {
  let queryUrl = id
    ? `${apis.PRODUCT}${apis.BUILDING}/${id}`
    : `${apis.PRODUCT}?all=true`;

  return useQuery<ProductInterface[]>(
    [queryNames.ORDER_PRODUCT_LIST, id || "all"],
    async () => {
      const res = await $api.get(queryUrl);
      if (res?.data) {
        return res.data;
      }
      throw Promise.reject("error");
    },
    {
      enabled: id !== 0,
    }
  );
}

////////////////////////////////////////////
//////////STATISTICS////////////////////////
////////////////////////////////////////////
export type TopStatisticDataType =
  | "top10products"
  | "top10qarzdor"
  | "top10supplier"
  | "top10vositachi";

export function useTopProducts(type: TopStatisticDataType) {
  let queryUrl = `${apis.STATISTICS}?type=${type}`;
  return useQuery<any[]>(
    [queryNames.STATISTICS_TOP_PRODUCTS, type],
    async () => {
      const res = await $api.get(queryUrl);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useCreditDebit() {
  let queryUrl = `${apis.STATISTICS}?type=1`;
  return useQuery<CreditDebitInfo>(
    [queryNames.STATISTICS_KREDIT_DEBIT],
    async () => {
      const res = await $api.get(queryUrl);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useProductStats(productId: number | undefined) {
  return useQuery<ProductStats>(
    [queryNames.PRODUCT_STATS, productId],
    async () => {
      const res = await $api.get(
        `${apis.STATISTICS}?type=productstatistic&id=${productId}`
      );
      return res.data[0];
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useExpenseStats(date: string[], type: DateType) {
  let queryUrl = `${apis.STATISTICS}?type=expenses&sort=${type}&begin=${date[0]}&end=${date[1]}`;
  return useQuery<{ [key: string]: number }>(
    [queryNames.EXPENSE_STATS, type, date],
    async () => {
      const res = await $api.get(queryUrl);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useBuildingStats(
  date: string[],
  type: DateType,
  expenseType: string
) {
  let queryUrl = `${apis.STATISTICS}?type=buildings&sort=${type}&begin=${date[0]}&end=${date[1]}&selected=${expenseType}`;
  return useQuery<ObjectExpenseTypes[]>(
    [queryNames.BUILDING_STATS, type, date, expenseType],
    async () => {
      const res = await $api.get(queryUrl);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

////////////////////////////////////////////
//////////HISTORY////////////////////////
////////////////////////////////////////////

export function useLatestHistory(date: string, isOnView: boolean) {
  const dispatch = useDispatch();
  let queryUrl =
    date === "initial" ? `${apis.HISTORY}` : `${apis.HISTORY}?next_day=${date}`;
  return useQuery<HistoryItem>(
    [queryNames.HISTORY, date],
    async () => {
      const res = await $api.get(queryUrl);
      return res.data;
    },
    {
      enabled: date !== "" && isOnView && date !== "last",
      onSuccess: (data: HistoryItem) => {
        dispatch(setHistoryArr(data.next_day));
      },
    }
  );
}

//////////// ActReconciliation

export function useActReconciliation(
  id: string,
  dateArr: string[],
  brokers: number[]
) {
  return useQuery<ActReconciliationResponseInterface>(
    [queryNames.ACT_RECONCILIATION, dateArr[0], dateArr[1], brokers],
    async () => {
      const { data } = await $api.get(
        `${apis.ACT_RECONCILIATION}/${id}?begin=${dateArr[0]}&end=${
          dateArr[1]
        }&array=${JSON.stringify(brokers || [])}`
      );
      const dataArr: ActReconciliationInterface[] = [...data?.data];
      dataArr?.sort((a, b) => {
        // @ts-ignore
        return new Date(a.date) - new Date(b.date);
      });
      return {
        ...data,
        data: dataArr,
      };
    },
    {
      select: (data) => {
        for (let i = 0; i < data.data.length; i++) {
          data.data[i].productList = [];
          for (let j = 0; j < data.product_lists.length; j++) {
            if (data.data[i].order_id === data.product_lists[j].order_id) {
              data.data[i]?.productList?.push(data.product_lists[j]);
            }
          }
        }

        return { ...data };
      },
    }
  );
}

export function useProductHistory(id: number, dateArr: string[]) {
  let URL = apis.PRODUCT_HISTORY;
  let idKey: number | string = id;
  let object = dateArr[3] ? `&building=${dateArr[2]}` : "";
  if (id === 0) {
    URL += `/all?begin=${dateArr[0]}&end=${dateArr[1]}${object}`;
    idKey = "all";
  } else {
    URL += `/${id}?begin=${dateArr[0]}&end=${dateArr[1]}`;
  }
  return useQuery<ProductHistoryModalInterface>(
    [queryNames.PRODUCT_HISTORY, dateArr[0], dateArr[1], dateArr[2], idKey],
    async () => {
      let sum = 0;
      let currentObj = {
        id: -1,
        price: 0,
      };
      const { data } = await $api.get(URL);
      data.forEach((item: ProductHistoryInterface, index: number) => {
        data[index].key = index;
        if (item.product_id !== currentObj.id) {
          currentObj = {
            id: item.product_id,
            price: item.price * item.valuta_history_price,
          };
        } else {
          if (currentObj.price !== item.price * item.valuta_history_price) {
            sum +=
              (currentObj.price - item.price * item.valuta_history_price) *
              item.count;
          }
        }
      });
      return {
        data,
        price: sum,
      };
    },
    {
      enabled: dateArr.length !== 0,
      staleTime: Infinity,
    }
  );
}

// object history
export function useObjectHistory(
  id: number,
  dateArr: string[],
  category: number | string,
  view: string
) {
  let URL = apis.OBJECT_HISTORY;
  // id
  if (id && id !== 0) {
    URL += `/${id}`;
  }
  if (!id || id === 0) {
    URL += "/all";
  }

  // date
  if (dateArr && dateArr.length > 0) {
    URL += `?begin=${dateArr[0]}&end=${dateArr[1]}`;
  }
  if (dateArr && dateArr.length === 0) {
    URL += `?begin=${dayjs(0).format("YYYY-MM-DD")}&end=${dayjs().format(
      "YYYY-MM-DD"
    )}`;
  }

  if (view) {
    URL += `&view=${view}`;
  }

  // category
  if (category) {
    URL += `&category_id=${category}`;
  }
  return useQuery<ObjectHistory>(
    [queryNames.OBJECT_HISTORY, dateArr[0], dateArr[1], id, category, view],
    async () => {
      if (URL) {
        const res = await $api.get(URL);
        return res.data;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

//  only objects name
export function useObjectsHistoryName(dateArr: string[]) {
  const URL =
    apis.OBJECTS_HISTORY_NAME + `?begin=${dateArr[0]}&end=${dateArr[1]}`;
  return useQuery(
    [queryNames.OBJECT_NAMES_HISTORY, dateArr[0], dateArr[1]],
    async () => {
      if (dateArr.length > 0) {
        const res = await $api.get(URL);
        return res.data;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useGetWarehouse(
  page: number,
  name: string,
  key: string,
  sort: string,
  begin?: string,
  end?: string
) {
  return useQuery<WarehouseModel>(
    [queryNames.WAREHOUSE, page, name, key, sort, begin, end],
    async () => {
      const res = await $api.get(
        `${apis.WAREHOUSE}?page=${page}${name ? `&name=${name}` : ""}${
          sort ? `&sort=${sort}` : ""
        }${key ? `&key=${key}` : ""}${begin ? `&begin=${begin}` : ""}${
          end ? `&end=${end}` : ""
        }`
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !begin || !end || !name,
    }
  );
}

export function useGetWarehouseProduct(
  id: number,
  page: number,
  key: string,
  sort: string,
  name: string
) {
  return useQuery(
    [queryNames.WAREHOUSE_PRODUCT, id, page, key, sort, name],
    async () => {
      const res = await $api.get(
        `${apis.WAREHOUSE}/${id}?page=${page}${sort ? `&sort=${sort}` : ""}${
          key ? `&key=${key}` : ""
        }${name ? `&name=${name}` : ""}`
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGetExpense(
  page: number,
  search: string,
  params: string,
  per_page: number
) {
  return useQuery(
    [queryNames.ALL_EXPENSE, page, search, params, per_page],
    async () => {
      const res = await $api.get(
        `${apis.VIEW_EXPENSE}?page=${page}&per_page=${per_page}&name=${search}${
          params ? `&${params}` : ""
        }`
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGetOneExpense(id: number) {
  return useQuery<OneExpenseModel>(
    [queryNames.GET_ONE_EXPENSE, id],
    async () => {
      const res = await $api.get(`${apis.VIEW_EXPENSE}${id ? `/${id}` : ""}`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

// export function useGetWarehouseProducts(id: number, name?: string) {
//   return useQuery<WareHouseProductDataModel[]>(
//     [queryNames.USED_PRODUCTS, id, name],
//     async () => {
//       const res = await $api.get(
//         `${apis.WAREHOUSE_PRODUCT}${id}?all=true${name ? `&name=${name}` : ""}`
//       );
//       return res.data;
//     },
//     {
//       refetchOnWindowFocus: false,
//       retry: false,
//     }
//   );
// }

export function useGetWarehouseUsed(
  page: number,
  name: string,
  key: string,
  sort: string
) {
  return useQuery<WarehouseModel>(
    [queryNames.WAREHOUSE_USED, page, name, key, sort],
    async () => {
      const res = await $api.get(
        `${apis.WAREHOUSE_USED}?page=${page}${name ? `&name=${name}` : ""}${
          sort ? `&sort=${sort}` : ""
        }${key ? `&key=${key}` : ""}`
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGetWarehouseUsedProduct(
  id: number,
  page: number,
  key: string,
  sort: string,
  name: string
) {
  return useQuery(
    [queryNames.WAREHOUSE_USED_PRODUCT, id, page, key, sort, name],
    async () => {
      const res = await $api.get(
        `${apis.WAREHOUSE_USED}/[${id}]?page=${page}${
          sort ? `&sort=${sort}` : ""
        }${key ? `&key=${key}` : ""}${name ? `&name=${name}` : ""}`
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

// Checkout page //
export function useGetCheckoutPayments(
  page: number,
  per_page: number,
  search: string,
  payment?: number,
  valuta?: number,
  params?: string | boolean,
  filter?: string | undefined
) {
  let url = `${apis.CHECKOUT_PATMENTS}?page=${page ?? 1}&per_page=${per_page}${
    search ? `&search=${search}` : ""
  }${payment ? `&payment_type_id=${payment}` : ""}${
    params ? `&${params}` : ""
  }${filter ? `&${filter}` : ""}`;

  // if (filter) {
  //   url = `${apis.CHECKOUT_PATMENTS}?page=${page ?? 1}&${filter}`;
  // }

  // if (
  //   typeof params === "string" &&
  //   (params.includes(CheckoutModalEnums.OUTLAY) ||
  //     params.includes(CheckoutModalEnums.REVENUE))
  // ) {
  //   url = `${apis.CHECKOUT_PATMENTS}?page=${page ?? 1}${
  //     search ? `&search=${search}` : ""
  //   }${payment ? `&payment_type_id=${payment}` : ""}${
  //     filter ? `&${filter}` : ""
  //   }`;
  // }

  return useQuery<CheckoutPaymentsType>(
    [
      queryNames.CHECKOUT_PAYMENTS,
      page,
      per_page,
      search,
      payment,
      valuta,
      params,
      filter,
    ],
    async () => {
      const res = await $api.get(url);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGetCheckoutView() {
  return useQuery<CheckoutViewType[]>(
    [queryNames.CHECKOUT_VIEW],
    async () => {
      const res = await $api.get(apis.CHECKOUT_VIEW);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function usePostUserRoleList() {
  return useQuery<string[]>(
    [queryNames.USER_ROLE_LIST],
    async () => {
      const res = await $api.post(apis.USER_ROLE_LIST);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

// UYSOT integration

export function useGetAllPayments() {
  return useQuery<AllPaymentsModel[]>(
    [queryNames.GET_ALL_PAYMENTS],
    async () => {
      const res = await $api.get(`${apis.SETTINGS_PAYMENT}?all=true`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGetUysotView() {
  return useQuery<UysotPaymentView[]>(
    [queryNames.UYSOT_VIEW],
    async () => {
      const res = await $api.get(`${apis.UYSOT_PAYMENT}${apis.VIEW}`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useKassaUysot() {
  return useQuery<{
    updated_at: string;
    integration: boolean;
    sum: number;
    sum_currency: number;
    uysot_payments: any[];
  }>(
    [queryNames.KASSA_UYSOT],
    async () => {
      const res = await $api.get(apis.CHECKOUT_UYSOT);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGetUysotKassa() {
  return useQuery<{
    updated_at: string;
    integration: boolean;
    sum: number;
    uysot_payments: {
      id: number;
      type: string;
      sum: number;
    }[];
  }>(
    [queryNames.GET_UYSOT_KASSA],
    async () => {
      const res = await $api.get(apis.UYSOT_KASSA);
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

// Conversion

export function useConversion(page: string) {
  const { search } = useLocation();
  const searchValue = search.slice(1);
  const queryParam = new URLSearchParams(search);
  let url = `${apis.CONVERSION}?page=${page ?? 1}`;

  if (searchValue) {
    if (queryParam.get("visibleView")) {
      queryParam.delete("visibleView");
    }
    url = `${apis.CONVERSION}?${queryParam.toString()}`;
  } else {
    url = `${apis.CONVERSION}?page=${page ?? 1}`;
  }

  return useQuery<ConversionType>(
    [queryNames.CONVERSION, page, searchValue],
    async () => {
      const res = await $api.get(url);
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function usePicturesFileView(id: number, type: "order" | "used") {
  let url = "/file/view";
  if (type === "used") {
    url += `?used_id=${id}`;
  } else {
    url += `?order_id=${id}`;
  }
  return useQuery<PicturesViewFile>(
    [queryNames.PICTURES_VIEW_FILE, id],
    async () => {
      const res = await $api.get(url);
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}
