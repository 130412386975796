import { Reducer } from "redux";
import { ALL_SELECET_WAREHOUSES } from "./types";

const initialState: { select: boolean } = {
  select: false
};

const allSelectWarehouseReducer: Reducer<typeof initialState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ALL_SELECET_WAREHOUSES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return {
        state
      };
  }
};

export default allSelectWarehouseReducer;
