import { Reducer } from "redux";
import { ORDER_ID_REDUCER } from "./types";

const initialValue: { copy: boolean } = {
  copy: false,
};

const orderIdReducer: Reducer<typeof initialValue> = (
  state = initialValue,
  action
) => {
  switch (action.type) {
    case ORDER_ID_REDUCER:
      return {
        id: state.copy,
        ...action.payload,
      };
    default:
      return {
        state,
      };
  }
};

export default orderIdReducer;
