import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { RootState } from "../../redux/rootReducer";
import mainPaths from "../../routes/main/mainPaths";
import { mainRoutes } from "../../routes/main/mainRoutes";
import { rootPaths } from "../../routes/rootRoutes";
import { roles } from "./settings/user/roles";

export const Content = () => {
  const user = useSelector((state: RootState) => state.user).userData;

  const filterMainRoutes = () => {
    if (user.role === roles[0] || user.role === roles[3]) {
      let data: {
        path: string;
        component: JSX.Element | React.FC;
        exact: boolean;
      }[] = [];
      for (let i = 0; i < mainRoutes.length; i++) {
        if (
          mainRoutes[i].path === mainPaths.EXPENSE ||
          mainRoutes[i].path === mainPaths.EXPENSE_CREATE ||
          mainRoutes[i].path === `${mainPaths.EXPENSE}/:id` ||
          mainRoutes[i].path === mainPaths.ORDERS ||
          mainRoutes[i].path === `${mainPaths.ORDERS}/:orderId`
        )
          data = [...data, mainRoutes[i]];
      }
      return data;
    } else if (user.role === roles[1]) return mainRoutes;
    return mainRoutes.filter((item) => item.path !== mainPaths.STATISTICS);
  };

  return (
    <div className="content">
      <Switch>
        {filterMainRoutes().map((route) => {
          return (
            <Route
              key={route.path}
              path={`${rootPaths.MAIN}${route.path}`}
              component={route.component as any}
              exact={route.exact}
            />
          );
        })}
        <Redirect to={`${rootPaths.MAIN}${mainPaths.ORDERS}`} />
      </Switch>
    </div>
  );
};
