import React from "react";

const ShopIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00586 7.97998V10.9733C2.00586 13.9666 3.20586 15.1666 6.19919 15.1666H9.79253C12.7859 15.1666 13.9859 13.9666 13.9859 10.9733V7.97998"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00097 8.49998C9.22097 8.49998 10.121 7.50665 10.001 6.28665L9.56097 1.83331H6.44764L6.00097 6.28665C5.88097 7.50665 6.78097 8.49998 8.00097 8.49998Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2065 8.49998C13.5532 8.49998 14.5399 7.40665 14.4065 6.06665L14.2199 4.23331C13.9799 2.49998 13.3132 1.83331 11.5665 1.83331H9.5332L9.99987 6.50665C10.1132 7.60665 11.1065 8.49998 12.2065 8.49998Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.7609 8.49998C4.8609 8.49998 5.85423 7.60665 5.9609 6.50665L6.10756 5.03331L6.42756 1.83331H4.39423C2.64757 1.83331 1.9809 2.49998 1.7409 4.23331L1.5609 6.06665C1.42756 7.40665 2.41423 8.49998 3.7609 8.49998Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00065 11.8334C6.88732 11.8334 6.33398 12.3867 6.33398 13.5V15.1667H9.66732V13.5C9.66732 12.3867 9.11398 11.8334 8.00065 11.8334Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShopIcon;
