import React from "react";

const SelectIcon = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 0.5L5 5.5L9.5 0.5H0.5Z" fill="#2C3F45" />
    </svg>
  );
};

export default SelectIcon;
