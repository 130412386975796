import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Spin } from "antd";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoginFormInterface from "../../models/interfaces/LoginFormInterface";
import { useLogin } from "../../queries/mutations";
import { Key } from "../../svg/Key";
import { LoginIcon } from "../../svg/LoginIcon";
import "./Login.scss";
import logoImg from "../../image/logo _uyqur.png";
import {useEffect} from 'react'

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  useDocumentTitle("Uyqur");
  const mutation = useLogin(history);

  useEffect(() => {
    form.setFieldsValue({
      domen: localStorage.getItem('domen-name')
    })
  }, [form])

  const onFinish = (values: LoginFormInterface) => {
    if (values.domen === "uyqur") {
      localStorage.setItem("domen", `https://backend.uyqur.uz/api`);
    } else {
      localStorage.setItem(
        "domen",
        `https://backend.${values.domen}.uyqur.uz/api`
      );
    }
    localStorage.setItem('domen-name', `${values.domen}`)
    mutation.mutate({
      login: values.login,
      password: values.password,
      domen: values.domen
    });
  };

  return (
    <div className={`LoginPage`}>
      <div className="login">
        <div className="logo">
          <img
            src={logoImg}
            alt={"Uyqur"}
            style={{
              objectFit: "contain",
              width: "100px"
            }}
          />
        </div>
        <h3
          style={{
            margin: "10px 0 30px 0"
          }}
        >
          UYQUR.UZ
        </h3>
        <Form
          form={form}
          className="formLogin"
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="domen"
            rules={[
              {
                required: true,
                message: ""
              }
            ]}
          >
            <Input
              className="input"
              prefix={<LoginIcon />}
              placeholder="Domen"
            />
          </Form.Item>
          <Form.Item
            name="login"
            rules={[
              {
                required: true,
                message: ""
              }
            ]}
          >
            <Input
              className="input"
              prefix={<LoginIcon />}
              placeholder="Login"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: ""
              }
            ]}
          >
            <Input.Password
              className="input"
              prefix={<Key />}
              placeholder="Parol"
            />
          </Form.Item>
          {mutation.isError && (
            <span className={"isError"}>Parol yoki Login xato</span>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Form.Item
              className="checkbox"
              name="rememberMe"
              valuePropName="checked"
            >
              <Checkbox>Parolni saqlash</Checkbox>
            </Form.Item>
            <Form.Item
              className="submitLogin"
              wrapperCol={{
                offset: 8,
                span: 16
              }}
            >
              <Button
                disabled={mutation.isLoading}
                style={{ width: "100px" }}
                type="primary"
                htmlType="submit"
              >
                {mutation.isLoading ? (
                  <Spin
                    indicator={
                      <Loading3QuartersOutlined
                        style={{ color: "#fff" }}
                        spin
                      />
                    }
                  />
                ) : (
                  "Kirish"
                )}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Login;
