import React, { useState } from "react";

import PayedModal from "./PayedModal";
import CreditCard from "../../../../svg/CreditCard";
import { OneSupplierType } from "../all/SupplierAllTypes";
import { Button } from "antd";
import WarningIcon from "../../../../svg/WarningIcon";
import LastPaymentModal from "./LastPaymentModal";

interface CardInterface {
  data: OneSupplierType | undefined;
  id: string;
  isLoading: boolean;
}

const Card: React.FC<CardInterface> = ({ data, id, isLoading }) => {
  const [openPayModal, setOpenPayModal] = useState(false);
  const [lastPayVisible, setLastPayVisible] = useState<boolean>(false);

  return (
    <div className="supplier_card">
      <CreditCard className="creditCard" />
      <div className="supplier_card_body">
        <div className="supplier_card_header">
          <div className="title">
            <span>Ta'minotchi</span>
            <p>{isLoading ? "yuklanmoqda..." : data?.name}</p>
          </div>
          <Button className="not_btn" onClick={() => setLastPayVisible(true)}>
            <WarningIcon />
          </Button>
        </div>
        <div className="supplier_card_balans">
          <p>Balans</p>
          <span className="price">
            {isLoading ? "0" : data?.balans?.toLocaleString("ru")}
          </span>
          <span className="supplier_currency">so'm</span>
        </div>
      </div>
      <div
        onClick={() => setOpenPayModal(true)}
        className="supplier_card_button"
      >
        Avtomatik to'lov
      </div>
      <PayedModal
        open={openPayModal}
        close={setOpenPayModal}
        supplier_id={id}
        title="Avtomatik to’lov"
      />
      <LastPaymentModal
        visible={lastPayVisible}
        setVisible={setLastPayVisible}
        data={data!}
      />
    </div>
  );
};

export default Card;
