import React, { useState, useEffect } from "react";
import { Button, Dropdown, Menu } from "antd";

import { WarehouseDataModel } from "../../../../../models/warehouse/WarehouseModel";
import BigFolderIcon from "../../../../../svg/BigFolderIcon";
import ThreePointsIcon from "../../../../../svg/ThreePointsIcon";
import styles from "../../expense.module.scss";
import EyeIcon from "../../../../../svg/EyeIcon";
import CheckIcon from "../../../../../svg/CheckIcon";
import CheckAllIcon from "../../../../../svg/CheckAllIcon";
import CloseIcon from "../../../../../svg/CloseIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/rootReducer";
import { WarehouseIdAction } from "../../../../../redux/actions";
import PatchMinusIcon from "../../../../../svg/PatchMinusIcon";
import FolderMinusIcon from "../../../../../svg/FolderMinusIcon";
import DownloadIcon from "../../../../../svg/DownloadIcon";
import { useDownloadWarehouseProducts } from "../../../../../queries/mutations";
import dayjs from "dayjs";

type Props = {
  address: string;
  id: number;
  name: string;
  summa: string;
  summa_mega: number;
  setProductsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setWarehouseData: React.Dispatch<React.SetStateAction<WarehouseDataModel>>;
  type: number;
  selectAll: boolean;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  search?: string;
  dateValue?: string[];
};

const ViewCard: React.FC<Props> = ({
  address,
  name,
  id,
  summa,
  setProductsVisible,
  setWarehouseData,
  type,
  summa_mega,
  selectAll,
  setSelectAll,
  search,
  dateValue,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const warehouseIds = useSelector((state: RootState) => state.warehouseId.id);
  const downloadWarehouseProducts = useDownloadWarehouseProducts();

  const handleClick = () => {
    setProductsVisible(true);
    setWarehouseData({ address, name, summa, id, summa_mega });
  };

  const summaCount = () => {
    const sum = type === 3 ? summa_mega : summa;
    if (String(sum).length > 13)
      return `${Number(String(sum).slice(0, 13)).toLocaleString()} ... so'm`;
    else return `${Number(String(sum)).toLocaleString()} so'm`;
  };

  const openDropdown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setVisible(!visible);
  };

  useEffect(() => {
    document.querySelector("body")?.addEventListener("click", () => {
      setVisible(false);
    });
  }, []);

  const clickElement = () => {
    const a = document.createElement("a");
    a.click();
    a.remove();
  };

  // close menu
  const closeMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    clickElement();
    setVisible(false);
  };

  // checked card
  const checkCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setSelectAll(false);
    if (!warehouseIds?.some((item) => item === id)) {
      dispatch(WarehouseIdAction([...(warehouseIds ?? []), ...[id]]));
    } else {
      dispatch(WarehouseIdAction(warehouseIds?.filter((item) => item !== id)));
    }
    clickElement();
    setVisible(false);
  };

  // select all cards
  const changeAllCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!selectAll) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
      dispatch(WarehouseIdAction([]));
    }
    setVisible(false);
  };

  // download warehouse products excel file
  const downloadProducts = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    downloadWarehouseProducts.mutate({
      name,
      products: warehouseIds?.length > 0 ? warehouseIds : [id],
      query: {
        begin: dateValue && dayjs(dateValue[0]).format("YYYY-MM-DD"),
        end: dateValue && dayjs(dateValue[1]).format("YYYY-MM-DD"),
        search: search,
      },
    });
  };

  // dropdown menu items
  const menu = (
    <Menu>
      <Menu.Item key={0} className={"menu_item"}>
        <div>
          <EyeIcon /> Ko'rish
        </div>
      </Menu.Item>
      <Menu.Item key={1} className={"menu_item"}>
        <div onClick={checkCard}>
          {warehouseIds?.some((item) => item === id) ? (
            <>
              <PatchMinusIcon /> O'chirish
            </>
          ) : (
            <>
              <CheckIcon /> Tanlash
            </>
          )}
        </div>
      </Menu.Item>
      <Menu.Item key={2} className={"menu_item"}>
        <div onClick={changeAllCard}>
          {selectAll ? (
            <>
              <FolderMinusIcon /> Barchasini o'chirish
            </>
          ) : (
            <>
              <CheckAllIcon /> Barchasini tanlash
            </>
          )}
        </div>
      </Menu.Item>
      <Menu.Item key={3} className={"menu_item"}>
        <div onClick={downloadProducts}>
          <DownloadIcon /> Mahsulotlarni yuklash
        </div>
      </Menu.Item>
      <Menu.Item key={4} className={"menu_item menu_exit"}>
        <div onClick={closeMenu}>
          <CloseIcon /> Yopish
        </div>
      </Menu.Item>
    </Menu>
  );

  // card active styles
  const activeStyle = () => {
    if (warehouseIds?.some((item) => item === id)) {
      return {
        background: "#E9F4FA",
        borderColor: "#0078D4",
      };
    } else
      return {
        background: "#ffffff",
      };
  };

  // card click contextmenu
  const onContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setSelectAll(false);
    if (!warehouseIds?.some((item) => item === id)) {
      dispatch(WarehouseIdAction([...(warehouseIds ?? []), ...[id]]));
    } else {
      dispatch(WarehouseIdAction(warehouseIds?.filter((item) => item !== id)));
    }
  };

  return (
    <>
      <div
        className={`${styles.card} warehouse_card_${id}`}
        onClick={handleClick}
        style={activeStyle()}
        onContextMenu={onContextMenu}
      >
        <div className={styles.card_top}>
          <div>
            <span>
              <BigFolderIcon />
            </span>
            <p>{name}</p>
          </div>
          <Dropdown visible={visible} overlay={menu} trigger={["click"]}>
            <Button onClick={openDropdown}>
              <ThreePointsIcon />
            </Button>
          </Dropdown>
        </div>
        <div className={styles.card_bottom}>
          <div>
            <h3>{summaCount()}</h3>
            <p>{address} </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCard;
