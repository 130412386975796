import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Row, Typography, Divider, Space } from "antd";
import { DatePicker } from "../../../../service/DatePicker";
import dayjs from "rc-picker/node_modules/dayjs";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useBrokers } from "../../../../queries/queries";
import { queryNames } from "../../../../queries/queryNames";
import CalendarIcon from "../../../../svg/CalendarIcon";
import ActReconciliationModal from "./act/ActReconciliationModal";
import DatePickerExtraFooter from "../../common/DatePickerExtraFooter";
import { OneSupplierType } from "../all/SupplierAllTypes";
import { DATE_FORMAT } from "../../../../models/settings/dateFormat";

const { RangePicker } = DatePicker;

interface BrokerListModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  supplierId: string;
  brok: OneSupplierType;
}
export type BrokerType = {
  user_id: number;
  name: string;
  debit: number;
};

const BrokerListModal: React.FC<BrokerListModalProps> = ({
  visible,
  setVisible,
  supplierId,
  brok,
}) => {
  const [form] = Form.useForm();
  const [dateArr, setDateArr] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [brokers, setBrokers] = useState<number[]>([]);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const onFinishAct = (val: any) => {
    const brokers = data?.map((broker: BrokerType) => broker.user_id);
    if (brokers) {
      setBrokers(brokers);
    }
    const beginDate = dayjs(val.dateArr[0]).format("YYYY-MM-DD");
    const endDate = dayjs(val.dateArr[1]).format("YYYY-MM-DD");
    setDateArr([beginDate, endDate]);
    setOpenModal(true);
  };

  useEffect(() => {
    const beginDate = dayjs().subtract(1, "month");
    const endDate = dayjs();
    form.setFieldsValue({ dateArr: [beginDate, endDate] });
    setDateArr([beginDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD")]);
  }, [form]);

  const { data, isLoading, isFetching } = useBrokers(supplierId);

  const qc = useQueryClient();
  useEffect(() => {
    if (visible) {
      qc.invalidateQueries([queryNames.SUPPLIER_BROKER, supplierId]);
    }
  }, [visible, qc, supplierId]);

  return (
    <Modal
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      title={
        <Row justify="space-between" align="middle">
          <Space>
            Akt sverka
            {isFetching && <LoadingOutlined />}
          </Space>
          <Button
            style={{ color: "white" }}
            type="text"
            onClick={() => setVisible(false)}
          >
            <CloseOutlined />
          </Button>
        </Row>
      }
      className="editModal"
    >
      <div className={"actReconciliation"}>
        <Form
          form={form}
          name="basic"
          onFinish={onFinishAct}
          autoComplete="off"
        >
          <div style={{ width: "100%", display: "flex" }}>
            <Form.Item name="dateArr" rules={[{ required: true, message: "" }]}>
              <RangePicker
                suffixIcon={<CalendarIcon />}
                className={"rangePicker"}
                placeholder={["Boshlanish vaqti", "Tugash vaqti"]}
                renderExtraFooter={() => {
                  return (
                    <DatePickerExtraFooter
                      form={form}
                      visible={setDatePickerVisible}
                      untilDay={brok.created_at}
                    />
                  );
                }}
                open={datePickerVisible}
                onOpenChange={(e) => setDatePickerVisible(e)}
                format={DATE_FORMAT}
              />
            </Form.Item>
            <Button
              title={"Export PDF (акт сверка)"}
              type={"primary"}
              htmlType={"submit"}
              style={{ height: "38px", flexGrow: 1, marginLeft: "1rem" }}
            >
              Akt sverkaa
            </Button>
          </div>
        </Form>
        <ActReconciliationModal
          brokers={brokers}
          id={supplierId}
          close={setOpenModal}
          open={openModal}
          dateArr={dateArr}
        />
      </div>
      {isLoading ? (
        <div>yuklanmoqda...</div>
      ) : (
        <div>
          <Typography.Title level={4}>Vositachilar</Typography.Title>
          <Divider plain />
          {data?.map((broker, index) => (
            <div
              key={broker.user_id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
              }}
            >
              <p
                style={{
                  margin: 0,
                  marginRight: "1rem",
                }}
              >
                {index + 1}
              </p>
              <p
                style={{
                  margin: 0,
                  marginRight: "1rem",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                {broker.name}
              </p>
              <p style={{ margin: 0 }}>
                {broker.debit && Number(broker?.debit).toLocaleString("ru")}{" "}
                so'm
              </p>
              <Button
                onClick={() => {
                  setOpenModal(true);
                  setBrokers([broker.user_id]);
                }}
                style={{ marginLeft: "auto" }}
                type="link"
              >
                akt sverka
              </Button>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default BrokerListModal;
