import { Button } from "antd";
import { useDispatch } from "react-redux";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import { toggleSupplierCUModal } from "../../../../redux/actions";
import { PlusDark } from "../../../../svg/PlusDark";
import SearchHeader from "../../universialComponents/SearchHeader";
import "./Suppliers.scss";
import SuppliersTable from "./SuppliersTable";

const SuppliersCont = () => {
  const dispatch = useDispatch();
  useDocumentTitle("Ta'minotchilar");
  return (
    <>
      <SearchHeader
        title={"Ta’minotchilar"}
        buttons={
          <Button
            icon={<PlusDark />}
            className="button"
            onClick={() => dispatch(toggleSupplierCUModal(true))}
          >
            Taminotchi yaratish
          </Button>
        }
        inputPlaceholder={"Nomi boyicha qidiring..."}
      />
      <SuppliersTable />
    </>
  );
};

export default SuppliersCont;
