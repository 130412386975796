import { Button, Form, Popover, Select } from "antd";
import { Dayjs } from "dayjs";
import dayjs from "rc-picker/node_modules/dayjs";
import React, { useState } from "react";
import { DATE_FORMAT } from "../../../models/settings/dateFormat";
import { view } from "../../../models/settings/ViewEnum";
import { useCategoryAll } from "../../../queries/queries";
import { DatePicker } from "../../../service/DatePicker";
import CalendarIcon from "../../../svg/CalendarIcon";
import DownloadIcon from "../../../svg/DownloadIcon";
import DatePickerExtraFooter from "../common/DatePickerExtraFooter";
import ObjectHistoryModal from "./ObjectHistoryModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IForm {
  dateArr: [Dayjs, Dayjs];
}

const OpenObjectHistory: React.FC = () => {
  const [form] = Form.useForm<IForm>();
  const [allState, setAllState] = useState<{
    dateArr: string[];
    visiblePopover: boolean;
    visibleModal: boolean;
    category_id: number | string;
    view: string;
  }>({
    dateArr: [],
    visibleModal: false,
    visiblePopover: false,
    category_id: "all",
    view: view.INCOME,
  });
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  // all categories
  const allCategories = useCategoryAll();

  // close modal
  const closeModal = () => {
    setAllState({
      ...allState,
      visibleModal: false,
    });
  };

  // form submit
  const handleSubmit = (fields: any) => {
    const beginDate = dayjs(fields.dateArr[0]).format("YYYY-MM-DD");
    const endDate = dayjs(fields.dateArr[1]).format("YYYY-MM-DD");
    const category_id = fields.category;
    const view: string = fields?.view;
    setAllState({
      ...allState,
      visiblePopover: false,
      dateArr: [beginDate, endDate],
      visibleModal: true,
      category_id: category_id,
      view,
    });
  };

  // change popover visibility
  const changePopoverVisible = (visiblePopover: boolean) => {
    setAllState({
      ...allState,
      visiblePopover,
    });
    form.resetFields();
  };

  const rangePickerComp = (
    <div className={"productHistoryMenu"}>
      <Form form={form} name="basic" onFinish={handleSubmit} autoComplete="off">
        <Form.Item name="dateArr" rules={[{ required: true, message: "" }]}>
          <RangePicker
            suffixIcon={<CalendarIcon />}
            className={"rangePicker"}
            placeholder={["Boshlanish vaqti", "Tugash vaqti"]}
            renderExtraFooter={() => {
              return (
                <DatePickerExtraFooter
                  form={form}
                  visible={setDatePickerVisible}
                />
              );
            }}
            open={datePickerVisible}
            onOpenChange={(e) => setDatePickerVisible(e)}
            format={DATE_FORMAT}
          />
        </Form.Item>
        <Form.Item name="view" initialValue={view.INCOME}>
          <Select>
            <Option value={view.INCOME}>Kirim</Option>
            <Option value={view.OUTLAY}>Chiqim</Option>
            <Option value={view.RESIDUE}>Qoldiq</Option>
          </Select>
        </Form.Item>
        <Form.Item name="category">
          <Select placeholder="Kategoriyani tanlang" defaultValue={""}>
            <Select.Option key="overall" value="">
              Barcha kategoriyalar
            </Select.Option>
            {allCategories?.data?.map((category) => (
              <Select.Option key={category.id} value={category.id!}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          title={"Export PDF (акт сверка)"}
          className={"rangePicker"}
          type={"primary"}
          htmlType={"submit"}
        >
          Ok
        </Button>
      </Form>
    </div>
  );
  return (
    <div>
      <Popover
        className="importExcelPopover"
        content={rangePickerComp}
        trigger={"click"}
        visible={allState.visiblePopover}
        onVisibleChange={(visible) => changePopoverVisible(visible)}
      >
        <Button
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "5px",
          }}
        >
          <DownloadIcon />
          <span style={{ marginLeft: "5px" }}>EXPORT</span>
        </Button>
      </Popover>
      <ObjectHistoryModal
        dateArr={allState.dateArr}
        visible={allState.visibleModal}
        close={closeModal}
        category={allState.category_id}
        view={allState.view}
      />
    </div>
  );
};

export default OpenObjectHistory;
