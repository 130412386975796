import React from 'react'

export const GitDiff = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.375 20.25C19.8247 20.25 21 19.0747 21 17.625C21 16.1753 19.8247 15 18.375 15C16.9253 15 15.75 16.1753 15.75 17.625C15.75 19.0747 16.9253 20.25 18.375 20.25Z"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3752 15L18.3751 11.2389C18.375 10.0454 17.9009 8.90093 17.0571 8.05706L13.5 4.5"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 8.25V4.5H17.25"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 9C7.07475 9 8.25 7.82475 8.25 6.375C8.25 4.92525 7.07475 3.75 5.625 3.75C4.17525 3.75 3 4.92525 3 6.375C3 7.82475 4.17525 9 5.625 9Z"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 9L5.62515 12.7611C5.6252 13.9546 6.0993 15.0991 6.94317 15.9429L10.5002 19.5"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 15.75V19.5H6.75"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
