import { ProductListTableItem } from "../redux/productListTypes";

export const controlProducts = (products: ProductListTableItem[]) => {
  let control: boolean = true;

  products?.forEach((item) => {
    if (
      !item.count ||
      !item.price ||
      parseFloat(item.count as string) === 0 ||
      parseFloat(item.price as string) === 0
    ) {
      control = false;
    }
  });

  return control;
};
