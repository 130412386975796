import React from "react";

const MinusCircleIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.25" cy="12" r="7" fill="white" />
      <path
        d="M12.25 2C6.74 2 2.25 6.49 2.25 12C2.25 17.51 6.74 22 12.25 22C17.76 22 22.25 17.51 22.25 12C22.25 6.49 17.76 2 12.25 2ZM16.17 12.75H8.17C7.76 12.75 7.42 12.41 7.42 12C7.42 11.59 7.76 11.25 8.17 11.25H16.17C16.58 11.25 16.92 11.59 16.92 12C16.92 12.41 16.59 12.75 16.17 12.75Z"
        fill="#303541"
      />
    </svg>
  );
};

export default MinusCircleIcon;
