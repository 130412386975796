import { message } from "antd";
import Joi from "joi";
import { ConversionStateType } from "../models/conversion/ConversionModel";

export const validateConverison = (conversion: ConversionStateType) => {
  const spentListValid = Joi.array()
    .items(
      Joi.object({
        payment_type_id: Joi.number().required().label("Kassa tanlanmagan"),
        amount: Joi.number().min(1).required().label("Summa kiritilmagan"),
        valuta_id: Joi.number()
          .min(1)
          .required()
          .label("Valyuta turi kiritilmagan"),
        valuta_history_price: Joi.number()
          .min(1)
          .required()
          .label("Valyuta qiymati kiritilmagan"),
        kirim: Joi.number().default(-1).required()
      })
    )
    .validate(conversion.spent_list);

  const formedListValid = Joi.array()
    .items(
      Joi.object({
        id: Joi.optional(),
        payment_type_id: Joi.number()
          .min(1)
          .required()
          .label("Kassa tanlanmagan"),
        amount: Joi.number().min(1).required().label("Summa kiritilmagan"),
        valuta_id: Joi.number()
          .min(1)
          .required()
          .label("Valyuta turi kiritilmagan"),
        valuta_history_price: Joi.number()
          .min(1)
          .required()
          .label("Valyuta qiymati kiritilmagan"),
        kirim: Joi.number().default(1).required(),
        expense_type_id: Joi.optional()
      })
    )
    .validate(conversion.formed_list);

  if (spentListValid.error) {
    messages(spentListValid.error);
    return true;
  }
  if (formedListValid.error) {
    messages(formedListValid.error);
    return true;
  }
  return false;
};

const messages = (error: Joi.ValidationError | undefined) => {
  return message.warning(String(error).split(`"`)[1]);
};
