import { Button, Form, Modal, Popover, Select } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { DATE_FORMAT } from "../../../models/settings/dateFormat";
import { view } from "../../../models/settings/ViewEnum";
import { useCreditDebitExcel } from "../../../queries/mutations";
import { useCategoryAll, useObjectHistory } from "../../../queries/queries";
import { queryNames } from "../../../queries/queryNames";
import { DatePicker } from "../../../service/DatePicker";
import CalendarIcon from "../../../svg/CalendarIcon";
import DownloadIcon from "../../../svg/DownloadIcon";
import DatePickerExtraFooter from "../common/DatePickerExtraFooter";
import ObjectHistoryTable from "./ObjectHistoryTable";

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  id: number;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

const OneObjectHistory: React.FC<Props> = ({ visible, setVisible, id }) => {
  const [form] = Form.useForm();
  const qc = useQueryClient();

  const returnDate = (): string[] => {
    if (form.getFieldValue("dateArr")?.length > 0) {
      const beginDate = dayjs(form.getFieldValue("dateArr")[0]).format(
        "YYYY-MM-DD"
      );
      const endDate = dayjs(form.getFieldValue("dateArr")[1]).format(
        "YYYY-MM-DD"
      );
      return [beginDate, endDate];
    } else return [];
  };

  const { data, isLoading } = useObjectHistory(
    id,
    returnDate(),
    "all",
    form.getFieldValue("view")
  );
  const [visiblePopover, setVisiblePopover] = useState(false);
  const getExcel = useCreditDebitExcel();
  const allCategories = useCategoryAll();
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [datePickerFilterVisible, setDatePickerFilterVisible] = useState(false);

  //  download excel
  const downloadExcel = (fields: {
    dateArr: string[];
    view: string;
    category: number;
  }) => {
    const beginDate = dayjs(fields.dateArr[0]).format("YYYY-MM-DD");
    const endDate = dayjs(fields.dateArr[1]).format("YYYY-MM-DD");
    const dateArr = [beginDate, endDate];
    const data = {
      type: "BUILDINGS_HISTORY",
      dateArr,
      ID: id,
      view: fields.view,
      category: fields.category,
    };
    getExcel.mutate(data);
    setVisiblePopover(false);
  };

  const rangePickerComp = (
    <div className={"productHistoryMenu"}>
      <Form
        form={form}
        name="basic"
        onFinish={downloadExcel}
        autoComplete="off"
      >
        <Form.Item name="dateArr" rules={[{ required: true, message: "" }]}>
          <RangePicker
            suffixIcon={<CalendarIcon />}
            format={DATE_FORMAT}
            className={"rangePicker"}
            placeholder={["Boshlanish vaqti", "Tugash vaqti"]}
            renderExtraFooter={() => {
              return (
                <DatePickerExtraFooter
                  form={form}
                  visible={setDatePickerVisible}
                />
              );
            }}
            open={datePickerVisible}
            onOpenChange={(e) => setDatePickerVisible(e)}
          />
        </Form.Item>
        <Form.Item name="view" initialValue={view.INCOME}>
          <Select>
            <Option value={view.INCOME}>Kirim</Option>
            <Option value={view.OUTLAY}>Chiqim</Option>
            <Option value={view.RESIDUE}>Qoldiq</Option>
          </Select>
        </Form.Item>
        <Form.Item name="category" initialValue="">
          <Select placeholder="Kategoriyani tanlang">
            <Select.Option key="overall" value="">
              Barcha kategoriyalar
            </Select.Option>
            {allCategories?.data?.map((category) => (
              <Select.Option key={category.id} value={category.id!}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          title="Export PDF (акт сверка)"
          className="rangePicker"
          htmlType="submit"
          type="primary"
        >
          Ok
        </Button>
      </Form>
    </div>
  );

  // change popover visibility
  const changePopoverVisible = (visiblePopover: boolean) => {
    setVisiblePopover(visiblePopover);
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
      className={"productHistoryModal"}
      width={1000}
      footer={false}
      centered
    >
      <div className="objectHistoryCont">
        <div className="productHistoryHeader">
          <div className="title">Export</div>
          <div className="right">
            <Form form={form} name="basic" layout="inline">
              <Form.Item
                name="dateArr"
                rules={[{ required: true, message: "" }]}
              >
                <RangePicker
                  suffixIcon={<CalendarIcon />}
                  format={DATE_FORMAT}
                  className={"rangePicker"}
                  placeholder={["Boshlanish vaqti", "Tugash vaqti"]}
                  renderExtraFooter={() => {
                    return (
                      <DatePickerExtraFooter
                        form={form}
                        visible={setDatePickerFilterVisible}
                      />
                    );
                  }}
                  open={datePickerFilterVisible}
                  onOpenChange={(e) => setDatePickerFilterVisible(e)}
                />
              </Form.Item>
              <Form.Item name="view" initialValue={view.INCOME}>
                <Select
                  onChange={() => {
                    qc.invalidateQueries([queryNames.OBJECT_HISTORY]);
                  }}
                >
                  <Option value={view.INCOME}>Kirim</Option>
                  <Option value={view.OUTLAY}>Chiqim</Option>
                  <Option value={view.RESIDUE}>Qoldiq</Option>
                </Select>
              </Form.Item>
            </Form>
          </div>
        </div>
        <br />
        <ObjectHistoryTable data={data} isLoading={isLoading} />
        <div className="footer">
          <Popover
            trigger="click"
            className="importExcelPopover"
            content={rangePickerComp}
            visible={visiblePopover}
            onVisibleChange={(visible) => {
              changePopoverVisible(visible);
            }}
          >
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "5px",
              }}
            >
              <DownloadIcon />
              <span style={{ marginLeft: "5px" }}>EXPORT</span>
            </Button>
          </Popover>
        </div>
      </div>
    </Modal>
  );
};

export default OneObjectHistory;
