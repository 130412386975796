import { Col, Form, FormInstance, Row, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useObject } from "../../../queries/queries";
import { setCurrentBuildingId } from "../../../redux/actions";
import { RootState } from "../../../redux/rootReducer";
import { DatePicker } from "../../../service/DatePicker";
import { selectFilter } from "../../../service/Helpers";
import OrderProductList from "../parts/OrderProductList";
import { TransferForm } from "./CreateTransfer";
import CreateTransferTable from "./CreateTransferTable";
const { Item } = Form;
const { Option } = Select;
interface CreateTransferFormProps {
  form: FormInstance<TransferForm>;
}

const CreateTransferForm: React.FC<CreateTransferFormProps> = ({ form }) => {
  const { data, isLoading } = useObject<undefined>();
  const dispatch = useDispatch();
  const currentBuildingId = useSelector(
    (state: RootState) => state.productList.currentBuildingId
  );
  return (
    <>
      <Row gutter={16} style={{ margin: "1rem 2.625rem", maxWidth: "1000px" }}>
        <Col span="8">
          <Item
            name="first_building"
            label={
              <>
                Obyekt<span className="text-bold">dan</span>
              </>
            }
          >
            <Select
              onChange={(value) => {
                dispatch(setCurrentBuildingId(value as number));
                if (value === form.getFieldValue("second_building")) {
                  form.resetFields(["second_building"]);
                }
              }}
              filterOption={selectFilter}
              showSearch
              placeholder="Obyekt nomi"
              loading={isLoading}
            >
              {data?.map((item) => (
                <Option key={item.id} value={item.id!}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span="8">
          <Item
            name="second_building"
            label={
              <>
                Obyekt<span className="text-bold">ga</span>
              </>
            }
          >
            <Select
              showSearch
              filterOption={selectFilter}
              placeholder="Obyekt nomi"
              loading={isLoading}
            >
              {data
                ?.filter((item) => item.id !== currentBuildingId)
                ?.map((item) => (
                  <Option key={item.id} value={item.id!}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col span="8">
          <Item name="date" label={"Sana"}>
            <DatePicker
              disabled={isLoading}
              style={{ width: "100%" }}
              placeholder="Sanani kiriting"
              format="DD.MM.YYYY"
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ margin: "1rem 2.625rem" }}>
        <Col span={6}>
          <OrderProductList hasCreateButton={false} isAll={true} />
        </Col>
        <Col span={18}>
          <CreateTransferTable />
        </Col>
      </Row>
    </>
  );
};

export default CreateTransferForm;
