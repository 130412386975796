import { CloseOutlined } from '@ant-design/icons'
import { Popconfirm, Button } from 'antd'
import React from 'react'

interface DeletePaymentPopProps {
  onConfirm: () => void
  title: string
  loading: boolean
}

export const DeletePaymentPop: React.FC<DeletePaymentPopProps> = ({
  onConfirm,
  title,
  loading,
}) => {
  return (
    <Popconfirm
      okButtonProps={{ danger: true, loading }}
      placement="topRight"
      title={title}
      onConfirm={onConfirm}
      okText="Ha"
      cancelText="Yoq"
    >
      <Button danger size="small" type="text" icon={<CloseOutlined />}></Button>
    </Popconfirm>
  )
}
