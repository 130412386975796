import React from 'react'
import {Diagram} from '../../../../models/settings/ProductTableInterface'
import Graph from '../../productGraph/Graph'
import {Empty, Spin} from 'antd'

interface LineGraphInterface {
  beginData: Diagram[]
  loading: boolean,
  width: number
}

export const LineGraph: React.FC<LineGraphInterface> = ({beginData, loading,width}) => {
  if (loading)
    return (
      <div className={'spinnerChart'}>
        <Spin size={'large'}/>
      </div>
    )
  if (beginData.length === 0)
    return (
      <Empty
        className={'emptyChart'}
        description={<span>Ma'lumot mavjud emas</span>}
      />
    )
  return (
    <Graph width={width} height={'98%'} beginData={beginData}/>
  )
}

export default LineGraph
