import { LeftCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import mainPaths from "../../../routes/main/mainPaths";
import { rootPaths } from "../../../routes/rootRoutes";
import SearchHeader from "../universialComponents/SearchHeader";
import TransfersTable from "./TransfersTable";

interface TransferPageProps {}

const TransferPage: React.FC<TransferPageProps> = () => {
  useDocumentTitle("Transferlar");
  const { push } = useHistory();

  return (
    <>
      <SearchHeader
        title={
          <>
            <Link
              to={`${rootPaths.MAIN}${mainPaths.ORDERS}`}
              className="cOrder_backIcon"
            >
              <LeftCircleOutlined />
            </Link>
            Transfer
          </>
        }
        buttons={
          <Button
            icon={<PlusOutlined />}
            onClick={() => push(`${rootPaths.MAIN}${mainPaths.TRANSFER}/new`)}
            className={"cOrder_headerBtn button"}
          >
            Transfer qilish
          </Button>
        }
        inputPlaceholder="Id , obyect nomlari bo'yicha qidirish"
      />
      <TransfersTable />
    </>
  );
};

export default TransferPage;
