import axios from "axios";
import { UserService } from "../service/UserService";

// export const API_URL = 'https://backend.uyqur.uz/public/api'

const $api = axios.create({
  baseURL: localStorage.getItem("domen")!
});

$api.interceptors.request.use((config) => {
  const token = UserService.getTokenLocal();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

$api.interceptors.response.use(undefined, (error) => {
  if (error.response?.status === 401 && window.location.pathname !== "/login") {
    UserService.logout();
  }
  return Promise.reject(error);
});

export default $api;
