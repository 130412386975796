const UploadIcon: React.FC = () => {
    return (
        <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3.5" y="3" width="40" height="40" rx="20" fill="#F2F4F7"/>
            <path
                d="M20.167 26.3333L23.5003 23M23.5003 23L26.8337 26.3333M23.5003 23V30.5M30.167 26.9524C31.1849 26.1117 31.8337 24.8399 31.8337 23.4167C31.8337 20.8854 29.7816 18.8333 27.2503 18.8333C27.0682 18.8333 26.8979 18.7383 26.8054 18.5814C25.7187 16.7374 23.7124 15.5 21.417 15.5C17.9652 15.5 15.167 18.2982 15.167 21.75C15.167 23.4718 15.8632 25.0309 16.9894 26.1613"
                stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="3.5" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" strokeWidth="6"/>
        </svg>
    )
}

export default UploadIcon;