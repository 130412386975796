import React from "react";
import { FormInstance, Tag } from "antd";
import dayjs from "rc-picker/node_modules/dayjs";

type Props = {
  form: FormInstance;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const DateFilterFooter: React.FC<Props> = ({ form, setVisible }) => {
  const green = "#34DBA1";

  // till today
  const untilToday = () => {
    form?.setFieldsValue({
      date: [dayjs(0), dayjs()],
    });
    setVisible(false);
  };

  // today
  const today = () => {
    form?.setFieldsValue({
      date: [dayjs(), dayjs()],
    });
    setVisible(false);
  };

  // yesterday
  const yesterday = () => {
    form?.setFieldsValue({
      date: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    });
    setVisible(false);
  };

  // month
  const month = () => {
    form?.setFieldsValue({
      date: [dayjs().startOf("month"), dayjs().endOf("month")],
    });
    setVisible(false);
  };

  // year
  const year = () => {
    form?.setFieldsValue({
      date: [dayjs().startOf("year"), dayjs().endOf("year")],
    });
    setVisible(false);
  };

  //previous month
  const previousMonth = () => {
    form?.setFieldsValue({
      date: [
        dayjs().startOf("month").subtract(1, "month"),
        dayjs().endOf("month").subtract(1, "month"),
      ],
    });
    setVisible(false);
  };

  return (
    <div className="extraFooter">
      <Tag onClick={untilToday} color={green}>
        Shu kungacha
      </Tag>

      <Tag onClick={today} color={green}>
        Bugun
      </Tag>
      <Tag onClick={yesterday} color={green}>
        Kecha
      </Tag>
      <Tag onClick={previousMonth} color={green}>
        Oldingi oy
      </Tag>
      <Tag onClick={month} color={green}>
        Joriy oy
      </Tag>
      <Tag onClick={year} color={green}>
        Joriy yil
      </Tag>
    </div>
  );
};

export default DateFilterFooter;
