import { UserInterface } from '../models/interfaces/UserInterface'
import { USER_LOGIN, USER_LOGOUT } from './types'

export type UserLoginAction = (
  user: UserInterface,
  token: string
) => {
  type: typeof USER_LOGIN
  payload: { user: UserInterface; token: string }
}
export type UserLogoutAction = () => {
  type: typeof USER_LOGOUT
}

export type UserActionType =
  | ReturnType<UserLoginAction>
  | ReturnType<UserLogoutAction>

/// action creators ///
export const userLogin: UserLoginAction = (user, token) => ({
  type: USER_LOGIN,
  payload: { user, token },
})
export const userLogout: UserLogoutAction = () => ({
  type: USER_LOGOUT,
})
