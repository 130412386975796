import React, { useEffect, useState } from "react";
import { ProductStats } from "./ProductStatsTypes";
import {
  DonutChartInterface,
  ObjectExpenseTypes
} from "../objectExpense/ObjectExpenseTypes";
import { convertLongMoney, generateColor } from "../../../../service/Helpers";
import { Spin } from "antd";
import colors from "../colors";
import { ResponsivePie } from "@nivo/pie";
import { mathRound } from "../../../../service/mathRound";
import { animated } from "@react-spring/web";

interface ProductStatsDonutChartInterface {
  allData: ProductStats | undefined;
  isLoading: boolean;
}

const ProductStatsDonutChart: React.FC<ProductStatsDonutChartInterface> = ({
  allData,
  isLoading
}) => {
  const [chartData, setChartData] = useState<{
    data: DonutChartInterface[];
    labels: ObjectExpenseTypes[];
    allSum: number;
    defaultData: DonutChartInterface[];
  }>({
    data: [],
    allSum: 0,
    labels: [],
    defaultData: []
  });

  useEffect(() => {
    const newArrData: DonutChartInterface[] = [];
    const newArrLabels: ObjectExpenseTypes[] = [];
    let sum = 0;
    if (allData?.orders && allData?.orders.length > 0) {
      allData?.orders?.forEach((order, index) => {
        let oneSum = order.sum_price;
        const transfer = allData?.transfer.find((tr) => tr.name === order.name);
        if (transfer) {
          oneSum += transfer.sum_price;
        }
        oneSum = Math.ceil(oneSum);
        if (colors[index]) {
          newArrData.push({
            color: colors[index],
            id: order.name,
            label: order.name,
            value: oneSum
          });
          newArrLabels.push({
            color: colors[index],
            summa: oneSum,
            name: order.name
          });
        } else {
          const color = generateColor();
          newArrData.push({
            color: color,
            id: order.name,
            label: order.name,
            value: oneSum
          });
          newArrLabels.push({
            color,
            summa: oneSum,
            name: order.name
          });
        }
        sum += oneSum;
      });
    } else {
      allData?.transfer?.forEach((transfer, index) => {
        let oneSum = transfer.sum_price;
        const order = allData?.orders.find((or) => or.name === transfer.name);
        if (transfer) {
          oneSum += order?.sum_price || 0;
        }
        oneSum = Math.ceil(oneSum);
        if (colors[index]) {
          newArrData.push({
            color: colors[index],
            id: transfer.name,
            label: transfer.name,
            value: oneSum
          });
          newArrLabels.push({
            color: colors[index],
            summa: oneSum,
            name: transfer.name
          });
        } else {
          const color = generateColor();
          newArrData.push({
            color: color,
            id: transfer.name,
            label: transfer.name,
            value: oneSum
          });
          newArrLabels.push({
            color,
            summa: oneSum,
            name: transfer.name
          });
        }
        sum += oneSum;
      });
    }
    setChartData({
      data: newArrData,
      labels: newArrLabels,
      allSum: sum,
      defaultData: newArrData
    });
  }, [allData]);

  if (isLoading)
    return (
      <div className={"spinnerChart"}>
        <Spin size={"large"} />
      </div>
    );

  const centeredMetric: (i: any) => any = ({ centerX, centerY }) => {
    const total = convertLongMoney(chartData.allSum);
    return (
      <>
        <text
          x={centerX}
          y={centerY - 20}
          textAnchor="middle"
          dominantBaseline="central"
          fill={"#9AA1A9"}
          fontWeight={"600"}
          cursor={"default"}
          fontSize={"14px"}
          fontFamily={"Gilroy"}
          style={{
            userSelect: "none"
          }}
        >
          Umumiy
        </text>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          fill={"#505D6F"}
          fontWeight={"600"}
          cursor={"default"}
          fontSize={"18px"}
          fontFamily={"Gilroy"}
          style={{
            userSelect: "none"
          }}
        >
          {total}
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          fill={"#9AA1A9"}
          fontWeight={"600"}
          cursor={"default"}
          fontSize={"14px"}
          fontFamily={"Gilroy"}
          style={{
            userSelect: "none"
          }}
        >
          so’m
        </text>
      </>
    );
  };

  const returnName: (name: string) => string = (name) => {
    if (name.length > 20) {
      return name.slice(0, 20) + " ...";
    } else {
      return name;
    }
  };

  const changeChartData = (label: string) => {
    if (chartData.data.some((item) => item.label === label)) {
      setChartData({
        labels: chartData.labels,
        allSum: chartData.allSum,
        data: chartData.data.filter((item) => item.label !== label),
        defaultData: chartData.defaultData
      });
    } else {
      const index = chartData.defaultData.findIndex(
        (item) => item.label === label
      );
      setChartData({
        labels: chartData.labels,
        allSum: chartData.allSum,
        defaultData: chartData.defaultData,
        data: [...chartData.data, chartData.defaultData[index]]
      });
    }
  };

  return (
    <div className={"cont"}>
      <div className={"title"}>Mahsulotni obyektlarda taqsimlanishi (so’m)</div>
      <div className={"body building"}>
        <div className={"info building"}>
          <div className="info_cont">
            <div className="first">
              <p className={"title"}>Mahsulot nomi</p>
              <p className={"count"} title={allData?.name}>
                {returnName(allData?.name || "")}
              </p>
            </div>
            <div>
              <p className={"title"}>Umumiy soni</p>
              <p className={"count"}>
                {allData?.count?.toLocaleString("ru") +
                  " " +
                  allData?.product_type_name}
              </p>
            </div>
          </div>
        </div>
        <div className={"chart"}>
          <ResponsivePie
            data={chartData.data}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.65}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            colors={{ datum: "data.color" }}
            borderColor={{ from: "color", modifiers: [["darker", 0]] }}
            enableArcLinkLabels={false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsComponent={({ datum, style }) => {
              const newLabel = mathRound(
                (100 * datum.value) / chartData.allSum,
                1
              );
              const index = chartData.data.findIndex(
                (item) => item.label === datum.label
              );
              return index > -1 ? (
                <animated.g
                  transform={style.transform as any}
                  style={{ pointerEvents: "none" }}
                >
                  <text
                    fontFamily={"Gilroy"}
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={"#fff"}
                    style={{
                      fontSize: 14,
                      fontWeight: 800
                    }}
                  >
                    {newLabel} %
                  </text>
                </animated.g>
              ) : (
                <text></text>
              );
            }}
            arcLabelsRadiusOffset={0.35}
            arcLabelsSkipAngle={7}
            layers={[
              "arcs",
              "arcLabels",
              "arcLinkLabels",
              "legends",
              centeredMetric
            ]}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            tooltip={(e) => {
              return (
                <div className={"tooltip"}>
                  <div className={"itemCont"}>
                    <div className={"title"}>Obyekt</div>
                    <div className={"data"}>{e.datum.label}</div>
                  </div>
                  <div className={"itemCont"}>
                    <div className={"title"}>Narxi</div>
                    <div className={"data"}>
                      {e.datum.value.toLocaleString("ru") + " so'm"}
                    </div>
                  </div>
                </div>
              );
            }}
            legends={[]}
          />
        </div>
        <div className={"labelsCont"}>
          {chartData.labels.map((label, index) => (
            <div
              className={"label"}
              key={index}
              style={{
                cursor: "pointer"
              }}
            >
              <span style={{ backgroundColor: label.color }} />
              <p onClick={() => changeChartData(label.name)}>{label.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductStatsDonutChart;
