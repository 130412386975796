import React from "react";

const SliderVerticalIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99935 2H10.9993C11.4127 2 11.7793 2.01333 12.106 2.06C13.8593 2.25333 14.3327 3.08 14.3327 5.33333V8C14.3327 10.2533 13.8593 11.08 12.106 11.2733C11.7793 11.32 11.4127 11.3333 10.9993 11.3333H4.99935C4.58602 11.3333 4.21935 11.32 3.89268 11.2733C2.13935 11.08 1.66602 10.2533 1.66602 8V5.33333C1.66602 3.08 2.13935 2.25333 3.89268 2.06C4.21935 2.01333 4.58602 2 4.99935 2Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33398 13.3333H13.334"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SliderVerticalIcon;
