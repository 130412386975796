import React from 'react'

export const LogOut = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#EB5757" />
      <path
        d="M10.999 6.62512V10.7814"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6251 6.96583C14.4946 7.53172 15.158 8.36344 15.5163 9.33701C15.8746 10.3106 15.9088 11.3739 15.6136 12.3684C15.3185 13.363 14.7099 14.2356 13.8785 14.8561C13.0471 15.4766 12.0374 15.8118 11 15.8118C9.96256 15.8118 8.95291 15.4766 8.12152 14.8561C7.29012 14.2355 6.6815 13.363 6.38636 12.3684C6.09122 11.3739 6.12537 10.3106 6.48372 9.33699C6.84206 8.36342 7.50542 7.53171 8.37491 6.96582"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
