import { Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./universialComponents.scss";
import { Search } from "../../../svg/Search";
import { RootState } from "../../../redux/rootReducer";
import { searchHeaderSearchString } from "../../../redux/actions";
import { SearchHeaderInterface } from "../../../models/interfaces/SearchHeaderInterface";

const SearchHeader: React.FC<SearchHeaderInterface> = (props) => {
  const dispatch = useDispatch();
  const searchString = useSelector(
    (state: RootState) => state.searchHeader.searchString
  );

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchValue(searchString);
  }, [searchString]);

  useEffect(() => {
    return () => {
      dispatch(searchHeaderSearchString(""));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="searchHeaderCont">
        <Typography className="title">{props.title}</Typography>
        {props.inputPlaceholder && (
          <Input
            value={searchValue}
            onChange={(e: any) => {
              setSearchValue(e.target.value || "");
            }}
            onPressEnter={() => {
              dispatch(searchHeaderSearchString(searchValue));
            }}
            className="input"
            placeholder={props.inputPlaceholder}
            suffix={<Search />}
          />
        )}
        {props.buttons}
      </div>
      <hr className="afterHr" />
    </>
  );
};

export default SearchHeader;
