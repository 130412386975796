import React, { FC } from 'react'
import { Content } from './Content'
import './Main.scss'
import { Menu } from './menu/Menu'
import ProductGraphCont from "./productGraph/ProductGraphCont";

export const Main: FC = () => {
  return (
    <div className="mainCont">
      <Menu />
      <Content />
      <ProductGraphCont />
    </div>
  )
}
