import React from 'react'

export const Ellipse = () => {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.63603 4.63603L0 0V22L4.63604 17.364C8.15076 13.8492 8.15075 8.15075 4.63603 4.63603Z"
        fill="#33D79F"
      />
      <circle cx="13.5" cy="11.5" r="2.5" fill="#33D79F" />
    </svg>
  )
}
