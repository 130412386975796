import React from "react";
import { useOrderDetails } from "../../../../queries/queries";
import "./OrderDetails.scss";
import { Button, Col, Empty, Row, Spin } from "antd";
import PrinterIcon from "../../../../svg/PrinterIcon";
import ReactToPrint from "react-to-print";
import { useLocation } from "react-router-dom";

interface OrderDetailsInterface {
  id: number;
  valuta: string;
}

const OrderDetails: React.FC<OrderDetailsInterface> = ({ id, valuta }) => {
  const location = useLocation();
  const locationPath = location.pathname.split("/")[2];

  const { data, isLoading } = useOrderDetails(id);

  const calculateSum = (count: number, price: number) => {
    return (count * price).toLocaleString("ru") + " " + valuta;
  };

  if (isLoading) return <Spin className={"spinCont"} />;

  const handleAfterPrint = () => {
    const head: any = document.querySelectorAll(".ant-table-thead");
    const printBtn: any = document.querySelectorAll(".pdfBtn");
    const printElem = document.querySelector(".oneSupplierTable");

    printBtn.forEach((btn: any) => {
      btn.style.display = "flex";
    });

    head.forEach((head: any) => {
      head.style.display = "table-header-group";
    });

    printElem?.querySelectorAll("tbody > tr").forEach((item: any) => {
      item.style.display = "table-row";
    });
  };

  return (
    <>
      <div className="orderDetailsPage" id={`Id_${id}`}>
        <Row className={"headerOrderDetails"} gutter={[16, 8]}>
          <Col className={"item"} span={2}>
            {/* PARTIYA #{id}
            {isFetching && <LoadingOutlined style={{ marginLeft: "10px" }} />} */}
            #
          </Col>
          <Col className={"item"} span={4}>
            Mahsulot nomi
          </Col>
          <Col className={"item"} span={4}>
            Miqdori
          </Col>
          <Col className={"item"} span={4}>
            O’lchami
          </Col>
          <Col className={"item"} span={4}>
            Birlik narx
          </Col>
          <Col className={"item"} span={4}>
            <span
              style={{
                marginRight: "10px"
              }}
            >
              Jami
            </span>
            {locationPath === "supplier" && (
              <ReactToPrint
                onBeforeGetContent={() => {
                  const printElem = document.querySelector(".oneSupplierTable");

                  printElem!.querySelector("thead")!.style.display = "none";

                  printElem
                    ?.querySelectorAll("tbody > tr")
                    .forEach((item: any) => {
                      if (!item.className.includes("Id_" + id)) {
                        item.style.display = "none";
                      }
                    });

                  let nextElement: any = document.querySelector(
                    ".Id_" + id
                  )?.nextElementSibling;

                  nextElement.style.display = "table-row";
                }}
                onAfterPrint={handleAfterPrint}
                onPrintError={handleAfterPrint}
                trigger={() => (
                  <Button className="pdfBtn">
                    <PrinterIcon />
                  </Button>
                )}
                content={() => {
                  const printElem = document.querySelector(".oneSupplierTable");
                  return printElem;
                }}
              />
            )}
          </Col>
        </Row>
        {data?.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>Ma'lumot mavjud emas</span>}
          />
        )}
        {data?.map((item, index) => (
          <Row className={"bodyOrderDetails"} gutter={[16, 8]} key={index}>
            <Col style={{ textAlign: "left" }} className={"item"} span={2}>
              {index + 1}
            </Col>
            <Col className={"item"} span={4}>
              {item.name}
            </Col>
            <Col className={"item"} span={4}>
              {item.count}
            </Col>
            <Col className={"item"} span={4}>
              {item.product_type}
            </Col>
            <Col className={"item"} span={4}>
              {item.price.toLocaleString("ru")}
            </Col>
            <Col className={"item"} span={4}>
              {calculateSum(item.count, item.price)}
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
};

export default OrderDetails;
