import React from "react";
import {BuildingCounts} from "../../../models/settings/ProductTableInterface";

interface CompanyCountsInterface {
  data: BuildingCounts[]
}

const CompanyCounts:React.FC<CompanyCountsInterface> = ({data}) => {
  return (
    <>
      <div className={'companyCountsTitle'}>
        Taqsimlanishi:
      </div>
      <div className={'scroll'}>
        {data.map((item,index) => (
          <div className={"item"} key={index}>
            <span>
              {item.name}
            </span>
            <span>
              {item.count.toLocaleString('ru')}
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

export default CompanyCounts