import { Main } from '../components/main/Main'

export const rootPaths = {
  MAIN: '/main',
  INDEX: '/',
  LOGIN: '/login',
}

export const rootRoutes = [
  {
    path: rootPaths.MAIN,
    component: Main,
    exact: false,
  },
]
