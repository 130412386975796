import {
  Button,
  Divider,
  Form,
  message,
  Modal,
  Rate,
  Row,
  Skeleton,
  Space,
} from "antd";
import {
  CheckCircleOutlined,
  DollarOutlined,
  LeftCircleOutlined,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import dayjs from "rc-picker/node_modules/dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  addToList,
  // orderIdAction,
  resetProductList,
} from "../../../redux/actions";
import NdsModal from "./NdsModal";
import OrderForm from "./OrderForm";
import { toTrim } from "../../../helper/toTrim";
import UnverifiedFiles from "./UnverifiedFiles";
import ImportExcelModal from "./ImportExcelModal";
import DownloadIcon from "../../../svg/DownloadIcon";
import { ExpanseTableType } from "./CreateOrderTypes";
import { rootPaths } from "../../../routes/rootRoutes";
import { RootState } from "../../../redux/rootReducer";
import mainPaths from "../../../routes/main/mainPaths";
import ThreePointsIcon from "../../../svg/ThreePointsIcon";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useCUOrderMutation } from "../../../queries/mutations";
import SearchHeader from "../universialComponents/SearchHeader";
import { SupplierCUModal } from "../supplier/all/SupplierCUModal";
import { useOneOrderDetails, useOrderSelects } from "../../../queries/queries";

import "./orders.scss";
import { controlProducts } from "../../../helper/controlProducts";
interface CreateOrderProps {}

export interface UnverifiedProduct {
  isOpen: boolean;
  data: any[] | undefined;
}

const CreateOrder: React.FC<CreateOrderProps> = () => {
  const params = useParams<{ orderId: string }>();
  const { push } = useHistory();
  const [form] = Form.useForm();
  //modal isOpen
  const [isOpen, setIsOpen] = useState(false);
  //CU mutation
  const mutation = useCUOrderMutation();
  const dispatch = useDispatch();
  //data refs
  const submitType = useRef<"" | "save" | "confirm">("");
  const remainderTotal = useRef(0);
  const totalExpansesArray = useRef<ExpanseTableType[]>([]);
  const [unvProduct, setUnvProduct] = useState<UnverifiedProduct>();
  const [importFileLoad, setImportFileLoad] = useState<boolean>(false);
  // const orderIdRed = useSelector((state: RootState) => state.orderIdReducer);
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [isVisibleNds, setIsVisibleNds] = useState<boolean>(false);
  const { data, isLoading } = useOrderSelects();
  const [visibleNds, setVisibleNds] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ndsValue, setNdsValue] = useState<number | undefined>(undefined);

  const products = useSelector(
    (state: RootState) => state.productList.products
  );
  const valuta = useSelector((state: RootState) => state.productList.valuta);

  const returnProductNds = (value: number): number => {
    if (value === 0) {
      return 1;
    } else if (value === 15) {
      return 1.15;
    } else if (value === 12) {
      return 1.12;
    } else {
      return 1;
    }
  };

  const totolPrice = useMemo(
    () =>
      products.reduce(
        (acc, value) =>
          acc +
          (value.price as number) *
            (value.count as number) *
            returnProductNds(value.nds!),
        0
      ),
    [products]
  );

  const getOrderId = (): string | number => {
    if (params.orderId === "new") {
      return "new";
    } else {
      let id = params.orderId.split("-");
      return id[0];
    }
  };

  const {
    data: orderData,
    isLoading: isOrderDataLoading,
    isFetching: isOrderDataFetching,
  } = useOneOrderDetails(getOrderId());

  const handleFinish = async (values: any) => {
    if (products.length === 0) {
      message.error("Partiyani yaratish uchun birorta mahsulot yarating", 5);
      return;
    }
    if (remainderTotal.current > 0) {
      message.error("To'lov summasi mahsulotlar narxidan oshib ketdi", 5);
      return;
    }

    if (submitType.current === "save") {
      values.rating = 0;
    }

    if (controlProducts(products)) {
      await mutation.mutateAsync({
        ...values,
        date: dayjs(values.date),
        products,
        valuta_id: values?.valuta_id ? values?.valuta_id : data?.valuties[0].id,
        payment_type_id: values?.payment_type_id
          ? values?.payment_type_id
          : data?.payment_types[0].id,
        payments: totalExpansesArray.current,
        status: submitType.current === "save" ? "0" : "1",
        number: values?.number ? values?.number : undefined,
        rating: values?.rating ? values?.rating : 5,
        description: values?.description ? values?.description : undefined,
      });
      setIsOpen(false);
      if (submitType.current === "confirm") {
        push(`${rootPaths.MAIN}${mainPaths.ORDERS}`);
      }
    } else {
      message.error(
        "Mahsulot miqdori yoki birlik narxi kiritilmagan (Mahsulot miqdori va birlik narxi 0 dan katta bo'lishi zarur)"
      );
    }
  };

  useEffect(
    () => () => {
      dispatch(resetProductList());
    },
    [dispatch]
  );

  const checkList = useSelector(
    (state: RootState) => state.productList.checkList
  );

  // import excel file
  const importFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    setImportModalVisible(false);
    reader.onloadstart = () => {
      setImportFileLoad(true);
    };
    reader.onload = (event: ProgressEvent<FileReader>) => {
      setImportFileLoad(true);
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(sheet) as any[];
      const filterRows: any[] = checkList
        .map((item) => {
          if (
            rows?.some(
              (obj: any) =>
                toTrim(String(obj["Mahsulot nomi"]).toUpperCase()) ===
                item?.name?.toUpperCase()
            ) &&
            !products?.some(
              (obj: any) =>
                toTrim(String(obj?.name).toUpperCase()) ===
                item?.name?.toUpperCase()
            )
          ) {
            const rw = rows?.filter(
              (row) =>
                toTrim(String(row["Mahsulot nomi"]).toUpperCase()) ===
                item?.name?.toUpperCase()
            )[0];
            return {
              ...item,
              check: null,
              last_price: null,
              date: form?.getFieldValue("date"),
              building_id: form?.getFieldValue("building_id"),
              count: rw["Miqdori"],
              price: rw["Birlik narxi"],
              maxCount: item.count,
            };
          }
        })
        .filter((item) => item);

      filterRows.forEach((item) => {
        dispatch(
          addToList({
            ...item,
          })
        );
      });

      setUnvProduct(() => {
        const notProduct = rows?.filter(
          (item) =>
            !checkList?.some(
              (list) =>
                toTrim(String(item["Mahsulot nomi"]).toUpperCase()) ===
                list?.name?.toUpperCase()
            )
        );

        return {
          isOpen: false,
          data: [...notProduct, ...(unvProduct?.data ?? [])],
        };
      });
    };

    reader.onloadend = () => {
      setTimeout(() => {
        setImportFileLoad(false);
      }, 1000);
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    const input = document.getElementById("excel_file");
    input?.addEventListener("change", (e) => importFile(e as any));
    return () => {
      input?.removeEventListener("change", (e) => importFile(e as any));
    };
  }, []);

  const visibleUnvBtn = (): boolean => {
    if (unvProduct?.data && unvProduct?.data?.length > 0) {
      return false;
    } else return true;
  };

  const styleUnvElem = (): React.CSSProperties | undefined => {
    return {
      opacity: visibleUnvBtn() ? 0 : 1,
      cursor: visibleUnvBtn() ? "default" : "pointer",
      position: visibleUnvBtn() ? "absolute" : "static",
    };
  };

  const buttons = (
    <Space>
      {!isOrderDataLoading && valuta.price !== 1 ? (
        <>
          <div>
            <span style={{ fontSize: "1rem" }} className="text-bold">
              1 {valuta.name}
            </span>{" "}
            ={" "}
            <span style={{ fontSize: "1rem" }} className="text-bold">
              {`${valuta.price.toLocaleString("ru")} `}
            </span>
            so'm
          </div>
          <Divider type="vertical" />
        </>
      ) : null}
      {isOrderDataLoading ? (
        <Space>
          <Skeleton.Button active size="default" />
          <Skeleton.Button active size="default" />
          <Skeleton.Button active size="default" />
          <Skeleton.Button active size="default" />
        </Space>
      ) : (
        <>
          <Button
            onClick={() =>
              setUnvProduct({ isOpen: true, data: unvProduct?.data })
            }
            disabled={visibleUnvBtn()}
            style={styleUnvElem()}
            className="uploadBtn"
          >
            Tasdiqlanmagan
            <div className="notUploadCount">
              {unvProduct?.data?.length ?? 0}
            </div>
          </Button>
          <Button
            onClick={() => setImportModalVisible(true)}
            className="uploadBtn"
          >
            <DownloadIcon /> Import
          </Button>
          <Button
            onClick={() => setIsModalOpen(true)}
            icon={<DollarOutlined style={{ display: "flex" }} />}
            className="button cOrder_paymentBtn"
            loading={isLoading}
          >
            To'lovlar
          </Button>
          <ImportExcelModal
            setIsOpen={setImportModalVisible}
            isOpen={importModalVisible}
            importFile={importFile}
          />
          {orderData?.status !== "1" && (
            <Button
              disabled={mutation.isLoading || products.length === 0}
              loading={mutation.isLoading && submitType.current === "save"}
              htmlType="submit"
              className="button"
              onClick={() => (submitType.current = "save")}
              icon={<SaveOutlined style={{ display: "flex" }} />}
            >
              Saqlash
            </Button>
          )}
          <Button
            disabled={
              mutation.isLoading ||
              products.length === 0 ||
              orderData?.transfers === "1"
            }
            className="button"
            onClick={() => {
              submitType.current = "confirm";
              form.submit();
            }}
            icon={<CheckCircleOutlined style={{ display: "flex" }} />}
          >
            Tasdiqlash
          </Button>
          <Button className="nds_button" onClick={() => setIsVisibleNds(true)}>
            <ThreePointsIcon />
          </Button>
        </>
      )}
      <Modal
        footer={null}
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        className="editModal"
        title="Partiya holatini baholang. Bu keyingi xaridlarda muhim!"
      >
        <Row justify="center" style={{ marginTop: "1rem" }}>
          <Form.Item name="rating">
            <Rate style={{ margin: "0 auto" }} />
          </Form.Item>
        </Row>
        <Row justify="center" style={{ marginBottom: "1rem" }}>
          <Button
            type="primary"
            disabled={mutation.isLoading}
            loading={mutation.isLoading && submitType.current === "confirm"}
            className="button"
            onClick={() => {
              submitType.current = "confirm";
              form.submit();
            }}
            icon={<CheckCircleOutlined style={{ display: "inline-flex" }} />}
          >
            Tasdiqlash
          </Button>
        </Row>
      </Modal>
    </Space>
  );

  const title = (
    <>
      <Link
        to={`${rootPaths.MAIN}${mainPaths.ORDERS}`}
        className="cOrder_backIcon"
      >
        <LeftCircleOutlined />
      </Link>
      <Space size="large">
        Kirim
        {isOrderDataFetching && <LoadingOutlined />}
      </Space>
    </>
  );

  useDocumentTitle("Kirim qilish");

  return (
    <>
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        className="cOrder"
      >
        <SearchHeader buttons={buttons} title={title} />
        <OrderForm
          form={form}
          data={data}
          nds={visibleNds}
          isLoading={isLoading}
          orderData={orderData}
          setNds={setVisibleNds}
          totalPrice={totolPrice}
          orderId={params.orderId}
          isModalOpen={isModalOpen}
          importFileLoad={importFileLoad}
          setIsModalOpen={setIsModalOpen}
          remainderTotal={remainderTotal}
          totalExpansesArray={totalExpansesArray}
          isOrderDataLoading={isOrderDataFetching}
          ndsValue={ndsValue}
        />
        <SupplierCUModal orderForm={form} />
        <NdsModal
          isLoading={isLoading}
          orderId={params.orderId}
          data={data}
          form={form}
          open={isVisibleNds}
          setOpen={setIsVisibleNds}
          orderData={orderData}
          isOrderDataLoading={isOrderDataFetching}
          nds={visibleNds}
          setNds={setVisibleNds}
          ndsValue={ndsValue}
          setNdsValue={setNdsValue}
        />
      </Form>
      <UnverifiedFiles
        form={form}
        state={unvProduct}
        setState={setUnvProduct}
      />
    </>
  );
};

export default CreateOrder;
