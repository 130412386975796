import React from "react";

const ConversionIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66602 8.8265V7.67318C1.66602 6.29318 2.79268 5.1665 4.17268 5.1665H11.826C13.206 5.1665 14.3327 6.29318 14.3327 7.67318V8.63318H12.986C12.6127 8.63318 12.2727 8.77983 12.026 9.03316C11.746 9.3065 11.586 9.69983 11.626 10.1198C11.686 10.8398 12.346 11.3665 13.066 11.3665H14.3327V12.1598C14.3327 13.5398 13.206 14.6665 11.826 14.6665H8.17268"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66602 8.27337V5.22674C1.66602 4.4334 2.15268 3.72671 2.89268 3.44671L8.18602 1.44671C9.01268 1.13337 9.89935 1.74673 9.89935 2.63339V5.16672"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0386 9.31327V10.6866C15.0386 11.0533 14.7452 11.3533 14.3719 11.3666H13.0652C12.3452 11.3666 11.6852 10.84 11.6252 10.12C11.5852 9.69995 11.7452 9.30662 12.0252 9.03328C12.2719 8.77995 12.6119 8.6333 12.9852 8.6333H14.3719C14.7452 8.64663 15.0386 8.94661 15.0386 9.31327Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66602 8H9.33268"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11H5.56C5.98666 11 6.33333 11.3466 6.33333 11.7733V12.6267"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.81333 10.1865L2 10.9998L2.81333 11.8132"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33333 14.5197H2.77334C2.34667 14.5197 2 14.173 2 13.7464V12.8931"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52148 15.3332L6.33482 14.5199L5.52148 13.7065"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ConversionIcon;
