import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "rc-picker/node_modules/dayjs";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import React, { MutableRefObject, useEffect, useState } from "react";

import {
  CreateOrderAllSelects,
  ExpanseTableType,
  OneOrderDataType,
} from "./CreateOrderTypes";
import {
  setBuildingForAll,
  setDateForAll,
  setPaymentAndValuta,
  setProductList,
  setValuta,
  toggleSupplierCUModal,
} from "../../../redux/actions";
import PaymentModal from "./PaymentModal";
import SelectIcon from "../../../svg/SelectIcon";
import OrderProductList from "./OrderProductList";
import CreateOrderTable from "./CreateOrderTable";
import { RootState } from "../../../redux/rootReducer";
import { selectFilter } from "../../../service/Helpers";
import { DatePicker } from "../../../service/DatePicker";
import { useOrderProductList } from "../../../queries/queries";
import { PlusOutlined } from "@ant-design/icons";

const { Item } = Form;
const { Option } = Select;

interface OrderFormProps {
  nds: boolean;
  orderId: string;
  totalPrice: number;
  isLoading: boolean;
  form: FormInstance<any>;
  importFileLoad?: boolean;
  isModalOpen: boolean;
  isOrderDataLoading: boolean;
  data?: CreateOrderAllSelects;
  orderData?: OneOrderDataType;
  remainderTotal: MutableRefObject<number>;
  setNds: React.Dispatch<React.SetStateAction<boolean>>;
  totalExpansesArray: MutableRefObject<ExpanseTableType[]>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ndsValue?: number;
}

export type ConfirmedPayments = {
  expanses: ExpanseTableType[];
  payments: ExpanseTableType[];
};

const OrderForm: React.FC<OrderFormProps> = ({
  nds,
  form,
  data,
  setNds,
  orderId,
  ndsValue,
  isLoading,
  orderData,
  totalPrice,
  isModalOpen,
  remainderTotal,
  importFileLoad,
  setIsModalOpen,
  totalExpansesArray,
  isOrderDataLoading,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const orderIdRed = useSelector(
    (state: RootState) => state.orderIdReducer.copy
  );
  const { data: products, isLoading: isProductListLoading } =
    useOrderProductList();
  const valutaPrice = useSelector(
    (state: RootState) => state.productList.valuta.price
  );
  const valuta = useSelector((state: RootState) => state.productList.valuta);

  const [total, setTotalPrices] = useState({
    expanses: 0,
    payments: 0,
    currentPayments: 0,
  });
  const [confirmedPayments, setConfirmedPayments] = useState<ConfirmedPayments>(
    {
      payments: [],
      expanses: [],
    }
  );

  remainderTotal.current =
    total.payments + total.currentPayments - totalPrice * valutaPrice;

  const onBuildingChange = (value: number) => {
    if (orderId) {
      dispatch(setBuildingForAll(value));
    }
  };

  const onDateChange = (_value: Dayjs | any, dateString: string) => {
    dispatch(setDateForAll(dateString));
  };

  useEffect(() => {
    if (data && orderId === "new") {
      form.setFieldsValue({
        valuta_id: data.valuties[0].id,
        building_id: data.buildings[0].id,
        date: dayjs(),
        payment_type_id: data.payment_types[0].id,
      });
      dispatch(
        setValuta({
          name: data.valuties[0].name,
          id: data.valuties[0].id,
          price: data.valuties[0].price!,
        })
      );
      dispatch(
        setPaymentAndValuta(data.valuties[0].id, data.payment_types[0].id)
      );
    }
  }, [orderId, form, data, dispatch]);

  // set data from backend
  useEffect(() => {
    if (!isLoading || !isProductListLoading || !isOrderDataLoading) {
      if (orderData && data?.valuties && products) {
        form.setFieldsValue({
          // ...orderData,
          building_id: Number(orderData.building_id),
          check: Number(orderData.check),
          date: dayjs(orderData.date),
          expense_sum: Number(orderData.expense_sum),
          expenses: orderData.expenses,
          id: Number(orderData.id),
          operator: Number(orderData.operator),
          payment_sum: Number(orderData.payment_sum),
          payment_type_id: Number(orderData.payment_type_id),
          payments: orderData.payments,
          price: Number(orderData.price),
          products: orderData.products,
          rating: Number(orderData.rating),
          status: Number(orderData.status),
          supplier_id: Number(orderData.supplier_id),
          transfers: Number(orderData.transfers),
          user_id: Number(orderData.user_id),
          valuta_history_price: Number(orderData.valuta_history_price),
          valuta_id: Number(orderData.valuta_id),
          number: orderData?.number,
          description: orderData?.description,
        });
        const valuta = data.valuties.find(
          (valuta) => valuta.id === Number(orderData.valuta_id)
        );
        if (valuta) {
          dispatch(
            setValuta({
              name: valuta.name,
              price:
                Number(orderData.valuta_history_price) || Number(valuta.price!),
              id: Number(valuta.id),
            })
          );
        }
      }
      if (orderData) {
        dispatch(
          setProductList(
            orderData?.products.map((product) => ({
              ...product,
              date: orderData.date,
              building_id: product.building_id,
            }))
          )
        );
        dispatch(
          setPaymentAndValuta(
            Number(orderData.valuta_id),
            Number(orderData.payment_type_id)
          )
        );
        if (
          orderData?.products?.some(
            (item) => item?.nds || Number(item?.nds) > 0
          )
        ) {
          setNds(true);
        }
      }
    }
  }, [
    orderData?.products,
    orderData,
    form,
    dispatch,
    data?.valuties,
    products,
    isLoading,
    isOrderDataLoading,
    isProductListLoading,
    queryClient,
  ]);

  useEffect(() => {
    setTotalPrices((prev) => {
      if (orderData?.expense_sum) {
        prev.expanses += orderData.expense_sum;
      }
      if (orderData?.payment_sum) {
        prev.payments += orderData.payment_sum;
      }
      return prev;
    });
    return () => {
      setTotalPrices({ expanses: 0, payments: 0, currentPayments: 0 });
    };
  }, [orderData?.expense_sum, orderData?.payment_sum]);

  const disabledSupplier = (): boolean => {
    if (!orderIdRed || orderId === "new") {
      return false;
    } else return true;
  };

  return (
    <>
      {orderData?.transfers === "1" && !orderIdRed && (
        <Row className="alertPanel" justify="center">
          Partiyaning ba'zi mahsulotlari boshqa obyektga transfer qilingan. Uni
          yana o'zgartirish uchun transferni bekor qiling.
        </Row>
      )}
      <div className="orderForm">
        {orderId !== "new" &&
        (isLoading || isProductListLoading || isOrderDataLoading) ? (
          <div className="centeredLoader">
            <Spin spinning size="default" tip="Yuklanmoqda..." />
          </div>
        ) : (
          <>
            <Row gutter={16} wrap>
              <Col span={8}>
                <Item
                  label={
                    <Space>
                      Ta'minotchi
                      <Button
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => dispatch(toggleSupplierCUModal(true))}
                      ></Button>
                    </Space>
                  }
                  name="supplier_id"
                  rules={[{ required: true, message: "Ta'minotchini tanlang" }]}
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    suffixIcon={<SelectIcon />}
                    filterOption={selectFilter}
                    placeholder="Ta'minotchi nomini kiriting"
                    disabled={disabledSupplier()}
                  >
                    {data?.suppliers.map((supplier) => (
                      <Option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  label="Vositachi"
                  name="user_id"
                  rules={[{ required: true, message: "Vositachini tanlang" }]}
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    filterOption={selectFilter}
                    suffixIcon={<SelectIcon />}
                    placeholder="Vositachini kiriting"
                    disabled={orderData?.transfers === "1" && !orderIdRed}
                  >
                    {data?.users.map((user) => (
                      <Option key={user.id} value={user.id}>
                        {user.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  label="Obyekt"
                  name="building_id"
                  rules={[{ required: true, message: "Obyektni tanlang" }]}
                >
                  <Select
                    showSearch
                    loading={isLoading}
                    suffixIcon={<SelectIcon />}
                    filterOption={selectFilter}
                    onChange={onBuildingChange}
                    placeholder="Obyektni kiriting"
                    disabled={orderData?.transfers === "1" && !orderIdRed}
                  >
                    {data?.buildings.map((building) => (
                      <Option key={building.id} value={building.id}>
                        {building.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={4}>
                <Item
                  label="Sana"
                  name="date"
                  rules={[{ required: true, message: "Sanani tanlang" }]}
                >
                  <DatePicker
                    disabled={orderData?.transfers === "1" && !orderIdRed}
                    onChange={onDateChange}
                    format="DD.MM.YYYY"
                    placeholder="Sanani kiriting"
                  />
                </Item>
              </Col>
              {/* <Col span={5}>
                <Item
                  label={"To'lov turi"}
                  name={"payment_type_id"}
                  rules={[{ required: true, message: "To'lov turini tanlang" }]}
                >
                  <Select
                    disabled={orderData?.transfers === "1" && !orderIdRed.id}
                    placeholder="To'lov turini kiriting"
                    showSearch
                    filterOption={selectFilter}
                    loading={isLoading}
                  >
                    {data?.payment_types.map((paymentType) => (
                      <Option key={paymentType.id} value={paymentType.id}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: paymentType.color,
                              borderRadius: "50%",
                              marginRight: "5px",
                            }}
                          />
                          {paymentType.name}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={5}>
                <Item
                  label={"Valyuta"}
                  name={"valuta_id"}
                  rules={[{ required: true, message: "Valyutani tanlang" }]}
                >
                  <Select
                    disabled={orderData?.transfers === "1" && !orderIdRed.id}
                    showSearch
                    loading={isLoading}
                    filterOption={selectFilter}
                    placeholder="Valyuta turini kiriting"
                    onChange={(value) => {
                      const valuta = data?.valuties.find(
                        (item) => item.id === value
                      );
                      dispatch(
                        setValuta({
                          name: valuta?.name!,
                          id: valuta?.id!,
                          price: valuta?.price!,
                        })
                      );
                    }}
                  >
                    {data?.valuties.map((valuta) => (
                      <Option key={valuta.id} value={valuta.id}>
                        {valuta.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col> */}
              {/* <Col span={5}>
                <Item label={"Xarajat summasi"}>
                  <span style={{ fontWeight: 600, fontSize: "1rem" }}>
                    {total.expanses.toLocaleString("ru")} so'm
                  </span>
                </Item>
              </Col>
              <Col span={5}>
                <Item label={"To'lov summasi"}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  />
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "1rem",
                      display: "inline-block",
                      marginRight: "1rem",
                    }}
                  >
                    {(total.payments + total.currentPayments).toLocaleString(
                      "ru"
                    )}
                    {"  "}
                    so'm
                  </span>
                  {valutaPrice !== 0 &&
                  (total.payments !== 0 || total.currentPayments !== 0) ? (
                    remainderTotal.current === 0 ? (
                      <CheckGreen />
                    ) : (
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: ".75rem",
                          display: "block",
                          color: remainderTotal.current > 0 ? "green" : "red",
                        }}
                      >
                        {remainderTotal.current.toLocaleString("ru")} so'm
                      </span>
                    )
                  ) : null}
                </Item>
              </Col>
              <Col span={4}>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  icon={<DollarOutlined />}
                  className="button cOrder_paymentBtn"
                  loading={isLoading}
                >
                  To'lovlar
                </Button>
              </Col> */}
            </Row>
            <Row gutter={16}>
              <Col span={5}>
                <OrderProductList
                  form={form}
                  isTransferred={orderData?.transfers}
                  ndsValue={ndsValue}
                  hasCreateButton
                />
              </Col>
              <Col span={19}>
                <h3 className="total">
                  Jami summa:
                  <span className="cOrder_total">
                    {totalPrice.toLocaleString("ru")} {valuta.name}
                  </span>
                </h3>
                <CreateOrderTable
                  buildings={data?.buildings || []}
                  isTransferred={orderData?.transfers}
                  isImportLoad={importFileLoad}
                  nds={nds}
                />
              </Col>
            </Row>
            <PaymentModal
              totalExpansesArray={totalExpansesArray}
              setTotalPrices={setTotalPrices}
              orderSelects={data}
              orderData={orderData}
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              confirmedPayments={confirmedPayments}
              setConfirmedPayments={setConfirmedPayments}
            />
          </>
        )}
      </div>
    </>
  );
};

export default OrderForm;
