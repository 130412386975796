import React, { useEffect, useState } from 'react'
import { Popconfirm, Spin, Table } from 'antd'
import { NotePencil } from '../../../../svg/NotePencil'
import { Garbage } from '../../../../svg/Garbage'
import CreateAndEditModal from './CreateAndEditModal'
import { isOpenSettingEditModal } from '../../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useObject } from '../../../../queries/queries'
import { useDeleteObject } from '../../../../queries/mutations'
import { LoadingOutlined } from '@ant-design/icons'
import PaginationComp from '../../universialComponents/PaginationComp'
import { RootState } from '../../../../redux/rootReducer'
import OneObjectHistory from '../../objectHistory/OneObjectHistory'

const { Column } = Table

const Objects = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [deleteId, setDeleteId] = useState(0)
  const [editedId, setEditedId] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [rowId, setRowId] = useState<number>()

  const dispatch = useDispatch()
  const searchString = useSelector(
    (state: RootState) => state.searchHeader.searchString
  )

  const { isLoading, data, isFetching } = useObject(currentPage, searchString)
  const deleteObject = useDeleteObject(setDeleteId, currentPage)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchString])

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id)
      await deleteObject.mutateAsync(id)
      if (data?.data.length === 1 && data.current_page !== 1)
        setCurrentPage(currentPage - 1)
    } catch (e) {
      console.log(e)
    }
  }

  // handle row
  const handleRow = (rowData: { id?: number; name?: string; address?: string }) => {
    return {
      onClick: () => {
        setRowId(rowData.id)
        setModalVisible(true)
      },
    }
  }

  return (
    <>
      <Table
        className="objectsTable"
        pagination={false}
        loading={isLoading}
        rowKey={(record) => record.id!}
        dataSource={data?.data || []}
        onRow={(rowData) => handleRow(rowData)}
      >
        <Column
          key={'key'}
          width={'10%'}
          title={'#'}
          align={'center'}
          render={(record, i, index) => (
            <span>{(currentPage - 1) * 10 + index + 1}</span>
          )}
        />
        <Column
          key={'name'}
          width={'20%'}
          title={
            <>
              <span style={{ marginRight: '1rem' }}>NOMI</span>
              {isFetching && <LoadingOutlined />}
            </>
          }
          dataIndex={'name'}
        />
        <Column
          key={'address'}
          width={'60%'}
          title={'MANZIL'}
          dataIndex={'address'}
        />
        <Column
          key={'action'}
          width={'10%'}
          title={'ACTION'}
          align={'center'}
          render={(record) => (
            <div>
              <span title={"O'zgartirish"}>
                <NotePencil
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setEditedId(record.id)
                    dispatch(isOpenSettingEditModal(true))
                  }}
                  className={'tableIcon'}
                />
              </span>
              {deleteObject.isLoading && deleteId === record.id ? (
                <Spin />
              ) : (
                <Popconfirm
                  placement="left"
                  title="O'chirshni xohlaysizmi?"
                  onConfirm={() => {
                    deleteFunc(record.id)
                  }}
                  okText="Xa"
                  cancelText="Yoq"
                >
                  <span title={"O'chirish"} onClick={(e) => e.stopPropagation()}>
                    <Garbage className={'tableIcon'} />
                  </span>
                </Popconfirm>
              )}
            </div>
          )}
        />
      </Table>
      <CreateAndEditModal
        page={currentPage}
        currentObjectId={editedId}
        setCurrentId={setEditedId}
      />
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          onChange: (e) => {
            setCurrentPage(e)
          },
        }}
      />
      <OneObjectHistory
        visible={modalVisible}
        setVisible={setModalVisible}
        id={rowId!}
      />
    </>
  )
}

export default Objects
