import { Button, Modal, Spin, Tabs } from "antd";
import React, { useState } from "react";
import {
  useObjectHistory,
  useObjectsHistoryName,
} from "../../../queries/queries";
import ObjectHistoryTable from "./ObjectHistoryTable";

import "./ObjectHistory.scss";
import DownloadIcon from "../../../svg/DownloadIcon";
import { useCreditDebitExcel } from "../../../queries/mutations";

type Props = {
  visible: boolean;
  close: () => void;
  dateArr: string[];
  category: number | string;
  view: string;
};

const ObjectHistoryModal: React.FC<Props> = ({
  visible,
  close,
  dateArr,
  category,
  view,
}) => {
  const [objectId, setObjectId] = useState<number>(0);
  const { data, isLoading } = useObjectHistory(
    objectId,
    dateArr,
    category,
    view
  );
  const objects = useObjectsHistoryName(dateArr);
  const getExcel = useCreditDebitExcel();

  // get object id
  const getObjectId = async (id: number) => {
    await setObjectId(id);
  };

  // download excel
  const downloadExcel = () => {
    const ID = objectId || 0;
    const data = {
      type: "BUILDINGS_HISTORY",
      dateArr,
      ID,
      category,
      view,
    };
    getExcel.mutate(data);
  };

  return (
    <Modal
      className={"productHistoryModal"}
      width={1000}
      footer={false}
      visible={visible}
      onCancel={close}
      centered
    >
      <div className={"productHistoryHeader"}>
        <div className={"title"}>Export</div>
        {dateArr.length > 0 && (
          <div className={"date"}>{dateArr[0] + " : " + dateArr[1]}</div>
        )}
      </div>
      <div className="objectHistoryCont">
        <Tabs
          color="white"
          defaultActiveKey="1"
          onChange={(id) => getObjectId(Number(id))}
        >
          <Tabs.TabPane tab="Umumiy" key="0">
            <ObjectHistoryTable data={data} isLoading={isLoading} />
          </Tabs.TabPane>
          {Array.isArray(objects?.data) ? (
            objects?.data?.map((building) => (
              <Tabs.TabPane tab={building.name} key={building.ID}>
                <ObjectHistoryTable data={data} isLoading={isLoading} />
              </Tabs.TabPane>
            ))
          ) : (
            <Tabs.TabPane tab={objects?.data?.name}>
              <ObjectHistoryTable data={data} isLoading={isLoading} />
            </Tabs.TabPane>
          )}
        </Tabs>
        <div className="footer">
          <Button onClick={downloadExcel}>
            <div className="btn excel">
              {getExcel.isLoading ? (
                <>
                  <Spin size="small" />
                  &nbsp;
                </>
              ) : (
                <DownloadIcon />
              )}
              <span>Excel</span>
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ObjectHistoryModal;
