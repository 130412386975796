import { Dispatch } from "redux";
import { UserInterface } from "../models/interfaces/UserInterface";
import { userLogin } from "../redux/userActions";
import { rootPaths } from "../routes/rootRoutes";

export const UserService = {
  login: (user: UserInterface, token: string, dispatch: Dispatch) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", JSON.stringify(token));
    dispatch(userLogin(user, token));
  },

  getUserLocal: (): UserInterface => {
    const initalUser: UserInterface = {
      id: 0,
      login: "",
      name: "",
      role: "vositachi",
      phone: "",
      building_id: []
    };

    const user = localStorage.getItem("user");
    if (user) {
      try {
        return JSON.parse(user);
      } catch (error) {
        localStorage.removeItem("user");
        return initalUser;
      }
    }
    return initalUser;
  },

  getTokenLocal: (): string => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        return JSON.parse(token);
      } catch (error) {
        return "";
      }
    }
    return "";
  },

  logout: (): void => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem('domen')
    window.location.replace(rootPaths.LOGIN);
  }
};
