import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 12.5C10.6495 12.5 13 10.1495 13 7.25C13 4.35051 10.6495 2 7.75 2C4.85051 2 2.5 4.35051 2.5 7.25C2.5 10.1495 4.85051 12.5 7.75 12.5Z"
        stroke="#868686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4629 10.9625L14.5004 14.0001"
        stroke="#868686"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
