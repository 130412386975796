import { Table } from 'antd'
import React from 'react'
import { useBrokersOfSupplier } from '../../../../queries/queries'

interface IBrokerOfSuppliers {
  id: number
  type: string
  entryType: string
}

interface OneSupplierData {
  debit: number
  user_id: number
  name: string
}

const BrokersOfSupplier: React.FC<IBrokerOfSuppliers> = ({
  id,
  type,
  entryType,
}) => {
  const { data, isLoading } = useBrokersOfSupplier(id, type, entryType)

  let customData: OneSupplierData[] = []
  data?.forEach((element: OneSupplierData) => {
    if (element.debit) {
      customData.push(element)
    }
  })

  return (
    <div key={id}>
      <Table
        key={id}
        pagination={false}
        dataSource={customData}
        loading={isLoading}
        className="expandTable"
      >
        <Table.Column
          title="Vositachi"
          key="name"
          render={(data) => {
            return <span>{data.name}</span>
          }}
        />
        <Table.Column
          title="Balansi"
          key="debit"
          render={(data) => {
            return <span>{data.debit.toLocaleString()}</span>
          }}
        />
      </Table>
    </div>
  )
}

export default BrokersOfSupplier
