export const ArrowsIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 9L13 11L11 13"
        stroke="#2C3F45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 11H13"
        stroke="#2C3F45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7L3 5L5 3"
        stroke="#2C3F45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 5H3"
        stroke="#2C3F45"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
