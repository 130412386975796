import React from 'react'
import IconInterface from '../models/interfaces/IconInterface'

export const PlusDark: React.FC<IconInterface> = ({ onClick, className }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1875 7.5H11.8125"
        stroke="#273B42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 2.6875V12.3125"
        stroke="#273B42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
