import InputMask from "react-input-mask";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import { DownOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons";

import {
  useCurrentUser,
  useGetAllOBject,
  usePostUserRoleList,
} from "../../../../queries/queries";
// import { roles } from "./roles";
import SelectIcon from "../../../../svg/SelectIcon";
import { RootState } from "../../../../redux/rootReducer";
import { useUserMutation } from "../../../../queries/mutations";
import { isOpenSettingEditModal } from "../../../../redux/actions";
import { UserInterface } from "../../../../models/interfaces/UserInterface";

const { Option } = Select;

interface CreateAndEditInterface {
  currentUserId: number;
  setUserId: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}

const CreateAndEditModal: FC<CreateAndEditInterface> = ({
  currentUserId,
  setUserId,
  page,
}) => {
  const [openPasswords, setOpenPasswords] = useState(false);
  const [allObject, setAllObject] = useState<boolean>(false);

  const cu = useUserMutation(page);
  const { data, isLoading } = useCurrentUser(currentUserId);
  const { data: getObjects, isLoading: getObjectsLoading } = useGetAllOBject();
  const { data: userRoleList, isLoading: isLoadingUserRoleList } =
    usePostUserRoleList();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isModalOpen = useSelector(
    (state: RootState) => state.settings.openCreateModal
  );

  const cancelFunc = () => {
    dispatch(isOpenSettingEditModal(false));
    setUserId(0);
    form.resetFields();
    setAllObject(false);
  };

  const onFinish = async (values: UserInterface) => {
    if (currentUserId) {
      await cu.mutateAsync({ ...values, id: currentUserId }).finally(() => {
        const user: UserInterface = JSON.parse(
          localStorage.getItem("user") as string
        );
        const userId = user?.id as number;
        if (currentUserId === userId) {
          localStorage.setItem(
            "user",
            JSON.stringify({ ...values, id: currentUserId })
          );
        }
      });
    } else {
      await cu.mutateAsync(values);
    }
    cancelFunc();
  };

  useEffect(() => {
    if (currentUserId > 0) {
      const toNumberBuildingId = data?.[0].building_id?.map((item) =>
        Number(item)
      );
      // const roleCont = roles.find((item) => item.value === data?.[0]?.role);
      form.setFieldsValue({
        name: data?.[0]?.name,
        login: data?.[0]?.login,
        role: data?.[0].role,
        phone: data?.[0]?.phone,
        building_id: toNumberBuildingId,
      });

      if (toNumberBuildingId?.length === getObjects?.length) {
        setAllObject(true);
      }
    }
  }, [currentUserId, data, form]);

  const ModalTitle = (
    <>
      <span style={{ display: "inline-block", marginRight: "1rem" }}>
        Foydalanuvchi yaratish
      </span>
      {isLoading && <LoadingOutlined />}
    </>
  );

  const changeAllObject = () => {
    setAllObject(!allObject);
    if (!allObject) {
      form.setFieldsValue({
        building_id: getObjects?.map((item) => item.id),
      });
    } else {
      form.setFieldsValue({
        building_id: [],
      });
    }
  };

  return (
    <Modal
      className={"editModal"}
      visible={isModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
    >
      <Form
        form={form}
        name="paymentEdit"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[20, 8]}>
          <Col span={12}>
            <label htmlFor={"name"}>F.I.O</label>
            <Form.Item name="name" rules={[{ required: true, message: "" }]}>
              <Input
                placeholder={"Erniyazov Laziz Shavkat o’g’li"}
                className={"input"}
                id={"name"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor={"login"}>Login</label>
            <Form.Item name="login" rules={[{ required: true, message: "" }]}>
              <Input autoComplete={"off"} className={"input"} id={"login"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor={"role"}>Lavozimi</label>
            <Form.Item name="role" rules={[{ required: true, message: "" }]}>
              <Select
                className={"select"}
                id={"color"}
                loading={isLoadingUserRoleList}
              >
                {userRoleList?.map((role, index) => (
                  <Option value={role} key={index}>
                    {role.toLocaleUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor={"phone"}>Telefon</label>
            <Form.Item name="phone" rules={[{ required: true, message: "" }]}>
              <InputMask mask="+\9\98 99 999 99 99">
                {() => (
                  <Input
                    placeholder={"+998 XX XXX XX XX"}
                    className={"input"}
                    id={"phone"}
                  />
                )}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={24}>
            <label
              htmlFor="obyekt"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>Obyektlar</span>
              <span
                style={{
                  display: "flex",
                  columnGap: "8px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={changeAllObject}
              >
                Barchasi
                <Switch checked={allObject} />
              </span>
            </label>
            <Form.Item
              name={"building_id"}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                mode="multiple"
                suffixIcon={<SelectIcon />}
                loading={getObjectsLoading}
                showArrow
                placeholder="Obyektlardan birini tanlang "
              >
                {getObjects?.map((item, index) => (
                  <Option value={item.id ?? 1} key={index}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {currentUserId === 0 ? (
            <>
              <Col span={12}>
                <label htmlFor={"password"}>Parol</label>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input.Password
                    autoComplete="new-password"
                    className={"input"}
                    id={"password"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <label htmlFor={"confirm_password"}>Parolni tasdiqlash</label>
                <Form.Item
                  name="confirm_password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(" "));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    autoComplete="confirm_password"
                    className={"input"}
                    id={"confirm_password"}
                  />
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <div
                style={{
                  marginLeft: "10px",
                  display: "block",
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                <Button
                  onClick={() => setOpenPasswords(!openPasswords)}
                  type="default"
                  shape="round"
                  icon={openPasswords ? <UpOutlined /> : <DownOutlined />}
                />
              </div>
              {openPasswords && (
                <>
                  <Col span={12}>
                    <label htmlFor={"password"}>Parol</label>
                    <Form.Item
                      name="password"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input.Password
                        autoComplete="new-password"
                        className={"input"}
                        id={"password"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label htmlFor={"confirm_password"}>
                      Parolni tasdiqlash
                    </label>
                    <Form.Item
                      name="confirm_password"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(" "));
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        autoComplete="confirm_password"
                        className={"input"}
                        id={"confirm_password"}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
        <div className={"footerCont"}>
          <Button
            onClick={cancelFunc}
            className={"modalFooterBack"}
            type="link"
          >
            Bekor qilish
          </Button>
          <Button
            disabled={isLoading}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit"
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAndEditModal;
