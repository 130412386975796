import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/rootReducer";
import {isOpenSettingEditModal} from "../../../../../redux/actions";
import {useProductSIMutation} from "../../../../../queries/mutations";
import {useCurrentProductSI} from "../../../../../queries/queries";
import {LoadingOutlined} from "@ant-design/icons";
import {types} from "./types";
import {ProductSiInterface} from "../../../../../models/interfaces/ProductSiInterface";

const {Option} = Select

interface CreateAndEditModalInterface {
  currentProductSI: number
  setProductSiId: React.Dispatch<React.SetStateAction<number>>
  page: number
}

const CreateAndEditModal: React.FC<CreateAndEditModalInterface> = ({currentProductSI,setProductSiId,page}) => {
  const cu = useProductSIMutation(page)
  const {data, isLoading} = useCurrentProductSI(currentProductSI)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const isModalOpen = useSelector(
    (state: RootState) => state.settings.openCreateModal
  )

  const cancelFunc = () => {
    dispatch(isOpenSettingEditModal(false))
    setProductSiId(0)
    form.resetFields()
  }

  const onFinish = async (values: ProductSiInterface) => {
    if (currentProductSI) {
      await cu.mutateAsync({...values, id: currentProductSI})
    } else {
      await cu.mutateAsync(values)
    }
    cancelFunc()
  }

  useEffect(() => {
    if (currentProductSI > 0) {
      const type = types.find((item) => item.values === data?.type)
      form.setFieldsValue({
        name: data?.name,
        type: type?.values
      })
    }
  }, [currentProductSI, data, form])

  const ModalTitle = (
    <>
      <span style={{display: 'inline-block', marginRight: '1rem'}}>
        Mahsulot birligi yaratish
      </span>
      {isLoading && <LoadingOutlined/>}
    </>
  )

  return (
    <Modal
      className={'editModal'}
      visible={isModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
    >
      <Form
        form={form}
        name="paymentEdit"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[20, 8]}>
          <Col span={12}>
            <label htmlFor={'name'}>
              Nomi
            </label>
            <Form.Item
              name="name"
              rules={[{required: true, message: ''}]}
            >
              <Input className={'input'} id={'name'}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor={'type'}>
              O'lchovi
            </label>
            <Form.Item
              name="type"
              rules={[{required: true, message: ''}]}
            >
              <Select className={'select'} id={'type'}>
                {types.map((type,index) => (
                  <Option value={type.values} key={index}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className={'footerCont'}>
          <Button
            onClick={cancelFunc}
            className={'modalFooterBack'}
            type="link">
            Bekor qilish
          </Button>
          <Button
            disabled={isLoading}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit">
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateAndEditModal