import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

import { usePaymentAll } from "../../../../queries/queries";
import styles from "./payments.module.scss";

const Payments: React.FC = () => {
  const { data, isLoading } = usePaymentAll();
  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
      <div className={styles.payments}>
        {data
          ?.filter((item) => item?.price! > 0 || item?.price_currency! > 0)
          .map((item, index) => (
            <div key={index} className={styles.card}>
              <div
                className={styles.line}
                style={{
                  background: item.color
                }}
              />
              <div className={styles.content}>
                <p>{item.name!}</p>
                {item?.price !== 0 && (
                  <h3>{item.price?.toLocaleString()} so’m</h3>
                )}
                {item.price_currency !== 0 && (
                  <h3>{item.price_currency?.toLocaleString()} $</h3>
                )}
              </div>
            </div>
          ))}
      </div>
    </Spin>
  );
};

export default Payments;
