import React, { useEffect, useState } from "react";
import { Button, Input, Spin } from "antd";

import styles from "./checkout.module.scss";
import CheckoutCards from "./cards/CheckoutCards";
import CheckoutTable from "./table/CheckoutTable";
import SearchIcon from "../../../svg/SearchIcon";
import { useHistory, useLocation } from "react-router-dom";
import CheckoutModal from "./modal/CheckoutModal";
import {
  CheckoutPaymentsCardType,
  CheckoutViewType,
} from "../../../models/checkout/CheckoutPaymentModel";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import ConversionIcon from "../../../svg/ConversionIcon";
import mainPaths from "../../../routes/main/mainPaths";
import { rootPaths } from "../../../routes/rootRoutes";
import Filter from "./filter/Filter";
import FilterIcon from "../../../svg/FilterIcon";
import XIcon from "../../../svg/XIcon";
import { LoadingOutlined } from "@ant-design/icons";
import { KEY, SORT } from "../../../helper/paramNames";
import ExelFileIcon from "../../../svg/ExelFileIcon";
import { useDownloadCheckPage } from "../../../queries/mutations";

const CheckoutPage = () => {
  useDocumentTitle("Kassa");
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [filterState, setFilterState] = useState<boolean>(false);
  const [filtrParams, setFilterParams] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filtrPrice, setFiltrPrice] = useState<{
    summa: number;
    summa$: number;
  }>();
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
  const downloadExcelFile = useDownloadCheckPage();
  const [time, setTime] = useState<NodeJS.Timer>();
  const [onePayment, setOnePayment] = useState<
    CheckoutPaymentsCardType | undefined
  >();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("search")) {
      setSearchValue(queryParams.get("search") ?? "");
    } else {
      setSearchValue("");
    }
  }, [location.search]);

  const [visible, setVisible] = useState<{
    visible: boolean;
    data: CheckoutViewType;
    payment: number;
  }>({
    visible: false,
    data: {
      payment_type_id: 0,
      payment_type_name: "",
      price: 0,
      price_currency: 0,
    },
    payment: 0,
  });

  const searchPayments = (e: React.ChangeEvent<HTMLInputElement>) => {
    const param = new URLSearchParams(location.search);
    const value = e.target.value;
    clearTimeout(time);
    setSearchValue(value);

    if (value) {
      param.set("search", value);
      if (param.get(KEY) || param.get(SORT)) {
        param.delete(KEY);
        param.delete(SORT);
      }
      setTime(
        setTimeout(() => {
          history.push(
            `${location.pathname}${
              param.toString().includes("?") ? "" : "?"
            }${param.toString()}`
          );
        }, 800)
      );
    } else {
      history.push(location.pathname);
    }
  };

  const openFilter = () => {
    setFilterState(true);
  };

  const visibleConversion = (): boolean => {
    if (filtrParams || params.get("search")) {
      return false;
    } else return true;
  };

  const clearFiltr = () => {
    setFilterParams(undefined);
  };

  const downloadExcel = () => {
    const param = new URLSearchParams(location.search);
    const searchValue = param.get("search");

    downloadExcelFile.mutate(
      `${filtrParams ?? ""}${searchValue ? `search=${searchValue}` : ""}`
    );
  };

  const resultFilter = () => {
    return (
      <Spin indicator={<LoadingOutlined />} spinning={isLoadingPayment}>
        <div className="d_f cg-10">
          <Button onClick={downloadExcel}>
            <ExelFileIcon /> Excel yuklab olish
          </Button>
          {/* {filtrParams && ( */}
          <div className={styles.result}>
            <p>
              Filtr natijasi: {filtrPrice?.summa?.toLocaleString()} so’m,{" "}
              {filtrPrice?.summa$?.toLocaleString()} $
            </p>
            <Button onClick={clearFiltr}>
              <XIcon />
            </Button>
          </div>
          {/* )} */}
        </div>
      </Spin>
    );
  };

  return (
    <div className={styles.checkout}>
      <CheckoutCards setVisible={setVisible} />
      <div className={styles.center}>
        <div className={styles.left}>
          <Input
            value={searchValue}
            placeholder="Nomi bo‘yicha qidiring..."
            suffix={<SearchIcon />}
            className={styles.search}
            onChange={searchPayments}
          />
          <Button onClick={openFilter}>
            <FilterIcon /> Filter
          </Button>
        </div>
        {!visibleConversion() && resultFilter()}
        {visibleConversion() && (
          <Button
            onClick={() => history.push(rootPaths.MAIN + mainPaths.CONVERSION)}
          >
            <ConversionIcon /> Konvertatsiyalar
          </Button>
        )}
      </div>
      <CheckoutTable
        visible={visible}
        setOnePayment={setOnePayment}
        setVisible={setVisible}
        filtrParams={filtrParams}
        filtrPrice={filtrPrice}
        setFiltrPrice={setFiltrPrice}
        setIsLoadingPayment={setIsLoadingPayment}
      />
      <CheckoutModal
        setVisible={setVisible}
        visible={visible}
        onePayment={onePayment}
        setOnePayment={setOnePayment}
      />
      <Filter
        visible={filterState}
        setVisible={setFilterState}
        setParams={setFilterParams}
        params={filtrParams}
      />
    </div>
  );
};

export default CheckoutPage;
