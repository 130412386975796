import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Empty,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import truckIcon from "../../../image/truck.png";
import { useDeletePaymentMutation } from "../../../queries/mutations";
import { getValutePriceById, moneyToView } from "../../../service/Helpers";
import {
  CreateOrderAllSelects,
  ExpanseTableType,
  OneOrderDataType,
} from "./CreateOrderTypes";
import { DeletePaymentPop } from "./DeletePaymentPop";
import { ConfirmedPayments } from "./OrderForm";
////
const { Option } = Select;
const { Column } = Table;
///
interface PaymentModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  orderSelects: CreateOrderAllSelects | undefined;
  orderData: OneOrderDataType | undefined;
  setTotalPrices: React.Dispatch<
    React.SetStateAction<{
      expanses: number;
      payments: number;
      currentPayments: number;
    }>
  >;
  totalExpansesArray: MutableRefObject<ExpanseTableType[]>;
  confirmedPayments: ConfirmedPayments;
  setConfirmedPayments: React.Dispatch<React.SetStateAction<ConfirmedPayments>>;
}

interface PrevState {
  payments: ExpanseTableType[];
  expanses: ExpanseTableType[];
  paymentTypes: any[];
  expanseTypes: any[];
}

////////////////////////////////////
const PaymentModal: React.FC<PaymentModalProps> = ({
  isOpen,
  setIsOpen,
  orderSelects,
  setTotalPrices,
  totalExpansesArray,
  orderData,
  confirmedPayments,
  setConfirmedPayments,
}) => {
  // to retrun to the previous state of payment modal onCancel
  const prevState = useRef<PrevState>({
    payments: [],
    expanses: [],
    expanseTypes: [],
    paymentTypes: [],
  });
  // local states
  const [expanses, setExpanses] = useState<ExpanseTableType[]>([]);
  const [payments, setPayments] = useState<ExpanseTableType[]>([]);
  const [expanseTypes, setExpanseTypes] = useState<any[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<any[]>([]);

  //orderSelects
  const deleteMutation = useDeletePaymentMutation();

  useEffect(() => {
    if (orderSelects?.expense_types && orderSelects?.payment_types) {
      const uncheckedExpenseTypes = orderSelects.expense_types.map((item) => ({
        ...item,
        checked: false,
      }));

      const uncheckedPaymentTypes = orderSelects.payment_types.map((item) => ({
        ...item,
        checked: false,
      }));

      setExpanseTypes(uncheckedExpenseTypes);
      setPaymentTypes(uncheckedPaymentTypes);
      prevState.current.expanseTypes = uncheckedExpenseTypes;
      prevState.current.paymentTypes = uncheckedPaymentTypes;
    }
  }, [orderSelects?.expense_types, orderSelects?.payment_types]);

  //set confirmed payments
  useEffect(() => {
    if (orderData) {
      setConfirmedPayments({
        expanses: orderData.expenses as any,
        payments: orderData.payments as any,
      });
    }
  }, [orderData]);

  const onExpanseTypeChange = (item: any, isChecked: boolean) => {
    const copy = [...expanseTypes];
    const expansesCopy = [...expanses];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].id === item.id) {
        copy[i].checked = isChecked;
        if (isChecked) {
          expansesCopy.push({
            expense_type_id: item.id,
            expanse_type_name: item.name,
            payment_type_id: 0,
            price: "",
            valuta_id: 0,
          });
          setExpanses(expansesCopy);
        } else {
          const filtered = expansesCopy.filter(
            (expanse) => expanse.expense_type_id !== item.id
          );
          setExpanses(filtered);
        }
        break;
      }
    }
    setExpanseTypes(copy);
  };

  const onCancel = () => {
    setIsOpen(false);
    setExpanses(prevState.current.expanses);
    setPayments(prevState.current.payments);
    setExpanseTypes(prevState.current.expanseTypes);
    setPaymentTypes(prevState.current.paymentTypes);

    totalExpansesArray.current = prevState.current.expanses.concat(
      prevState.current.payments
    );
  };

  const onSave = () => {
    const data = expanses.concat(payments);
    const filtered: any = data.filter((item) => {
      return item.price !== "" && item.price !== "0";
    });
    const totalExpanses = expanses.reduce(
      (acc: number, item: any) =>
        acc +
        (parseFloat(item.price) *
          getValutePriceById(item.valuta_id, orderSelects?.valuties!) || 1),
      0
    );
    const totalPayment = payments.reduce(
      (acc: number, item: any) =>
        acc +
        (parseFloat(item.price) *
          getValutePriceById(item.valuta_id, orderSelects?.valuties!) || 1),
      0
    );

    setTotalPrices((prev) => {
      prev.expanses = totalExpanses;
      prev.currentPayments = totalPayment;
      return prev;
    });
    totalExpansesArray.current = filtered;
    setIsOpen(false);
    prevState.current = {
      payments: payments.map((i) => ({ ...i })),
      expanses: expanses.map((i) => ({ ...i })),
      expanseTypes: expanseTypes.map((i) => ({ ...i })),
      paymentTypes: paymentTypes.map((i) => ({ ...i })),
    };
  };

  return (
    <Modal
      className={"editModal paymentModal"}
      visible={isOpen}
      title={
        <>
          To'lovlar{"  "}
          <Tooltip
            placement="bottom"
            title="To'lovlar faqat partiya tasdiqlangandan so'ng saqlanadi. Tasdiqlangan to'lovlarni qayta kiritish yoki o'zgartirish imkoniyati yo'q."
          >
            <span>
              <ExclamationCircleOutlined />
            </span>
          </Tooltip>
        </>
      }
      onCancel={() => setIsOpen(false)}
      centered
      footer={false}
    >
      {/*if payments are fully  paid check is "1", else "0" */}
      {orderData?.transfers !== "1" ? (
        <Row className="paymentModal">
          <Col span={12}>
            <div className="paymentModal_expanses paymentModal_block expanses">
              <div className="block_head">
                <p>Xarajatlar</p>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      {expanseTypes.map((item, index) => (
                        <Menu.Item key={item.id}>
                          {
                            <Checkbox
                              checked={item.checked}
                              onClick={(e) => e.stopPropagation()}
                              onChange={({ target }) =>
                                onExpanseTypeChange(item, target.checked)
                              }
                            >
                              {item.name}
                            </Checkbox>
                          }
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button
                    // onClick={() => setIsexpanseVisible(true)}
                    type="text"
                    color="primary"
                  >
                    + Yangi xarajat qo'shish
                  </Button>
                </Dropdown>
              </div>
              <Table<ExpanseTableType>
                pagination={false}
                dataSource={expanses}
                className="block_table"
                bordered={false}
              >
                <Column
                  dataIndex="expanse_type_name"
                  key="expanse_type_name"
                  title="Turi"
                />
                <Column
                  dataIndex="price"
                  key="id"
                  title="Summa"
                  render={(price, _, index) => {
                    // console.log(_)
                    return (
                      <Input
                        value={moneyToView(price)}
                        onChange={({ target }) => {
                          const copy = [...expanses];
                          const value = target.value.replace(/\s/g, "") as any;
                          if (isNaN(value)) {
                            return;
                          }

                          if (value === "") {
                            copy[index].price = "";
                            return setExpanses(copy);
                          }
                          copy[index].price = value;
                          setExpanses(copy);
                        }}
                      />
                    );
                  }}
                />
                <Column
                  dataIndex="valuta_id"
                  key="id"
                  title="Valuta"
                  render={(valuta_id, _, index) => {
                    const copy = [...expanses];
                    if (valuta_id === 0) {
                      copy[index].valuta_id = orderSelects?.valuties[0].id || 0;
                      setExpanses(copy);
                    }
                    return (
                      <Select
                        onChange={(val) => {
                          const expansesCopy = [...expanses];
                          expansesCopy[index].valuta_id = val;
                          setExpanses(expansesCopy);
                        }}
                        style={{ width: "100%" }}
                        bordered={false}
                        value={valuta_id}
                      >
                        {orderSelects?.valuties.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    );
                  }}
                />
                <Column
                  dataIndex="payment_type_id"
                  key="payment_type_id"
                  title="To'lov turi"
                  render={(payment_type_id, _, index) => {
                    const copy = [...expanses];
                    if (payment_type_id === 0) {
                      copy[index].payment_type_id =
                        orderSelects?.payment_types[0].id || 0;
                      setExpanses(copy);
                    }
                    return (
                      <Select
                        onChange={(val) => {
                          const expansesCopy = [...expanses];
                          expansesCopy[index].payment_type_id = val;
                          setExpanses(expansesCopy);
                        }}
                        style={{ width: "100%" }}
                        bordered={false}
                        value={
                          payment_type_id || orderSelects?.payment_types[0].id
                        }
                      >
                        {orderSelects?.payment_types.map((item) => (
                          <Option key={item.id} value={item.id}>
                            <div
                              className="coloredDot"
                              style={{ backgroundColor: item.color }}
                            ></div>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    );
                  }}
                />
              </Table>
            </div>
          </Col>
          <Col span={12}>
            <div className="paymentModal_paymentTypes paymentModal_block">
              <div className="block_head">
                <p>To'lovlar</p>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      {paymentTypes.map((item, index) => (
                        <Menu.Item key={item.id}>
                          {
                            <Checkbox
                              checked={item.checked}
                              onClick={(e) => e.stopPropagation()}
                              onChange={({ target }) => {
                                const copy = [...paymentTypes];
                                const paymentsCopy = [...payments];
                                for (let i = 0; i < copy.length; i++) {
                                  if (copy[i].id === item.id) {
                                    copy[i].checked = target.checked;
                                    if (target.checked) {
                                      paymentsCopy.push({
                                        expense_type_id: 0,
                                        payment_type_name: item.name,
                                        payment_type_id: item.id,
                                        price: "",
                                        valuta_id: 0,
                                      });
                                      setPayments(paymentsCopy);
                                    } else {
                                      const filtered = paymentsCopy.filter(
                                        (payment) =>
                                          payment.payment_type_id !== item.id
                                      );
                                      setPayments(filtered);
                                    }
                                    break;
                                  }
                                }
                                setPaymentTypes(copy);
                              }}
                            >
                              <div
                                className="coloredDot"
                                style={{ backgroundColor: item.color }}
                              />
                              {item.name}
                            </Checkbox>
                          }
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button type="text" color="primary">
                    + Yangi to'lov qo'shish
                  </Button>
                </Dropdown>
              </div>
              <Table<ExpanseTableType>
                style={{ margin: 0 }}
                pagination={false}
                dataSource={payments}
                className="block_table"
                bordered={false}
              >
                <Column
                  dataIndex="payment_type_name"
                  key="payment_type_name"
                  title="Turi"
                />
                <Column
                  dataIndex="price"
                  key="id"
                  title="Summa"
                  render={(price, _, index) => {
                    return (
                      <Input
                        min={5}
                        value={moneyToView(price)}
                        onChange={({ target }) => {
                          const copy = [...payments];
                          const value = target.value.replace(/\s/g, "") as any;
                          if (isNaN(value)) {
                            return;
                          }

                          if (value === "") {
                            copy[index].price = "";
                            return setPayments(copy);
                          }
                          copy[index].price = value;
                          setPayments(copy);
                        }}
                      />
                    );
                  }}
                />
                <Column
                  dataIndex="valuta_id"
                  key="id"
                  title="Valuta"
                  render={(valuta_id, _, index) => {
                    const copy = [...payments];
                    if (valuta_id === 0) {
                      copy[index].valuta_id = orderSelects?.valuties[0].id || 0;
                      setPayments(copy);
                    }
                    return (
                      <Select
                        onChange={(val) => {
                          const paymentsCopy = [...payments];
                          paymentsCopy[index].valuta_id = val;
                          setPayments(paymentsCopy);
                        }}
                        style={{ width: "100%" }}
                        bordered={false}
                        value={valuta_id}
                      >
                        {orderSelects?.valuties.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    );
                  }}
                />
              </Table>
            </div>
          </Col>
        </Row>
      ) : (
        <Row style={{ margin: "1rem 0" }} justify="center">
          {/* {orderData.check === '1' ? (
            <Empty
              image={
                <CheckCircleTwoTone
                  twoToneColor="#00b894"
                  style={{ fontSize: '5rem' }}
                />
              }
              description="Ushbu partiya uchun barcha to'lov va xarajatlar kiritilgan va tasdiqlangan."
            />
          ) : (
            orderData.transfers === '1' && ( */}
          <Empty
            image={<img alt="truck lorry" src={truckIcon} />}
            description="Ushbu partiyaning ba'zi mahsulotlari boshqa obyektga transfer qilingan. To'lov kiritish imkoniyati yo'q"
          />
        </Row>
      )}
      <Divider />
      {/* confirmed payments */}
      <Row gutter={16}>
        <Col span={24}>
          <Typography.Title level={5}>Tasdiqlangan to'lovlar</Typography.Title>
        </Col>
        {orderData?.expenses?.length! > 0 ||
        orderData?.payments?.length! > 0 ? (
          <>
            <Col span={12}>
              <Typography.Paragraph type="secondary">
                Xarajatlar
              </Typography.Paragraph>
              <Table
                rowKey="id"
                pagination={false}
                dataSource={confirmedPayments.expanses}
                className="block_table"
                bordered={false}
              >
                <Column
                  dataIndex="expense_type_name"
                  key="expense_type_name"
                  title="Turi"
                />
                <Column
                  dataIndex="price"
                  key="id"
                  title="Summa"
                  render={(price, _, index) => {
                    return price.toLocaleString("ru");
                  }}
                />
                <Column
                  dataIndex="valuta_name"
                  key="id"
                  title="Valuta"
                  // render={(valuta_id, _, index) => {
                  //   return <></>
                  // }}
                />
                <Column
                  dataIndex="payment_type_name"
                  key="payment_type_name"
                  title="To'lov turi"
                  // render={() => {
                  //   return <></>
                  // }}
                />
                <Column
                  dataIndex="id"
                  key="payment_type_name"
                  width={50}
                  render={(id, _, index) => (
                    <DeletePaymentPop
                      onConfirm={async () => {
                        try {
                          await deleteMutation.mutateAsync(id);
                          setConfirmedPayments((prev: any) => {
                            const copy = [...prev.expanses];
                            copy.splice(index, 1);
                            return { ...prev, expanses: copy };
                          });
                          setTotalPrices((prev) => {
                            prev.expanses -= confirmedPayments.expanses[index]
                              .price as number;
                            return prev;
                          });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      loading={deleteMutation.isLoading}
                      title="Xarajatni o'chirasizmi?"
                    />
                  )}
                />
              </Table>
            </Col>

            <Col span={12}>
              <Typography.Paragraph type="secondary">
                To'lovlar
              </Typography.Paragraph>
              <Table
                rowKey="id"
                pagination={false}
                dataSource={confirmedPayments?.payments}
                className="block_table"
                bordered={false}
              >
                <Column
                  dataIndex="payment_type_name"
                  key="payment_type_name"
                  title="To'lov turi"
                />
                <Column
                  dataIndex="price"
                  key="id"
                  title="Summa"
                  render={(price, _, index) => {
                    return price.toLocaleString("ru");
                  }}
                />
                <Column dataIndex="valuta_name" key="id" title="Valuta" />
                <Column
                  dataIndex="id"
                  key="payment_type_name"
                  width={50}
                  render={(id, _, index) =>
                    id ? (
                      <DeletePaymentPop
                        loading={deleteMutation.isLoading}
                        onConfirm={async () => {
                          try {
                            await deleteMutation.mutateAsync(id);
                            setConfirmedPayments((prev: any) => {
                              const copy = [...prev.payments];
                              const filtered = copy.filter(
                                (item: any) => item.id !== id
                              );

                              return { ...prev, payments: filtered };
                            });
                            setTotalPrices((prev) => {
                              prev.payments -= confirmedPayments.payments[index]
                                .price as number;
                              return prev;
                            });
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                        title="To'lovni o'chirasizmi?"
                      />
                    ) : null
                  }
                />
              </Table>
            </Col>
          </>
        ) : (
          <Col span="24" style={{ margin: "1rem 0" }}>
            <Empty description="Hozircha tasdiqlangan to'lov yoki xarajatlar yo'q" />
          </Col>
        )}
        <Col span={24} className="paymentModal_footer">
          <Button onClick={onCancel}>Bekor qilish</Button>
          <Button type="primary" onClick={onSave}>
            Saqlash
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default PaymentModal;
