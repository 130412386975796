import React from 'react'
import CompanyCounts from './CompanyCounts'
import { ProductDetailsInterface } from '../../../models/settings/ProductTableInterface'

interface ProductDistributionInterface {
  data: ProductDetailsInterface | undefined
}

const ProductDistribution: React.FC<ProductDistributionInterface> = ({ data }) => {
  return (
    <div className={'distribution'}>
      <div className={'titleLabel'}>Mahsulot nomi</div>
      <div className={'title'}>{data?.name}</div>
      <div className={'item'}>
        <div>Umumiy miqdori</div>
        <div>
          {data?.count.toLocaleString('ru')}
          <span className={'lighter'}>{data?.product_type_name}</span>
        </div>
      </div>
      <CompanyCounts data={data?.building_counts || []} />
    </div>
  )
}

export default ProductDistribution
