import { Button, Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";
import { isOpenSettingEditModal } from "../../../../redux/actions";
import {
  useObjectMutation,
  useGetBuildingUser,
} from "../../../../queries/mutations";
import { useCurrentObject } from "../../../../queries/queries";
import { LoadingOutlined } from "@ant-design/icons";
import { ObjectInterface } from "../../../../models/interfaces/ObjectInterface";

interface CreateAndEditInterface {
  currentObjectId: number;
  setCurrentId: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}

const CreateAndEditModal: React.FC<CreateAndEditInterface> = ({
  page,
  currentObjectId,
  setCurrentId,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isModalOpen = useSelector(
    (state: RootState) => state.settings.openCreateModal
  );
  const users = useGetBuildingUser();

  const cu = useObjectMutation(page);
  const { data, isLoading } = useCurrentObject(currentObjectId);

  const cancelFunc = () => {
    dispatch(isOpenSettingEditModal(false));
    setCurrentId(0);
    form.resetFields();
  };

  const onFinish = async (values: ObjectInterface) => {
    if (currentObjectId) {
      await cu.mutateAsync({ ...values, id: currentObjectId });
    } else {
      await cu.mutateAsync(values);
    }
    cancelFunc();
  };

  useEffect(() => {
    if (currentObjectId > 0) {
      form.setFieldsValue({
        name: data?.name,
        address: data?.address,
      });
      if (data?.responsibles) {
        users.mutate(data?.responsibles!);
      }
    }
  }, [currentObjectId, data, form]);

  const ModalTitle = (
    <>
      <span style={{ display: "inline-block", marginRight: "1rem" }}>
        Ob'yekt yaratish
      </span>
      {isLoading && <LoadingOutlined />}
    </>
  );

  return (
    <Modal
      className={"editModal"}
      visible={isModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
    >
      <Form
        form={form}
        name="paymentEdit"
        onFinish={onFinish}
        autoComplete="off"
      >
        <label htmlFor={"name"}>Nomi</label>
        <Form.Item name="name" rules={[{ required: true, message: "" }]}>
          <Input className={"input"} id={"name"} />
        </Form.Item>
        <label htmlFor={"address"}>Manzil</label>
        <Form.Item name="address" rules={[{ required: true, message: "" }]}>
          <Input
            placeholder={"Shahar, ko’cha, uy"}
            className={"input"}
            id={"address"}
          />
        </Form.Item>
        {users.data && <label>Mas'ul shaxslar</label>}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {users.data &&
            users.data.data?.map((item: any) => (
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#273B42",
                  padding: "8px",
                  background: "#D9D9D9",
                  marginRight: "10px",
                  borderRadius: "10px",
                }}
              >
                {item.name}
              </p>
            ))}
        </div>
        <div className={"footerCont"}>
          <Button
            onClick={cancelFunc}
            className={"modalFooterBack"}
            type="link"
          >
            Bekor qilish
          </Button>
          <Button
            disabled={isLoading}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit"
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAndEditModal;
