import React from 'react'
import IconInterface from '../models/interfaces/IconInterface'

export const LoginIcon: React.FC<IconInterface> = ({ onClick, className }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99994 10.4997C9.38065 10.4997 10.4999 9.38041 10.4999 7.99969C10.4999 6.61898 9.38065 5.49969 7.99994 5.49969C6.61923 5.49969 5.49994 6.61898 5.49994 7.99969C5.49994 9.38041 6.61923 10.4997 7.99994 10.4997Z"
        stroke="#C1C7D0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3175 12.9998C10.2036 13.7391 8.87471 14.0852 7.5417 13.9832C6.20869 13.8812 4.94791 13.337 3.95941 12.4369C2.97092 11.5368 2.31132 10.3324 2.08529 9.01471C1.85926 7.69704 2.07974 6.34164 2.71175 5.16355C3.34375 3.98546 4.35108 3.05217 5.5739 2.51177C6.79672 1.97136 8.16499 1.8548 9.4616 2.18057C10.7582 2.50635 11.9089 3.25579 12.7311 4.31C13.5532 5.36421 13.9998 6.66279 13.9999 7.9997C13.9999 9.38041 13.4999 10.4997 12.2499 10.4997C10.9999 10.4997 10.4999 9.38041 10.4999 7.9997V5.4997"
        stroke="#C1C7D0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
