import { LoadingOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin, Tooltip } from "antd";
import React from "react";

import {
  useCancelUysot,
  useRetryUysotIntegration,
} from "../../../../queries/mutations";
import RefreshIcon from "../../../../svg/RefreshIcon";
import UysotIcon from "../../../../svg/UysotIcon";
import styles from "./card.module.scss";
import DownloadIcon from "../../../../svg/DownloadIcon";

type Props = {
  id: number;
  type: string;
  sum: number;
  updated_at: string;
  current_sum: number;
  payments: { id: number; type: string; sum: number }[];
  cancel: React.MouseEventHandler<HTMLElement> | undefined;
};

const UysotCard: React.FC<Props> = ({
  id,
  type,
  sum,
  updated_at,
  payments,
  current_sum,
  cancel,
}) => {
  const retryUysotIntegration = useRetryUysotIntegration();
  const cancelUysot = useCancelUysot();

  const retryUysot = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    retryUysotIntegration.mutate();
    e.stopPropagation();
  };

  const openInfoModal = () => {
    // setVisible(true);
  };
  return (
    <>
      <Spin
        spinning={retryUysotIntegration.isLoading || cancelUysot.isLoading}
        indicator={<LoadingOutlined />}
      >
        <div
          className={`${styles.card} ${styles.uysot_card}`}
          onClick={openInfoModal}
        >
          <div className={styles.top}>
            <div className={styles.icon}>
              <UysotIcon />
            </div>
            <div className={styles.date} onClick={retryUysot}>
              <span className={styles.text}>{updated_at}</span>
              <span className={styles.retry} onClick={retryUysot}>
                <RefreshIcon /> Yangilash
              </span>
            </div>
          </div>
          <div className={styles.bottom}>
            <p>{type}</p>
            <h3>
              {sum?.toLocaleString()}
              <span>So'm</span>
            </h3>
          </div>
          <div className={styles.current_sum}>
            <h3>
              {current_sum?.toLocaleString()}
              <span>$</span>
            </h3>
            <Tooltip title="Chiqish">
              <Popconfirm
                placement="right"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  cancelUysot.mutate();
                }}
                okButtonProps={{
                  loading: cancelUysot.isLoading,
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
                title="Integratsiyani uzishni xohlaysizmi ?"
                okText="Ha"
                cancelText="Yo'q"
              >
                <Button onClick={cancel}>
                  <DownloadIcon />
                </Button>
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default UysotCard;
