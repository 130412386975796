import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Empty,
  FormInstance,
  Input,
  Space,
  Spin,
} from "antd";
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useOrderProductList } from "../../../queries/queries";
import {
  addToList,
  filterProducts,
  isOpenSettingEditModal,
  removeFromList,
  setCheckList,
} from "../../../redux/actions";
import { RootState } from "../../../redux/rootReducer";
import mainPaths from "../../../routes/main/mainPaths";
import { rootPaths } from "../../../routes/rootRoutes";
import SearchPart from "../../../svg/SearchPart";
import CreateAndEditModal from "../settings/product/table/CreateAndEditModal";

interface OrderProductListProps {
  hasCreateButton?: boolean;
  isTransferred?: "1" | "0";
  form?: FormInstance;
  ndsValue?: number;
  isAll?: boolean;
}

const OrderProductList: React.FC<OrderProductListProps> = ({
  form,
  hasCreateButton,
  isTransferred,
  ndsValue,
  isAll,
}) => {
  const dispatch = useDispatch();
  const currentBuildingId = useSelector(
    (state: RootState) => state.productList.currentBuildingId
  );
  const {
    data: products,
    isLoading,
    isFetching,
  } = useOrderProductList(currentBuildingId);
  const checkList = useSelector(
    (state: RootState) => state.productList.checkList
  );
  const allProducts = useSelector(
    (state: RootState) => state.productList.products
  );

  const ndsSelectValue = (): number | undefined => {
    if (ndsValue === 0) {
      return 0;
    } else {
      return ndsValue ? ndsValue : undefined;
    }
  };

  useEffect(() => {
    if (products) {
      dispatch(
        setCheckList(
          products.map((product) => {
            if (allProducts?.some((item: any) => item?.id === product.id)) {
              return { ...product, checked: true };
            } else {
              return { ...product, checked: false };
            }
          })
        )
      );
    }
  }, [products, dispatch]);

  const onProductSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    dispatch(filterProducts(target.value));
  };

  const allProductState = () => {
    if (checkList.length === allProducts.length) {
      return true;
    } else return false;
  };

  const disabledAllBtn = () => {
    if (checkList.length > 0) {
      return false;
    } else return true;
  };

  const onClickAllProduct = () => {
    if (!allProductState()) {
      checkList.forEach((product) => {
        dispatch(
          addToList({
            ...product,
            check: null,
            last_price: null,
            date: form?.getFieldValue("date"),
            building_id: form?.getFieldValue("building_id"),
            count: String(product.count),
            price: "",
            maxCount: product.count,
            nds: ndsSelectValue(),
          })
        );
      });
    } else {
      checkList.forEach((product) => {
        dispatch(removeFromList(product.id));
      });
    }
  };

  const disabled = isTransferred === "1";

  return (
    <Space className="productList" direction="vertical">
      <label className="productList_label">
        <span>Mahsulot</span>
        {isAll && (
          <Button
            type="primary"
            disabled={disabledAllBtn()}
            onClick={onClickAllProduct}
          >
            {allProductState()
              ? "- Barchasini o'chirish"
              : "+ Barchasini qo'shish"}
          </Button>
        )}
      </label>
      <Input
        allowClear
        onChange={onProductSearch}
        placeholder="Mahsulot nomi bilan qidirish"
        suffix={<SearchPart />}
      />
      <div className="orderForm_products productList_body">
        {hasCreateButton && (
          <>
            <div className="productList_head">
              <Button
                disabled={disabled}
                onClick={() => dispatch(isOpenSettingEditModal(true))}
                size="large"
                type="text"
                style={{ width: "100%", height: "unset" }}
              >
                + Yangi mahsulot yaratish
              </Button>
            </div>
          </>
        )}
        <div
          style={{ marginTop: hasCreateButton ? 0 : "1rem" }}
          className="productList_checkboxes"
        >
          {isLoading ? (
            <Spin tip="Yuklanyapti" spinning />
          ) : checkList.length === 0 && currentBuildingId !== 0 ? (
            <Empty
              description={
                <>
                  Bu Obyektda mahsulotlar yo'q. Obyektga mahsulotlarni qo'shish
                  uchun yangi{" "}
                  <Link to={`${rootPaths.MAIN}${mainPaths.ORDERS}/new`}>
                    partiya yarating
                  </Link>
                </>
              }
            />
          ) : (
            <>
              {!form && isFetching && (
                <div className={"themeColor"}>
                  <LoadingOutlined />
                  <span style={{ display: "inline-block", marginLeft: "1rem" }}>
                    Yuklanmoqda...
                  </span>
                </div>
              )}
              {checkList.map((product, index) => (
                <Checkbox
                  key={product.id}
                  disabled={(!form && isFetching) || disabled}
                  className="productList_checkbox"
                  checked={product.checked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(
                        addToList({
                          ...product,
                          check: null,
                          last_price: null,
                          date: form?.getFieldValue("date"),
                          building_id: form?.getFieldValue("building_id"),
                          count: "",
                          price: "",
                          maxCount: product.count,
                          nds: ndsSelectValue(),
                        })
                      );
                    } else {
                      const btn = document.getElementById(
                        `deleteBtn${product.id}`
                      );
                      btn?.click();
                      dispatch(removeFromList(product.id));
                    }
                  }}
                >
                  <span title={product.name}>{product.name}</span>
                </Checkbox>
              ))}
            </>
          )}
        </div>
      </div>
      {hasCreateButton && <CreateAndEditModal />}
    </Space>
  );
};

export default OrderProductList;
