import React from "react";
import {useTransferOrders} from "../../../queries/queries";
import {Col, Empty, Row, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import '../supplier/one/OrderDetails.scss'

interface TransferOrdersInterface {
 id: number
}

const TransferOrders: React.FC<TransferOrdersInterface> = ({id}) => {
  const {isLoading,data,isFetching} = useTransferOrders(id)
  if (isLoading)
    return (
      <Spin className={'spinCont'}/>
    )

  return (
    <>
      <div>
        <Row className={'headerOrderDetails'} gutter={[16, 8]}>
          <Col
            className={'item'}
            span={4}>
            TRANSFER #{id}
            {isFetching && <LoadingOutlined style={{marginLeft: '10px'}}/>}
          </Col>
          <Col
            className={'item'}
            span={4}>
            Mahsulot nomi
          </Col>
          <Col
            className={'item'}
            span={4}>
            Miqdori
          </Col>
          <Col
            className={'item'}
            span={4}>
            O’lchami
          </Col>
        </Row>
        {data?.length === 0 &&
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <span>
              Ma'lumot mavjud emas
            </span>
          }/>}
        {data?.map((item, index) => (
          <Row
            className={'bodyOrderDetails'}
            gutter={[16, 8]}
            key={index}>
            <Col
              style={{textAlign: 'right'}}
              className={'item'}
              span={4}>
              {index + 1}
            </Col>
            <Col
              className={'item'}
              span={4}>
              {item.name}
            </Col>
            <Col
              className={'item'}
              span={4}>
              {item.count}
            </Col>
            <Col
              className={'item'}
              span={4}>
              {item.product_type}
            </Col>
          </Row>
        ))}
      </div>
    </>
  )
}

export default TransferOrders