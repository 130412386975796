import react, { useEffect, useState } from "react";
import { Button, Empty, Input, message, Modal, Table, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import type { UploadProps } from "antd";
import type { ColumnsType } from "antd/es/table";

import styles from "./pictures.module.scss";
import { RootState } from "../../../../redux/rootReducer";
import { pictureVisibleAction } from "../../../../redux/actions";
import UploadIcon from "../../../../svg/UploadIcon";
import React from "react";
import { usePicturesFileView } from "../../../../queries/queries";
import { PicturesViewFileData } from "../../../../models/PicturesModel";
import DownloadIcon from "../../../../svg/DownloadIcon";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import PictureFolderIcon from "../../../../svg/PictureFolderIcon";
import { useQueryClient } from "react-query";
import { queryNames } from "../../../../queries/queryNames";
import {
  useDeletePictureFile,
  useDownloadPicture,
  useFileNameUpdate,
} from "../../../../queries/mutations";
import { tableNumber } from "../../../../helper/TableNumber";
import { log } from "util";

const { Dragger } = Upload;

type Props = {
  type: "order" | "used";
  id: number;
};

const Pictures: React.FC<Props> = ({ id, type }) => {
  const visible = useSelector((state: RootState) => state.pictureVisible);
  const dispatch = useDispatch();
  const { data, isLoading } = usePicturesFileView(id, type);
  const qc = useQueryClient();
  const deleteFile = useDeletePictureFile();
  const downloadFile = useDownloadPicture();
  const [uploadLoader, setUploadLoader] = useState<boolean>(false);
  const editFileName = useFileNameUpdate();
  const [filesName, setFilesName] = useState<{ id: number; name: string }[]>(
    []
  );

  useEffect(() => {
    setFilesName(
      data?.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      }) ?? []
    );
  }, [data?.data]);

  const cancelModal = () => {
    const filter = filesName.filter((item) => item.name.length < 3);
    if (filter.length > 0) {
      message.warning("Fayl nomi kamida 3 ta harfdan iborat bo'lishi zarur.");
    } else {
      dispatch(pictureVisibleAction(false));
    }
  };

  const imageFile = (name: string) => {
    const nameArr = name.split(".");
    const index = nameArr.length - 1;
    const fileFormat = nameArr[index];
    const imgFormat = ["png", "svg", "jpg", "jpeg"];

    return imgFormat.some((item) => item === fileFormat);
  };

  const onBlurFileName = (
    e: React.FocusEvent<HTMLInputElement>,
    id: number,
    file: string
  ) => {
    const name = e.target.value;

    if (name.length < 3) {
      message.warning("Fayl nomi kamida 3 ta harfdan iborat bo'lishi zarur.");
    }

    if (file !== name && name.length > 2) {
      editFileName.mutate({
        name,
        id,
      });
    }
  };

  const fileName = (id: number) => {
    return filesName.filter((item) => item.id === id)[0]?.name;
  };

  const onChangeFileName = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    setFilesName((state) => {
      return state.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            name: e.target.value,
          };
        } else return item;
      });
    });
  };

  const column: ColumnsType<PicturesViewFileData> = [
    {
      title: "№",
      className: styles.text_center,
      render: (_, __, index) => {
        return <>{tableNumber(index, "1")}</>;
      },
    },
    {
      title: "Fayllar",
      render: (record: PicturesViewFileData) => {
        return (
          <div className={styles.file_name}>
            {imageFile(record.file) ? (
              <img src={record.file.replace("public/", "")} alt={record.file} />
            ) : (
              <PictureFolderIcon />
            )}
            <Input
              value={fileName(record.id)}
              minLength={1}
              onChange={(e) => onChangeFileName(e, record.id)}
              onBlur={(e) => onBlurFileName(e, record.id, record.name)}
              style={{
                borderColor:
                  fileName(record.id)?.length < 3 ? "red" : "transparent",
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Sana",
      render: (value: PicturesViewFileData) => {
        return <>{dayjs(value.created_at * 1000).format("DD.MM.YYYY")}</>;
      },
    },
    {
      title: "Biriktirgan xodim",
      dataIndex: "user_name",
    },
    {
      title: "",
      render: (value: PicturesViewFileData) => {
        return (
          <div className={styles.actions}>
            <Button
              onClick={() =>
                downloadPictureFile({
                  ...value,
                  name:
                    filesName.filter((item) => item.id === value.id)[0]?.name ??
                    "fayl",
                })
              }
            >
              <DownloadIcon />
            </Button>
            <Button onClick={() => onDeleteFile(value.id)}>
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const props: UploadProps = {
    name: "image",
    action: `${
      localStorage.getItem("domen") ?? "https://backend.dev.uyqur.uz/api"
    }/file/upload`,
    headers: {
      authorization:
        "Bearer " + localStorage.getItem("token")?.replace(/"/gi, "") ?? "",
    },
    accept:
      ".png,.jpg,.jpeg,.svg,.pdf,.doc,.docx,.xls,.xlsx,.txt,.ppt,.pptx,.odp,.tiff,.tif,.csv,.pot,.rtf,.zip,.rar",
    beforeUpload(file) {
      if (file.size > 5120000) {
        message.warning("Fayl hajmi 5120kb dan yuqori bo'lmasligi lozim");
        return false;
      }
    },
    data: (file) => {
      if (type === "used") {
        return {
          file,
          used_id: id,
          name: file.name,
        };
      }
      return {
        file,
        order_id: id,
        name: file.name,
      };
    },
    onChange(info) {
      setUploadLoader(true);
      const { status } = info.file;
      if (status === "error") {
        setUploadLoader(false);
        message.error("Faylni yuborishda xatolik yuz berdi !");
      }
      if (status === "done") {
        setUploadLoader(false);
        message.success("Yuklandi !");
        qc.invalidateQueries([queryNames.PICTURES_VIEW_FILE]);
      }
      if (!status) {
        setUploadLoader(false);
      }
    },
  };

  const onDeleteFile = (id: number) => {
    deleteFile.mutate(id);
  };

  const downloadPictureFile = (data: PicturesViewFileData) => {
    const fileNameArr = data.file.split(".");
    const format = fileNameArr[fileNameArr.length - 1];
    const name = data.name.replace(`.${format}`, "");
    downloadFile.mutate({
      id: data.id,
      name,
      format,
    });
  };

  return (
    <div className="picture">
      <Modal
        visible={visible}
        onCancel={cancelModal}
        title="Hisobot"
        className={styles.pictures}
        centered
      >
        <div className={styles.top}>
          <Dragger {...props}>
            <div className={styles.upload}>
              <div className={styles.icon}>
                <UploadIcon />
              </div>
              <div className={styles.text}>
                <p>
                  <span>Yuklash uchun bosing</span> yoki faylni olib keling{" "}
                  <br /> SVG, PNG, JPG , PDF, DOC, XLS (maksimal hajm: 5MB)
                </p>
              </div>
            </div>
          </Dragger>
        </div>
        <div className={styles.bottom}>
          <Table
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Rasmlar mavjud emas!"
                />
              ),
            }}
            dataSource={data?.data}
            pagination={false}
            columns={column}
            loading={{
              spinning:
                isLoading ||
                deleteFile.isLoading ||
                uploadLoader ||
                downloadFile.isLoading ||
                editFileName.isLoading,
              indicator: <LoadingOutlined />,
            }}
            className={styles.table}
            rowKey={(record: PicturesViewFileData) => record.id}
          />
        </div>
        <div className={styles.footer}>
          <Button type="primary" onClick={cancelModal}>
            Yopish
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Pictures;
