import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
} from "antd";
import dayjs from "rc-picker/node_modules/dayjs";
import { FC, useEffect } from "react";
import InputMask from "react-input-mask";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useSupplierMutation } from "../../../../queries/mutations";
import { useCurrentSupplier } from "../../../../queries/queries";
import { queryNames } from "../../../../queries/queryNames";
import { toggleSupplierCUModal } from "../../../../redux/actions";
import { RootState } from "../../../../redux/rootReducer";
import { DatePicker } from "../../../../service/DatePicker";
import ArrowSelectIcon from "../../../../svg/ArrowSelectIcon";

interface Props {
  supplierId?: number;
  setSupplierId?: (id: number) => void;
  page?: number;
  orderForm?: FormInstance;
}

const { Panel } = Collapse;
const { Item } = Form;

export const SupplierCUModal: FC<Props> = ({
  supplierId,
  setSupplierId,
  page,
  orderForm,
}) => {
  const qc = useQueryClient();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const cu = useSupplierMutation(page, orderForm);
  const isSupplierModalOpen = useSelector(
    (state: RootState) => state.searchHeader.isSupplierModalOpen
  );
  const { data, isLoading, isFetching } = useCurrentSupplier(supplierId);

  const cancelFunc = () => {
    dispatch(toggleSupplierCUModal(false));
    if (setSupplierId) {
      setSupplierId(0);
    }
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    const created_at = dayjs(values?.created_at).format("YYYY-MM-DD");
    for (const key in values) {
      if (!values[key]) {
        delete values[key];
      }
    }
    if (supplierId) {
      await cu
        .mutateAsync({
          ...values,
          id: supplierId,
          created_at,
        })
        .then(() => {
          qc.invalidateQueries([queryNames.SUPPLIER]);
        });
    } else {
      await cu
        .mutateAsync({
          ...values,
          created_at,
        })
        .then(() => {
          qc.invalidateQueries([queryNames.SUPPLIER]);
        });
    }

    cancelFunc();
  };

  useEffect(() => {
    if (supplierId && supplierId > 0) {
      form.setFieldsValue({
        ...data,
        created_at: data?.created_at
          ? dayjs(data?.created_at)
          : dayjs()?.locale("en"),
      });
    } else {
      form.setFieldsValue({
        created_at: dayjs()?.locale("en"),
      });
    }
  }, [supplierId, data, form]);

  const ModalTitle = (
    <>
      <span style={{ display: "inline-block", marginRight: "1rem" }}>
        Ta'minotchi yaratish
      </span>
      {(isLoading || isFetching) && <LoadingOutlined />}
    </>
  );

  return (
    <Modal
      className="editModal"
      visible={isSupplierModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
      centered
    >
      <Form
        form={form}
        onFinish={onFinish}
        name="paymentEdit"
        autoComplete="off"
        layout="vertical"
      >
        <label htmlFor={"name"}>Ta'minotchi yaratish</label>
        <Form.Item name="name" rules={[{ required: true, message: "" }]}>
          <Input
            minLength={4}
            placeholder="Firma yoki MCHJ nomi"
            className="input"
            id="name"
          />
        </Form.Item>
        <Row gutter={[20, 8]}>
          <Col span={16}>
            <label htmlFor={"phone"}>Telefon</label>
            <Form.Item name="phone" rules={[{ required: true, message: "" }]}>
              <InputMask mask="+\9\9899 999 99 99">
                {() => (
                  <Input
                    placeholder={"+998 XX XXX XX XX"}
                    className={"input"}
                    id={"phone"}
                  />
                )}
              </InputMask>
            </Form.Item>
          </Col>
        </Row>
        <Collapse
          className="collapse"
          bordered={false}
          expandIcon={(e) =>
            e.isActive ? (
              <>
                Yashirish <ArrowSelectIcon transform={180} />
              </>
            ) : (
              <>
                Qo'shimcha
                <ArrowSelectIcon transform={90} />
              </>
            )
          }
        >
          <Panel key={1} header="">
            <Row gutter={[20, 8]}>
              <Col span={12}>
                <Item name="created_at" label="Boshlanish sanasi">
                  <DatePicker
                    placeholder="Sanani kiriting"
                    format="DD.MM.YYYY"
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="balans" label="Boshlang’ich balans">
                  <Input
                    type="number"
                    placeholder="Balansni kiriting"
                    suffix="so'm"
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="address" label="Manzil">
                  <Input placeholder="Manzilni kiriting" />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="extra_phone" label="Qo’shimcha telefon">
                  <InputMask mask="+\9\9899 999 99 99">
                    {() => (
                      <Input
                        placeholder={"+998 XX XXX XX XX"}
                        className="input"
                        id="extra_phone"
                      />
                    )}
                  </InputMask>
                </Item>
              </Col>
              <Col span={12}>
                <Item name="inn" label="INN">
                  <Input type="number" placeholder="INN ni kiriting" />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="shot" label="Hisob raqam">
                  <Input type="number" placeholder="Hisob raqamni kiriting" />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="nds" label="NDS">
                  <Input type="number" placeholder="NDS ni kiriting" />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="mfo" label="MFO">
                  <Input type="number" placeholder="MFO ni kiriting" />
                </Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <div className="footerCont">
          <Button
            onClick={cancelFunc}
            className={"modalFooterBack"}
            type="link"
          >
            Bekor qilish
          </Button>
          <Button
            disabled={isLoading || isFetching}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit"
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
