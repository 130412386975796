import { FormInstance, Tag } from "antd";
import dayjs from "rc-picker/node_modules/dayjs";
import React from "react";

import "./DatePickerExtraFooter.scss";

interface Props {
  form?: FormInstance;
  visible?: (visibility: boolean) => void;
  untilDay?: string;
  setState?: React.Dispatch<React.SetStateAction<any[]>>;
}

const DatePickerExtraFooter: React.FC<Props> = ({
  form,
  visible,
  untilDay,
  setState,
}) => {
  const green = "#34DBA1";

  // till today
  const untilToday = () => {
    if (untilDay) {
      form?.setFieldsValue({
        dateArr: [dayjs(untilDay), dayjs()],
      });
      setState && setState([dayjs(untilDay), dayjs()]);
    } else {
      form?.setFieldsValue({
        dateArr: [dayjs(0), dayjs()],
      });
      setState && setState([dayjs(0), dayjs()]);
    }
    if (visible) {
      visible(false);
    }
  };

  // today
  const today = () => {
    form?.setFieldsValue({
      dateArr: [dayjs(), dayjs()],
    });
    setState && setState([dayjs(), dayjs()]);
    if (visible) {
      visible(false);
    }
  };

  // yesterday
  const yesterday = () => {
    form?.setFieldsValue({
      dateArr: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    });
    setState &&
      setState([dayjs().subtract(1, "day"), dayjs().subtract(1, "day")]);
    if (visible) {
      visible(false);
    }
  };

  // month
  const month = () => {
    form?.setFieldsValue({
      dateArr: [dayjs().startOf("month"), dayjs().endOf("month")],
    });
    setState && setState([dayjs().startOf("month"), dayjs().endOf("month")]);
    if (visible) {
      visible(false);
    }
  };

  // year
  const year = () => {
    form?.setFieldsValue({
      dateArr: [dayjs().startOf("year"), dayjs().endOf("year")],
    });
    setState && setState([dayjs().startOf("year"), dayjs().endOf("year")]);
    if (visible) {
      visible(false);
    }
  };

  return (
    <div className="extraFooter">
      <Tag onClick={untilToday} color={green}>
        Shu kungacha
      </Tag>
      <Tag onClick={today} color={green}>
        Bugun
      </Tag>
      <Tag onClick={yesterday} color={green}>
        Kecha
      </Tag>
      <Tag onClick={month} color={green}>
        Joriy oy
      </Tag>
      <Tag onClick={year} color={green}>
        Joriy yil
      </Tag>
    </div>
  );
};

export default DatePickerExtraFooter;
