import React from 'react'

const CreditCard: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="61"
      height="47"
      viewBox="0 0 61 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <rect
          x="59.7773"
          y="1"
          width="44.5569"
          height="58.7772"
          rx="12"
          transform="rotate(90 59.7773 1)"
          fill="url(#paint0_linear)"
          stroke="white"
        />
        <rect
          x="36.5518"
          y="7.7561"
          width="31.0444"
          height="12.3256"
          rx="6.16278"
          transform="rotate(90 36.5518 7.7561)"
          stroke="white"
        />
        <path d="M1 10.0789H25.5819" stroke="white" />
        <path d="M35.3193 10.0789H59.9013" stroke="white" />
        <path d="M1 23.2786H24.2259" stroke="white" />
        <path d="M36.6396 23.2786H59.8655" stroke="white" />
        <path d="M1 36.478H25.5819" stroke="white" />
        <path d="M35.3193 36.478H59.9013" stroke="white" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="61.4911"
          y1="3.30499"
          x2="102.515"
          y2="59.4372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C4C4" />
          <stop offset="1" stopColor="#C4C4C4" stopOpacity="0.3" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CreditCard
