import React from 'react'

interface BackOutlinedProps {}

const BackOutlined: React.FC<BackOutlinedProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.875 10.125L6.1875 8.4375L7.875 6.75"
        stroke="#FF3830"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39775 1.2415L16.7585 8.60225C16.9782 8.82192 16.9782 9.17808 16.7585 9.39775L9.39775 16.7585C9.17808 16.9782 8.82192 16.9782 8.60225 16.7585L1.2415 9.39775C1.02183 9.17808 1.02183 8.82192 1.2415 8.60225L8.60225 1.2415C8.82192 1.02183 9.17808 1.02183 9.39775 1.2415Z"
        stroke="#FF3830"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8125 10.6875V10.125C11.8125 9.67745 11.6347 9.24822 11.3182 8.93176C11.0018 8.61529 10.5726 8.4375 10.125 8.4375H6.1875"
        stroke="#FF3830"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BackOutlined
