import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Empty, Popconfirm, Table } from "antd";

import styles from "../checkout.module.scss";
import TrashIcon from "../../../../svg/TrashIcon";
import { LoadingOutlined } from "@ant-design/icons";
import GrowthIcon from "../../../../svg/GrowthIcon";
import DecreaseIcon from "../../../../svg/DecreaseIcon";
import BoldPointIcon from "../../../../svg/BoldPointIcon";
import { useGetCheckoutPayments } from "../../../../queries/queries";
import { useDeleteCheckoutPayment } from "../../../../queries/mutations";
import {
  CheckoutPaymentsCardType,
  CheckoutViewType,
} from "../../../../models/checkout/CheckoutPaymentModel";
import { CheckoutSearchKeys } from "../../../../models/checkout/CheckoutSearchKeys";
import PaginationComp from "../../universialComponents/PaginationComp";
import { ASC, DESC, KEY, SORT } from "../../../../helper/paramNames";
import ArrowUpIcon from "../../../../svg/ArrayUpIcon";
import ArrowDownIcon from "../../../../svg/ArrowDownIcon";
import ArrowDownUpIcon from "../../../../svg/ArrayDownUpIcon";
import { CheckoutModalEnums } from "../../../../models/checkout/CheckoutModalTypes";

type Props = {
  visible: {
    visible: boolean;
    data: CheckoutViewType;
    payment: number;
  };
  setOnePayment: React.Dispatch<
    React.SetStateAction<CheckoutPaymentsCardType | undefined>
  >;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      data: CheckoutViewType;
      payment: number;
    }>
  >;
  filtrParams: string | undefined;
  filtrPrice: { summa: number; summa$: number } | undefined;
  setFiltrPrice: React.Dispatch<
    React.SetStateAction<{ summa: number; summa$: number } | undefined>
  >;
  setIsLoadingPayment: React.Dispatch<React.SetStateAction<boolean>>;
};

const CheckoutTable: React.FC<Props> = ({
  visible,
  setOnePayment,
  setVisible,
  filtrParams,
  setFiltrPrice,
  setIsLoadingPayment,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  let searchParams = location.search.replace("?", "");
  let searchQuery = new URLSearchParams(location.search);

  const searchValue = useCallback(() => {
    // const value = location.search.replace("?", "").split("=")[1];
    // const key = location.search.replace("?", "").split("=")[0];

    // switch (key) {
    //   case CheckoutSearchKeys.SEARCH:
    //     return value;
    //   default:
    //     return "";
    // }
    const value = location.search;

    if (value.includes("?")) {
      return value.slice(1, value?.length);
    }
    return value;
  }, [location.search]);

  const { data, isLoading } = useGetCheckoutPayments(
    page,
    perPage,
    searchQuery.get("search") ?? "",
    visible.payment,
    1,
    searchParams,
    filtrParams
  );

  useEffect(() => {
    setIsLoadingPayment(isLoading);
    setFiltrPrice({ summa: data?.summa!, summa$: data?.summa_dol! });
    // if (data)
    //   setFiltrPrice(
    //     data?.data?.reduce((pre, cur) => {
    //       if (cur?.valuta_history_price && cur?.valuta_id > 1) {
    //         return pre + cur?.price * cur?.valuta_history_price;
    //       } else return pre + cur?.price ?? 0;
    //     }, 0)
    //   );
  }, [data]);

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (searchValue()) setPage(1);
  }, [searchValue]);

  const deleteCheckoutPayment = useDeleteCheckoutPayment();

  // delete payments
  const deletePayments = (e: CheckoutPaymentsCardType) => {
    deleteCheckoutPayment.mutate(e.id);
  };

  const changeSortData = (key: string) => {
    params.set(KEY, key);
    params.set(SORT, params.toString().includes(ASC) ? DESC : ASC);
    history.push(
      `${location.pathname}${
        params.toString().includes("?") ? "" : "?"
      }${params.toString()}`
    );
  };

  const tableHeaderSortIcon = (sortName: string) => {
    // sortType = location.search && location.search.split("=")[2]
    // sortKey = location.search && location.search.split("=")[1].split("&")[0]
    const sortType = params.get(SORT);
    const sortKey = params.get(KEY);
    if (sortName === sortKey) {
      if (sortType === DESC) return <ArrowUpIcon />;
      else return <ArrowDownIcon />;
    } else return <ArrowDownUpIcon />;
  };

  // table columns
  const columns: ColumnsType<CheckoutPaymentsCardType> = [
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          # {tableHeaderSortIcon("id")}
        </div>
      ),
      className: styles.kirim,
      render: (e: CheckoutPaymentsCardType) => <span>#{e.id}</span>,
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("id");
          },
        };
      },
    },
    {
      title: <span>KIRIM-CHIQIM {tableHeaderSortIcon("kirim")}</span>,
      className: styles.kirim,
      render: (e: CheckoutPaymentsCardType) => (
        <span>{e.kirim === 1 ? <GrowthIcon /> : <DecreaseIcon />}</span>
      ),
      width: 140,
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("kirim");
          },
        };
      },
    },
    {
      title: <span>SANA {tableHeaderSortIcon("date")}</span>,
      render: (e: CheckoutPaymentsCardType) => (
        <span>{dayjs(e.date).format("DD.MM.YYYY HH:mm")}</span>
      ),
      width: 140,
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("date");
          },
        };
      },
    },
    {
      title: <span>KASSA {tableHeaderSortIcon("payment_type_name")}</span>,
      className: styles.kassa,
      render: (e: CheckoutPaymentsCardType) => (
        <div>
          <BoldPointIcon /> {e.payment_type_name}
        </div>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("payment_type_name");
          },
        };
      },
      width: 100,
    },
    {
      title: <span>SUMMA {tableHeaderSortIcon("price")}</span>,
      render: (e: CheckoutPaymentsCardType) => (
        <span>
          {Number(e.price).toLocaleString()} {e.valuta_name}
        </span>
      ),
      // width: "max-content",
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("price");
          },
        };
      },
    },
    {
      title: <span>MANBAA {tableHeaderSortIcon("supplier_name")}</span>,
      render: (e: CheckoutPaymentsCardType) => (
        <span>{e.supplier_name ? e.supplier_name : e.expense_type_name}</span>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("supplier_name");
          },
        };
      },
    },
    {
      title: <span>OBYEKT {tableHeaderSortIcon("building_name")}</span>,
      render: (e: CheckoutPaymentsCardType) => (
        <span>{e?.building_name ?? "Tanlanmagan"}</span>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("building_name");
          },
        };
      },
    },
    {
      title: "ACTION",
      className: styles.kirim,
      render: (e: CheckoutPaymentsCardType) => {
        return (
          <Popconfirm
            placement="left"
            title="O'chirishni davom ettirasizmi ?"
            okText="Ha !"
            cancelText="Yo'q."
            okType="danger"
            onConfirm={() => {
              deletePayments(e);
            }}
          >
            <span className={styles.action}>
              <Button danger>
                <TrashIcon />
              </Button>
            </span>
          </Popconfirm>
        );
      },
      onCell: () => {
        return {
          onClick: (e) => {
            e.stopPropagation();
          },
        };
      },
    },
  ];

  // table locale content
  const localeTable = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Ma'lumotlar mavjud emas!"
      />
    ),
  };

  // table loading content
  const loadingTable = {
    spinning: isLoading || deleteCheckoutPayment.isLoading,
    indicator: <LoadingOutlined />,
  };

  // on row function
  const onTableRow = (e: CheckoutPaymentsCardType) => {
    setVisible({
      visible: true,
      data: {
        payment_type_id: 0,
        payment_type_name: "",
        price: 0,
        price_currency: 0,
      },
      payment: 0,
    });
    setOnePayment(e);
  };

  return (
    <div className={styles.table}>
      <Table
        dataSource={data?.data}
        columns={columns}
        locale={localeTable}
        loading={loadingTable}
        pagination={false}
        onRow={(e) => {
          return {
            onClick: () => onTableRow(e),
          };
        }}
        rowKey={(e) => e.id}
      />
      <PaginationComp
        paginationProps={{
          onChange: (number) => setPage(number),
          total: data?.total,
          current: data?.current_page,
          pageSize: 10,
        }}
        per_page={perPage}
        setPerPage={setPerPage}
        showSizeChanger={true}
      />
    </div>
  );
};

export default CheckoutTable;
