import { CheckCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Button, Form, message, Popconfirm } from "antd";
import dayjs, { Dayjs } from "rc-picker/node_modules/dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useCUTransfer } from "../../../queries/mutations";
import { useObject } from "../../../queries/queries";
import { resetProductList, setCurrentBuildingId } from "../../../redux/actions";
import { RootState } from "../../../redux/rootReducer";
import mainPaths from "../../../routes/main/mainPaths";
import { rootPaths } from "../../../routes/rootRoutes";
import SearchHeader from "../universialComponents/SearchHeader";
import CreateTransferForm from "./CreateTransferForm";

const { useForm } = Form;
interface CreateTransferProps {}

export type TransferForm = {
  first_building: number;
  second_building: number;
  date: Dayjs;
};
const CreateTransfer: React.FC<CreateTransferProps> = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { push } = useHistory();
  const products = useSelector(
    (state: RootState) => state.productList.products
  );
  useDocumentTitle("Transfer qilish");
  const params = useParams<{ transferId: string }>();
  const mutation = useCUTransfer(params.transferId);
  const [form] = useForm<TransferForm>();
  const { data } = useObject<undefined>();
  useEffect(() => {
    if (params.transferId === "new") {
      form.setFieldsValue({
        date: dayjs(),
        first_building: data?.[0].id || undefined,
        second_building: data?.[1].id || undefined,
      });

      if (data?.[0].id) {
        dispatch(setCurrentBuildingId(data[0].id));
      }
    }
  }, [params.transferId, form, data, dispatch]);

  const onFinish = (values: TransferForm) => {
    if (products.length === 0) {
      message.error(
        "Transferni amalga oshirish uchun birorta mahsulotni tanlang",
        5
      );
      return;
    }
    mutation.mutate({
      ...values,
      date: dayjs(values.date).format("YYYY-MM-DD"),
      products,
    });
    // await
  };

  useEffect(() => {
    return () => {
      dispatch(resetProductList());
    };
  }, [dispatch]);

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <SearchHeader
        title={
          <>
            <Link
              to={`${rootPaths.MAIN}${mainPaths.TRANSFER}`}
              className="cOrder_backIcon"
            >
              <LeftCircleOutlined />
            </Link>
            Transfer yaratish
          </>
        }
        buttons={
          <Popconfirm
            title="Tasdiqlashni xohlaysizmi?"
            okText="Ha"
            cancelText="Yoq"
            onConfirm={() => form.submit()}
          >
            <Button
              loading={mutation.isLoading}
              icon={<CheckCircleOutlined />}
              className={"cOrder_headerBtn button"}
            >
              Tasdiqlash
            </Button>
          </Popconfirm>
        }
      />
      <CreateTransferForm form={form} />
    </Form>
  );
};

export default CreateTransfer;
