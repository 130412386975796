import {mathRound} from "./mathRound";

export function moneyToView(number: any) {
  if (typeof number === 'number') {
    number = number.toString()
  }
  return isNaN(number) ||
    number === '' ||
    (typeof number === 'string' && number.includes('.'))
    ? number
    : parseInt(number).toLocaleString('ru')
}

export const getValutePriceById = (id: number, valuties: any[]) => {
  for (let i = 0; i < valuties?.length; i++) {
    if (valuties[i].id === id) {
      return valuties[i].price
    }
  }
}

export const selectFilter = (input: string, option: any) => {
  if (option) {
    const { children } = option.props
    const lowerCaseInput = input.toLowerCase()
    let lowerCaseOption
    if (typeof children === 'string') {
      lowerCaseOption = option.props?.children?.toLowerCase()
    } else {
      const littleChildren = children.props.children
      lowerCaseOption = littleChildren[littleChildren.length - 1]
    }
    return lowerCaseOption?.includes(lowerCaseInput)
  }
  return false
}

export const convertLongMoney = (money: number) => {
  return money >1e9
    ? `${mathRound((money / 1e9),3)} mlrd`
    :
    money > 1e6
    ? `${mathRound((money / 1e6),3)} mln`
    : money > 1000
    ? `${mathRound((money / 1e3),3)} ming`
    : money
}

// export const printElement = (element: HTMLElement | HTMLDivElement | null) => {
//   if (element) {
//     const printer: any = document.getElementById('iframeToPrint')
//     if (printer) {
//       const contentWindow = printer.contentWindow
//       contentWindow.document.open()
//       contentWindow.document.write(element.innerHTML)
//       contentWindow.document.close()
//       contentWindow.focus()
//       contentWindow.print()
//     }
//   }
// }
// export const printElement2 = () => {
//   const element: any = document.getElementById('root')
//   if (element) {
//     element.focus()
//     window.print()
//   }
// }

export function isElementInViewport(el: any) {
  // console.log('element', el)
  if (!el) return false

  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  )
}

export const getLastItem = <T>(arr: T[]): T => {
  return arr[arr.length - 1]
}

export const generateColor:() => string = () => {
  const letters = '0123456789ABCDEF'
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor (Math.random () * 16)];
  }
  return color
}