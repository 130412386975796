import {Reducer} from "redux";
import {PICTURE_VISIBLE} from "./types";


const initialState: boolean = false;

const pictureVisibleReducer: Reducer<typeof initialState> = (
    state = initialState,
    action
   ) => {
    switch (action.type) {
        case PICTURE_VISIBLE: return action.payload;
        default: return state
    }
}

export default pictureVisibleReducer;
