import React, { useEffect, useState } from "react";
import { NotePencil } from "../../../../svg/NotePencil";
import { Garbage } from "../../../../svg/Garbage";
import { Popconfirm, Spin, Table } from "antd";
import CreateAndEditModal from "./CreateAndEditModal";
import { isOpenSettingEditModal } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "../../../../queries/queries";
import { LoadingOutlined } from "@ant-design/icons";
import { useDeleteUser } from "../../../../queries/mutations";
import PaginationComp from "../../universialComponents/PaginationComp";
import { RootState } from "../../../../redux/rootReducer";

const { Column } = Table;

const User = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setUserId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);

  const dispatch = useDispatch();
  const searchString = useSelector(
    (state: RootState) => state.searchHeader.searchString
  );

  const { data, isLoading, isFetching } = useUser(currentPage, searchString);
  const deleteUser = useDeleteUser(setDeleteId, currentPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchString]);

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id);
      await deleteUser.mutateAsync(id);
      if (data?.data.length === 1 && data.current_page !== 1)
        setCurrentPage(currentPage - 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Table
        loading={isLoading}
        rowKey={(record) => record.id!}
        pagination={false}
        dataSource={data?.data || []}
        onRow={(e) => {
          return {
            onClick: () => {
              setUserId(e.id!);
              dispatch(isOpenSettingEditModal(true));
            }
          };
        }}
      >
        <Column
          key={"key"}
          width={"5%"}
          title={"#"}
          align={"center"}
          render={(record, i, index) => (
            <span>{(currentPage - 1) * 10 + index + 1}</span>
          )}
        />
        <Column
          key={"name"}
          title={
            <>
              <span style={{ marginRight: "10px" }}>F.I.O</span>
              {isFetching && <LoadingOutlined />}
            </>
          }
          dataIndex={"name"}
        />
        <Column key={"login"} title={"LOGIN"} dataIndex={"login"} />
        <Column
          key={"role"}
          width={"10%"}
          title={"LAVOZIMI"}
          dataIndex={"role"}
        />
        <Column
          key={"phone"}
          width={"15%"}
          title={"TELEFON"}
          dataIndex={"phone"}
        />
        <Column
          key={"action"}
          width={"10%"}
          title={"ACTION"}
          align={"center"}
          render={(record) => (
            <div>
              <span title={"O'zgartirish"}>
                <NotePencil
                  onClick={() => {
                    setUserId(record.id);
                    dispatch(isOpenSettingEditModal(true));
                  }}
                  className={"tableIcon"}
                />
              </span>
              {deleteUser.isLoading && deleteId === record.id ? (
                <Spin />
              ) : (
                <Popconfirm
                  placement="left"
                  title="O'chirshni xohlaysizmi?"
                  onConfirm={() => deleteFunc(record.id)}
                  okText="Xa"
                  cancelText="Yoq"
                >
                  <span title={"O'chirish"}>
                    <Garbage className={"tableIcon"} />
                  </span>
                </Popconfirm>
              )}
            </div>
          )}
          onCell={() => {
            return {
              onClick: (e) => {
                e.stopPropagation();
              }
            };
          }}
        />
      </Table>
      <CreateAndEditModal
        page={currentPage}
        setUserId={setUserId}
        currentUserId={userId}
      />
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          onChange: (page) => {
            setCurrentPage(page);
          }
        }}
      />
    </>
  );
};

export default User;
