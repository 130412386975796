import React from "react";
import { Spin } from "antd";
import { Autoplay, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { LoadingOutlined } from "@ant-design/icons";

import Card from "./Card";
import { useGetCheckoutView } from "../../../../queries/queries";
import styles from "../checkout.module.scss";
import { CheckoutViewType } from "../../../../models/checkout/CheckoutPaymentModel";
import GeneralCard from "./GeneralCard";

type Props = {
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      data: CheckoutViewType;
      payment: number;
    }>
  >;
};

const CheckoutCards: React.FC<Props> = ({ setVisible }) => {
  const { data, isLoading } = useGetCheckoutView();

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
      <div className={styles.cards}>
        <Swiper
          mousewheel={true}
          pagination={false}
          spaceBetween={16}
          breakpoints={{
            1300: {
              slidesPerView: 4,
              width: 1115,
            },
            1900: {
              slidesPerView: 6,
              width: 1670,
            },
            768: {
              slidesPerView: 1,
              width: 300,
            },
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          modules={[Mousewheel, Autoplay]}
        >
          <SwiperSlide>
            <GeneralCard data={data} />
          </SwiperSlide>
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <Card data={item} setVisible={setVisible} key={index} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Spin>
  );
};

export default CheckoutCards;
