import React from "react";

const DecreaseIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: "rotate(180deg)"
      }}
    >
      <path
        d="M13.491 1.66675L6.50768 1.66675C3.47435 1.66675 1.66602 3.47508 1.66602 6.50841L1.66602 13.4834C1.66602 16.5251 3.47435 18.3334 6.50768 18.3334H13.4827C16.516 18.3334 18.3244 16.5251 18.3244 13.4917V6.50841C18.3327 3.47508 16.5244 1.66675 13.491 1.66675ZM14.6077 6.27508L8.17435 12.7084L10.691 12.7084C11.0327 12.7084 11.316 12.9917 11.316 13.3334C11.316 13.6751 11.0327 13.9584 10.691 13.9584L6.66602 13.9584C6.32435 13.9584 6.04102 13.6751 6.04102 13.3334L6.04102 9.30841C6.04102 8.96675 6.32435 8.68341 6.66602 8.68341C7.00768 8.68341 7.29102 8.96675 7.29102 9.30841V11.8251L13.7244 5.39175C13.8494 5.26675 14.0077 5.20841 14.166 5.20841C14.3244 5.20841 14.4827 5.26675 14.6077 5.39175C14.8494 5.63341 14.8494 6.03341 14.6077 6.27508Z"
        fill="#F74753"
      />
    </svg>
  );
};

export default DecreaseIcon;
