import {SupplierOrder} from "../components/main/supplier/all/SupplierAllTypes";
import {mathRound} from "./mathRound";

export const calculateResidue = (data: SupplierOrder[]) => {
  let sum = 0
  let isDifferentValuta = false
  let valuta = data[0].valuta
  data.forEach(item => {
    if (item.valuta !== valuta) {
      isDifferentValuta = true
    }
  })
  if (isDifferentValuta) {
    data.forEach(item => {
      const payed = item?.payment_sum ? item?.payment_sum : 0
      const residue = item.price * item.valuta_history_price - payed
      sum += residue
    })
    return {
      valuta: "so'm",
      residue: sum,
      valuta_history_price: data[0].valuta_history_price
    }
  } else {
    data.forEach(item => {
      const payed = item?.payment_sum ? item?.payment_sum / item.valuta_history_price : 0
      const residue = item.price - payed
      sum += residue
    })
    return {
      valuta: valuta,
      residue: mathRound(sum, 3),
      valuta_history_price: data[0].valuta_history_price
    }
  }
}

export const calculateOne = (
  price: number | undefined,
  payment_sum: number | undefined,
  old_valuta_history_price: number | undefined,
  new_valuta_history_price: number | undefined) => {
  let payed = 0
  let residue = 0
  const old_history = old_valuta_history_price || 1
  const new_history = new_valuta_history_price || 1
  if (!price)
    return 0
  if (old_valuta_history_price === new_valuta_history_price) {
    payed = payment_sum ? payment_sum / old_history : 0
    residue = price - payed
  } else {
    payed = payment_sum ? payment_sum / new_history : 0
    residue = (price * old_history) / new_history - payed
  }
  return mathRound(residue, 3)
}