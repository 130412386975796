import { ResponsiveBar } from '@nivo/bar'
import { FC } from 'react'
import { useTheme } from '@nivo/core'
import { convertLongMoney } from '../../../../service/Helpers'

const BoldTick = ({
  value: _value,
  x,
  y,
  opacity,
  rotate,
  format,
  lineX,
  lineY,
  onClick,
  textX,
  textY,
  textBaseline,
  textAnchor,
}: any) => {
  const theme = useTheme()

  let value = _value
  let gStyle: any = { opacity }
  if (onClick) {
    gStyle['cursor'] = 'pointer'
  }

  return (
    <g
      transform={`translate(${x},${y})`}
      {...(onClick ? { onClick: (e) => onClick(e, value) } : {})}
      style={gStyle}
    >
      <line x1={0} x2={lineX} y1={0} y2={lineY} style={theme.axis.ticks.line} />
      <text
        dominantBaseline={textBaseline}
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY}) rotate(${rotate})`}
        // (1) merge theme styles with our fontWeight
        style={{ ...theme.axis.ticks.text, fontWeight: 'bold' }}
      >
        {convertLongMoney(value)}
      </text>
    </g>
  )
}

export const TopProductsChart: FC<any> = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={['sum_price']}
    indexBy="name"
    margin={{ top: 0, right: 50, bottom: 50, left: 160 }}
    padding={0.5}
    layout="horizontal"
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    // valueFormat={{ format: '', enabled: false }}
    colors={['#33D79F']}
    tooltip={({ value, data }) => (
      <div className="stats-tooltip">
        <div>{data.name}</div>
        <div className="text-bold">{value.toLocaleString('ru')} so'm</div>
      </div>
    )}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    borderRadius={4}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickRotation: 45,
      renderTick: BoldTick,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
  />
)
