import { Popconfirm } from "antd";
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../redux/rootReducer";
import mainPaths from "../../../routes/main/mainPaths";
import { rootPaths } from "../../../routes/rootRoutes";
import { UserService } from "../../../service/UserService";
import { Ellipse } from "../../../svg/Ellipse";
import { LogOut } from "../../../svg/LogOut";
import { roles } from "../settings/user/roles";
import logo from "./../../../image/logo.png";
// import Ellipse from './../../../image/ellipse.png'
import "./Menu.scss";
import { menuList } from "./menuList";

export const Menu = () => {
  const { push, location } = useHistory();
  const user = useSelector((state: RootState) => state.user.userData);
  const onLogout = () => {
    UserService.logout();
  };

  const menuLists = () => {
    if (user.role === roles[0] || user.role === roles[3]) {
      let list: { path: string; name: string; icon: JSX.Element }[] = [];
      for (let i = 0; i < menuList.length; i++) {
        if (
          menuList[i].path === mainPaths.EXPENSE ||
          menuList[i].path === mainPaths.EXPENSE_CREATE ||
          menuList[i].path === `${mainPaths.EXPENSE}/:id` ||
          menuList[i].path === mainPaths.ORDERS ||
          menuList[i].path === `${mainPaths.ORDERS}/:orderId`
        )
          list = [...list, menuList[i]];
      }
      return list;
    } else if (user.role === roles[1]) return menuList;
    else return menuList.filter((item) => item.path !== mainPaths.STATISTICS);
  };

  const brandName = () => {
    const name = localStorage.getItem("domen-name") ?? "";

    if (name === "uyqur") {
      return null;
    } else {
      if (name.length > 100) {
        return name.slice(0, 100).concat("...").toUpperCase();
      } else return name.toUpperCase();
    }
  };

  return (
    <div className="menuComponent">
      <div className="logoUyqurUz">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div>
          <h1>UYQUR</h1>
          <p>{brandName()}</p>
        </div>
      </div>
      <div className="menu">
        <ul>
          {menuLists().map((item) => {
            return (
              <li
                key={item.name}
                className={
                  location.pathname.includes(item.path) ? "active" : ""
                }
                onClick={() => push(`${rootPaths.MAIN}${item.path}`)}
              >
                <span className="ellipse">
                  <Ellipse />
                </span>
                <span className="liIcons">{item.icon}</span>
                {item.name}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="logOut">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            transform: "translateX(-20%)",
          }}
        >
          <p
            style={{
              marginBottom: "0px",
              fontFamily: "Gilroy",
              fontWeight: 600,
              fontSize: "14px",
              color: "white",
            }}
          >
            {user.name}
          </p>
          <p
            style={{
              marginBottom: "0px",
              fontFamily: "Gilroy",
              fontWeight: 300,
              fontSize: "10px",
              color: "white",
            }}
          >
            {user.role?.[0].toUpperCase() + user.role?.slice(1)}
          </p>
        </div>
        <Popconfirm
          title="Tizimdan chiqmoqchimisiz?"
          onConfirm={onLogout}
          okButtonProps={{ danger: true }}
          cancelText="Yoq"
          okText="Ha"
        >
          <div style={{ cursor: "pointer" }}>
            <LogOut />
          </div>
        </Popconfirm>
      </div>
    </div>
  );
};
