import React from "react";

const ArrowSquareRightIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.64062 22.6001H15.6406C20.6406 22.6001 22.6406 20.6001 22.6406 15.6001V9.6001C22.6406 4.6001 20.6406 2.6001 15.6406 2.6001H9.64062C4.64062 2.6001 2.64062 4.6001 2.64062 9.6001V15.6001C2.64062 20.6001 4.64062 22.6001 9.64062 22.6001Z"
        stroke="#1C212D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3809 16.1303L14.9009 12.6003L11.3809 9.07031"
        stroke="#1C212D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowSquareRightIcon;
