import React from 'react'

const SaveIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 5.70711V13C13.5 13.1326 13.4473 13.2598 13.3536 13.3536C13.2598 13.4473 13.1326 13.5 13 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V3C2.5 2.86739 2.55268 2.74021 2.64645 2.64645C2.74021 2.55268 2.86739 2.5 3 2.5H10.2929C10.3586 2.5 10.4236 2.51293 10.4842 2.53806C10.5449 2.56319 10.6 2.60002 10.6464 2.64645L13.3536 5.35355C13.4 5.39998 13.4368 5.4551 13.4619 5.51576C13.4871 5.57643 13.5 5.64145 13.5 5.70711Z"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 13.5005V9.50049C5 9.36788 5.05268 9.2407 5.14645 9.14693C5.24021 9.05317 5.36739 9.00049 5.5 9.00049H10.5C10.6326 9.00049 10.7598 9.05317 10.8536 9.14693C10.9473 9.2407 11 9.36788 11 9.50049V13.5005"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 4.50055H6"
        stroke="#273B42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SaveIcon
