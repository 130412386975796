import mainPaths from "../../../routes/main/mainPaths";
import { ChartLineUp } from "../../../svg/ChartLineUp";
import DollarIcon from "../../../svg/DollarIcon";
import { GearSix } from "../../../svg/GearSix";
import { GitDiff } from "../../../svg/GitDiff";
import { HistoryOutlined } from "../../../svg/HistoryOutlined";
import ShopRemoveIcon from "../../../svg/ShopRemoveIcon";
import { Stack } from "../../../svg/Stack";

export const menuList = [
  {
    name: "Partiyalar",
    icon: <Stack />,
    path: mainPaths.ORDERS,
  },
  {
    name: "Chiqim",
    icon: <ShopRemoveIcon />,
    path: mainPaths.EXPENSE,
  },
  {
    name: "Ta'minotchilar",
    icon: <GitDiff />,
    path: mainPaths.SUPPLIER,
  },
  {
    name: "Kassa",
    icon: <DollarIcon />,
    path: mainPaths.CHECKOUT,
  },
  // {
  //   name: "Amallar tarixi",
  //   icon: <HistoryOutlined />,
  //   path: mainPaths.HISTORY
  // },
  // {
  //   name: 'Mahsulot',
  //   icon: <Package />,
  //   path: mainPaths.PRODUCT,
  // },
  // {
  //   name: "To'lovlar",
  //   icon: <CurrencyCircleDollar />,
  //   path: mainPaths.PAYMENTS,
  // },
  {
    name: "Statistika",
    icon: <ChartLineUp />,
    path: mainPaths.STATISTICS,
  },
  {
    name: "Sozlamalar",
    icon: <GearSix />,
    path: mainPaths.SETTINGS,
  },
];
