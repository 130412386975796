import type { ColumnsType } from "antd/es/table";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Empty, Input, Modal, Select, Table } from "antd";

import {
  useGetWarehouse,
  useGetWarehouseUsed,
} from "../../../../queries/queries";
import {
  useDownloadWarehouse,
  useDownloadWarehouseProducts,
} from "../../../../queries/mutations";
import styles from "../expense.module.scss";
// import PointIcon from "../../../../svg/PointIcon";
import CloseIcon from "../../../../svg/CloseIcon";
import TableIcon from "../../../../svg/TableIcon";
import SelectIcon from "../../../../svg/SelectIcon";
import FilterIcon from "../../../../svg/FilterIcon";
import FolderIcon from "../../../../svg/FolderIcon";
import { LoadingOutlined } from "@ant-design/icons";
import SearchIcon from "../../../../svg/SearchIcon";
import ArrowUpIcon from "../../../../svg/ArrayUpIcon";
import DownloadIcon from "../../../../svg/DownloadIcon";
import ExelFileIcon from "../../../../svg/ExelFileIcon";
import ArrowDownIcon from "../../../../svg/ArrowDownIcon";
import { RootState } from "../../../../redux/rootReducer";
import { tableNumber } from "../../../../helper/TableNumber";
import ArrowDownUpIcon from "../../../../svg/ArrayDownUpIcon";
import { WarehouseIdAction } from "../../../../redux/actions";
import RowVerticalIcon from "../../../../svg/RowVerticalIcon";
import ViewWarehouseCards from "./viewCards/ViewWarehouseCards";
import { viewTypes } from "../../../../helper/WarehouseVIewTypes";
import SliderVerticalIcon from "../../../../svg/SliderVerticalIcon";
import SmallPictogramIcon from "../../../../svg/SmallPictogramIcon";
import PaginationComp from "../../universialComponents/PaginationComp";
import { WarehouseDataModel } from "../../../../models/warehouse/WarehouseModel";
import { DatePicker } from "../../../../service/DatePicker";
import CalendarIcon from "../../../../svg/CalendarIcon";
import DatePickerExtraFooter from "../../common/DatePickerExtraFooter";
import { DATE_FORMAT } from "../../../../models/settings/dateFormat";
import dayjs from "dayjs";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setProductsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setWarehouseData: React.Dispatch<React.SetStateAction<WarehouseDataModel>>;
  type?: number;
  setType: React.Dispatch<React.SetStateAction<number>>;
};

const { Option } = Select;
const { RangePicker } = DatePicker;

const WarehouseModal: React.FC<Props> = ({
  setVisible,
  visible,
  setProductsVisible,
  setWarehouseData,
  type,
  setType,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [view, setView] = useState<string>(viewTypes.SMALL_ICON);
  const [sortKey, setSortKey] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [dateValue, setDateValue] = useState<any[]>();
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);

  const [selectAll, setSelectAll] = useState<boolean>(false);

  const excelWarehouse = useDownloadWarehouse();

  const warehouseIds = useSelector((state: RootState) => state.warehouseId.id);

  const downloadWarehouseProducts = useDownloadWarehouseProducts();

  const { data, isLoading } = useGetWarehouse(
    pageNumber,
    searchValue,
    sortKey,
    location.search.split("=")[1],
    dateValue && dateValue?.length > 0
      ? dayjs(dateValue![0]).format("YYYY-MM-DD")
      : undefined,
    dateValue && dateValue?.length > 0
      ? dayjs(dateValue![1]).format("YYYY-MM-DD")
      : undefined
  );

  const { data: dataUsed, isLoading: isLoadingUsed } = useGetWarehouseUsed(
    pageNumber,
    searchValue,
    sortKey,
    location.search.split("=")[1]
  );

  useEffect(() => {
    setSortKey("");
    history.push(location.pathname);
  }, [visible]);

  useEffect(() => {
    if (selectAll) {
      let allWarehouseIds: number[] = [...(warehouseIds ?? [])];
      data?.data.forEach((item) => {
        if (!warehouseIds?.some((value) => value === item.id))
          allWarehouseIds = [...allWarehouseIds, ...[item.id]];
      });
      dispatch(WarehouseIdAction([...allWarehouseIds]));
    }
  }, [data?.data, pageNumber, selectAll]);

  const handleCancel = () => {
    setVisible(!visible);
  };

  const changeView = (e: string) => {
    setView(e);
    setSortKey("");
    history.push(`${location.pathname}`);
  };

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const tableHeaderSortIcon = (sortName: string) => {
    const sortType = location.search.split("=")[1];
    if (sortName === sortKey) {
      if (sortType === "desc") return <ArrowUpIcon />;
      else return <ArrowDownIcon />;
    } else return <ArrowDownUpIcon />;
  };

  const columns: ColumnsType<WarehouseDataModel> = [
    {
      title: "№",
      render: (_, __, index) => <p>{tableNumber(index, `${pageNumber}`)}</p>,
      width: 60,
      // onHeaderCell: () => {
      //   return {
      //     onClick: () => {
      //       setSortKey("id");
      //       history.push(
      //         location.search
      //           ? `${location.pathname}?sort=${
      //               location.search.split("=")[1] === "asc" ? "desc" : "asc"
      //             }`
      //           : `${location.pathname}?sort=asc`
      //       );
      //     }
      //   };
      // }
    },
    {
      title: (
        <div className="table_header">
          NOMI {tableHeaderSortIcon("name")}
          {/* <TableDownIcon style={tableHeaderIconStyle("name")} /> */}
        </div>
      ),
      render: (e: WarehouseDataModel) => (
        <p>
          <FolderIcon /> {e.name}
        </p>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => {
            setSortKey("name");
            history.push(
              location.search
                ? `${location.pathname}?sort=${
                    location.search.split("=")[1] === "asc" ? "desc" : "asc"
                  }`
                : `${location.pathname}?sort=asc`
            );
          },
        };
      },
      width: 300,
    },
    {
      title: (
        <div className="table_header">
          MANZILI {tableHeaderSortIcon("address")}
        </div>
      ),
      dataIndex: "address",
      onHeaderCell: () => {
        return {
          onClick: () => {
            setSortKey("address");
            history.push(
              location.search
                ? `${location.pathname}?sort=${
                    location.search.split("=")[1] === "asc" ? "desc" : "asc"
                  }`
                : `${location.pathname}?sort=asc`
            );
          },
        };
      },
      width: 220,
    },
    {
      title: (
        <div className="table_header">
          UMUMIY QIYMATI{" "}
          {tableHeaderSortIcon(type === 3 ? "summa_mega" : "summa")}
        </div>
      ),
      render: (e: WarehouseDataModel) => (
        <p>
          {Number(type === 3 ? e.summa_mega : e.summa).toLocaleString()} so'm
        </p>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => {
            setSortKey("summa");
            history.push(
              location.search
                ? `${location.pathname}?sort=${
                    location.search.split("=")[1] === "asc" ? "desc" : "asc"
                  }`
                : `${location.pathname}?sort=asc`
            );
          },
        };
      },
      width: 300,
    },
  ];

  const screenWidth = window.innerWidth;

  const changeSort = (e: number) => {
    setType(e);
  };

  const disabledDownload = () => {
    if (selectAll) return false;
    return warehouseIds?.length > 0 ? false : true;
  };

  const downloadExcelFile = () => {
    excelWarehouse.mutate({
      id: warehouseIds,
      all: selectAll,
      query: {
        begin: dateValue && dayjs(dateValue[0]).format("YYYY-MM-DD"),
        end: dateValue && dayjs(dateValue[1]).format("YYYY-MM-DD"),
        search: searchValue,
      },
    });
  };

  const avtiveClassNameDownloadBtn = () => {
    if (selectAll) return styles.active_btn;
    return warehouseIds?.length > 0 ? styles.active_btn : styles.active_not_btn;
  };

  const rowSelectAll = (selected: boolean, rowData: WarehouseDataModel[]) => {
    // setSelectAll(selected);
  };

  const tableLocale = () => {
    return {
      emptyText: (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Mahsulotlar mavjud emas!"
        />
      ),
    };
  };

  const rowOnChange = (e: React.Key[]) => {
    // if (selectAll) {
    // setSelectAll(false);
    // const sliceWarehouseIds = warehouseIds?.slice(
    //   (pageNumber - 1) * 10,
    //   pageNumber * 10
    // );
    // const filterWarehouseIds = warehouseIds?.filter(
    //   (item) => !sliceWarehouseIds?.includes(item)
    // );
    // const concatIndex = (pageNumber - 1) * 10;
    // const filterWarehouseIds = warehouseIds?.splice(
    //   concatIndex,
    //   0,
    //   ...(e as number[])
    // );
    // dispatch(WarehouseIdAction([...(filterWarehouseIds ?? [])]));
    // } else {
    // }
    // }
    dispatch(WarehouseIdAction([...(e as number[])]));
  };

  const tableLoading = () => {
    return {
      spinning: type === 1 || type === 3 ? isLoading : isLoadingUsed,
      indicator: <LoadingOutlined />,
    };
  };

  const tableOnRow = (record: WarehouseDataModel) => {
    return {
      onClick: () => {
        setProductsVisible(true);
        setWarehouseData(record);
      },
    };
  };

  const downloadProductsStyle = () => {
    if (view === viewTypes.TABLE)
      return {
        display: "flex",
        marginLeft: "10px",
      };
    else
      return {
        display: "none",
        marginLeft: "10px",
      };
  };

  const downloadProducts = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    downloadWarehouseProducts.mutate({
      name: "Mahsulotlar",
      products: warehouseIds,
    });
  };

  const disabledProductsDownload = () => {
    if (view === viewTypes.TABLE && warehouseIds?.length > 0) return false;
    else return true;
  };

  const activeClassNameProductsDownload = () => {
    return warehouseIds?.length > 0 ? styles.active_btn : styles.active_not_btn;
  };

  const dateChange = (e: any) => {
    setDateValue(e);
  };

  return (
    <>
      <Modal
        className={styles.warehouse}
        visible={visible}
        title={type === 1 || type === 3 ? "Skladlar" : "Tarixlar"}
        onCancel={handleCancel}
        cancelText="Yopish"
        centered
        closeIcon={<CloseIcon />}
      >
        <div className={styles.top}>
          <div className={styles.left}>
            <Select
              placeholder={
                <>
                  <RowVerticalIcon /> Ko'rinishi
                </>
              }
              className={styles.view}
              suffixIcon={<SelectIcon />}
              onChange={changeView}
              value={view}
            >
              <Option value={viewTypes.BIG_ICON}>
                <SliderVerticalIcon /> Katta piktogramma
              </Option>
              <Option value={viewTypes.SMALL_ICON}>
                <SmallPictogramIcon /> Kichik piktogramma
              </Option>
              <Option value={viewTypes.TABLE}>
                <TableIcon /> Jadval
              </Option>
            </Select>
            {/* <div className="d_f ai_c sort_select">
              <label htmlFor="sort">
                <FilterIcon />
              </label> */}
            <Select
              placeholder="Saralash"
              className={`${styles.view} ${styles.sort}`}
              suffixIcon={<SelectIcon />}
              id="sort"
              onChange={changeSort}
              value={type}
            >
              <Option value={1} className="sort_option">
                <FilterIcon />
                Qoldiq
              </Option>
              <Option value={2} className="sort_option">
                <FilterIcon />
                Chiqim
              </Option>
              <Option value={3} className="sort_option">
                <FilterIcon />
                Kirim
              </Option>
            </Select>
            {/* </div> */}
            <Button
              disabled={disabledDownload()}
              className={avtiveClassNameDownloadBtn()}
              onClick={downloadExcelFile}
              loading={excelWarehouse.isLoading}
              style={{
                opacity: disabledDownload() ? 0 : 1,
                cursor: disabledDownload() ? "default" : "pointer",
              }}
            >
              <ExelFileIcon /> Excel yuklab olish
            </Button>
            <Button
              disabled={disabledProductsDownload()}
              style={downloadProductsStyle()}
              className={activeClassNameProductsDownload()}
              onClick={downloadProducts}
              loading={downloadWarehouseProducts.isLoading}
            >
              <DownloadIcon /> Mahsulotlarni yuklash
            </Button>
          </div>
          <div className={styles.right}>
            <RangePicker
              className={styles.date}
              suffixIcon={<CalendarIcon />}
              placeholder={["02.02.2002", "12.12.2012"]}
              separator="-"
              open={datePickerVisible}
              value={
                dateValue && dateValue?.length > 0
                  ? [dateValue[0], dateValue[1]]
                  : undefined
              }
              renderExtraFooter={() => {
                return (
                  <DatePickerExtraFooter
                    // form={form}
                    setState={setDateValue as any}
                    visible={setDatePickerVisible}
                  />
                );
              }}
              format={DATE_FORMAT}
              onChange={dateChange}
              onOpenChange={(e) => setDatePickerVisible(e)}
            />
            <Input
              placeholder="Nomi bo'yicha qidirish..."
              className={styles.search}
              onChange={changeSearch}
              value={searchValue}
              suffix={<SearchIcon />}
            />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.warehouses}>
            {view === viewTypes.TABLE ? (
              <Table
                columns={columns}
                scroll={{
                  y: screenWidth > 1900 ? 410 : 284,
                }}
                locale={tableLocale()}
                dataSource={
                  type === 1 || type === 3 ? data?.data : dataUsed?.data
                }
                pagination={false}
                rowSelection={{
                  type: "checkbox",
                  onSelectAll: rowSelectAll,
                  onChange: rowOnChange,
                  selectedRowKeys: warehouseIds,
                }}
                loading={tableLoading()}
                rowKey={(record) => record.id!}
                onRow={tableOnRow}
              />
            ) : (
              <ViewWarehouseCards
                data={type === 1 || type === 3 ? data?.data : dataUsed?.data}
                isLoading={type === 1 || type === 3 ? isLoading : isLoadingUsed}
                setProductsVisible={setProductsVisible}
                setWarehouseData={setWarehouseData}
                view={view}
                type={type!}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                search={searchValue}
                dateValue={dateValue}
              />
            )}
            <PaginationComp
              paginationProps={{
                onChange: (page) => setPageNumber(page),
                total: type === 1 || type === 3 ? data?.total : dataUsed?.total,
                current:
                  type === 1 || type === 3
                    ? data?.current_page
                    : dataUsed?.current_page,
                pageSize: 10,
              }}
            />
          </div>
          <div className={styles.total}>
            <p>
              Skladlar:{" "}
              {type === 1 || type === 3 ? data?.total : dataUsed?.total}{" "}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WarehouseModal;
