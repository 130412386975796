import React from "react";

const GrowthIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: "rotate(180deg)"
      }}
    >
      <path
        d="M6.50898 18.3333L13.4923 18.3333C16.5257 18.3333 18.334 16.5249 18.334 13.4916L18.334 6.51659C18.334 3.47492 16.5257 1.66659 13.4923 1.66659L6.51732 1.66658C3.48398 1.66658 1.67565 3.47492 1.67565 6.50825L1.67565 13.4916C1.66731 16.5249 3.47565 18.3333 6.50898 18.3333ZM5.39232 13.7249L11.8257 7.29159L9.30898 7.29159C8.96732 7.29159 8.68398 7.00825 8.68398 6.66659C8.68398 6.32492 8.96732 6.04159 9.30898 6.04159L13.334 6.04159C13.6757 6.04159 13.959 6.32492 13.959 6.66659L13.959 10.6916C13.959 11.0333 13.6757 11.3166 13.334 11.3166C12.9923 11.3166 12.709 11.0333 12.709 10.6916L12.709 8.17492L6.27565 14.6083C6.15065 14.7333 5.99232 14.7916 5.83398 14.7916C5.67565 14.7916 5.51732 14.7333 5.39232 14.6083C5.15065 14.3666 5.15065 13.9666 5.39232 13.7249Z"
        fill="#33D79F"
      />
    </svg>
  );
};

export default GrowthIcon;
