import { ReactNode } from 'react'
import { HistoryProductType } from '../components/main/history/historyTypes'
import { Stack } from '../svg/Stack'

export const getTag = (type: HistoryProductType): ReactNode => {
  const greenText = '#4BB152'
  const greenBg = '#EFFDEA'
  const blueText = '#2291CC'
  const blueBg = '#E9F4FA'
  const violetText = '#8345D0'
  const violetBg = '#F3EDFB'
  switch (type) {
    case 'PRODUCT':
      return (
        <span
          style={{ color: greenText, background: greenBg }}
          className="historyPaper__tag"
        >
          <Stack color={greenText} />
          Mahsulot
        </span>
      )
    case 'ORDER':
      return (
        <span
          style={{ color: greenText, background: greenBg }}
          className="historyPaper__tag"
        >
          <Stack color={greenText} />
          Partiya
        </span>
      )
    case 'PAYMENT':
      return (
        <span
          style={{ color: blueText, background: blueBg }}
          className="historyPaper__tag"
        >
          <Stack color={blueText} />
          To'lov
        </span>
      )
    case 'SUPPLIER':
      return (
        <span
          style={{ color: greenText, background: blueBg }}
          className="historyPaper__tag"
        >
          <Stack color={greenText} />
          Ta'minotchi
        </span>
      )
    case 'TRANSFER':
      return (
        <span
          style={{ color: violetText, background: violetBg }}
          className="historyPaper__tag"
        >
          <Stack color={violetText} />
          Transfer
        </span>
      )

    default:
      return <></>
  }
}
