import React from "react";

const FolderIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00424 4.59054C0.950361 4.00492 1.417 3.5 2.01209 3.5H15.9879C16.583 3.5 17.0496 4.00492 16.9958 4.59054L16.1678 13.5905C16.1204 14.1056 15.6833 14.5 15.1599 14.5H2.8401C2.31669 14.5 1.87964 14.1056 1.83225 13.5905L1.00424 4.59054Z"
        fill="url(#paint0_linear_1921_2550)"
      />
      <path
        d="M1.91592 1.5V3.5H16.0841V3C16.0841 2.44772 15.631 2 15.0721 2H8.01764C7.68256 2 7.3692 1.83611 7.18078 1.56231L6.75094 0.93769C6.56252 0.663891 6.24916 0.5 5.91408 0.5H2.92793C2.36901 0.5 1.91592 0.947715 1.91592 1.5Z"
        fill="url(#paint1_linear_1921_2550)"
      />
      <path
        d="M1.00424 4.59054C0.950361 4.00492 1.417 3.5 2.01209 3.5H15.9879C16.583 3.5 17.0496 4.00492 16.9958 4.59054L16.1678 13.5905C16.1204 14.1056 15.6833 14.5 15.1599 14.5H2.8401C2.31669 14.5 1.87964 14.1056 1.83225 13.5905L1.00424 4.59054Z"
        stroke="#75A8D7"
      />
      <path
        d="M1.91592 1.5V3.5H16.0841V3C16.0841 2.44772 15.631 2 15.0721 2H8.01764C7.68256 2 7.3692 1.83611 7.18078 1.56231L6.75094 0.93769C6.56252 0.663891 6.24916 0.5 5.91408 0.5H2.92793C2.36901 0.5 1.91592 0.947715 1.91592 1.5Z"
        stroke="#75A8D7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1921_2550"
          x1="9"
          y1="0.5"
          x2="9"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6F0FF" />
          <stop offset="1" stopColor="#CADCF5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1921_2550"
          x1="9"
          y1="0.5"
          x2="9"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6F0FF" />
          <stop offset="1" stopColor="#CADCF5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FolderIcon;
