import { SettingsAction } from './actionTypes'
import { SETTINGS_OPEN_EDIT_MODAL } from './types'

const initialState = {
  openCreateModal: false,
}

const settingsReducer = (state = initialState, action: SettingsAction) => {
  switch (action.type) {
    case SETTINGS_OPEN_EDIT_MODAL:
      return { ...state, openCreateModal: action.payload }
    default:
      return state
  }
}

export default settingsReducer
