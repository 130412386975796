const mainPaths = {
  SETTINGS: "/settings",
  TRANSFER: "/transfer",
  SUPPLIER: "/supplier",
  ORDERS: "/orders",
  PRODUCT: "/product",
  HISTORY: "/history",
  STATISTICS: "/statistics",
  ONE_SUPPLIER: "/supplier/:id",
  EXPENSE: "/expense",
  EXPENSE_CREATE: "/expense/create",
  EPXENSE_EDIT: "expense/:id",
  CHECKOUT: "/checkout",
  CONVERSION: "/conversion"
};

export default mainPaths;
