import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Spin } from "antd";
import React from "react";
import { viewTypes } from "../../../../../helper/WarehouseVIewTypes";
import { WarehouseDataModel } from "../../../../../models/warehouse/WarehouseModel";

import styles from "../../expense.module.scss";
import ViewCard from "./ViewCard";

type Props = {
  data: WarehouseDataModel[] | undefined;
  isLoading: boolean;
  setProductsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setWarehouseData: React.Dispatch<React.SetStateAction<WarehouseDataModel>>;
  view: string;
  type: number;
  selectAll: boolean;
  setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
  search?: string;
  dateValue?: string[];
};
const ViewWarehouseCards: React.FC<Props> = ({
  data,
  isLoading,
  setProductsVisible,
  setWarehouseData,
  view,
  type,
  selectAll,
  setSelectAll,
  search,
  dateValue,
}) => {
  const viewClassName = () => {
    switch (view) {
      case viewTypes.BIG_ICON:
        return `${styles.warehouse_view} ${styles.big_icon}`;
      default:
        return styles.warehouse_view;
    }
  };
  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
      <div className={viewClassName()}>
        {data &&
          data.map((item, index) => (
            <ViewCard
              key={index}
              type={type}
              {...item}
              summa_mega={item.summa_mega ?? 1}
              setProductsVisible={setProductsVisible}
              setWarehouseData={setWarehouseData}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              search={search}
              dateValue={dateValue}
            />
          ))}
        {data?.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Mahsulotlar mavjud emas!"
          />
        )}
      </div>
    </Spin>
  );
};

export default ViewWarehouseCards;
