import { Button, Form, Modal, Select } from "antd";
import dayjs from "rc-picker/node_modules/dayjs";
import React, { useEffect, useState } from "react";

import { useGetBuildings } from "../../../../features/expense/services/queries";
import { arrayToString } from "../../../../helper/arrayToString";
import { DATE_FORMAT } from "../../../../models/settings/dateFormat";
import {
  useExpenseAll,
  usePaymentAll,
  useValutaAll,
} from "../../../../queries/queries";
import { DatePicker } from "../../../../service/DatePicker";
import SelectIcon from "../../../../svg/SelectIcon";
import styles from "../checkout.module.scss";
import DateFilterFooter from "./DateFilterFooter";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setParams: React.Dispatch<React.SetStateAction<string | undefined>>;
  params: string | undefined;
};

const { Item } = Form;
const { Option } = Select;

const Filter: React.FC<Props> = ({
  visible,
  setVisible,
  setParams,
  params,
}) => {
  const [form] = Form.useForm();
  const { data: payments } = usePaymentAll();
  const { data: expences } = useExpenseAll();
  const { data: buildings } = useGetBuildings();
  const { data: valutas } = useValutaAll();
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const query = new URLSearchParams();

  useEffect(() => {
    if (!params) {
      form.resetFields();
    } else {
      const queryParse = new URLSearchParams(params);
      form.setFieldsValue({
        start: queryParse.get("start"),
        finish: queryParse.get("finish"),
        type: queryParse.get("type"),
        valuta_id: Number(queryParse.get("valuta_id")),
        payment_type_id: queryParse
          .get("payment_type_id")
          ?.split(",")
          ?.map((item) => Number(item)),
        building_id: queryParse
          .get("building_id")
          ?.split(",")
          ?.map((item) => Number(item)),
        expense_type_id: queryParse
          .get("expense_type_id")
          ?.split(",")
          ?.map((item) => Number(item)),
      });
    }
  }, [params, form]);

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = (fields: any) => {
    let data: any = {};

    data = {
      start:
        fields?.date && fields?.date[0]
          ? dayjs(fields?.date[0]).format("YYYY-MM-DD")
          : "",
      finish:
        fields?.date && fields?.date[1]
          ? dayjs(fields?.date[1]).format("YYYY-MM-DD")
          : "",
      type: fields?.type,
      payment_type_id: arrayToString(fields?.payment_type_id),
      building_id: arrayToString(fields?.building_id),
      expense_type_id: arrayToString(fields?.expense_type_id),
      valuta_id: fields?.valuta_id,
    };
    if (!fields?.date) {
      delete data?.start;
    }
    if (!fields?.date) {
      delete data?.finish;
    }
    if (!data?.type) {
      delete data?.type;
    }
    if (!data?.valuta_id) {
      delete data?.valuta_id;
    }
    if (!data?.payment_type_id) {
      delete data?.payment_type_id;
    }
    if (!data?.building_id) {
      delete data?.building_id;
    }
    if (!data?.expense_type_id) {
      delete data?.expense_type_id;
    }

    for (let key in data) {
      query.append(key, data[key]);
    }

    setParams(query.toString());
    setVisible(false);
  };

  const clearForm = () => {
    form.resetFields();
    setVisible(false);
    setParams(undefined);
  };

  return (
    <Modal
      title="Filter"
      className={styles.filtr}
      visible={visible}
      onCancel={onCancel}
      centered
    >
      <Form name="filter" layout="vertical" form={form} onFinish={onFinish}>
        <Item name="date" label="Oraliq muddat">
          <DatePicker.RangePicker
            format={DATE_FORMAT}
            open={openDatePicker}
            onOpenChange={(e) => setOpenDatePicker(e)}
            placeholder={["Boshlang'ich vaqti", "Tugash vaqti"]}
            renderExtraFooter={() => {
              return (
                <DateFilterFooter form={form} setVisible={setOpenDatePicker} />
              );
            }}
            picker="date"
          />
        </Item>
        <Item label="Turi" name="type">
          <Select placeholder="Filtr turini tanlang">
            <Option value="-1,1">Barchasi</Option>
            <Option value="1">Kirimlar</Option>
            <Option value="-1">Chiqimlar</Option>
          </Select>
        </Item>
        <Item label="Kassa" name="payment_type_id">
          <Select placeholder="Kassani tanlang" mode="multiple">
            {payments?.map((item, index) => (
              <Option value={item?.id!} key={index}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Obyekt" name="building_id">
          <Select placeholder="Obyektni tanlang" mode="multiple">
            {buildings?.map((item, index) => (
              <Option value={item?.id} key={index}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Manbaa" name="expense_type_id">
          <Select
            mode="multiple"
            placeholder="Manbaa turini tanlang"
            allowClear
            // suffixIcon={<SelectIcon />}
            // removeIcon={<SelectIcon />}
          >
            <Option value={0} key={0}>
              Ta'minotchiga berilgan pullar
            </Option>
            {expences?.map((item, index) => (
              <Option value={item?.id!} key={index + 1}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Valyuta" name="valuta_id">
          <Select placeholder="Valyuta turini tanlang">
            {valutas?.map((item, index) => (
              <Option value={item.id!} key={index}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item>
          <div className={styles.footer}>
            <Button onClick={clearForm}>Tozalash</Button>
            <Button htmlType="submit">Filtrni saqlash</Button>
          </div>
        </Item>
      </Form>
    </Modal>
  );
};

export default Filter;
