import { Button, Modal } from "antd";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { RootState } from "../../../redux/rootReducer";
import SearchHeader from "../universialComponents/SearchHeader";
import "./history.scss";
import HistoryPaper from "./HistoryPaper";
interface HistoryPageProps {}

const HistoryPage: React.FC<HistoryPageProps> = () => {
  const modalref = useRef<HTMLDivElement>(null);
  const historyArr = useSelector(
    (state: RootState) => state.productGraph.historyArr
  );
  useDocumentTitle("Amallar tarixi");
  return (
    <>
      <SearchHeader title="Amallar tarixi" buttons="" />
      {/* {isLoading ? 'loading...' : <pre>{JSON.stringify(data, null, 2)}</pre>} */}
      <Modal width="50rem" visible={false}>
        <div ref={modalref}>
          <Button
            onClick={() => {
              // printElement(modalref.current)
            }}
          >
            print
          </Button>
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </div>
      </Modal>
      <div className="history">
        {historyArr.map((item, index) => {
          return <HistoryPaper key={index} date={item} index={index} />;
        })}
      </div>
    </>
  );
};

export default HistoryPage;
