import React from "react";

const WalletIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.4957 1.8886L9.4732 1.9411L7.2982 6.9886H5.1607C4.6507 6.9886 4.1632 7.0936 3.7207 7.2811L5.0332 4.1461L5.0632 4.0711L5.1157 3.9511C5.1307 3.9061 5.1457 3.8611 5.1682 3.8236C6.1507 1.5511 7.2607 1.0336 9.4957 1.8886Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5369 7.13867C13.1994 7.03367 12.8394 6.98867 12.4794 6.98867H7.29688L9.47187 1.94117L9.49437 1.88867C9.60687 1.92617 9.71187 1.97867 9.82437 2.02367L11.4819 2.72117C12.4044 3.10367 13.0494 3.50117 13.4394 3.98117C13.5144 4.07117 13.5744 4.15367 13.6269 4.25117C13.6944 4.35617 13.7469 4.46117 13.7769 4.57367C13.8069 4.64117 13.8294 4.70867 13.8444 4.76867C14.0469 5.39867 13.9269 6.17117 13.5369 7.13867Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1403 10.6488V12.1113C16.1403 12.2613 16.1328 12.4113 16.1253 12.5613C15.9828 15.1788 14.5203 16.4988 11.7453 16.4988H5.89531C5.71531 16.4988 5.53531 16.4838 5.36281 16.4613C2.97781 16.3038 1.70281 15.0288 1.54531 12.6438C1.52281 12.4713 1.50781 12.2913 1.50781 12.1113V10.6488C1.50781 9.14127 2.42281 7.84377 3.72781 7.28127C4.17781 7.09377 4.65781 6.98877 5.16781 6.98877H12.4878C12.8553 6.98877 13.2153 7.04127 13.5453 7.13877C15.0378 7.59627 16.1403 8.99127 16.1403 10.6488Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.0325 4.146L3.72 7.281C2.415 7.8435 1.5 9.141 1.5 10.6485V8.451C1.5 6.321 3.015 4.5435 5.0325 4.146Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.138 8.45074V10.6482C16.138 8.99824 15.043 7.59574 13.543 7.14574C13.933 6.17074 14.0455 5.40574 13.858 4.76824C13.843 4.70074 13.8205 4.63324 13.7905 4.57324C15.1855 5.29324 16.138 6.77074 16.138 8.45074Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WalletIcon;
