import React from "react";

type Props = {
  transform?: number;
};

const ArrowSelectIcon: React.FC<Props> = ({ transform }) => {
  return (
    <svg
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transition: "0.3s ease-in-out",
        transform: transform ? `rotate(${transform}deg)` : `rotate(0deg)`,
      }}
    >
      <path
        d="M12 5.75L7.20711 0.957107C6.81658 0.566583 6.18342 0.566582 5.79289 0.957106L1 5.75"
        stroke="#2291CC"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowSelectIcon;
