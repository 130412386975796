import React from "react";

interface HeaderInterface {
  dateArr: string[]
}

const Header:React.FC<HeaderInterface> = ({dateArr}) => {
  return (
    <div className={'productHistoryHeader'}>
      <div className={'title'}>
        Export Pdf
      </div>
      {dateArr.length > 0 &&
      <div className={'date'}>
        {dateArr[0] + ' : ' + dateArr[1]}
      </div>
      }
    </div>
  )
}

export default Header