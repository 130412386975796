import { Reducer } from "redux";
import { EXPENCE_COPY } from "./types";

const initialValue: { copy?: boolean } = {
  copy: false,
};

const expenceCopyReducer: Reducer<typeof initialValue> = (
  state = initialValue,
  action
) => {
  switch (action.type) {
    case EXPENCE_COPY:
      return {
        copy: state.copy,
        ...action.payload,
      };
    default:
      return {
        state,
      };
  }
};

export default expenceCopyReducer;
