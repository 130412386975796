import React from "react";
import { Button, Modal, Spin, Typography } from "antd";
import "./productGraph.scss";
import ProductDistribution from "./ProductDistribution";
import Graph from "./Graph";
import { useProductDetails } from "../../../queries/queries";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { isOpenProductGraph } from "../../../redux/actions";
import OpenButtonComp from "../productHistory/OpenButtonComp";
import { ResponsiveLine } from "@nivo/line";

const ProductGraphCont = () => {
  const dispatch = useDispatch();

  const open = useSelector((state: RootState) => state.productGraph.isOpen);
  const id = useSelector((state: RootState) => state.productGraph.id);

  const { data, isLoading, isFetching } = useProductDetails(id);

  const closeFunc = () => {
    dispatch(isOpenProductGraph(false, 0));
  };

  const ModalTitle = (
    <>
      <span style={{ display: "inline-block", marginRight: "1rem" }}>
        Mahsulot haqida ma’lumot
      </span>
      {isFetching && <LoadingOutlined />}
    </>
  );

  const defaultData = [
    {
      id: "japan",
      color: "hsl(331, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 47,
        },
        {
          x: "helicopter",
          y: 187,
        },
        {
          x: "boat",
          y: 216,
        },
        {
          x: "train",
          y: 150,
        },
        {
          x: "subway",
          y: 285,
        },
        {
          x: "bus",
          y: 232,
        },
        {
          x: "car",
          y: 133,
        },
        {
          x: "moto",
          y: 152,
        },
        {
          x: "bicycle",
          y: 156,
        },
        {
          x: "horse",
          y: 39,
        },
        {
          x: "skateboard",
          y: 68,
        },
        {
          x: "others",
          y: 145,
        },
      ],
    },
    {
      id: "france",
      color: "hsl(58, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 216,
        },
        {
          x: "helicopter",
          y: 220,
        },
        {
          x: "boat",
          y: 290,
        },
        {
          x: "train",
          y: 185,
        },
        {
          x: "subway",
          y: 3,
        },
        {
          x: "bus",
          y: 50,
        },
        {
          x: "car",
          y: 221,
        },
        {
          x: "moto",
          y: 19,
        },
        {
          x: "bicycle",
          y: 262,
        },
        {
          x: "horse",
          y: 270,
        },
        {
          x: "skateboard",
          y: 52,
        },
        {
          x: "others",
          y: 151,
        },
      ],
    },
    {
      id: "us",
      color: "hsl(231, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 113,
        },
        {
          x: "helicopter",
          y: 59,
        },
        {
          x: "boat",
          y: 35,
        },
        {
          x: "train",
          y: 201,
        },
        {
          x: "subway",
          y: 139,
        },
        {
          x: "bus",
          y: 173,
        },
        {
          x: "car",
          y: 252,
        },
        {
          x: "moto",
          y: 35,
        },
        {
          x: "bicycle",
          y: 21,
        },
        {
          x: "horse",
          y: 56,
        },
        {
          x: "skateboard",
          y: 4,
        },
        {
          x: "others",
          y: 170,
        },
      ],
    },
    {
      id: "germany",
      color: "hsl(86, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 300,
        },
        {
          x: "helicopter",
          y: 168,
        },
        {
          x: "boat",
          y: 237,
        },
        {
          x: "train",
          y: 75,
        },
        {
          x: "subway",
          y: 220,
        },
        {
          x: "bus",
          y: 201,
        },
        {
          x: "car",
          y: 295,
        },
        {
          x: "moto",
          y: 203,
        },
        {
          x: "bicycle",
          y: 191,
        },
        {
          x: "horse",
          y: 293,
        },
        {
          x: "skateboard",
          y: 269,
        },
        {
          x: "others",
          y: 287,
        },
      ],
    },
    {
      id: "norway",
      color: "hsl(22, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 109,
        },
        {
          x: "helicopter",
          y: 28,
        },
        {
          x: "boat",
          y: 209,
        },
        {
          x: "train",
          y: 297,
        },
        {
          x: "subway",
          y: 18,
        },
        {
          x: "bus",
          y: 235,
        },
        {
          x: "car",
          y: 213,
        },
        {
          x: "moto",
          y: 169,
        },
        {
          x: "bicycle",
          y: 216,
        },
        {
          x: "horse",
          y: 89,
        },
        {
          x: "skateboard",
          y: 5,
        },
        {
          x: "others",
          y: 265,
        },
      ],
    },
  ];

  return (
    <Modal
      centered
      visible={open}
      width={800}
      title={ModalTitle}
      onCancel={closeFunc}
      footer={false}
    >
      {isLoading ? (
        <Spin size="large" className="productGraphContSpin" />
      ) : (
        <div className="productGraphCont">
          <div className="body">
            <ProductDistribution data={data} />
            <div className="border" />
            <div className="graph">
              <Typography className="title">Mahsulot kirim grafigi</Typography>
              <Graph
                height={400}
                width={530}
                beginData={data?.diagramm || []}
              />
            </div>
          </div>
          <div className="footer">
            <Button onClick={closeFunc} type="link" className="button">
              Bekor qilish
            </Button>
            {/* <Select>
            <Select.Option value={"chiqim"}>Chiqim</Select.Option>
              <Select.Option value={"chiqim"}>Chiqim</Select.Option>
            </Select> */}
            <OpenButtonComp id={id} type="oneProduct" />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ProductGraphCont;
