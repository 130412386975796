import { Button, Popconfirm, Spin, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ASC, DESC, KEY, SORT } from "../../../helper/paramNames";
import { useDeleteOnePart } from "../../../queries/mutations";
import { useParts } from "../../../queries/queries";
import { orderIdAction, pictureVisibleAction } from "../../../redux/actions";
import { RootState } from "../../../redux/rootReducer";
import mainPaths from "../../../routes/main/mainPaths";
import { rootPaths } from "../../../routes/rootRoutes";
import ArrowDownUpIcon from "../../../svg/ArrayDownUpIcon";
import ArrowUpIcon from "../../../svg/ArrayUpIcon";
import ArrowDownIcon from "../../../svg/ArrowDownIcon";
import CopyIcon from "../../../svg/CopyIcon";
import dayjs from "rc-picker/node_modules/dayjs";
import { Garbage } from "../../../svg/Garbage";
import ImageIcon from "../../../svg/ImageIcon";
import { NotePencil } from "../../../svg/NotePencil";
// import TableDownIcon from "../../../svg/TableDownIcon";
import PaginationComp from "../universialComponents/PaginationComp";
import Pictures from "../universialComponents/pictures/Pictures";
import OrderDetailedTable from "./OrderDetailedTable";

const { Column } = Table;

const OrdersTable = () => {
  const [deleteId, setDeleteId] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { push, location } = useHistory();
  const params = new URLSearchParams("key=&sort=");
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState<number>(1);

  const searchString = useSelector(
    (state: RootState) => state.searchHeader.searchString
  );

  const { data, isLoading } = useParts(page, searchString, location, perPage);
  const deletePart = useDeleteOnePart(setDeleteId, page);

  useEffect(() => {
    setPage(1);
  }, [searchString]);

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id);
      await deletePart.mutateAsync(id);
      if (data?.data.length === 1 && data.current_page !== 1) setPage(page - 1);
    } catch (e) {
      console.log(e);
    }
  };

  const changeSortData = (key: string) => {
    params.set(KEY, key);
    params.set(SORT, location.search.split("=")[2] === ASC ? DESC : ASC);
    push(`${location.pathname}?${params.toString()}`);
  };

  const tableHeaderSortIcon = (sortName: string) => {
    const sortType = location.search && location.search.split("=")[2];
    const sortKey =
      location.search && location.search.split("=")[1].split("&")[0];
    if (sortName === sortKey) {
      if (sortType === DESC) return <ArrowUpIcon />;
      else return <ArrowDownIcon />;
    } else return <ArrowDownUpIcon />;
  };

  const visiblePictureModal = (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    setOrderId(id);
    dispatch(pictureVisibleAction(true));
  };

  return (
    <>
      <Table
        className={"table partsTable"}
        pagination={false}
        dataSource={data?.data || []}
        loading={isLoading}
        expandable={{
          expandIconColumnIndex: 10,
          expandIcon: () => <></>,
          expandedRowRender: (record, index, indent, expanded) => {
            if (expanded) return <OrderDetailedTable dataOne={record} />;
            else return null;
          },
          expandRowByClick: true,
        }}
        rowKey={(record) => record.id!}
      >
        <Column
          key={"key"}
          width={"5%"}
          title={<div># {tableHeaderSortIcon("id")}</div>}
          render={(record) => <span>#{record.id}</span>}
          align={"center"}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("id");
              },
            };
          }}
        />
        <Column
          key={"date"}
          width={"10%"}
          title={
            <div>
              {/* <span
                style={{
                  display: "inline-block",
                  marginRight: "1rem"
                }}
              > */}
              SANA {tableHeaderSortIcon("date")}
              {/* </span> */}
              {/* {isFetching && <LoadingOutlined />} */}
            </div>
          }
          dataIndex={"date"}
          render={(record) => <>{dayjs(record)?.format("DD.MM.YYYY")}</>}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("date");
              },
            };
          }}
        />
        <Column
          key={"supplier_name"}
          title={<div>TA'MINOTCHI {tableHeaderSortIcon("supplier_name")}</div>}
          dataIndex={"supplier_name"}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("supplier_name");
              },
            };
          }}
        />
        <Column
          key={"vositachi"}
          title={<div>VOSITACHI {tableHeaderSortIcon("vositachi")}</div>}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("vositachi");
              },
            };
          }}
          dataIndex={"vositachi"}
        />
        {/* <Column
          key={"rating"}
          width={"5%"}
          title={<div>REYTING {tableHeaderSortIcon("rating")}</div>}
          dataIndex={"rating"}
          align="center"
          render={(rating) => (
            <>
              <span
                style={{
                  display: "inline-block",
                  marginRight: ".25rem",
                }}
              >
                {rating || 0}
              </span>
              <Star />
            </>
          )}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("rating");
              },
            };
          }}
        /> */}
        <Column
          width={"13%"}
          key={"price"}
          title={<div>NARXI {tableHeaderSortIcon("price")}</div>}
          render={(record) => (
            <span>
              {parseFloat(record.price).toLocaleString("ru")} {record.valuta}
            </span>
          )}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("price");
              },
            };
          }}
        />
        {/* <Column
          key={"payment_type_name"}
          width={"120px"}
          title={
            <div>TO'LOV TURI {tableHeaderSortIcon("payment_type_name")}</div>
          }
          render={(record) => (
            <div
              style={{
                color: record.color,
                backgroundColor: `${record.color}15`,
              }}
              className={"partsTableTag"}
            >
              {record.payment_type_name}
            </div>
          )}
          align="center"
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("payment_type_name");
              },
            };
          }}
        /> */}
        <Column
          key={"building"}
          title={<div>OBYEKT {tableHeaderSortIcon("building")}</div>}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("building");
              },
            };
          }}
          dataIndex={"building"}
        />
        <Column
          key={"status"}
          width={"5%"}
          title={<div>STATUS {tableHeaderSortIcon("status")}</div>}
          render={(record) => (
            <div
              style={{
                color: record.status === "1" ? "#099E74" : "#2291CC",
              }}
            >
              {record.status === "1" ? "Tasdiqlangan" : "Saqlangan"}
            </div>
          )}
          align="center"
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("status");
              },
            };
          }}
        />
        <Column
          key={"action"}
          width={"10%"}
          title={"ACTION"}
          align={"center"}
          render={(record) => (
            <>
              <div className="table_action">
                <Tooltip title="Nusxa olish" placement="left">
                  <Button
                    className="picture copy"
                    onClick={() => {
                      dispatch(orderIdAction(true));
                      push(
                        `${rootPaths.MAIN}${mainPaths.ORDERS}/${record.id}-copy`
                      );
                    }}
                  >
                    <CopyIcon />
                  </Button>
                </Tooltip>
                <Button
                  className="picture"
                  onClick={(e) => visiblePictureModal(e, record.id)}
                >
                  <ImageIcon />
                </Button>
                <span
                  onClick={() => {
                    dispatch(orderIdAction(false));
                    push(`${rootPaths.MAIN}${mainPaths.ORDERS}/${record.id}`);
                  }}
                  className="table-edit"
                  title="Tahrirlash"
                >
                  <NotePencil className="tableIcon" />
                </span>
                {deletePart.isLoading && deleteId === record.id ? (
                  <Spin />
                ) : (
                  <Popconfirm
                    placement="left"
                    title="O'chirshni xohlaysizmi?"
                    onConfirm={() => {
                      deleteFunc(record.id);
                    }}
                    okButtonProps={{
                      danger: true,
                      loading: deletePart.isLoading,
                    }}
                    okText="Xa"
                    cancelText="Yoq"
                  >
                    <span
                      title={"O'chirish"}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="table-edit"
                    >
                      <Garbage className={"tableIcon"} />
                    </span>
                  </Popconfirm>
                )}
              </div>
            </>
          )}
        />
      </Table>
      <PaginationComp
        paginationProps={{
          onChange: (page, per_page) => {
            setPage(page);
            setPerPage(per_page ? per_page : perPage);
          },
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
        }}
        per_page={perPage}
        showSizeChanger={true}
        setPerPage={setPerPage}
      />
      <Pictures type="order" id={orderId} />
    </>
  );
};

export default OrdersTable;
