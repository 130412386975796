import React, {useEffect, useState} from 'react'
import { Popconfirm, Spin, Table } from 'antd'
import { NotePencil } from '../../../../svg/NotePencil'
import { Garbage } from '../../../../svg/Garbage'
import CreateAndEditModal from './CreateAndEditModal'
import { isOpenSettingEditModal } from '../../../../redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import { useCategory } from '../../../../queries/queries'
import { LoadingOutlined } from '@ant-design/icons'
import { useDeleteCategory } from '../../../../queries/mutations'
import PaginationComp from '../../universialComponents/PaginationComp'
import {RootState} from "../../../../redux/rootReducer";

const { Column } = Table

const Category = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [deleteId, setDeleteId] = useState(0)
  const [editedCategoryId, setEditedCategoryId] = useState(0)

  const dispatch = useDispatch()

  const searchString = useSelector((state:RootState) => state.searchHeader.searchString)

  const { isLoading, data, isFetching } = useCategory(currentPage,searchString)
  const deleteCategory = useDeleteCategory(setDeleteId, currentPage)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchString]);

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id)
      await deleteCategory.mutateAsync(id)
      if (data?.data.length === 1 && data.current_page !== 1)
        setCurrentPage(currentPage - 1)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Table
        rowKey={(record) => record.id!}
        loading={isLoading}
        pagination={false}
        dataSource={data?.data || []}
      >
        <Column
          key={'key'}
          width={'10%'}
          title={'#'}
          align={'center'}
          render={(record, i, index) => (
            <span>{(currentPage - 1) * 10 + index + 1}</span>
          )}
        />
        <Column
          key={'name'}
          width={'80%'}
          title={
            <>
              <span style={{ marginRight: '1rem' }}>NOMI</span>
              {isFetching && <LoadingOutlined />}
            </>
          }
          dataIndex={'name'}
        />
        <Column
          key={'action'}
          width={'10%'}
          title={'ACTION'}
          align={'center'}
          render={(record) => (
            <div>
              <span title={"O'zgartirish"}>
                <NotePencil
                  onClick={() => {
                    setEditedCategoryId(record.id)
                    dispatch(isOpenSettingEditModal(true))
                  }}
                  className={'tableIcon'}
                />
              </span>
              {deleteCategory.isLoading && record.id === deleteId ? (
                <Spin />
              ) : (
                <Popconfirm
                  placement="left"
                  title="O'chirshni xohlaysizmi?"
                  onConfirm={() => deleteFunc(record.id)}
                  okText="Xa"
                  cancelText="Yoq"
                >
                  <span title={"O'chirish"}>
                    <Garbage className={'tableIcon'} />
                  </span>
                </Popconfirm>
              )}
            </div>
          )}
        />
      </Table>
      <CreateAndEditModal
        page={currentPage}
        currentCategoryId={editedCategoryId}
        setCurrentCategory={setEditedCategoryId}
      />
      <PaginationComp
        paginationProps={{
          current: data?.current_page,
          total: data?.total,
          pageSize: data?.per_page || 10,
          onChange: (page) => {
            setCurrentPage(page)
          },
        }}
      />
    </>
  )
}

export default Category
