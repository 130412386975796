import { Row } from "antd";
import React from "react";
import SearchHeader from "../universialComponents/SearchHeader";
import ObjectExpenseStats from "./objectExpense/ObjectExpenseStats";
import ExpenseStats from "./expenseStats/ExpenseStats";
import "./stats.scss";
import TopProducts from "./top10s/TopProducts";
import ProductStats from "./productStats/ProductStats";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

interface StatsPageProps {}

const StatsPage: React.FC<StatsPageProps> = () => {
  useDocumentTitle("Statistika");
  return (
    <>
      <SearchHeader buttons="" title="Statistika" />
      <div className="stats">
        <Row gutter={[16, 16]}>
          <ExpenseStats />
          <ObjectExpenseStats />
          <ProductStats />
          <TopProducts />
        </Row>
      </div>
    </>
  );
};

export default StatsPage;
