import {Tabs} from 'antd'

import ProductsTable from "./table/ProductsTable";
import ProductSI from "./productSI/ProductSI";

import styles from './product.module.scss'
import {useState} from "react";

const Product = () => {
  const [activeKey, setActiveKey] = useState(1)

  // pages
  const pages = {
    1: <ProductsTable/>,
    2: <ProductSI/>
  }

  // change active key
  const handleChangeActiveKey = (key: string) => {
    setActiveKey(+key)
  }

  return (
    <div className={styles.product}>
      <Tabs className={styles.tabs} onChange={handleChangeActiveKey}>
        <Tabs.TabPane tab="Ro'yxat" key={1}/>
        <Tabs.TabPane tab="Birlik" key={2}/>
      </Tabs>
      {pages[activeKey as keyof typeof pages]}
    </div>
  )
}

export default Product
