import { CloseOutlined } from '@ant-design/icons'
import { Button, Input, message, Table } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromList, setCount } from '../../../redux/actions'
import { RootState } from '../../../redux/rootReducer'
const { Column } = Table
interface CreateTransferTableProps {}

const CreateTransferTable: React.FC<CreateTransferTableProps> = () => {
  const dispatch = useDispatch()
  const products = useSelector((state: RootState) => state.productList.products)
  return (
    <Table
      pagination={false}
      dataSource={products}
      scroll={{ y: 'calc(100vh - 23.5rem)' }}
      rowKey={(record) => record.id}
    >
      <Column
        width="8%"
        title="#"
        dataIndex="name"
        key="name"
        render={(_, __, index) => {
          return index + 1
        }}
      />
      <Column title="NOMI" dataIndex="name" key="name" />
      <Column
        title="MIQDORI"
        dataIndex="count"
        key="id"
        render={(count, record: any, index) => {
          const intCount = parseInt(count)
          return (
            <Input
              value={
                record.type === 'BOLAK' || isNaN(intCount)
                  ? count
                  : intCount.toLocaleString('ru')
              }
              color="secondary"
              onChange={({ target }) => {
                const withoutSpace = target.value.replace(/\s/g, '')
                if (parseFloat(withoutSpace) > record.maxCount) {
                  return message.error(
                    `Maksimal ${record.maxCount} dan oshmasligi kerak`
                  )
                }
                dispatch(setCount(withoutSpace, index))
              }}
            />
          )
        }}
      />
      <Column title="MAKS. MIQDORI" dataIndex="maxCount" key="id" />
      <Column title="O'LCHAMI" dataIndex="product_type_name" key="name" />
      <Column
        dataIndex="id"
        key="id"
        width="5rem"
        align="center"
        render={(id) => {
          return (
            <Button type="text" danger onClick={() => dispatch(removeFromList(id))}>
              <CloseOutlined color="red" />
            </Button>
          )
        }}
      />
    </Table>
  )
}

export default CreateTransferTable
