import React, { useEffect, useState } from "react";
import { DonutChartInterface, ObjectExpenseTypes } from "./ObjectExpenseTypes";
import { Spin } from "antd";
import { ResponsivePie } from "@nivo/pie";
import { animated } from "@react-spring/web";
import colors from "../colors";
import { convertLongMoney, generateColor } from "../../../../service/Helpers";
import { mathRound } from "../../../../service/mathRound";

interface ChartObjectExpenseInterface {
  data: ObjectExpenseTypes[];
  isLoading: boolean;
}

const ChartObjectExpense: React.FC<ChartObjectExpenseInterface> = ({
  isLoading,
  data
}) => {
  const [chartData, setChartData] = useState<{
    data: DonutChartInterface[];
    labels: ObjectExpenseTypes[];
    allSum: number;
  }>({
    data: [],
    labels: [],
    allSum: 0
  });
  const [defaultChartData, setDefaultChartData] = useState<{
    data: DonutChartInterface[];
    labels: ObjectExpenseTypes[];
    allSum: number;
  }>({
    data: [],
    labels: [],
    allSum: 0
  });

  useEffect(() => {
    const newArrData: DonutChartInterface[] = [];
    const newArrLabels: ObjectExpenseTypes[] = [];
    let sum = 0;
    data.forEach((item, index) => {
      if (colors[index]) {
        newArrData.push({
          color: colors[index],
          id: item.name,
          label: item.name,
          value: item.summa
        });
        newArrLabels.push({
          color: colors[index],
          summa: item.summa,
          name: item.name
        });
      } else {
        const color = generateColor();
        newArrData.push({
          color: color,
          id: item.name,
          label: item.name,
          value: item.summa
        });
        newArrLabels.push({
          color,
          summa: item.summa,
          name: item.name
        });
      }
      sum += Number(item.summa);
    });
    setChartData({
      data: newArrData,
      labels: newArrLabels,
      allSum: sum
    });
    setDefaultChartData({
      data: newArrData,
      labels: newArrLabels,
      allSum: sum
    });
  }, [data]);

  const filterChartData = (label: string) => {
    if (chartData.data.some((item) => item.label === label)) {
      setChartData({
        data: chartData.data.filter((item) => item.label !== label),
        labels: chartData.labels,
        allSum: chartData.allSum
      });
    } else {
      const data = defaultChartData.data.filter(
        (item) => item.label === label
      )[0];
      setChartData({
        labels: chartData.labels,
        allSum: chartData.allSum,
        data: [...chartData.data, data]
      });
    }
  };

  if (isLoading)
    return (
      <div className={"spinnerChart"}>
        <Spin size={"large"} />
      </div>
    );

  const centeredMetric: (i: any) => any = ({ centerX, centerY }) => {
    const total = convertLongMoney(chartData.allSum);
    return (
      <>
        <text
          x={centerX}
          y={centerY - 20}
          textAnchor="middle"
          dominantBaseline="central"
          fill={"#9AA1A9"}
          fontWeight={"600"}
          cursor={"default"}
          fontSize={"14px"}
          fontFamily={"Gilroy"}
          style={{
            userSelect: "none"
          }}
        >
          umumiy
        </text>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          fill={"#505D6F"}
          fontWeight={"600"}
          cursor={"default"}
          fontSize={"18px"}
          fontFamily={"Gilroy"}
          style={{
            userSelect: "none"
          }}
        >
          {total}
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          fill={"#9AA1A9"}
          fontWeight={"600"}
          cursor={"default"}
          fontSize={"14px"}
          fontFamily={"Gilroy"}
          style={{
            userSelect: "none"
          }}
        >
          so’m
        </text>
      </>
    );
  };

  return (
    <>
      <div className={"bodyCont"}>
        <div className={"chart"}>
          <ResponsivePie
            data={chartData.data}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            valueFormat=" >-"
            innerRadius={0.65}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            colors={{ datum: "data.color" }}
            borderColor={{ from: "color", modifiers: [["darker", 0]] }}
            enableArcLinkLabels={false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsComponent={({ datum, style }) => {
              const newLabel = mathRound(
                (100 * datum.value) / chartData.allSum,
                1
              );
              return (
                <animated.g
                  transform={style.transform as any}
                  style={{ pointerEvents: "none" }}
                >
                  <text
                    fontFamily={"Gilroy"}
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={"#fff"}
                    style={{
                      fontSize: 14,
                      fontWeight: 800
                    }}
                  >
                    {newLabel} %
                  </text>
                </animated.g>
              );
            }}
            arcLabelsRadiusOffset={0.35}
            arcLabelsSkipAngle={7}
            layers={[
              "arcs",
              "arcLabels",
              "arcLinkLabels",
              "legends",
              centeredMetric
            ]}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            tooltip={(e) => {
              return (
                <div className={"tooltip"}>
                  <div className={"itemCont"}>
                    <div className={"title"}>Obyekt</div>
                    <div className={"data"}>{e.datum.label}</div>
                  </div>
                  <div className={"itemCont"}>
                    <div className={"title"}>Narxi</div>
                    <div className={"data"}>
                      {e.datum.value.toLocaleString("ru") + " so'm"}
                    </div>
                  </div>
                </div>
              );
            }}
            legends={[]}
          />
        </div>
        <div className={"rightSection"}>
          {chartData.labels.map((label, index) => (
            <div className={"cont"} key={index}>
              <span className={"name"}>{label.name}</span>
              <span className={"value"}>{convertLongMoney(label.summa)}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={"labelsCont"}>
        {chartData.labels.map((label, index) => (
          <div
            className={"label"}
            key={index}
            style={{
              cursor: "pointer"
            }}
            onClick={() => filterChartData(label.name)}
          >
            <span style={{ backgroundColor: label.color }} />
            <p>{label.name}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default ChartObjectExpense;
