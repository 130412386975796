import { Empty, Spin } from "antd";
import React from "react";
import { ObjectHistory } from "../../../models/settings/ObjectHistory";

type Props = {
  data: ObjectHistory | undefined;
  isLoading: boolean;
};

const ObjectHistoryTable: React.FC<Props> = ({ data, isLoading }) => {
  const isEmpty = () => {
    if (data?.brokers && data?.products && data?.suppliers) {
      if (
        data?.expenses?.length! +
          data?.brokers?.length +
          data?.products?.length +
          data?.suppliers?.length >
        0
      ) {
        return false;
      } else return true;
    }
  };

  const allExpenses = () => {
    let expenses = 0;
    if (data?.products) {
      data.products.forEach((product) => {
        expenses += Number(product.summa);
      });
    }
    return expenses.toLocaleString();
  };

  return (
    <div className="historyTable">
      {isLoading ? (
        <div className="centerMaker">
          <Spin />
        </div>
      ) : isEmpty() ? (
        <div className="centerMaker">
          <Empty />
        </div>
      ) : (
        <div className="historyTable_cont">
          <h3>Obyekt nomi</h3>
          {Array.isArray(data?.buildings) ? (
            data?.buildings?.map((building) => (
              <p key={building.ID}>
                <span>{building.name}</span>
                <span>{Number(building.summa).toLocaleString()} so'm</span>
              </p>
            ))
          ) : (
            <p key={data?.buildings?.ID}>
              <span>{data?.buildings?.name}</span>
              <span>{allExpenses()} so'm</span>
            </p>
          )}
          {data?.expenses?.length! > 0 && (
            <>
              <h3>Xarajarlat</h3>
              {data?.expenses?.map((expense, index) => (
                <p key={index}>
                  <span>{expense.name}</span>
                  <span>{Number(expense.summa).toLocaleString()} so'm</span>
                </p>
              ))}
            </>
          )}
          {data?.suppliers?.length! > 0 && (
            <>
              <h3>Ta'minotchilar</h3>
              {data?.suppliers?.map((supplier, index) => (
                <p key={index}>
                  <span>{supplier.name}</span>
                  <span>{Number(supplier.summa).toLocaleString()} so'm</span>
                </p>
              ))}
            </>
          )}
          {data?.brokers?.length! > 0 && (
            <>
              <h3>Vositachilar</h3>
              {data?.brokers?.map((broker, index) => (
                <p key={index}>
                  <span>{broker.name}</span>
                  <span>{Number(broker.summa).toLocaleString()} so'm</span>
                </p>
              ))}
            </>
          )}
          {data?.categories?.length! > 0 && (
            <>
              <h3>Kategoriyalar</h3>
              {data?.categories?.map((category, index) => (
                <p key={index}>
                  <span>{category.name}</span>
                  <span>{Number(category.summa).toLocaleString()} so'm</span>
                </p>
              ))}
            </>
          )}
          {data?.products?.length! > 0 && (
            <>
              <h3>Mahsulotlar kirimi</h3>
              {data?.products?.map((product, index) => (
                <p key={index}>
                  <span>{product.name}</span>
                  <span>
                    x{Number(product.summa_count).toLocaleString()}
                    &nbsp;
                    {product.product_type_name}
                  </span>
                  <span>{Number(product.summa).toLocaleString()} so'm</span>
                </p>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ObjectHistoryTable;
