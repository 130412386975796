export const downloadFile = (data: Blob, name: string, type: string) => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.setAttribute("download", `${name}${type}`);
  document.body.appendChild(a);
  a.click();
  a.remove();
};
