import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootReducer'
import { isOpenSettingEditModal } from '../../../../redux/actions'
import { useCurrentCategory } from '../../../../queries/queries'
import { LoadingOutlined } from '@ant-design/icons'
import { CategoryInterface } from '../../../../models/interfaces/CategoryInterface'
import { useCategoryMutation } from '../../../../queries/mutations'

interface CreateAndEditModalInterface {
  currentCategoryId: number
  setCurrentCategory: React.Dispatch<React.SetStateAction<number>>
  page: number
}

const CreateAndEditModal: React.FC<CreateAndEditModalInterface> = ({
  currentCategoryId,
  setCurrentCategory,
  page,
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const isModalOpen = useSelector(
    (state: RootState) => state.settings.openCreateModal
  )

  const cu = useCategoryMutation(page)
  const { data, isLoading } = useCurrentCategory(currentCategoryId)

  const cancelFunc = () => {
    dispatch(isOpenSettingEditModal(false))
    setCurrentCategory(0)
    form.resetFields()
  }

  const onFinish = async (values: CategoryInterface) => {
    if (currentCategoryId) {
      await cu.mutateAsync({ ...values, id: currentCategoryId })
    } else {
      await cu.mutateAsync(values)
    }
    cancelFunc()
  }

  useEffect(() => {
    if (currentCategoryId > 0) {
      form.setFieldsValue({
        name: data?.[0]?.name,
      })
    }
  }, [currentCategoryId, data, form])

  const ModalTitle = (
    <>
      <span style={{ display: 'inline-block', marginRight: '1rem' }}>
        Kategoriya yaratish
      </span>
      {isLoading && <LoadingOutlined />}
    </>
  )

  return (
    <Modal
      className={'editModal'}
      visible={isModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
    >
      <Form form={form} name="paymentEdit" onFinish={onFinish} autoComplete="off">
        <label htmlFor={'name'}>Nomi</label>
        <Form.Item name="name" rules={[{ required: true, message: '' }]}>
          <Input className={'input'} id={'name'} />
        </Form.Item>
        <div className={'footerCont'}>
          <Button onClick={cancelFunc} className={'modalFooterBack'} type="link">
            Bekor qilish
          </Button>
          <Button
            disabled={isLoading}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit"
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateAndEditModal
