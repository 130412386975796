import "antd/dist/antd.less";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import RootRouter from "./routes/RootRouter";

const queryClient = new QueryClient();

function App() {
  // console.log(process.env.NODE_ENV, process.env.REACT_APP_BASE_URL)
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <RootRouter />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
