import React from 'react';
import IconInterface from "../models/interfaces/IconInterface";

const TableDownIcon: React.FC<IconInterface> = ({className,style}) => {
  return (
    <svg className={className} style={style} width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 0L5 5L9.5 0H0.5Z" fill="#2C3F45"/>
    </svg>
  );
}

export default TableDownIcon;