import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";
import { isOpenSettingEditModal } from "../../../../redux/actions";
import { useExpenseMutation } from "../../../../queries/mutations";
import { useCategoryAll, useCurrentExpense } from "../../../../queries/queries";
import { PaymentInterface } from "../../../../models/interfaces/PaymentInterface";
import { LoadingOutlined } from "@ant-design/icons";

interface CreateAndEditModalInterface {
  currentExpenseId: number;
  setCurrentId: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}

const { Option } = Select;

const CreateAndEditModal: React.FC<CreateAndEditModalInterface> = ({
  setCurrentId,
  currentExpenseId,
  page,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isModalOpen = useSelector(
    (state: RootState) => state.settings.openCreateModal
  );

  const category = useCategoryAll();
  const cu = useExpenseMutation(page);
  const { data, isLoading } = useCurrentExpense(currentExpenseId);

  const cancelFunc = () => {
    dispatch(isOpenSettingEditModal(false));
    setCurrentId(0);
    form.resetFields();
  };

  const onFinish = async (values: PaymentInterface) => {
    if (currentExpenseId) {
      await cu.mutateAsync({ ...values, id: currentExpenseId });
    } else {
      await cu.mutateAsync(values);
    }
    cancelFunc();
  };

  useEffect(() => {
    if (currentExpenseId > 0) {
      form.setFieldsValue({
        name: data?.[0]?.name,
        category_id: data?.[0]?.category_id,
      });
    }
  }, [currentExpenseId, data, form]);

  const ModalTitle = (
    <>
      <span style={{ display: "inline-block", marginRight: "1rem" }}>
        Xarajat yaratish
      </span>
      {isLoading && <LoadingOutlined />}
    </>
  );
  return (
    <Modal
      className="editModal"
      visible={isModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="paymentEdit"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Nomi"
          name="name"
          rules={[{ required: true, message: "" }]}
        >
          <Input className="input" />
        </Form.Item>
        <Form.Item
          label="Kategoriya"
          name="category_id"
          rules={[{ required: true, message: "" }]}
        >
          <Select placeholder="Kategoriyani tanlang">
            {category?.data?.map((item) => (
              <Option value={item.id!} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="footerCont">
          <Button onClick={cancelFunc} className="modalFooterBack" type="link">
            Bekor qilish
          </Button>
          <Button
            disabled={isLoading}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit"
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAndEditModal;
