import { Button, Form, FormInstance, Input, Modal, Switch } from "antd";
import React from "react";

import styles from "./expenseCreate.module.scss";

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<SettingsFormType>;
};

export type SettingsFormType = {
  number: string;
  description: string;
  comment_product: boolean;
};

const { Item } = Form;
const { TextArea } = Input;

const SettingsModal: React.FC<Props> = ({ visible, setVisible, form }) => {
  const onFinish = (fields: SettingsFormType) => {
    setVisible(false);
  };

  const cancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      className={styles.settings}
      footer={false}
      onCancel={cancel}
      title="Qo'shimcha sozlamalar"
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="number" label="Naryad nomeri">
          <Input placeholder="Naryad nomerini kiriting" />
        </Item>
        <Item name="description">
          <TextArea placeholder="Izoh kiritng" />
        </Item>
        <div className="w_100 d_f jc_sb ai_c">
          <h3>Har bir mahsulotga izoh</h3>
          <Item valuePropName="checked" name="comment_product">
            <Switch />
          </Item>
        </div>
        <div className="d_f ai_c jc_fe cg-10">
          <Button onClick={cancel}>Chiqish</Button>
          <Button htmlType="submit" type="primary">
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SettingsModal;
