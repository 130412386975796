import React from "react";

const RefreshIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17969 5.17334H11.4297V2.92334"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00391 3.58443C4.38659 3.20118 4.84108 2.89714 5.34137 2.6897C5.84166 2.48226 6.37794 2.37549 6.91953 2.37549C7.46112 2.37549 7.9974 2.48226 8.49769 2.6897C8.99799 2.89714 9.45247 3.20118 9.83516 3.58443L11.4289 5.17349"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66016 7.82666H2.41016V10.0767"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83516 9.41572C9.45247 9.79897 8.99799 10.103 8.49769 10.3104C7.9974 10.5179 7.46112 10.6247 6.91953 10.6247C6.37794 10.6247 5.84166 10.5179 5.34137 10.3104C4.84108 10.103 4.38659 9.79897 4.00391 9.41572L2.41016 7.82666"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;
