import { CloseOutlined } from "@ant-design/icons";
import { Button, Empty, Grid, Input, Select, Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToList,
  removeFromList,
  setBuilding,
  setCount,
  setNds,
  setPrice,
} from "../../../redux/actions";
import { ProductListTableItem } from "../../../redux/productListTypes";
import { RootState } from "../../../redux/rootReducer";
import { moneyToView } from "../../../service/Helpers";
import { OrderTableProductName } from "./OrderTableProductName";

const { Column } = Table;
const { Option } = Select;

interface CreateOrderTableProps {
  buildings: any[];
  isTransferred: "1" | "0" | undefined;
  isImportLoad?: boolean;
  nds: boolean;
}

const CreateOrderTable: React.FC<CreateOrderTableProps> = ({
  buildings,
  isTransferred,
  isImportLoad,
  nds,
}) => {
  const dispatch = useDispatch();
  const products = useSelector(
    (state: RootState) => state.productList.products
  );
  const currency = useSelector((state: RootState) => state.productList.valuta);
  const disabled = isTransferred === "1";

  const ndsValue = [
    {
      label: "15%",
      value: 15,
    },
    {
      label: "12%",
      value: 12,
    },
    {
      label: "0%",
      value: 0,
    },
  ];

  const { xxl } = Grid.useBreakpoint();

  const ndsSelectValue = (index: number): number => {
    const oneProduct = products[index];
    if (oneProduct.nds === 0) {
      return 0;
    } else {
      return oneProduct.nds ? oneProduct?.nds : 15;
    }
  };

  return (
    <Table<ProductListTableItem>
      rowKey={(record) => record.id}
      dataSource={products}
      className="orderForm_table"
      scroll={{ y: "calc(100vh - 18rem)" }}
      pagination={false}
      loading={isImportLoad}
      locale={{
        emptyText: (
          <div>
            <Empty description="Mahsulotlar mavjud emas" />
          </div>
        ),
      }}
    >
      <Column
        title="#"
        width="5rem"
        dataIndex="id"
        align="center"
        key="id"
        render={(_, __, index) => index + 1}
      />
      <Column
        title="MAHSULOT NOMI"
        dataIndex="name"
        width={xxl ? "15rem" : "12rem"}
        key="name"
        render={(name, data: ProductListTableItem) => {
          return (
            <OrderTableProductName
              values={{
                product_id: data.id,
                building_id: data.building_id,
                date: data.date,
                name: data.name,
                comment: data.comment!,
              }}
            />
          );
        }}
      />
      <Column
        title="MIQDORI"
        dataIndex="count"
        key="count"
        width={xxl ? "9rem" : "8rem"}
        render={(count, record: any, index) => {
          const intCount = parseInt(count);
          return (
            <Input
              disabled={disabled}
              value={
                record.type === "BOLAK" || isNaN(intCount)
                  ? count
                  : intCount.toLocaleString("ru")
              }
              onChange={({ target }) => {
                dispatch(setCount(target.value.replace(/\s/g, ""), index));
              }}
              suffix={record?.product_type_name}
            />
          );
        }}
      />
      {/* <Column
        width="5.5rem"
        title="O'LCHAMI"
        dataIndex="product_type_name"
        key="id"
      /> */}
      <Column
        title="BIRLIK NARXI"
        dataIndex="price"
        key="price"
        render={(price, _data: any, index) => {
          return (
            <Input
              style={
                _data.last_price && _data.last_price / currency.price < price
                  ? { color: "red" }
                  : {}
              }
              disabled={disabled}
              value={moneyToView(price)}
              onChange={({ target }) => {
                dispatch(setPrice(target.value.replace(/\s/g, ""), index));
              }}
            />
          );
        }}
      />
      {nds && (
        <Column
          title="NDS"
          key="nds"
          dataIndex="nds"
          width="6rem"
          render={(nds, _, index) => {
            return (
              <Select
                value={ndsSelectValue(index)}
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  dispatch(setNds(Number(e), index));
                }}
              >
                {ndsValue.map((item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            );
          }}
        />
      )}
      <Column
        title="OBYEKT"
        dataIndex="building_id"
        key="name"
        render={(building_id, data: any, index) => {
          return (
            <Select
              disabled={disabled}
              style={{ width: "100%" }}
              value={Number(data.building_id)}
              onChange={(value) => {
                dispatch(setBuilding(value, index));
              }}
            >
              {buildings.map((building) => (
                <Select.Option value={Number(building.id)} key={building.id}>
                  {building.name}
                </Select.Option>
              ))}
            </Select>
          );
        }}
      />
      <Column
        title="JAMI NARXI"
        dataIndex="price"
        key="price"
        render={(_, record: any) => {
          return (
            <>
              {`${(record.price * record.count).toLocaleString("ru")} ${
                currency.name
              }`}{" "}
            </>
          );
        }}
      />
      {nds && (
        <Column
          title="JAMI NDS NARXI"
          key="id"
          render={(record) => {
            return (
              <>
                {(
                  record.price *
                  record.count *
                  (record.nds / 100 + 1)
                ).toLocaleString("ru")}{" "}
                {currency.name}
              </>
            );
          }}
        />
      )}
      {!disabled && (
        <Column
          title=""
          dataIndex="id"
          key="index"
          width="5rem"
          align="center"
          render={(id, __, _index) => {
            return (
              <Button
                type="text"
                id={`deleteBtn${id}`}
                danger
                onClick={() => {
                  // requests.current.delete(id)
                  dispatch(removeFromList(id));
                }}
              >
                <CloseOutlined color="red" />
              </Button>
            );
          }}
        />
      )}
    </Table>
  );
};

export default CreateOrderTable;
