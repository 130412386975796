import { FormInstance, message } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { SupplierType } from "../components/main/supplier/all/SupplierAllTypes";
import { endpoints } from "../features/expense/utils/constants/endpoints";
import { ErrorRes } from "../features/expense/utils/models/responseType";
import { apis } from "../http/apis";
import $api from "../http/http";
import { CategoryInterface } from "../models/interfaces/CategoryInterface";
import { ExpenseInterface } from "../models/interfaces/ExpenseInterface";
import LoginFormInterface from "../models/interfaces/LoginFormInterface";
import { ObjectInterface } from "../models/interfaces/ObjectInterface";
import { PaymentCreateInterface } from "../models/interfaces/PaymentCreateInterface";
import { PaymentInterface } from "../models/interfaces/PaymentInterface";
import { ProductCreateInterface } from "../models/interfaces/ProductCreateInterface";
import { ProductSiInterface } from "../models/interfaces/ProductSiInterface";
import { UserInterface } from "../models/interfaces/UserInterface";
import { ValutaInterface } from "../models/interfaces/ValutaInterface";
import { LoginModelInterface } from "../models/login/LoginModelInterface";
import { RootState } from "../redux/rootReducer";

import mainPaths from "../routes/main/mainPaths";
import { rootPaths } from "../routes/rootRoutes";
import { downloadFile } from "../service/downloadExcelFile";
import { UserService } from "../service/UserService";
import { queryNames } from "./queryNames";

export function useLogin(history: RouteComponentProps["history"]) {
  const dispatch = useDispatch();
  return useMutation(
    async (values: LoginFormInterface) => {
      const res = await axios.post(
        `https://backend.${
          values.domen === "uyqur" ? "" : `${values.domen}.`
        }uyqur.uz/api/login`,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data: LoginModelInterface) => {
        UserService.login(data.user, data.Authorization, dispatch);
        history.push(`${rootPaths.MAIN}${mainPaths.ORDERS}`);
        setTimeout(() => window.location.reload(), 100);
      },
    }
  );
}

// create Update Payment
export function usePaymentMutation(page: number) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, PaymentInterface>(
    async (values) => {
      const res = await $api.post(apis.SETTINGS_PAYMENT, values);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Saqlandi");
        queryClient.invalidateQueries([queryNames.SETTINGS_PAYMENTS, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

//delete payment
export function useDeletePayment(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, number>(
    async (id) => {
      const res = await $api.delete(`${apis.SETTINGS_PAYMENT}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.SETTINGS_PAYMENTS, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

//create and update user
export function useUserMutation(page: number) {
  const queryClient = useQueryClient();
  return useMutation<any, any, UserInterface>(
    async (values) => {
      const res = await $api.post(apis.USER, values);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Saqlandi");
        queryClient.invalidateQueries([queryNames.SETTINGS_USERS, page]);
      },
      onError: (err: ErrorRes) => {
        message.error("Xatolik mavjud");
      },
    }
  );
}

//delete user
export function useDeleteUser(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.USER}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.SETTINGS_USERS, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

////////// create and update Expense

export function useExpenseMutation(page: number) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, ExpenseInterface>(
    async (values) => {
      const res = await $api.post(apis.EXPENSE, values);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Saqlandi");
        queryClient.invalidateQueries([queryNames.SETTINGS_EXPENSES, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

// settings mutations
// create Update Payment
export function useSupplierMutation(page?: number, form?: FormInstance) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, SupplierType>(
    async (values) => {
      const res = await $api.post(apis.SUPPLIER, values);
      return res.data;
    },
    {
      onSuccess: (data) => {
        message.success("Saqlandi");
        if (page) {
          queryClient.invalidateQueries([
            queryNames.SUPPLIER,
            queryNames.PAGE,
            page,
          ]);
        } else {
          if (form) {
            form.setFieldsValue({ supplier_id: data.id });
          }
          queryClient.invalidateQueries([queryNames.ORDER_SELECTS]);
        }
      },
      onError: (err: any) => {
        message.error("Xatolik yuz berdi, qaytadan urinib ko'ring");
      },
    }
  );
}

//delete expense
export function useDeleteExpense(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.EXPENSE}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.SETTINGS_EXPENSES, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

////////// create and update Category

export function useCategoryMutation(page: number) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, CategoryInterface>(
    async (values) => {
      const res = await $api.post(apis.CATEGORY, values);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Saqlandi");
        queryClient.invalidateQueries([queryNames.SETTINGS_CATEGORIES, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

//delete supplier
export function useDeleteSupplier() {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, number>(
    async (id) => {
      const res = await $api.delete(`${apis.SUPPLIER}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries(queryNames.SUPPLIER);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

//delete category
export function useDeleteCategory(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.CATEGORY}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.SETTINGS_CATEGORIES, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

//// create and update product SI
export function useProductSIMutation(page: number) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, ProductSiInterface>(
    async (values) => {
      const res = await $api.post(apis.PRODUCT_SI, values);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Saqlandi");
        queryClient.invalidateQueries([queryNames.SETTINGS_PRODUCTS_SI, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

//// delete product SI
export function useDeleteProductSI(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.PRODUCT_SI}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.SETTINGS_PRODUCTS_SI, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

//// delete object
export function useDeleteObject(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.BUILDING}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.SETTINGS_OBJECTS, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

////////// create and update object

export function useObjectMutation(page: number) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, ObjectInterface>(
    async (values) => {
      const res = await $api.post(apis.BUILDING, values);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Saqlandi");
        queryClient.invalidateQueries([queryNames.SETTINGS_OBJECTS, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

//delete valuta
export function useDeleteValuta(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.VALUTA}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.SETTINGS_VALUTA, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

//// create and update valuta

export function useValutaMutation(page: number) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, ValutaInterface>(
    async (values) => {
      const res = await $api.post(apis.VALUTA, values);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Saqlandi");
        queryClient.invalidateQueries([queryNames.SETTINGS_VALUTA, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

//delete product
export function useDeleteProduct(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.PRODUCT}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.SETTINGS_PRODUCTS, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

////// create and update Product

export function useProductMutation(page?: number) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, ProductCreateInterface>(
    async (values) => {
      const res = await $api.post(apis.PRODUCT, values);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Saqlandi");
        if (page) {
          queryClient.invalidateQueries([queryNames.SETTINGS_PRODUCTS, page]);
        }
        queryClient.invalidateQueries([queryNames.ORDER_PRODUCT_LIST]);
      },
      onError: (err: any) => {
        message.error(
          "Xatolik, mahsulot nomini o'zgartiring yoki qaytadan urinib ko'ring"
        );
      },
    }
  );
}

//////// parts table part delete

export function useDeleteOnePart(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.ORDER}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.PARTS, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

////////// createPayment

export function useCreatePaymentMutation(supplier_id: string) {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, PaymentCreateInterface>(
    async (values) => {
      const res = await $api.post(apis.PAYMENT, values);
      return res.data;
    },
    {
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryNames.SUPPLIER_DETAILS,
          supplier_id,
        ]);
      },
    }
  );
}

export function useDeletePaymentMutation() {
  return useMutation<any, unknown, PaymentCreateInterface>(
    async (id) => {
      const res = await $api.delete(apis.PAYMENT + "/" + id);
      return res.data;
    },
    {
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSuccess: () => {
        message.success("O`chirildi");
      },
    }
  );
}

//////////////////// create and update order
export function useCUOrderMutation() {
  const params = useParams<{ orderId: string }>();
  const orderIdRed = useSelector(
    (state: RootState) => state.orderIdReducer.copy
  );

  const { push } = useHistory();
  return useMutation<any, unknown, any>(
    async (values) => {
      let data = values;
      const intId = params.orderId.split("-");
      if (params.orderId !== "new" && !intId[1]) {
        data = { ...values, id: Number(intId[0]) };
      } else {
        delete data.id;
      }
      const res = await $api.post(apis.ORDER, data);
      return res.data;
    },

    {
      onSuccess: (data) => {
        if (data.message.includes("created")) {
          push(`${rootPaths.MAIN}${mainPaths.ORDERS}/${data.id}`);
        }
        message.success("Saqlandi");
      },
      onError: (err: any) => {
        message.error(
          typeof err.response?.data?.message === "string"
            ? err.response.data.message
            : "Ma'lumotlarni saqlashda xatolik yuz berdi."
        );
      },
    }
  );
}

// export const useCheckProduct = () => {
//   const dispatch = useDispatch()
//   const innerProductId = useRef(0)
//   return useMutation<
//    ,
//     any,
//     { product_id: number; building_id: number; date: string }
//   >(
//     async (values) => {
//       innerProductId.current = values.product_id
//       const res = await $api.post(apis.CHECK_PRODUCT, {
//         ...values,
//         date: dayjs(values.date).format('YYYY-MM-DD'),
//       })
//       return res.data
//     },
//     {
//       onSuccess: (data) => {
//       },
//     }
//   )
// }

//////////////////// create and update transfer
export function useCUTransfer(transferId: string) {
  const { push } = useHistory();
  return useMutation<any, unknown, any>(
    async (values) => {
      const intId = parseInt(transferId);
      if (transferId !== "new" && !isNaN(intId)) {
        values.id = intId;
      }
      const res = await $api.post(apis.TRANSFER, values);
      return res.data;
    },

    {
      onSuccess: () => {
        push(`${rootPaths.MAIN}${mainPaths.TRANSFER}`);
        message.success("Saqlandi");
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

//////// delete transfer

export function useDeleteTransfer(
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.TRANSFER}/${id}`);
      return res?.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.TRANSFERS, page]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
      onSettled: () => {
        setDeleteId(0);
      },
    }
  );
}

// credit and debit excel
export function useCreditDebitExcel() {
  return useMutation(
    async (data: {
      type: string;
      dateArr?: string[];
      ID?: number;
      category?: number | string;
      view?: string;
    }) => {
      let URL = apis.EXCEL + `?type=${data?.type}&view=${data?.view}`;
      if (data?.type === "BUILDINGS_HISTORY") {
        if (data?.dateArr?.length && data?.dateArr?.length > 0) {
          URL += `&begin=${data?.dateArr[0]}&end=${data?.dateArr[1]}`;
        }
        if (data?.ID) {
          URL += `&building_id=${data?.ID}`;
        } else {
          URL += `&building_id=all`;
        }
        if (data?.category) {
          URL += `&category_id=${data.category}`;
        }
      }
      const res = await $api.get(URL, {
        responseType: "blob",
      });
      return res.data;
    },
    {
      onSuccess: (data) => {
        // const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        // const link = document.createElement("a");
        // link.href = downloadUrl;
        // link.setAttribute("download", `${data.type}`);
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
        downloadFile(data, "Obyektlar", ".xlsx");
      },
      onError: (err: any) => {
        message.error(err.response?.data?.message);
      },
    }
  );
}

// delete expense
export function useDeleteOneExpense(page: number, search: string) {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.VIEW_EXPENSE}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi");
        queryClient.invalidateQueries([queryNames.ALL_EXPENSE, page, search]);
      },
      onError: (err: any) => {
        message.error(err.response.data.message);
      },
    }
  );
}

// get all products for warehouse
export function useGetWarehouseProduct() {
  return useMutation(async (data: { id: number; name?: string }) => {
    const res = await $api.get(
      `${apis.WAREHOUSE_PRODUCT}${data.id}?all=true${
        data.name ? `&name=${data.name}` : ""
      }`
    );
    return res.data;
  });
}

// create expense
export function useCreateExpense(toBack: () => void) {
  return useMutation(
    async (data: any) => {
      const res = await $api.post(apis.CREATE_EXPENSE, data);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Muvofaqqiyatli qo'shildi !");
        toBack();
      },
      onError: () => {
        message.error("Xatolik yuz berdi");
      },
    }
  );
}

// update expense
export function useUpdateExpense(toBack: () => void) {
  return useMutation(
    async (data: any) => {
      const res = await $api.post(apis.UPDATE_EXPENSE, data);
      return res;
    },
    {
      onSuccess: () => {
        message.success("Muvofaqqiyatli o'zgartirildi !");
        toBack();
      },
      onError: (err: AxiosError) => {
        if (err.response?.status === 403) {
          toBack();
          message.error("Sizga ruxsat yo'q !");
        } else {
          toBack();
          message.error("Tizimda xatolik mavjud");
        }
      },
    }
  );
}

// get one expense
export function useGetOneAllExpense() {
  return useMutation(async (id: number) => {
    const res = await $api.get(`${apis.VIEW_EXPENSE}/${id}`);
    return res.data;
  });
}

export function useGetBuildingUser() {
  return useMutation(async (id: string[] | null) => {
    const res = await $api.get(`${endpoints.USERS}?id=[${id}]`);
    return res.data;
  });
}

export function useDownloadWarehouse() {
  return useMutation(
    async (parametrs: {
      id?: number[];
      all?: boolean;
      query?: {
        search?: string;
        begin?: string;
        end?: string;
      };
    }) => {
      let url = `${apis.WAREHOUSE}?${
        parametrs.all ? `all=true` : `id=[${parametrs.id}]`
      }&excel=true`;

      if (parametrs?.query) {
        Object.keys(parametrs.query).forEach((key) => {
          const value = parametrs.query?.[key as keyof typeof parametrs.query];

          if (value) {
            url += `&${key}=${value}`;
          }
        });
      }

      const res = await $api.get(url, {
        responseType: "blob",
      });
      return res.data;
    },
    {
      onSuccess: (data) => {
        downloadFile(data, `Skladlar`, ".xlsx");
      },
      onError: () => {
        message.warning("Yuklab bo'lmadi");
      },
    }
  );
}

export function useDownloadWarehouseProducts() {
  return useMutation(
    async (data: {
      products: number[];
      name: string;
      query?: {
        search?: string;
        begin?: string;
        end?: string;
      };
    }) => {
      let url = `${apis.WAREHOUSE}/${data.products}?excel=true`;

      if (data?.query) {
        Object.keys(data.query).forEach((key) => {
          const value = data.query?.[key as keyof typeof data.query];

          if (value) {
            url += `&${key}=${value}`;
          }
        });
      }

      const res = await $api.get(url, {
        responseType: "blob",
      });
      return res.data;
    },
    {
      onSuccess: (data, record) => {
        downloadFile(data, `${record.name}`, ".xlsx");
      },
      onError: () => {
        message.warning("Yuklab bo'lmadi");
      },
    }
  );
}

// CHECKOUT PAGE //

export function useDeleteCheckoutPayment() {
  const qc = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.CHECKOUT_DELETE}${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Muvofaqqiyatli o'chirildi !");
        qc.invalidateQueries([queryNames.CHECKOUT_PAYMENTS]);
        qc.invalidateQueries([queryNames.CHECKOUT_VIEW]);
      },
      onError: () => {
        message.warning("O'chirishda muammo mavjud");
      },
    }
  );
}

export function useCreateCheckout(closeModal: () => void) {
  const qc = useQueryClient();
  return useMutation(
    async (data: any) => {
      const res = await $api.post(apis.CREATE_CHECKOUT, data);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Muvofaqqiyatli qo'shildi !");
        closeModal();
        qc.invalidateQueries([queryNames.CHECKOUT_PAYMENTS]);
        qc.invalidateQueries([queryNames.CHECKOUT_VIEW]);
        qc.invalidateQueries([queryNames.SUPPLIER, 1, "", true]);
      },
      onError: () => {
        message.warning("Xatolik mavjud");
      },
    }
  );
}

// UYSOT integration

export function useUysotIntegration() {
  const qc = useQueryClient();
  const history = useHistory();
  return useMutation(
    async (key: string) => {
      const res = await $api.get(`${apis.UYSOT_INTEGRATION}?key=${key}`);
      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.KASSA_UYSOT]);
        qc.invalidateQueries([queryNames.GET_ALL_PAYMENTS]);
        qc.invalidateQueries([queryNames.UYSOT_VIEW]);
        history.push({
          pathname: history.location.pathname,
          search: "",
        });
      },
      onError: () => {
        message.warning("Xatolik yuz berdi !");
      },
    }
  );
}

export function useRetryUysotIntegration() {
  const qc = useQueryClient();
  return useMutation(
    async () => {
      const res = await $api.get(apis.UYSOT_RETRY);
      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.KASSA_UYSOT]);
      },
    }
  );
}

export function useUpdatePaymentUysot(closeModal: () => void) {
  const qc = useQueryClient();
  return useMutation(
    async (data: any) => {
      const res = await $api.put(`${apis.UYSOT_PAYMENT}${apis.UPDATE}`, data);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Muvafaqqiyatli yangilandi !");
        qc.invalidateQueries([queryNames.KASSA_UYSOT]);
        qc.invalidateQueries([queryNames.GET_ALL_PAYMENTS]);
        closeModal();
      },
      onError: () => {
        message.error("Yangilashda xatolik mavjud !");
      },
    }
  );
}

export function useCancelUysot() {
  const qc = useQueryClient();
  return useMutation(
    async () => {
      const res = await $api.delete(`${apis.UYSOT}/cancel`);
      return res.data;
    },
    {
      onError: () => {
        message.warning("Tizimda xatolik mavjud");
      },
      onSuccess: () => {
        qc.invalidateQueries([queryNames.KASSA_UYSOT]);
        qc.invalidateQueries([queryNames.UYSOT_VIEW]);
      },
    }
  );
}

// Conversion

export function useDeleteConversion() {
  const qc = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`${apis.CONVERSION}/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'chirildi !");
        qc.invalidateQueries([queryNames.CONVERSION]);
        qc.invalidateQueries([queryNames.SETTINGS_PAYMENT, "all"]);
      },
      onError: () => {
        message.warning("O'chirishda xatolik mavjud !");
      },
    }
  );
}

export function useCreateConversion(closeModal: () => void) {
  const qc = useQueryClient();
  return useMutation(
    async (data: any) => {
      const res = await $api.post(apis.CONVERSION, data);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Muvafaqqiyatli yaratildi !");
        qc.invalidateQueries([queryNames.CONVERSION]);
        qc.invalidateQueries([queryNames.SETTINGS_PAYMENT, "all"]);
        closeModal();
      },
      onError: () => {
        message.warning("Yaratishda xatolik mavjud !");
      },
    }
  );
}

export function useDeletePictureFile() {
  const qc = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`/file/delete?id=${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.PICTURES_VIEW_FILE]);
      },
      onError: () => {
        message.error("Tizimda xatolik yuz berdi !");
      },
    }
  );
}

export function useDownloadPicture() {
  return useMutation(
    async (data: { id: number; format: string; name: string }) => {
      const res = await $api.get(`/file/download?id=${data.id}`, {
        responseType: "blob",
      });
      return res.data;
    },
    {
      onSuccess: (data, e) => {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${e.name}.${e.format}`);
        link.click();
      },
    }
  );
}

export function useFileNameUpdate() {
  return useMutation(
    async (data: { id: number; name: string }) => {
      const res = await $api.put("/file/update", data);
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("O'zgartirildi !");
      },
      onError: () => {
        message.error("Tizimda xatolik yuz berdi");
      },
    }
  );
}

export function useDownloadCheckPage() {
  return useMutation(
    async (data: string) => {
      const res = await $api.get(
        `${apis.CHECKOUT_PATMENTS}?excel=true&${data}`,
        {
          responseType: "blob",
        }
      );
      return res;
    },
    {
      onSuccess: (data: AxiosResponse) => {
        const fileName = data?.headers["content-type"]?.split(".")[0];
        downloadFile(data?.data, fileName, ".xlsx");
      },
    }
  );
}

export function useDownloadConversion() {
  const { search } = useLocation();
  const queryParam = new URLSearchParams(search);
  let url = `${apis.CONVERSION}?excel=true`;

  if (queryParam.get("visibleView")) {
    queryParam.delete("visibleView");
  }

  url = `${apis.CONVERSION}?excel=true&${queryParam.toString()}`;

  return useMutation(
    async () => {
      const res = await $api.get(url, {
        responseType: "blob",
      });
      return res;
    },
    {
      onSuccess: (data: AxiosResponse) => {
        const fileName = data?.headers["content-type"]?.split(".")[0];
        downloadFile(data?.data, fileName, ".xlsx");
      },
    }
  );
}
