import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty, Input, Popconfirm, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { ACTIVE } from "../../../helper/ExpenseType";
import { ASC, DESC, KEY, SORT } from "../../../helper/paramNames";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {
  ExpenseDataModel,
  WarehouseDataModel,
} from "../../../models/warehouse/WarehouseModel";
import {
  useDeleteOneExpense,
  useGetOneAllExpense,
} from "../../../queries/mutations";
import { useGetExpense } from "../../../queries/queries";
import { queryNames } from "../../../queries/queryNames";
import ArrowDownUpIcon from "../../../svg/ArrayDownUpIcon";
import ArrowUpIcon from "../../../svg/ArrayUpIcon";
import ArrowDownIcon from "../../../svg/ArrowDownIcon";
import MinusIcon from "../../../svg/MinusIcon";
import { NotePencil } from "../../../svg/NotePencil";
import SearchIcon from "../../../svg/SearchIcon";
import ShopIcon from "../../../svg/ShopIcon";
import TableDownIcon from "../../../svg/TableDownIcon";
import TrashIcon from "../../../svg/TrashIcon";
import PaginationComp from "../universialComponents/PaginationComp";
import styles from "./expense.module.scss";
import ProductTable from "./ProductTable";
import ProductsWarehouseModal from "./warehouse-modal/ProductsWarehouseModal";
import WarehouseModal from "./warehouse-modal/WarehouseModal";
import { useDispatch } from "react-redux";
import {
  expenceCopyAction,
  pictureVisibleAction,
} from "../../../redux/actions";
import ImageIcon from "../../../svg/ImageIcon";
import Pictures from "../universialComponents/pictures/Pictures";
import CopyIcon from "../../../svg/CopyIcon";

const Expense = () => {
  useDocumentTitle("Chiqimlar");
  const queryClient = useQueryClient();
  const [visibleWareHouse, setVisibleWareHouse] = useState<boolean>(false);
  const [visibleProductWareHouse, setVisibleProductWareHouse] =
    useState<boolean>(false);
  const [warehouseData, setWarehouseData] = useState<WarehouseDataModel>({
    id: 1,
    address: "",
    name: "",
    summa: "",
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [perPage, setPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState<string>("");
  const [modalType, setModalType] = useState<number>(1);
  const params = new URLSearchParams("key=&sort=");
  const history = useHistory();
  const location = useLocation();
  const searchParams = location.search.slice(1, location.search.length);
  const oneAllExpense = useGetOneAllExpense();
  const { data, isLoading } = useGetExpense(
    pageNumber,
    searchValue,
    searchParams,
    perPage
  );
  const deleteOneExpense = useDeleteOneExpense(pageNumber, searchValue);
  const dispatch = useDispatch();
  const [usedId, setUsedId] = useState<number>(1);

  // warehouse modal open
  const handleOpenWareHouse = (type: number) => {
    setVisibleWareHouse(true);
    setModalType(type);
  };

  // go edit page
  const goEditPage = (id: number) => {
    dispatch(expenceCopyAction(false));
    history.push(`/main/expense/${id}`);
  };

  // delete expense
  const deleteExpense = (e: ExpenseDataModel) => {
    deleteOneExpense.mutate(e.id);
  };

  const changeSortData = (key: string) => {
    params.set(KEY, key);
    params.set(SORT, location.search.split("=")[2] === ASC ? DESC : ASC);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const tableHeaderSortIcon = (sortName: string) => {
    const sortType = location.search && location.search.split("=")[2];
    const sortKey =
      location.search && location.search.split("=")[1].split("&")[0];
    if (sortName === sortKey) {
      if (sortType === "desc") return <ArrowUpIcon />;
      else return <ArrowDownIcon />;
    } else return <ArrowDownUpIcon />;
  };

  const visiblePictureModal = (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    setUsedId(id);
    dispatch(pictureVisibleAction(true));
  };

  const toCopyExpence = (id: number) => {
    dispatch(expenceCopyAction(true));
    history.push(`/main/expense/${id}`);
  };

  // table columns
  const columns: ColumnsType<ExpenseDataModel> = [
    {
      title: "#",
      render: (e: ExpenseDataModel) => <p>#{e.id}</p>,
      width: 44,
    },
    {
      title: (
        <div className="table_header">SANA {tableHeaderSortIcon("date")}</div>
      ),
      // dataIndex: "date"
      render: (e: ExpenseDataModel) => (
        <p>{dayjs(e.date).format("DD.MM.YYYY HH:mm")}</p>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("date");
          },
        };
      },
    },
    {
      title: (
        <div className="table_header">
          XODIM {tableHeaderSortIcon("user_name")}
        </div>
      ),
      dataIndex: "user_name",
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("user_name");
          },
        };
      },
    },
    {
      title: (
        <div className="table_header">
          SKLAD {tableHeaderSortIcon("building_name")}
        </div>
      ),
      dataIndex: "building_name",
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("building_name");
          },
        };
      },
    },
    // {
    //   title: (
    //     <div className="table_header">
    //       OPERATOR {tableHeaderSortIcon("operator_name")}
    //     </div>
    //   ),
    //   render: (e: ExpenseDataModel) => e.operator_name ?? "Tanlanmagan",
    //   onHeaderCell: () => {
    //     return {
    //       onClick: () => {
    //         changeSortData("operator_name");
    //       },
    //     };
    //   },
    // },
    {
      title: (
        <div className="table_header">SUMMA {tableHeaderSortIcon("price")}</div>
      ),
      render: (e: ExpenseDataModel) => {
        return <p>{Number(e.price).toLocaleString()} so'm</p>;
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("price");
          },
        };
      },
    },
    {
      title: (
        <div className="table_header">
          STATUS {tableHeaderSortIcon("status")}
        </div>
      ),
      render: (e: ExpenseDataModel) => (
        <p className={`status_${e.status}`}>
          {e.status === ACTIVE ? "Tasdiqlangan" : "Saqlangan"}
        </p>
      ),
      onHeaderCell: () => {
        return {
          onClick: () => {
            changeSortData("status");
          },
        };
      },
    },
    {
      title: "ACTION",
      render: (e: ExpenseDataModel) => {
        return (
          <div
            className="btns"
            style={{
              columnGap: "5px",
            }}
          >
            <Tooltip title="Nusxa olish" placement="left">
              <Button onClick={() => toCopyExpence(e.id)}>
                <CopyIcon />
              </Button>
            </Tooltip>
            <Button
              style={{
                margin: 0,
              }}
              onClick={(event) => visiblePictureModal(event, e.id)}
            >
              <ImageIcon />
            </Button>
            <Button onClick={() => goEditPage(e.id)}>
              <NotePencil />
            </Button>
            <Popconfirm
              placement="left"
              title="O'chirishni davom ettirasizmi ?"
              okText="Ha !"
              cancelText="Yo'q."
              okType="danger"
              onConfirm={() => deleteExpense(e)}
            >
              <Button danger>
                <TrashIcon />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  // change search input
  const changeSearch = (e: any) => {
    if (e.key === "Enter") setSearchValue(e.target.value);
  };

  // when input value si is like
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setSearchValue("");
      queryClient.invalidateQueries([queryNames.ALL_EXPENSE]);
    }
  };

  return (
    <>
      <div className={styles.expense}>
        <div className={styles.top}>
          <h2>Chiqim</h2>
          <Input
            placeholder="Nomi yoki sana bo‘yicha qidiring..."
            className={styles.search}
            onKeyDown={changeSearch}
            onChange={onChangeSearch}
            suffix={<SearchIcon />}
          />
          <div className={styles.top_right}>
            <Button onClick={() => handleOpenWareHouse(1)}>
              <ShopIcon /> Sklad
            </Button>
            <Button onClick={() => history.push("/main/expense/create")}>
              <MinusIcon /> Chiqim
            </Button>
          </div>
        </div>
        <div className={styles.bottom}>
          <Table
            columns={columns}
            dataSource={data?.data}
            loading={{
              spinning: isLoading || deleteOneExpense.isLoading,
              indicator: <LoadingOutlined />,
            }}
            pagination={false}
            className={`expenseTable ${styles.table}`}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Mahsulotlar mavjud emas!"
                />
              ),
            }}
            expandable={{
              expandIconColumnIndex: 10,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (expanded)
                  return (
                    <span
                      className={styles.table_expand_icon}
                      onClick={(e) => onExpand(record, e)}
                    >
                      <TableDownIcon
                        style={{
                          transform: "rotate(180deg)",
                        }}
                      />
                    </span>
                  );
                return (
                  <span
                    onClick={(e) => onExpand(record, e)}
                    className={styles.table_expand_icon}
                  >
                    <TableDownIcon />
                  </span>
                );
              },
              expandedRowRender: (record, _, __, expanded) => {
                if (expanded) {
                  return <ProductTable id={record.id} dataOne={record} />;
                }
              },
              expandRowByClick: true,
            }}
            rowKey={(record) => record.id}
            onRow={(record) => {
              return {
                onClick: () => {
                  oneAllExpense.mutate(record.id);
                },
              };
            }}
          />
          <PaginationComp
            paginationProps={{
              onChange: (page) => setPageNumber(page),
              total: data?.total,
              current: data?.current_page,
              pageSize: perPage,
            }}
            per_page={perPage}
            setPerPage={setPerPage}
            showSizeChanger={true}
          />
        </div>
      </div>
      <Pictures type="used" id={usedId} />
      <WarehouseModal
        visible={visibleWareHouse}
        setVisible={setVisibleWareHouse}
        setProductsVisible={setVisibleProductWareHouse}
        setWarehouseData={setWarehouseData}
        type={modalType}
        setType={setModalType}
      />
      <ProductsWarehouseModal
        visible={visibleProductWareHouse}
        setVisible={setVisibleProductWareHouse}
        warehouse={warehouseData}
        type={modalType}
      />
    </>
  );
};

export default Expense;
