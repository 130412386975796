import React from 'react'

export const GearSix = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.5002C14.4853 16.5002 16.5 14.4855 16.5 12.0002C16.5 9.51496 14.4853 7.50024 12 7.50024C9.51472 7.50024 7.5 9.51496 7.5 12.0002C7.5 14.4855 9.51472 16.5002 12 16.5002Z"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5074 7.561C18.7292 7.8867 18.9262 8.22859 19.0968 8.58381L21.5293 9.93414C21.8256 11.2949 21.8266 12.7034 21.5323 14.0645L19.0985 15.4156C18.9273 15.7706 18.7297 16.1121 18.5074 16.4375L18.5542 19.2192C17.5239 20.1562 16.3047 20.8613 14.9787 21.287L12.5917 19.8548C12.1988 19.8841 11.8041 19.8837 11.4112 19.8539L9.02557 21.2853C7.69898 20.8615 6.4787 20.1581 5.44704 19.2227L5.49389 16.4394C5.27207 16.1137 5.07503 15.7718 4.90444 15.4166L2.47199 14.0663C2.17571 12.7055 2.17469 11.297 2.46901 9.93585L4.90282 8.58478C5.07396 8.22983 5.27154 7.88824 5.49388 7.5629L5.44705 4.78117C6.47736 3.8442 7.69662 3.13909 9.0226 2.71338L11.4096 4.14559C11.8025 4.11633 12.1971 4.11665 12.5901 4.14652L14.9757 2.7151C16.3023 3.1389 17.5226 3.84225 18.5542 4.77772L18.5074 7.561Z"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
