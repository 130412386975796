import { LoadingOutlined } from "@ant-design/icons";
import { Col, Select } from "antd";
import dayjs from "rc-picker/node_modules/dayjs";
import React, { useState } from "react";
import { useExpenseStats } from "../../../../queries/queries";
import { DatePicker } from "../../../../service/DatePicker";
import { CreditDebitStats } from "./CreditDebitStats";
// import LineGraphExpense from './LineGraphExpense'
import ResponsiveBarExpense from "./ResponsiveBarExpense";

interface ExpenseStatsProps {}
const { Option } = Select;

export type DateType = "month" | "year" | "day";
export const dateOptions = [
  {
    value: "day",
    label: "Kunlik",
  },
  {
    value: "month",
    label: "Oylik",
  },
  {
    value: "year",
    label: "Yillik",
  },
];

const ExpenseStats: React.FC<ExpenseStatsProps> = () => {
  const [dateType, setDateType] = useState<DateType>("day");
  const [date, setDate] = useState([
    dayjs().startOf("month").format("YYYY-MM-DD"),
    dayjs().endOf("month").format("YYYY-MM-DD"),
  ]);
  const {
    data: expenses,
    isLoading,
    isFetching,
  } = useExpenseStats(date, dateType);
  return (
    <Col span="12" className="expenseStats">
      <CreditDebitStats />
      <div className="expenseStats_body cover">
        <div className="expenseStats_body_head">
          <p>
            Xarajatlar
            {isFetching && <LoadingOutlined style={{ marginLeft: "10px" }} />}
          </p>
          <Select
            style={{ width: "5rem", marginLeft: "auto", marginRight: "1rem" }}
            value={dateType}
            onChange={(value) => setDateType(value)}
          >
            {dateOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          {dateType === "year" ? (
            <DatePicker.RangePicker
              style={{ width: "14rem" }}
              onChange={(_m, date) => {
                setDate(date);
              }}
              picker="year"
              format="DD.MM.YYYY"
            />
          ) : dateType === "month" ? (
            <DatePicker.RangePicker
              style={{ width: "14rem" }}
              onChange={(_m, date) => {
                setDate(date);
              }}
              picker="month"
            />
          ) : (
            <DatePicker.RangePicker
              style={{ width: "14rem" }}
              // defaultValue={dayjs()}
              onChange={(_m, date) => {
                setDate(date);
              }}
              picker="date"
              format="DD.MM.YYYY"
            />
          )}
        </div>
        {/* <LineGraphExpense data={expenses} isLoading={isLoading} /> */}
        <ResponsiveBarExpense data={expenses} isLoading={isLoading} />
      </div>
    </Col>
  );
};

export default ExpenseStats;
