import React from "react";
import { toDateFormat } from "../../../../../helper/toDateFormat";

interface HeaderInterface {
  dateArr: string[];
  title: any;
}

const Header: React.FC<HeaderInterface> = ({ dateArr, title }) => {
  return (
    <div className={"actReconciliationHeader"}>
      <div className={"title"}>{title}</div>
      {dateArr.length > 0 && (
        <div className={"date"}>
          {toDateFormat(dateArr[0]) + " : " + toDateFormat(dateArr[1])}
        </div>
      )}
    </div>
  );
};

export default Header;
