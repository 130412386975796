import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";

import "./act.scss";
import Header from "./Header";
import Footer from "./Footer";
import { OneSupplierType } from "../../all/SupplierAllTypes";
import { queryNames } from "../../../../../queries/queryNames";
import { useActReconciliation } from "../../../../../queries/queries";
import { ActReconciliationInterface } from "../../../../../models/act/actReconciliationInterface";
import { toDateFormat } from "../../../../../helper/toDateFormat";

interface ActReconciliationModalInterface {
  id: string;
  dateArr: string[];
  open: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  brokers: number[];
}

const { Column } = Table;

const ActReconciliationModal: React.FC<ActReconciliationModalInterface> = ({
  id,
  dateArr,
  close,
  open,
  brokers,
}) => {
  const { data, isLoading } = useActReconciliation(id, dateArr, brokers);
  const [viewProducts, setViewProducts] = useState<boolean>(true);

  const closeFunc = () => {
    close(false);
  };

  const qc = useQueryClient();

  useEffect(() => {
    if (open) {
      qc.invalidateQueries([
        queryNames.ACT_RECONCILIATION,
        dateArr[0],
        dateArr[1],
        brokers,
      ]);
    }
  }, [qc, open, dateArr, brokers]);

  return (
    <Modal
      className={"actReconciliationModal"}
      width={900}
      footer={false}
      visible={open}
      onCancel={closeFunc}
      centered
    >
      <Header
        title={
          isLoading
            ? "Yuklanmoqda..."
            : `${
                qc.getQueryData<OneSupplierType>([
                  queryNames.SUPPLIER_DETAILS,
                  id,
                ])?.name ?? "Akt sverka"
              } (${data?.array.map((broker) => ` ${broker.name} `)})`
        }
        dateArr={dateArr}
      />
      <Table
        id={"actReconciliationModal"}
        className={"table supplier-table"}
        pagination={false}
        dataSource={data?.data || []}
        loading={isLoading}
        scroll={{ y: "70vh" }}
      >
        <Column
          onCell={() => ({
            style: {
              verticalAlign: "baseline",
              fontWeight: 600,
            },
          })}
          key={"date"}
          title={"SANA"}
          dataIndex={"date"}
          align={"center"}
          width="7rem"
          render={(date) => <>{toDateFormat(date?.split(" ")[0])}</>}
        />
        <Column
          key={"description"}
          title={
            <>
              <span
                style={{
                  display: "inline-block",
                  marginRight: "1rem",
                }}
              >
                IZOH
              </span>
              {isLoading && <LoadingOutlined />}
            </>
          }
          dataIndex={"description"}
          render={(description, rootData: ActReconciliationInterface) => (
            <div>
              <p style={{ margin: 0, fontWeight: 600, fontSize: "1rem" }}>
                {description}
              </p>
              {viewProducts && rootData?.productList.length > 0 ? (
                <>
                  <hr />
                  <div>
                    {rootData?.productList.map((product, index) => (
                      <p
                        key={product.name}
                        style={{ margin: 0, display: "flex" }}
                      >
                        <span>
                          {index + 1 + ". "}
                          <span style={{ fontWeight: 600 }}>
                            {product.name}
                          </span>
                          {" (" + product.building_name + ")"}
                        </span>
                        <span style={{ marginLeft: "auto" }}>
                          {
                            product.count +
                              " " +
                              product.product_type +
                              " x " +
                              product.price.toLocaleString("ru") +
                              " "
                            // +
                            // (rootData.summa
                            //   ? rootData.summa?.split(" ")[1]
                            //   : rootData.type?.split(" ")[1])
                          }
                        </span>
                      </p>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          )}
        />
        <Column
          width={150}
          onCell={() => ({
            style: {
              verticalAlign: "baseline",
              fontWeight: 600,
            },
          })}
          key={"debit"}
          title={"DEBIT"}
          dataIndex={"summa"}
          render={(data, allData: ActReconciliationInterface) => {
            const type = allData.type;
            const debit = data?.split(" ");
            if (debit && type === "debit") {
              return `${parseFloat(debit[0]).toLocaleString()} ${debit[1]}`;
            }
          }}
        />
        <Column
          width={150}
          onCell={() => ({
            style: {
              verticalAlign: "baseline",
              fontWeight: 600,
            },
          })}
          key={"kredit"}
          title={"KREDIT"}
          render={(data: ActReconciliationInterface) => {
            const type = data.type;
            const debit = data.summa?.split(" ");
            if (type !== "debit" && debit) {
              return `${parseFloat(debit[0]).toLocaleString("ru")} ${debit[1]}`;
            }
          }}
        />
      </Table>
      <Footer
        debit_sum={data?.debit_sum || 0}
        kredit_sum={data?.kredit_sum || 0}
        closeFunc={closeFunc}
        viewProducts={viewProducts}
        setViewProducts={setViewProducts}
      />
    </Modal>
  );
};

export default ActReconciliationModal;
