import { Button, DatePicker, Input, Tag } from "antd";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  DATE_FORMAT,
  RES_DATE_FORMAT,
} from "../../../../models/settings/dateFormat";
import { useDownloadConversion } from "../../../../queries/mutations";
import ExelFileIcon from "../../../../svg/ExelFileIcon";
import SearchIcon from "../../../../svg/SearchIcon";

import styles from "./conversionFilter.module.scss";

type Props = {
  children: ReactNode;
};

const ConversionFilter: React.FC<Props> = ({ children }) => {
  const green = "#34DBA1";
  const location = useLocation();
  const history = useHistory();
  const downloadExcel = useDownloadConversion();
  const [time, setTime] = useState<NodeJS.Timer>();
  const [searchValue, setSearchValue] = useState("");
  const queryParam = new URLSearchParams(location.search);
  const [visibleExcelButton, setVisibleExcelButton] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    setVisibleExcelButton(
      Boolean(queryParams.get("search") || queryParams.get("begin"))
    );

    if (queryParams.get("search")) {
      setSearchValue(queryParams.get("search") ?? "");
    }
  }, [location.search]);

  const onChangeExtraDate = (start: Dayjs, finish: Dayjs) => {
    const queryValue = new URLSearchParams(location.search);

    queryValue.set("begin", start.format(RES_DATE_FORMAT));
    queryValue.set("end", finish.format(RES_DATE_FORMAT));

    history.push({
      search: queryValue.toString(),
    });
    setVisibleExcelButton(true);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const queryValue = new URLSearchParams(location.search);
    setSearchValue(value);
    clearTimeout(time);

    if (value) {
      queryValue.set("search", value);

      setTime(
        setTimeout(() => {
          history.push({
            search: queryValue.toString(),
          });
          setVisibleExcelButton(true);
        }, 800)
      );
    } else {
      queryValue.delete("search");
      history.push({
        search: queryValue.toString(),
      });
    }
  };

  const extraContent = () => (
    <div className="extraFooter">
      <Tag onClick={() => onChangeExtraDate(dayjs(0), dayjs())} color={green}>
        Shu kungacha
      </Tag>
      <Tag onClick={() => onChangeExtraDate(dayjs(), dayjs())} color={green}>
        Bugun
      </Tag>
      <Tag
        onClick={() =>
          onChangeExtraDate(
            dayjs().subtract(1, "day"),
            dayjs().subtract(1, "day")
          )
        }
        color={green}
      >
        Kecha
      </Tag>
      <Tag
        onClick={() =>
          onChangeExtraDate(
            dayjs().startOf("month").subtract(1, "month"),
            dayjs().endOf("month").subtract(1, "month")
          )
        }
        color={green}
      >
        Oldingi oy
      </Tag>
      <Tag
        onClick={() =>
          onChangeExtraDate(dayjs().startOf("month"), dayjs().endOf("month"))
        }
        color={green}
      >
        Joriy oy
      </Tag>
      <Tag
        onClick={() =>
          onChangeExtraDate(dayjs().startOf("year"), dayjs().endOf("year"))
        }
        color={green}
      >
        Joriy yil
      </Tag>
    </div>
  );

  const onChangeDate = (start?: string, finish?: string) => {
    const queryValue = new URLSearchParams(location.search);

    if (start && finish) {
      queryValue.set("begin", start);
      queryValue.set("end", finish);
      setVisibleExcelButton(true);
    } else {
      queryValue.delete("begin");
      queryValue.delete("end");
    }

    history.push({
      search: queryValue.toString(),
    });
  };

  const onDownloadExcel = () => {
    downloadExcel.mutate();
  };

  // const dateValue = useMemo(() => {
  //   const queryParam = new URLSearchParams(location.search);
  //   const begin = queryParam.get("begin");
  //   const end = queryParam.get("end");

  //   if (begin) return [moment(begin), moment(end)];
  //   else return [undefined, undefined];
  // }, [location.search]);

  return (
    <div className={styles.filter}>
      <div className={styles.filter__left}>
        <Input
          placeholder="Qidirish"
          onChange={onSearch}
          value={searchValue}
          suffix={<SearchIcon />}
          className={styles.search}
        />
        <DatePicker.RangePicker
          format={DATE_FORMAT}
          value={[
            queryParam.get("begin")
              ? moment(queryParam.get("begin"))
              : moment(),
            queryParam.get("end") ? moment(queryParam.get("end")) : moment(),
          ]}
          onChange={(e) =>
            onChangeDate(
              e?.[0]?.format(RES_DATE_FORMAT),
              e?.[1]?.format(RES_DATE_FORMAT)
            )
          }
          placeholder={["03.03.2023", "04.04.2024"]}
          renderExtraFooter={extraContent}
        />
        {visibleExcelButton && (
          <Button onClick={onDownloadExcel} loading={downloadExcel.isLoading}>
            <ExelFileIcon /> Excel yuklab olish
          </Button>
        )}
      </div>
      <div className={styles.filter__right}>{children}</div>
    </div>
  );
};

export default ConversionFilter;
