import { LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, Spin, Table } from 'antd'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useDeletePayment } from '../../../../queries/mutations'
import { useSettingPayment } from '../../../../queries/queries'
import { isOpenSettingEditModal } from '../../../../redux/actions'
import { Garbage } from '../../../../svg/Garbage'
import { NotePencil } from '../../../../svg/NotePencil'
import CreateAndEditModal from './CreateAndEditModal'
import PaginationComp from '../../universialComponents/PaginationComp'
import {RootState} from "../../../../redux/rootReducer";

const { Column } = Table

const Payment = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [deleteId, setDeleteId] = useState(0)
  const [paymentId, setPaymentId] = useState(0)

  const dispatch = useDispatch()
  const searchString = useSelector((state:RootState) => state.searchHeader.searchString)


  const deletePayment = useDeletePayment(setDeleteId, currentPage)
  const { data, isLoading, isFetching } = useSettingPayment(currentPage,searchString)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchString]);

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id)
      await deletePayment.mutateAsync(id)
      if (data?.data.length === 1 && data.current_page !== 1)
        setCurrentPage(currentPage - 1)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Table
        pagination={false}
        dataSource={data?.data || []}
        loading={isLoading}
        rowKey={(record) => record.id!}
      >
        <Column
          key={'key'}
          width={'5%'}
          title={'#'}
          align={'center'}
          render={(record, i, index) => (
            <span>{(currentPage - 1) * 10 + index + 1}</span>
          )}
        />
        <Column
          key={'name'}
          width={'10%'}
          title={'NOMI'}
          dataIndex={'name'}
          align={'center'}
        />
        <Column
          key={'color'}
          width={'75%'}
          title={
            <>
              <span style={{ marginRight: '1rem' }}>RANGI</span>
              {isFetching && <LoadingOutlined />}
            </>
          }
          dataIndex={'color'}
          align={'left'}
          render={(color) => (
            <span
              style={{
                display: 'inline-block',
                width: '14px',
                height: '14px',
                borderRadius: '50%',
                backgroundColor: color,
              }}
            />
          )}
        />

        <Column
          key={'action'}
          width={'10%'}
          title={'ACTION'}
          align={'center'}
          render={(record) => (
            <div>
              <span title="O'zgartirish">
                <NotePencil
                  onClick={() => {
                    setPaymentId(record.id)
                    dispatch(isOpenSettingEditModal(true))
                  }}
                  className={'tableIcon'}
                />
              </span>
              {deletePayment.isLoading && deleteId === record.id ? (
                <Spin />
              ) : (
                <Popconfirm
                  placement="left"
                  title="O'chirshni xohlaysizmi?"
                  onConfirm={() => deleteFunc(record.id)}
                  okText="Xa"
                  cancelText="Yoq"
                >
                  <span title="O'chirish">
                    <Garbage className={'tableIcon'} />
                  </span>
                </Popconfirm>
              )}
            </div>
          )}
        />
      </Table>
      <CreateAndEditModal
        currentPage={currentPage}
        setPaymentId={setPaymentId}
        currentPaymentId={paymentId}
      />
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          onChange: (e) => {
            setCurrentPage(e)
          },
        }}
      />
    </>
  )
}

export default Payment
