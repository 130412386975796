import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

import { CheckoutViewType } from "../../../../models/checkout/CheckoutPaymentModel";
import { AllPaymentsModel } from "../../../../models/uysot/UysotPaymentViewModel";
import styles from "./card.module.scss";

type Props = {
  data?: any[] | undefined;
  title?: string;
  uysot?: {
    sum?: number;
    current_sum?: number;
  };
};

const GeneralCard: React.FC<Props> = ({ data, title, uysot }) => {
  const price = (): number | undefined => {
    let sum = data?.reduce((pre, cur) => {
      return pre + cur?.price ?? 0;
    }, 0);

    if (uysot?.sum) {
      sum = sum! + uysot?.sum;
    }

    return sum;
  };

  const price$ = (): number | undefined => {
    let sum$ = data?.reduce((pre, cur) => {
      return pre + cur?.price_currency ?? 0;
    }, 0);

    if (uysot?.current_sum) {
      sum$ = sum$! + uysot?.current_sum;
    }

    return sum$;
  };

  return (
    <Spin spinning={false} indicator={<LoadingOutlined />}>
      <div
        className={`${styles.card} ${styles.uysot_card} ${styles.general_card}`}
      >
        <div className={styles.bottom}>
          <p>{title ? title : "Umumiy balans"}</p>
          <h3>
            {price()?.toLocaleString()}
            <span>So'm</span>
          </h3>
          <h3>
            {price$()?.toLocaleString()}
            <span>$</span>
          </h3>
        </div>
      </div>
    </Spin>
  );
};

export default GeneralCard;
