import { Button, Checkbox, Form, Input, message, Modal, Select } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import dayjs from "rc-picker/node_modules/dayjs";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { customCurrency } from "../../../../helper/CustomCurrency";
import { CheckoutModalEnums } from "../../../../models/checkout/CheckoutModalTypes";
import {
  CheckoutPaymentsCardType,
  CheckoutViewType,
} from "../../../../models/checkout/CheckoutPaymentModel";
import { CheckoutSearchKeys } from "../../../../models/checkout/CheckoutSearchKeys";
import { ExpenseInterface } from "../../../../models/interfaces/ExpenseInterface";
import { DATE_FORMAT } from "../../../../models/settings/dateFormat";
import { useCreateCheckout } from "../../../../queries/mutations";
import {
  useAllSuppliers,
  useBrokers,
  useExpense,
  useGetAllOBject,
  useUser,
  useValutaAll,
} from "../../../../queries/queries";
import { DatePicker } from "../../../../service/DatePicker";
import CloseIcon from "../../../../svg/CloseIcon";
import SelectIcon from "../../../../svg/SelectIcon";
import { SupplierType } from "../../supplier/all/SupplierAllTypes";
import styles from "./checkoutModal.module.scss";

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

type Props = {
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      data: CheckoutViewType;
      payment: number;
    }>
  >;
  visible: {
    visible: boolean;
    data: CheckoutViewType;
    payment: number;
  };
  onePayment: CheckoutPaymentsCardType | undefined;
  setOnePayment: React.Dispatch<
    React.SetStateAction<CheckoutPaymentsCardType | undefined>
  >;
};

type FormType = {
  supplier_id: number;
  date: Date;
  price: string;
  payment_type_id: number;
  valuta_id: number;
  description: string;
};

const CheckoutModal: React.FC<Props> = ({
  setVisible,
  visible,
  onePayment,
  setOnePayment,
}) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const { data: allValuta } = useValutaAll();

  const [allPriceState, setAllPriceState] = useState<boolean>(false);
  const [supplerType, setSuplerType] = useState<boolean>(false);
  const [supleirId, setSupleirId] = useState<number>(0);
  const [valutaValue, setValutaValue] = useState<{
    visibleValutaHistoryPrice: boolean;
    valutaHistoryPrice?: string;
    valuta_id?: number;
  }>({
    valutaHistoryPrice: "1",
    visibleValutaHistoryPrice: false,
    valuta_id: 1,
  });

  const type = location.search.replace("?", "").split("=")[1];
  const key = location.search.replace("?", "").split("=")[0];

  const { data: Expense, isLoading: isLoadingExpense } = useExpense(
    1,
    "",
    true
  );
  const { data: Supplier, isLoading: isLoadingSupplier } = useAllSuppliers(
    1,
    "",
    10,
    true
  );
  const { data: getObjects, isLoading: getObjectsLoading } = useGetAllOBject();
  const { data: Users, isLoading: isLoadingUsers } = useUser(1, "", true);
  const { data: brokers, isLoading: isLoadingBrokers } = useBrokers(
    String(supleirId)
  );

  // set from values
  useEffect(() => {
    if (visible.visible) {
      if (onePayment?.price) {
        form.setFieldsValue({
          payment_type_id: onePayment.payment_type_id,
          valuta_id: onePayment.valuta_id,
          date: dayjs(onePayment.date).locale("en"),
          building_id: onePayment.building_id,
          user_id: onePayment.user_id,
          supplier_id: onePayment?.supplier_id,
          expense_type_id: onePayment?.expense_type_id,
          price: customCurrency(String(onePayment?.price)),
          description: onePayment?.description,
        });
        setValutaValue({
          ...valutaValue,
          valutaHistoryPrice: String(onePayment.valuta_history_price) ?? "1",
          valuta_id: onePayment.valuta_id,
        });
        setSupleirId(onePayment?.supplier_id);
      } else {
        form.setFieldsValue({
          payment_type_id: visible.data.payment_type_id,
          valuta_history_price: customCurrency(String(1)),
        });
      }
    }
  }, [
    visible.visible,
    form,
    visible.data.payment_type_id,
    onePayment,
    visible.data.price,
  ]);

  // close modal
  const handleCloseModal = () => {
    setVisible({
      ...visible,
      visible: false,
    });
    setSuplerType(false);
    form.setFieldsValue({
      supplier_id: undefined,
      price: undefined,
      description: undefined,
      expense_type_id: undefined,
      user_id: undefined,
      building_id: undefined,
    });
    setSupleirId(0);
    setOnePayment(undefined);
    // history.push(location.pathname);
  };

  const createCheckout = useCreateCheckout(handleCloseModal);

  // disabled element
  const disabledElement = () => {
    if (onePayment?.price && visible.data.price === 0) return true;
    return false;
  };

  // change valuta history price
  const changeValutaHistoryPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValutaValue({
      ...valutaValue,
      valutaHistoryPrice: customCurrency(e.target.value),
    });
  };

  // title for modal
  const modalTitle = () => {
    const value = customCurrency(String(valutaValue.valutaHistoryPrice));
    // if (key === CheckoutSearchKeys.TYPE) {
    return (
      <>
        {type === CheckoutModalEnums.OUTLAY ? "Chiqim" : "Tushum"} qo'shish
        {valutaValue.valuta_id! === 2 ? (
          <div className={styles.valuta_history_price}>
            1 $ =
            <Input
              style={{
                borderColor: valutaValue.valutaHistoryPrice
                  ? "#d9d9d9"
                  : "#ff4d4f",
              }}
              placeholder="summa"
              value={value}
              onChange={changeValutaHistoryPrice}
              disabled={disabledElement()}
            />
            so'm
          </div>
        ) : (
          ""
        )}
      </>
    );
    // } else return "";
  };

  // on finish
  const onFinish = (e: FormType) => {
    let data: any = {
      ...e,
      kirim: 1,
      price: Number(e.price.replace(/\s/g, "")),
      date: dayjs(e.date).format("YYYY-MM-DD HH:mm:ss"),
      valuta_history_price: Number(
        valutaValue.valutaHistoryPrice?.replace(/\s/g, "")
      ),
    };

    if (key === CheckoutSearchKeys.TYPE && type === CheckoutModalEnums.OUTLAY) {
      data = {
        ...data,
        kirim: -1,
      };
    }

    if (valutaValue.valutaHistoryPrice) {
      createCheckout.mutate(data);
    } else message.warning("Valuta narxini kiriting!");
  };

  // validate
  const validate = [{ required: true, message: "" }];

  // label text
  const userNameLabel = () => {
    switch (type) {
      case CheckoutModalEnums.REVENUE:
        return "Manbaa";
      default:
        return (
          <span>
            Manbaa ( Ta'minotchi
            <Checkbox
              checked={supplerType}
              onChange={changeSupplerType}
              disabled={disabledElement()}
            />{" "}
            )
          </span>
        );
    }
  };

  // change supper type
  const changeSupplerType = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({
      supplier_id: undefined,
    });
    setSuplerType(e.target.checked);
  };

  // suppler options
  const SupplerOptions = () => {
    if (
      supplerType ||
      type === CheckoutModalEnums.REVENUE ||
      onePayment?.supplier_id
    ) {
      return Supplier?.map((item: SupplierType, index: number) => (
        <Option key={index} value={item?.id!}>
          {item?.name}
        </Option>
      ));
    } else {
      return Expense?.map((item: ExpenseInterface, index: number) => (
        <Option key={index} value={item?.id!}>
          {item?.name}
        </Option>
      ));
    }
  };

  // change price
  const changePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      price: e.target.value ? customCurrency(e.target.value) : undefined,
    });
  };

  // form item name
  const itemNameSuppleir = () => {
    if (onePayment?.expense_type_id) {
      return "expense_type_id";
    } else if (onePayment?.supplier_id) {
      return "supplier_id";
    } else {
      if (key === CheckoutSearchKeys.TYPE) {
        switch (type) {
          case CheckoutModalEnums.OUTLAY:
            return supplerType ? "supplier_id" : "expense_type_id";
          default:
            return "supplier_id";
        }
      }
    }
  };

  // change supplier
  const changeUserId = (e: number) => {
    const price = Supplier?.filter((item: SupplierType) => item.id === e)[0]
      ?.balans;
    if (allPriceState) {
      form.setFieldsValue({
        price: price ? customCurrency(String(price)) : undefined,
      });
    }

    type === CheckoutModalEnums.OUTLAY
      ? setSupleirId(supplerType ? e : 0)
      : setSupleirId(e);
  };

  // change all price for form price item
  const changeAllPrice = (e: CheckboxChangeEvent, price: number) => {
    const value: boolean = e.target.checked;
    setAllPriceState(value);

    if (value) {
      form.setFieldsValue({
        price: price ? customCurrency(String(price)) : undefined,
      });
    } else {
      form.setFieldsValue({
        price: undefined,
      });
    }
  };

  // return all balans
  const allBalans = () => {
    const price = Supplier?.filter(
      (item: SupplierType) => item.id === supleirId
    )[0]?.balans;
    return (
      supleirId > 0 && (
        <div className={styles.price}>
          <h4>
            Manbaa summasi:
            <span
              style={{
                marginLeft: "5px",
              }}
            >
              {price?.toLocaleString() ?? "0"}
              {"  "}
              so'm
            </span>
          </h4>
          {!disabledElement() && (
            <Checkbox
              checked={allPriceState}
              onChange={(e) => changeAllPrice(e, price)}
            >
              Balansni qoplash
            </Checkbox>
          )}
        </div>
      )
    );
  };

  // select filter option
  const selectFilterOption = (input: string, option: any | undefined) =>
    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // users select options
  const userOptions = () => {
    if (type === CheckoutModalEnums.OUTLAY && supleirId) {
      return brokers?.map(
        (
          broker: {
            debit: number;
            user_id: number;
            name: string;
          },
          index
        ) => (
          <Option value={broker?.user_id} key={index}>
            {broker?.name}
          </Option>
        )
      );
    } else
      return Users?.map((user: any) => (
        <Option value={user.id} key={user.id}>
          {user.name}
        </Option>
      ));
  };

  // change valuta
  const changeValuta = (e: number) => {
    setValutaValue({
      ...valutaValue,
      valutaHistoryPrice: e === 1 ? "1" : "11300",
      valuta_id: e,
    });
  };

  return (
    <Modal
      visible={visible.visible}
      className={styles.expense_modal}
      title={modalTitle()}
      onCancel={handleCloseModal}
      closeIcon={<CloseIcon />}
      centered
    >
      <Form
        form={form}
        className={styles.form}
        layout="vertical"
        onFinish={onFinish}
      >
        <div className={styles.top}>
          <Item
            label={userNameLabel()}
            name={itemNameSuppleir()}
            className="name"
            rules={validate}
          >
            <Select
              suffixIcon={<SelectIcon />}
              placeholder="Manbaani tanlang"
              showSearch
              loading={isLoadingExpense || isLoadingSupplier}
              onChange={changeUserId}
              optionFilterProp="children"
              filterOption={selectFilterOption}
              disabled={disabledElement()}
            >
              {SupplerOptions()}
            </Select>
          </Item>
          <Item label="Sana" name="date" className="date" rules={validate}>
            <DatePicker
              disabled={disabledElement()}
              format={DATE_FORMAT}
              placeholder="YIL-OY-SANA VAQT"
            />
          </Item>
        </div>
        <div className={styles.center}>
          <Item
            label="Summa"
            name="price"
            className={styles.price}
            rules={validate}
          >
            <Input
              onChange={changePrice}
              disabled={disabledElement()}
              min={0}
              minLength={1}
              placeholder="Summani kiriting"
            />
          </Item>
          <Item
            label="Valyuta"
            name="valuta_id"
            className={styles.valuta_name}
            initialValue={allValuta && allValuta[0]?.id!}
          >
            <Select
              suffixIcon={<SelectIcon />}
              disabled={disabledElement()}
              onChange={changeValuta}
              placeholder="Valuta turi"
            >
              {allValuta?.map((item) => (
                <Option key={item.id} value={item.id!}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            label="To'lov turi"
            name="payment_type_id"
            className={styles.payment_name}
          >
            <Select
              value={
                onePayment?.price
                  ? onePayment.payment_type_id
                  : visible.data.payment_type_id
              }
              disabled
              suffixIcon={<SelectIcon />}
            >
              <Option
                value={
                  onePayment?.price
                    ? onePayment.payment_type_id
                    : visible.data.payment_type_id
                }
              >
                {onePayment?.price
                  ? onePayment.payment_type_name
                  : visible.data.payment_type_name}
              </Option>
            </Select>
          </Item>
        </div>
        <div className={styles.building}>
          <Item name="building_id" label="Obyektni tanlang" rules={validate}>
            <Select
              suffixIcon={<SelectIcon />}
              placeholder="Obyektni tanlang"
              showSearch
              filterOption={selectFilterOption}
              loading={getObjectsLoading}
              disabled={disabledElement()}
            >
              {getObjects?.map((item, index) => (
                <Option value={item?.id!} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name="user_id" label="Xodim tanlang">
            <Select
              suffixIcon={<SelectIcon />}
              placeholder="Xodim tanlang"
              showSearch
              filterOption={selectFilterOption}
              loading={isLoadingUsers || isLoadingBrokers}
              disabled={disabledElement()}
              notFoundContent={"Xodimlar mavjud emas"}
            >
              {userOptions()}
            </Select>
          </Item>
        </div>
        <div className={styles.comments}>
          <Item label="Izoh" name="description" className={styles.comment}>
            <TextArea
              placeholder="Izohni kiriting"
              disabled={disabledElement()}
            />
          </Item>
        </div>
        {allBalans()}
        <div className={styles.bottom}>
          <Button onClick={handleCloseModal}>Chiqish</Button>
          {!disabledElement() && (
            <Button
              type="primary"
              htmlType="submit"
              loading={createCheckout.isLoading}
            >
              Saqlash
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default CheckoutModal;
