import { InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Grid, Input, Modal, Space } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useGetBuildings } from "../../../features/expense/services/queries";
import { CheckProductParams, useCheckProduct } from "../../../queries/queries";
import { isOpenProductGraph, setComment } from "../../../redux/actions";
import { RootState } from "../../../redux/rootReducer";
import StatisticsIcon from "../../../svg/StatisticsIcon";

interface OrderTableProductNameProps {
  values: CheckProductParams;
}

const { TextArea } = Input;

export const OrderTableProductName: React.FC<OrderTableProductNameProps> = ({
  values,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  // const currency = useSelector((state: RootState) => state.productList.valuta);
  // const { data: buildings } = useGetBuildings();
  const dispatch = useDispatch();
  const products = useSelector(
    (state: RootState) => state.productList.products
  );
  const { data } = useCheckProduct(values);
  const [text, setText] = useState("");

  const onProductComment = () => {
    const index = products.findIndex((item) => item.id === values.product_id);
    dispatch(setComment(text ?? "", index));
    setVisible(false);
  };

  const openModal = () => {
    setVisible(true);
    // data?.comment ? data?.comment :
    setText("");
  };

  const openProductGraph = () => {
    dispatch(isOpenProductGraph(true, values?.product_id));
  };

  const returnCommentText = () => {
    const productComment = products.filter(
      (item) => item.id === values.product_id
    )[0]?.comment;
    let textComment = "";
    if (data?.comment && !productComment) {
      textComment = data?.comment;
    } else {
      textComment = productComment ?? "";
    }

    return (
      textComment && (
        <div className="product_name_line">
          <p>Izoh:</p>
          <h4>{textComment}</h4>
        </div>
      )
    );
  };

  const { xxl } = Grid.useBreakpoint();

  return (
    <Space
      style={{
        width: "100%",
      }}
    >
      <div
        className="d_f cg-10 jc_sb"
        style={{
          width: xxl ? "12rem" : "9rem",
        }}
      >
        <span>{values.name}</span>
        <span className="cursor_p" onClick={openModal}>
          <InfoCircleTwoTone twoToneColor={data?.check ? "red" : "blue"} />
        </span>
      </div>
      <Modal
        visible={visible}
        onOk={onProductComment}
        onCancel={() => setVisible(false)}
        centered
        cancelText="Chiqish"
        okText="Saqlash"
        title={
          <div className="w_100 d_f ai_c jc_sb">
            <span>So’nggi kirim ma’lumotlari</span>
            <Button className="not_btn" onClick={openProductGraph}>
              <StatisticsIcon />
            </Button>
          </div>
        }
      >
        <div className="product_name_line">
          <p>Partiya:</p>
          <h4>#{data?.last_check}</h4>
        </div>
        <div className="product_name_line">
          <p>Narxi:</p>
          <h4>
            {Number(data?.last_price).toLocaleString()} {data?.valuta_name}
          </h4>
        </div>
        <div className="product_name_line">
          <p>Obyekt:</p>
          <h4>{data?.building_name}</h4>
        </div>
        <div className="product_name_line">
          <p>Sana:</p>
          <h4>{data?.last_date}</h4>
        </div>
        {returnCommentText()}
        <TextArea
          value={text!}
          placeholder="Izoh kiriting"
          onChange={(e) => setText(e.target.value)}
        />
      </Modal>
    </Space>
  );
};
