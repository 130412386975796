import { Dispatch } from "redux";
import { ProductInterface } from "../models/interfaces/ProductInterface";
import { ValutaInterface } from "../models/interfaces/ValutaInterface";
import { getLastItem } from "../service/Helpers";
import { SearchHeaderSearchString, SettingsAction } from "./actionTypes";
import { ProductListTableItem } from "./productListTypes";
import { RootState } from "./rootReducer";
import {
  ALL_SELECET_WAREHOUSES,
  EXPENCE_COPY,
  HISTORY_SET_HISTORY,
  ORDER_ID_REDUCER,
  PICTURE_VISIBLE,
  PRODUCT_GRAPH,
  PRODUCT_LIST_ADD,
  PRODUCT_LIST_ADD_CHECKLIST,
  PRODUCT_LIST_BUILDING_FOR_ALL,
  PRODUCT_LIST_COUNT_CHANGE,
  PRODUCT_LIST_DATE_FOR_ALL,
  PRODUCT_LIST_FILTER,
  PRODUCT_LIST_NDS,
  PRODUCT_LIST_PRICE_CHANGE,
  PRODUCT_LIST_REMOVE,
  PRODUCT_LIST_RESET,
  PRODUCT_LIST_SET_BUILDING,
  PRODUCT_LIST_SET_BUILDING_ID,
  PRODUCT_LIST_SET_CHECKED,
  PRODUCT_LIST_SET_CHECKLIST,
  PRODUCT_LIST_SET_COMMENT,
  PRODUCT_LIST_SET_PRODUCTS,
  PRODUCT_LIST_SET_VALUTA,
  SEARCH_HEADER_SEARCH_STRING,
  SEARCH_HEADER_TOGGLE_SUPPLIER_MODAL,
  SETTINGS_OPEN_EDIT_MODAL,
  WAREHOUSE_CARD_ID,
} from "./types";

export const isOpenSettingEditModal = (isOpen: boolean) => {
  return (dispatch: Dispatch<SettingsAction>) => {
    dispatch({
      type: SETTINGS_OPEN_EDIT_MODAL,
      payload: isOpen,
    });
  };
};

// supplier page CU modal toggle
export const toggleSupplierCUModal = (isOpen: boolean) => {
  return {
    type: SEARCH_HEADER_TOGGLE_SUPPLIER_MODAL,
    payload: isOpen,
  };
};

export const searchHeaderSearchString = (text: string) => {
  return (dispatch: Dispatch<SearchHeaderSearchString>) => {
    dispatch({
      type: SEARCH_HEADER_SEARCH_STRING,
      payload: text,
    });
  };
};

// product list actions
export const addToList =
  (product: ProductListTableItem, mutation?: any) => async (dispatch: any) => {
    dispatch({
      type: PRODUCT_LIST_ADD,
      payload: { product },
    });
  };
export const setProductList = (products: ProductListTableItem[]) => ({
  type: PRODUCT_LIST_SET_PRODUCTS,
  payload: { products },
});
export const addNewProduct = (product: ProductInterface) => ({
  type: PRODUCT_LIST_ADD_CHECKLIST,
  payload: { product },
});
//
export const removeFromList = (productId: number) => ({
  type: PRODUCT_LIST_REMOVE,
  payload: productId,
});
export const setCheckList = (products: ProductInterface[]) => ({
  type: PRODUCT_LIST_SET_CHECKLIST,
  payload: products,
});
export const filterProducts = (input: string) => ({
  type: PRODUCT_LIST_FILTER,
  payload: input,
});
export const setCount = (count: string, index: number) => ({
  type: PRODUCT_LIST_COUNT_CHANGE,
  payload: { count, index },
});
export const setPrice = (price: string, index: number) => ({
  type: PRODUCT_LIST_PRICE_CHANGE,
  payload: { price, index },
});
export const setNds = (number: number, index: number) => ({
  type: PRODUCT_LIST_NDS,
  payload: { number, index },
});
export const setValuta = (valuta: ValutaInterface) => ({
  type: PRODUCT_LIST_SET_VALUTA,
  payload: valuta,
});
export const setComment = (comment: string, index: number) => ({
  type: PRODUCT_LIST_SET_COMMENT,
  payload: { comment, index },
});
export const setPaymentAndValuta = (
  valuta_id: number,
  payment_type_id: number
) => ({
  type: "",
  payload: { valuta_id, payment_type_id },
});
export const setCurrentBuildingId = (id: number) => ({
  type: PRODUCT_LIST_SET_BUILDING_ID,
  payload: id,
});

export const resetProductList = () => ({
  type: PRODUCT_LIST_RESET,
});

export const setBuilding = (building_id: number, index: number) => ({
  type: PRODUCT_LIST_SET_BUILDING,
  payload: { building_id, index },
});

export const isOpenProductGraph = (isOpen: boolean, id: number) => ({
  type: PRODUCT_GRAPH,
  payload: {
    isOpen,
    id,
  },
});

export const setHistoryArr =
  (next_day: string) => (dispatch: any, getState: () => RootState) => {
    const prev = getState().productGraph.historyArr;
    if (getLastItem(prev) === "last" || prev.includes(next_day)) {
      dispatch({ payload: prev, type: HISTORY_SET_HISTORY });
      return;
    }
    dispatch({
      payload: [...prev, next_day || "last"],
      type: HISTORY_SET_HISTORY,
    });
  };

// set product checked
export const setProductChecked = (data: {
  id: number;
  check: number | null;
  last_price: number | null;
  building_name: string | undefined;
}) => ({
  type: PRODUCT_LIST_SET_CHECKED,
  payload: data,
});

export const setBuildingForAll = (building_id: number) => {
  return {
    type: PRODUCT_LIST_BUILDING_FOR_ALL,
    payload: building_id,
  };
};

export const setDateForAll = (date: string) => {
  return {
    type: PRODUCT_LIST_DATE_FOR_ALL,
    payload: date,
  };
};

export const WarehouseIdAction = (id: number[]) => ({
  type: WAREHOUSE_CARD_ID,
  payload: { id },
});

export const allSelectWarehouseAction = (select: boolean) => ({
  type: ALL_SELECET_WAREHOUSES,
  payload: {
    select,
  },
});

export const pictureVisibleAction = (state: boolean) => ({
  type: PICTURE_VISIBLE,
  payload: state,
});

export const orderIdAction = (copy: boolean) => ({
  type: ORDER_ID_REDUCER,
  payload: { copy },
});

export const expenceCopyAction = (copy: boolean) => ({
  type: EXPENCE_COPY,
  payload: { copy },
});
