import { MetaActions } from './actionTypes'
import {
  SEARCH_HEADER_CREATE_MODAL,
  SEARCH_HEADER_SEARCH_STRING,
  SEARCH_HEADER_TOGGLE_SUPPLIER_MODAL,
} from './types'

const initialState = {
  openCreateModal: false,
  searchString: '',
  isSupplierModalOpen: false,
}

const searchHeaderReducer = (state = initialState, action: MetaActions) => {
  switch (action.type) {
    case SEARCH_HEADER_CREATE_MODAL:
      return { ...state, openCreateModal: action.payload }

    case SEARCH_HEADER_SEARCH_STRING:
      return { ...state, searchString: action.payload }

    case SEARCH_HEADER_TOGGLE_SUPPLIER_MODAL:
      return { ...state, isSupplierModalOpen: action.payload }

    default:
      return state
  }
}

export default searchHeaderReducer
