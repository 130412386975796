import { LoadingOutlined } from "@ant-design/icons";
import { Popconfirm, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ASC, DESC, KEY, SORT } from "../../../../helper/paramNames";
import { useDeleteSupplier } from "../../../../queries/mutations";
import { useAllSuppliers } from "../../../../queries/queries";
import { toggleSupplierCUModal } from "../../../../redux/actions";
import { RootState } from "../../../../redux/rootReducer";
import mainPaths from "../../../../routes/main/mainPaths";
import { rootPaths } from "../../../../routes/rootRoutes";
import ArrowDownUpIcon from "../../../../svg/ArrayDownUpIcon";
import ArrowUpIcon from "../../../../svg/ArrayUpIcon";
import ArrowDownIcon from "../../../../svg/ArrowDownIcon";
import { Garbage } from "../../../../svg/Garbage";
import { NotePencil } from "../../../../svg/NotePencil";
import { Star } from "../../../../svg/Star";
import PaginationComp from "../../universialComponents/PaginationComp";
import { SupplierCUModal } from "./SupplierCUModal";

const { Column } = Table;

const SuppliersTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [supplierId, setSupplierId] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams("key=&sort=");
  const searchParams =
    location.search.replace("?", "").split("=")[0] === KEY &&
    location.search.slice(1, location.search.length);

  const searchString = useSelector(
    (state: RootState) => state.searchHeader.searchString
  );

  const { data, isLoading } = useAllSuppliers(
    currentPage,
    searchString,
    perPage,
    false,
    searchParams
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchString]);

  //mutation
  const deleteSupplier = useDeleteSupplier();
  const deleteFunc = (id: number) => {
    deleteSupplier.mutate(id);
  };

  const changeSortData = (key: string) => {
    params.set(KEY, key);
    params.set(SORT, location.search.split("=")[2] === ASC ? DESC : ASC);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  // table header icon
  const tableHeaderSortIcon = (sortName: string) => {
    const sortType = location.search && location.search.split("=")[2];
    const sortKey =
      location.search && location.search.split("=")[1].split("&")[0];
    if (sortName === sortKey) {
      if (sortType === "desc") return <ArrowUpIcon />;
      else return <ArrowDownIcon />;
    } else return <ArrowDownUpIcon />;
  };

  // table loading content
  const loadingTable = {
    spinning: isLoading,
    indicator: <LoadingOutlined />,
  };
  return (
    <>
      <Table
        rowKey={(record) => record.id}
        className={"table supplier-table"}
        pagination={false}
        dataSource={data?.data}
        loading={loadingTable}
        onRow={(record) => ({
          className: "table-row",
          onClick: () => {
            history.push(`${rootPaths.MAIN}${mainPaths.SUPPLIER}/${record.id}`);
          },
        })}
      >
        <Column
          key={"key"}
          title={"#"}
          dataIndex={"key"}
          render={(record, i, index) => (
            <span>{(currentPage - 1) * 10 + index + 1}</span>
          )}
          align={"center"}
        />
        <Column
          width={"25%"}
          key={"supplier"}
          title={
            <span>
              TA’MINOTCHI
              {tableHeaderSortIcon("name")}
            </span>
          }
          dataIndex={"name"}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("name");
              },
            };
          }}
        />
        <Column
          key={"balans"}
          title={() => {
            return <span>BALANS {tableHeaderSortIcon("balans")}</span>;
          }}
          dataIndex={"balans"}
          render={(balans) => (
            <span>{parseFloat(balans).toLocaleString("ru")} so'm</span>
          )}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("balans");
              },
            };
          }}
        />
        <Column
          key={"phone"}
          width={"15%"}
          title={() => (
            <span>TELEFON RAQAMI {tableHeaderSortIcon("phone")}</span>
          )}
          dataIndex={"phone"}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("phone");
              },
            };
          }}
        />
        <Column
          key={"rating"}
          width={"10%"}
          title={() => <span>REYTING {tableHeaderSortIcon("rating")}</span>}
          dataIndex={"rating"}
          align="center"
          render={(rating) => (
            <>
              <span
                style={{
                  display: "inline-block",
                  marginRight: ".25rem",
                }}
              >
                {rating || 0}
              </span>
              <Star />
            </>
          )}
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("rating");
              },
            };
          }}
        />
        {/* <Column
          key={"extra_phone"}
          width={"20%"}
          title={() => (
            <span>
              QO'SHIMCHA TEL. RAQAM {tableHeaderSortIcon("extra_phone")}
            </span>
          )}
          dataIndex={"extra_phone"}
          render={(ep) => ep || "-"}
          align="center"
          onHeaderCell={() => {
            return {
              onClick: () => {
                changeSortData("extra_phone");
              },
            };
          }}
        /> */}
        <Column
          key={"action"}
          width={"10%"}
          title={"ACTION"}
          align={"center"}
          render={(_, all: any) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                onClick={(e: any) => {
                  e.stopPropagation();
                  setSupplierId(all.id);
                  dispatch(toggleSupplierCUModal(true));
                }}
                className="table-edit"
              >
                <NotePencil className={"tableIcon"} />
              </span>
              <Popconfirm
                placement="left"
                title="O'chirshni xohlaysizmi?"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  deleteFunc(all.id);
                }}
                okButtonProps={{
                  danger: true,
                  loading: deleteSupplier.isLoading,
                }}
                okText="Xa"
                cancelText="Yoq"
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="table-edit"
                >
                  <Garbage className={"tableIcon"} />
                </span>
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          onChange: (page) => {
            setCurrentPage(page);
          },
        }}
        per_page={perPage}
        setPerPage={setPerPage}
        showSizeChanger={true}
      />
      <SupplierCUModal
        page={currentPage}
        setSupplierId={setSupplierId}
        supplierId={supplierId}
      />
    </>
  );
};

export default SuppliersTable;
