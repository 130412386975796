import React from 'react'

export const ChartLineUp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 19.5H3V4.5"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 6L12 13.5L9 10.5L3 16.5"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 9.75V6H15.75"
        stroke="#717982"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
