import {
  CloseOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useSupplier } from "../../../../queries/queries";
import SortIcon from "../../../../svg/SortIcon";
import SearchHeader from "../../universialComponents/SearchHeader";
import OneSupplierTable from "./OneSupplierTable";
import "./OneSupplier.scss";
import Card from "./Card";
import { useHistory } from "react-router-dom";
import BrokerListModal from "./BrokerListModal";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

const OneSupplierCont = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const [filter, setFilter] = useState<string>("");
  const { data, isLoading, isFetching } = useSupplier(params?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const filterFunc = (key: string) => {
    setFilter(key);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => filterFunc("new")} key={"new"}>
        To'lanmagan
      </Menu.Item>
      <Menu.Item onClick={() => filterFunc("doing")} key={"doing"}>
        Chala to'langan
      </Menu.Item>
      <Menu.Item onClick={() => filterFunc("cancelled")} key={"cancelled"}>
        To'langan
      </Menu.Item>
    </Menu>
  );

  const getFilterNameFunc = (str: string) => {
    if (str === "new") return "To'lanmagan";
    if (str === "doing") return "Chala to'langan";
    return "To'langan";
  };

  useDocumentTitle("Ta'minotchi yaratish");

  return (
    <>
      <SearchHeader
        title={
          <>
            <span
              onClick={() => {
                history.push("/main/supplier");
              }}
              style={{ cursor: "pointer" }}
            >
              Ta'minotchilar
            </span>
            <span
              style={{
                display: "inline-block",
                fontSize: "1.25rem",
                marginLeft: "1rem",
              }}
            >
              {isLoading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <RightOutlined />
                  <span style={{ margin: " 0 1rem", fontSize: "1.75rem" }}>
                    {data?.name}
                  </span>
                  {isFetching && <LoadingOutlined />}
                </>
              )}
            </span>
          </>
        }
        buttons={
          <div style={{ display: "flex" }}>
            <Dropdown trigger={["click"]} overlay={menu} placement="bottomLeft">
              <Button
                className="button"
                style={{
                  width: "170px",
                }}
                icon={<SortIcon />}
              >
                {filter ? getFilterNameFunc(filter) : "Saralash"}
              </Button>
            </Dropdown>
            {filter !== "" && (
              <Button type="text" danger onClick={() => setFilter("")}>
                <CloseOutlined color="red" />
              </Button>
            )}
          </div>
        }
      />
      <div className="supplier_body">
        <div style={{ paddingRight: "1rem" }}>
          <Card isLoading={isLoading} id={params.id} data={data} />
          <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
            size="large"
            type="primary"
            style={{ width: "100%" }}
          >
            Akt sverka
          </Button>
          <BrokerListModal
            supplierId={params.id}
            visible={isModalOpen}
            setVisible={setIsModalOpen}
            brok={data!}
          />
          {/* <ActReconciliation id={params.id} /> */}
        </div>
        <OneSupplierTable filter={filter} id={params.id} />
      </div>
    </>
  );
};

export default OneSupplierCont;
