import { Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSupplier } from "../../../../queries/queries";
import { Star } from "../../../../svg/Star";
import { SupplierOrder } from "../all/SupplierAllTypes";
import WarningCircle from "../../../../svg/WarningCircle";
import XCircle from "../../../../svg/XCircle";
import TableDownIcon from "../../../../svg/TableDownIcon";
import {
  calculateOne,
  calculateResidue
} from "../../../../service/calculateResidue";
import OrderDetails from "./OrderDetails";
import dayjs from "rc-picker/node_modules/dayjs";
import PaginationComp from "../../universialComponents/PaginationComp";

const { Column } = Table;

interface OneSupplierTableProps {
  id: string;
  filter: string;
}

const OneSupplierTable: React.FC<OneSupplierTableProps> = ({ id, filter }) => {
  const [selectedItemsArr, setSelectedItemsArr] = useState<SupplierOrder[]>([]);
  const [tableArr, setTableArr] = useState<SupplierOrder[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data, isLoading } = useSupplier(id, pageNumber);

  useEffect(() => {
    const newDataArr: SupplierOrder[] = [];
    if (filter) {
      switch (filter) {
        case "new":
          data?.orders.forEach((order) => {
            const residue = calculateOne(
              order?.price,
              order?.payment_sum,
              order.valuta_history_price,
              order.valuta_history_price
            );
            if (residue === order?.price) {
              newDataArr.push(order);
            }
          });
          break;
        case "doing":
          data?.orders.forEach((order) => {
            const residue = calculateOne(
              order?.price,
              order?.payment_sum,
              order.valuta_history_price,
              order.valuta_history_price
            );
            if (residue !== order?.price && residue !== 0) {
              newDataArr.push(order);
            }
          });
          break;
        case "cancelled":
          data?.orders.forEach((order) => {
            const residue = calculateOne(
              order?.price,
              order?.payment_sum,
              order.valuta_history_price,
              order.valuta_history_price
            );
            if (residue === 0) {
              newDataArr.push(order);
            }
          });
          break;
      }
      setTableArr(newDataArr);
    } else {
      setTableArr(data?.orders ? data.orders : []);
    }

    // eslint-disable-next-line
  }, [filter, data]);

  const actionFunc = (record: SupplierOrder) => {
    const residue = calculateOne(
      record?.price,
      record?.payment_sum,
      record.valuta_history_price,
      record.valuta_history_price
    );
    if (residue === record.price)
      return (
        <div className={"errorTag"}>
          <XCircle />
          <span>To‘lov</span>
        </div>
      );
    if (residue <= 0)
      return (
        <div className={"successTag"}>
          <span>To‘langan</span>
        </div>
      );
    return (
      <div className={"warningTag"}>
        <WarningCircle />
        <span>To‘lov</span>
      </div>
    );
  };

  const rowSelection = {
    onSelect: (
      record: SupplierOrder,
      selected: boolean,
      selectedRows: SupplierOrder[]
    ) => {
      setSelectedItemsArr(selectedRows);
    },
    onSelectAll: (selected: boolean, selectedRows: SupplierOrder[]) => {
      setSelectedItemsArr(selectedRows);
    },
    getCheckboxProps: (record: SupplierOrder) => {
      const payed = record?.payment_sum
        ? record?.payment_sum * record.valuta_history_price
        : 0;
      const residue = record.price - payed;
      if (residue === 0) {
        return {
          disabled: true
        };
      }
      return {
        disabled: false
      };
    }
  };

  const sumResidues = () => {
    const { residue, valuta } = calculateResidue(selectedItemsArr);
    return `${residue.toLocaleString("ru")} ${valuta}`;
  };

  return (
    <div
      style={{
        width: "calc(100% - 23rem)"
      }}
    >
      {selectedItemsArr.length > 0 && (
        <div className={"oneSupplierTableHeader"}>
          <div className={"cont"}>
            <Typography className={"title"}>
              Tanlangan: {selectedItemsArr.length} ta partya
            </Typography>
          </div>
          <div className={"cont"}>
            <Typography className={"sum"}>
              Umumiy qoldiq summa: {sumResidues()}
            </Typography>
          </div>
        </div>
      )}
      <Table
        pagination={false}
        expandable={{
          expandIconColumnIndex: 8,
          expandIcon: ({ expanded, onExpand, record }) => {
            if (expanded)
              return (
                <span
                  onClick={(e) => onExpand(record, e)}
                  style={{
                    padding: "5px",
                    color: "red",
                    cursor: "pointer"
                  }}
                >
                  <TableDownIcon style={{ transform: "rotate(180deg)" }} />
                </span>
              );
            return (
              <span
                onClick={(e) => onExpand(record, e)}
                style={{ padding: "5px", color: "red", cursor: "pointer" }}
              >
                <TableDownIcon />
              </span>
            );
          },
          expandedRowRender: (record, index, indent, expanded) => {
            if (expanded)
              return <OrderDetails valuta={record.valuta} id={record.id} />;
            else return null;
          },
          expandRowByClick: true
        }}
        dataSource={tableArr}
        loading={isLoading}
        rowSelection={{ ...rowSelection }}
        rowClassName={(record) => `Id_${record.id}`}
        className={"oneSupplierTable"}
      >
        <Column
          title="#"
          dataIndex="id"
          key="id"
          width={20}
          render={(record) => `#${record}`}
        />
        <Column
          title="TO'LOV"
          key="price"
          render={(record) => (
            <span>
              {Number(record?.payment_sum).toLocaleString("ru") + " " + record.valuta}
            </span>
          )}
        />
        <Column
          title="SUMMA"
          key="valuta_history_price"
          render={(record) => {
            // let residue = calculateOne(
            //   record?.price,
            //   record?.payment_sum,
            //   record.valuta_history_price,
            //   record.valuta_history_price
            // );
            // if (residue === -0) residue = 0;
            return (
              <span>{record?.price.toLocaleString("ru") + " " + record.valuta}</span>
            );
          }}
        />
        <Column
          title="REYTING"
          dataIndex="rating"
          key="id"
          render={(rating) => (
            <>
              <span
                style={{
                  display: "inline-block",
                  marginRight: ".25rem"
                }}
              >
                {rating || 0}
              </span>
              <Star />
            </>
          )}
        />
        <Column
          key={"vositachi"}
          title={"VOSITACHI"}
          dataIndex="vositachi"
          align="center"
        />
        <Column
          key={"date"}
          title={"SANA"}
          dataIndex="date"
          align="center"
          render={(date) => {
            return dayjs(date).format("DD.MM.YYYY");
          }}
        />
        <Column
          key={"action"}
          width={"120px"}
          title={"ACTION"}
          render={actionFunc as any}
          align="center"
        />
      </Table>
      <PaginationComp
        paginationProps={{
          onChange: (page) => setPageNumber(page),
          total: data?.total,
          current: data?.current_page,
          pageSize: 10
        }}
      />
    </div>
  );
};

export default OneSupplierTable;
