import React, { useState } from "react";
import { Button, DatePicker, Form, Popover, Select } from "antd";
import DownloadIcon from "../../../svg/DownloadIcon";
import CalendarIcon from "../../../svg/CalendarIcon";
import dayjs from "dayjs";
import "./productHistory.scss";
import ProductHistoryCont from "./ProductHistoryCont";
import { useAllOjects } from "../../../queries/queries";
import DatePickerExtraFooter from "../common/DatePickerExtraFooter";
import { view } from "../../../models/settings/ViewEnum";
import { DATE_FORMAT } from "../../../models/settings/dateFormat";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IOneObject {
  id: number;
  name: string;
  addres: string;
}

const OpenButtonComp: React.FC<{ id: number; type: string }> = ({
  id,
  type,
}) => {
  const [form] = Form.useForm();
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [allState, setAllState] = useState<{
    dateArr: string[];
    visible: boolean;
    openModal: boolean;
  }>({
    dateArr: [],
    openModal: false,
    visible: false,
  });

  //all objects
  const allObjects = useAllOjects();

  const onFinishAct = (val: any) => {
    const beginDate = dayjs(val.dateArr[0]).format("YYYY-MM-DD");
    const endDate = dayjs(val.dateArr[1]).format("YYYY-MM-DD");
    const building_id = val.object;
    setAllState({
      visible: false,
      dateArr: [beginDate, endDate, building_id && building_id],
      openModal: true,
    });
  };

  const closeFunc = () => {
    setAllState({
      visible: false,
      dateArr: [],
      openModal: false,
    });
    form.resetFields();
  };

  const rangePickerComp = (
    <div className="productHistoryMenu">
      <Form form={form} name="basic" onFinish={onFinishAct} autoComplete="off">
        <Form.Item name="dateArr" rules={[{ required: true, message: "" }]}>
          <RangePicker
            suffixIcon={<CalendarIcon />}
            className={"rangePicker"}
            placeholder={["Boshlanish vaqti", "Tugash vaqti"]}
            renderExtraFooter={() => {
              return (
                <DatePickerExtraFooter
                  form={form}
                  visible={setDatePickerVisible}
                />
              );
            }}
            open={datePickerVisible}
            format={DATE_FORMAT}
            onOpenChange={(e) => setDatePickerVisible(e)}
          />
        </Form.Item>
        <Form.Item name="view" initialValue={view.INCOME}>
          <Select>
            <Option value={view.INCOME}>Kirim</Option>
            <Option value={view.OUTLAY}>Chiqim</Option>
            <Option value={view.RESIDUE}>Qoldiq</Option>
          </Select>
        </Form.Item>
        <Form.Item name="object" initialValue="">
          <Select placeholder="Obyektni tanlang">
            <Select.Option key="overall" value="">
              Barcha binolar
            </Select.Option>
            {allObjects?.data?.map((item: IOneObject) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Button
          title="Export PDF (акт сверка)"
          className="rangePicker"
          type="primary"
          htmlType="submit"
        >
          Ok
        </Button>
      </Form>
    </div>
  );

  return (
    <>
      <Popover
        trigger="click"
        className="importExcelPopover"
        content={rangePickerComp}
        visible={allState.visible}
        onVisibleChange={(visible) => {
          setAllState({
            ...allState,
            visible,
          });
        }}
      >
        <Button
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "5px",
          }}
        >
          <DownloadIcon />
          <span style={{ marginLeft: "5px" }}>EXPORT</span>
        </Button>
      </Popover>
      <ProductHistoryCont
        id={id}
        open={allState.openModal}
        close={closeFunc}
        dateArr={allState.dateArr}
        popOverForm={form}
        type={type}
      />
    </>
  );
};

export default OpenButtonComp;
