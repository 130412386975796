const PictureFolderIcon: React.FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#F4EBFF"/>
            <path
                d="M21.6663 11.8912V15.3333C21.6663 15.8 21.6663 16.0334 21.7572 16.2117C21.8371 16.3685 21.9645 16.4959 22.1213 16.5758C22.2996 16.6667 22.533 16.6667 22.9997 16.6667H26.4418M26.6663 18.3235V24.3333C26.6663 25.7335 26.6663 26.4335 26.3939 26.9683C26.1542 27.4387 25.7717 27.8212 25.3013 28.0609C24.7665 28.3333 24.0665 28.3333 22.6663 28.3333H17.333C15.9329 28.3333 15.2328 28.3333 14.698 28.0609C14.2276 27.8212 13.8452 27.4387 13.6055 26.9683C13.333 26.4335 13.333 25.7335 13.333 24.3333V15.6667C13.333 14.2665 13.333 13.5665 13.6055 13.0317C13.8452 12.5613 14.2276 12.1788 14.698 11.9392C15.2328 11.6667 15.9329 11.6667 17.333 11.6667H20.0095C20.621 11.6667 20.9267 11.6667 21.2144 11.7357C21.4695 11.797 21.7134 11.898 21.9371 12.0351C22.1893 12.1897 22.4055 12.4059 22.8379 12.8382L25.4948 15.4951C25.9271 15.9275 26.1433 16.1437 26.2979 16.396C26.435 16.6196 26.536 16.8635 26.5973 17.1186C26.6663 17.4063 26.6663 17.712 26.6663 18.3235Z"
                stroke="#7F56D9" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default PictureFolderIcon;