import { LeftCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Empty,
  Form,
  Grid,
  Input,
  message,
  Select,
  Spin,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import dayjs from "rc-picker/node_modules/dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { useGetBuildings } from "../../../../features/expense/services/queries";
import { ACTIVE, PASSIVE } from "../../../../helper/ExpenseType";
import { WholeNumber } from "../../../../helper/WholeNumberValid";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import { DATE_FORMAT } from "../../../../models/settings/dateFormat";
import { WareHouseProductDataModel } from "../../../../models/warehouse/WarehouseModel";
import {
  useCreateExpense,
  useGetBuildingUser,
  useGetWarehouseProduct,
  useUpdateExpense,
} from "../../../../queries/mutations";
import { useGetOneExpense } from "../../../../queries/queries";
import { expenceCopyAction } from "../../../../redux/actions";
import { RootState } from "../../../../redux/rootReducer";
import { DatePicker } from "../../../../service/DatePicker";
import CheckGreen from "../../../../svg/CheckGreen";
import SaveIcon from "../../../../svg/SaveIcon";
import SelectIcon from "../../../../svg/SelectIcon";
import ThreePointsIcon from "../../../../svg/ThreePointsIcon";
import XIcon from "../../../../svg/XIcon";
import styles from "./expenseCreate.module.scss";
import SettingsModal, { SettingsFormType } from "./SettingsModal";

const { Item } = Form;
const { Option } = Select;

type SubmitDataType = {
  id?: number;
  building_id: string;
  user_id: number;
  date: string;
  status: string;
  products: {
    id: number;
    count: string;
    comment?: string;
  }[];
  description?: string;
  number?: string;
};

const ExpenseCreate = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { xxl } = Grid.useBreakpoint();
  const goBack = () => {
    dispatch(expenceCopyAction(false));
    history.push("/main/expense");
  };
  const [settingsForm] = Form.useForm<SettingsFormType>();

  const { data: building, isLoading: buildingLoading } = useGetBuildings();
  const users = useGetBuildingUser();
  const getWarehouseProducts = useGetWarehouseProduct();
  const createExpense = useCreateExpense(goBack);
  const updateExpense = useUpdateExpense(goBack);
  const id = location.pathname.split("/")[3];
  const { data: oneExpense, isLoading: oneExpenseLoading } = useGetOneExpense(
    Number(id)
  );
  const [searchProduct, setSearchProduct] = useState<{
    data: WareHouseProductDataModel[];
    search: boolean;
  }>();
  const [productState, setProductState] = useState<WareHouseProductDataModel[]>(
    []
  );
  const [visibleSettings, setVisibleSettings] = useState<boolean>(false);
  const [statusEpxense, setStatusExpense] = useState<string>("");
  const expenceCopy = useSelector(
    (state: RootState) => state.expenceCopyReducer.copy
  );

  useEffect(() => {
    if (oneExpense && oneExpense[0]?.building_id) {
      const userId = building
        ?.filter((item) => item.id === oneExpense[0].building_id)[0]
        .responsibles?.filter(
          (value) => Number(value) === oneExpense[0].user_id
        )[0];

      form.setFieldsValue({
        building_id: oneExpense[0].building_id,
        user_id: userId ? Number(userId) : "",
        date: dayjs(oneExpense[0].date).locale("en"),
      });

      settingsForm.setFieldsValue({
        description: oneExpense[0]?.description,
        number: oneExpense[0]?.number,
        comment_product: oneExpense[0]?.products?.some(
          (item) => item.comment && item.comment?.length > 0
        ),
      });

      getWarehouseProducts
        .mutateAsync({ id: oneExpense[0].building_id })
        .then((data) => {
          if (data && data.length > 0) {
            setProductState(
              oneExpense[0].products.map((item) => {
                if (oneExpense[0].status === ACTIVE) {
                  return {
                    ...item,
                    // max_count: Number(item.summa_count),
                    // +
                    // data.filter(
                    //   (e: any) => e?.product_id === item?.product_id
                    // )[0]?.summa_count,
                  };
                } else {
                  return {
                    ...item,
                    // max_count: Number(
                    //   data.filter(
                    //     (e: any) => e.product_id === item?.product_id
                    //   )[0]?.summa_count
                    // ),
                  };
                }
              })
            );
          }
        });

      users.mutate(
        building?.filter(
          (item) => item.id === form.getFieldValue("building_id")
        )[0].responsibles!
      );

      setStatusExpense(oneExpense[0].status);
    } else {
      form.setFieldsValue({
        date: dayjs().locale("en"),
      });
    }
  }, [form, oneExpense]);

  // product table columns
  const column: ColumnsType<WareHouseProductDataModel> = [
    {
      title: "№",
      render: (_, __, index) => productState.length - index,
      width: 60,
    },
    {
      title: "NOMI",
      dataIndex: "product_name",
      width: 200,
    },
    {
      title: "MAX",
      render: (e: WareHouseProductDataModel) => maxCount(e),
      // dataIndex: "max_count",
      width: 80,
    },
    {
      title: "MIQDORI",
      render: (e: WareHouseProductDataModel) => (
        <Input
          type="number"
          // defaultValue={statusEpxense.length > 0 ? e.summa_count : 1}
          value={
            productState.filter((item) => e.product_id === item.product_id)[0]
              .summa_count
          }
          // max={Number(e.summa_count)}
          className={`dangerSummaCount_${e.product_id}`}
          style={productCountStyle(e)}
          // min={0}
          onChange={(value) => changeProductCount(e, value)}
          suffix={e.product_type_name}
        />
      ),
      width: "8rem",
    },
    // {
    //   title: "O'LCHAMI",
    //   render: (e: WareHouseProductDataModel) => e.product_type_name,
    //   width: 100,
    // },
    settingsForm?.getFieldValue("comment_product")
      ? {
          title: "IZOH",
          width: "18rem",
          render: (record: WareHouseProductDataModel) => {
            return (
              <Input
                style={{
                  height: "39px",
                  width: "100%",
                }}
                value={
                  productState.filter(
                    (item) => record.product_id === item.product_id
                  )[0].comment
                }
                onChange={(e) => changeProductComment(e.target.value, record)}
              />
            );
          },
        }
      : {},
    {
      title: "",
      render: (e: WareHouseProductDataModel) => (
        <Button
          className="d_f ai_c jc_fe h_100"
          danger
          onClick={() => deleteOneProduct(e)}
        >
          <XIcon />
        </Button>
      ),
    },
  ];

  const changeProductComment = (
    value: string,
    e: WareHouseProductDataModel
  ) => {
    const copyProductState = productState?.slice();
    const index = productState?.findIndex(
      (item) => item.product_id === e.product_id
    );
    copyProductState[index] = {
      ...e,
      comment: value,
    };
    setProductState(copyProductState);
  };

  const productCountStyle = (
    e: WareHouseProductDataModel
  ): React.CSSProperties | undefined => {
    let state = false;
    const index = oneExpense![0]?.products?.findIndex(
      (item) => item?.product_id === e?.product_id
    );

    if (
      Number(oneExpense![0]?.products[index]?.summa_count) > Number(maxCount(e))
    ) {
      state = true;
    }

    const elem = productState?.filter(
      (item) => item?.product_id === e?.product_id
    )[0];
    if (!Number(elem?.summa_count)) {
      state = true;
    }
    if (Number(elem?.summa_count) > Number(maxCount(e))) {
      state = true;
    }
    return {
      borderColor: state ? "red" : "",
    };
  };

  const submitState = (): boolean => {
    let state: boolean = false;

    const allCountInput = document.querySelectorAll(
      `.${styles.content}  .ant-table-body .ant-table-row .ant-table-cell input`
    );

    allCountInput.forEach((item: any) => {
      if (item.style.borderColor === "red") {
        state = true;
      }
    });

    productState?.forEach((item) => {
      if (!Number(item.summa_count)) {
        state = true;
      }
      if (Number(item.summa_count) > maxCount(item)) {
        state = true;
      }
    });

    return state;
  };

  // finishing create or update expense
  const onFinish = (e: any) => {
    let products: { id: number; count: string; comment?: string }[] = [];

    productState.forEach((item) => {
      products.push({
        id: item.product_id,
        count: String(item.summa_count),
        comment: item.comment ? item.comment : undefined,
      });
    });

    let data: SubmitDataType = {
      building_id: e.building_id,
      user_id: e.user_id,
      date: e.date,
      status: statusEpxense,
      products,
      description: settingsForm?.getFieldValue("description"),
      number: settingsForm?.getFieldValue("number"),
    };

    if (submitState() && statusEpxense === ACTIVE) {
      message.error("Xatolik mavjud");
    } else {
      if (id !== "create" && !expenceCopy) {
        Object.assign(data, { id: Number(id) });
        updateExpense.mutate(data);
      } else {
        delete data.id;
        createExpense.mutateAsync(data).then(() => {
          dispatch(expenceCopyAction(false));
        });
      }
    }
  };

  // change checkbox
  const changeCheckboxGroup = (e: CheckboxChangeEvent) => {
    if (!productState.some((item) => item.product_id === e.target.value)) {
      let data: WareHouseProductDataModel[] = [];

      const indexElem = getWarehouseProducts.data.findIndex(
        (item: WareHouseProductDataModel) => item.product_id === e.target.value
      );

      data.push({
        product_id: getWarehouseProducts.data[indexElem].product_id,
        product_name: getWarehouseProducts.data[indexElem].product_name,
        product_type_name:
          getWarehouseProducts.data[indexElem].product_type_name,
        type: getWarehouseProducts.data[indexElem].type,
        summa_count: "",
        // max_count: maxCount(getWarehouseProducts.data[indexElem]),
        comment: undefined,
      });
      setProductState([...data, ...productState]);
    } else {
      setProductState(
        productState.filter((item) => item.product_id !== e.target.value)
      );
    }
  };

  // delete one product
  const deleteOneProduct = (e: WareHouseProductDataModel) => {
    setProductState(
      productState.filter((item) => item.product_id !== e.product_id)
    );
  };

  // save expense
  const saveExpense = (status: string) => {
    const filterProduct = productState.filter(
      (item) => item.summa_count.length > 0
    );
    if (filterProduct.length !== 0 || productState.length !== 0) {
      if (submitState() && status === ACTIVE) {
        message.error("Mahsulot miqdorida xatolik mavjud ");
      } else {
        setStatusExpense(status);
        form.submit();
      }
    } else {
      message.warning("Mahsulot miqdori kiritilmagan !");
    }
  };

  // change product count number
  const changeProductCount = (
    e: WareHouseProductDataModel,
    item: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = item.target.value;
    const element: any = document.querySelector(
      `.dangerSummaCount_${e.product_id}`
    );

    if (Number(value) > maxCount(e) || !value) {
      message.warning(
        Number(value) > 0
          ? `Mahsulotning chegaraviy miqdoridan ( ${maxCount(e)}  ${
              e.product_type_name
            } dan ) oshib ketdi`
          : `Mahsulot miqdori 0 dan katta bo'lishi zarur`
      );
      element.style.borderColor = "red";
    } else {
      element.style.borderColor = "#d9d9d9";
    }

    if (e.type === "BOLAK") {
      const copyArr = productState.slice();
      const indexElem = productState.findIndex(
        (item) => item.product_id === e.product_id
      );
      copyArr[indexElem] = {
        ...e,
        summa_count: value,
      };
      setProductState(copyArr);
    } else {
      if (WholeNumber(value)) {
        const copyArr = productState.slice();
        const indexElem = productState.findIndex(
          (item) => item.product_id === e.product_id
        );
        copyArr[indexElem] = {
          ...e,
          summa_count: value,
        };
        setProductState(copyArr);
      } else {
        element.style.borderColor = "red";
        message.warning("Mahsulot miqdori faqat butun sonda kiritilishi zarur");
      }
    }
  };

  // change product name
  const changeProductName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterString = e.target.value.toLowerCase();
    if (filterString != "") {
      let filteredItemsList = getWarehouseProducts.data.filter((el: any) => {
        const inName = el.product_name
          ? el.product_name.toLocaleLowerCase().includes(filterString)
          : false;
        return inName;
      });
      setSearchProduct({ data: filteredItemsList, search: true });
    } else {
      setSearchProduct({
        data: [],
        search: false,
      });
    }
  };

  // submit button style
  const submitBtnStyle = (e: boolean) => {
    if (e) {
      return statusEpxense === ACTIVE || statusEpxense === PASSIVE
        ? styles.submit_btn_active
        : styles.submit_btn;
    } else {
      return statusEpxense === ACTIVE
        ? styles.submit_btn_active
        : styles.submit_btn;
    }
  };

  // table max count
  const maxCount = (e: WareHouseProductDataModel) => {
    if (statusEpxense === ACTIVE) {
      let defaultMaxCount = 0;
      if (
        oneExpense &&
        oneExpense[0]?.products.some((item) => item.product_id === e.product_id)
      ) {
        defaultMaxCount = Number(
          oneExpense[0]?.products?.filter(
            (item) => item.product_id === e.product_id
          )[0].summa_count
        );
      }
      return (
        defaultMaxCount +
        Number(
          (getWarehouseProducts.data &&
            getWarehouseProducts.data.filter(
              (item: any) => item?.product_id === e?.product_id
            )[0]?.summa_count) ??
            0
        )
      );
    } else
      return Number(
        getWarehouseProducts.data &&
          getWarehouseProducts.data.filter(
            (item: any) => item.product_id === e?.product_id
          )[0]?.summa_count
      );
  };

  const changeBuilding = (e: SelectValue) => {
    getWarehouseProducts.mutate({
      id: form.getFieldValue("building_id"),
    });
    setProductState([]);
    users.mutate(building?.filter((item) => item.id === e)[0].responsibles!);
    form.setFieldsValue({
      user_id: undefined,
    });
  };

  useDocumentTitle(
    oneExpense && oneExpense[0]?.building_id
      ? "Chiqimni tahrirlash"
      : "Chiqim yaratish"
  );

  const disabledAllBtn = (): boolean => {
    if (getWarehouseProducts?.data?.length > 0) {
      return false;
    } else return true;
  };

  const onClickAllProduct = () => {
    if (!allProductState()) {
      setProductState([
        ...getWarehouseProducts?.data?.map((item: any) => ({
          ...item,
          commen: undefined,
        })),
      ]);
    } else {
      setProductState([]);
    }
  };

  const allProductState = (): boolean => {
    if (productState?.length === getWarehouseProducts?.data?.length) {
      return true;
    } else return false;
  };

  return (
    <Spin
      spinning={
        oneExpenseLoading ||
        users.isLoading ||
        buildingLoading ||
        getWarehouseProducts.isLoading ||
        updateExpense.isLoading ||
        createExpense.isLoading
      }
      indicator={<LoadingOutlined />}
      style={{ fontSize: 44 }}
    >
      <div className={styles.container}>
        <div className={styles.container_header}>
          <div className={styles.left}>
            <span onClick={goBack}>
              <LeftCircleOutlined />
            </span>
            <h1>Chiqim qilish</h1>
          </div>
          <div className={styles.right}>
            <Button
              onClick={() => saveExpense(PASSIVE)}
              className={submitBtnStyle(true)}
            >
              <SaveIcon />
              <span>Saqlash</span>
            </Button>
            <Button
              onClick={() => saveExpense(ACTIVE)}
              className={submitBtnStyle(false)}
            >
              <CheckGreen />
              <span>Tasdiqlash</span>
            </Button>
            <Button
              className={styles.edit_btn}
              onClick={() => setVisibleSettings(true)}
            >
              <ThreePointsIcon />
            </Button>
          </div>
        </div>
        <Form
          className={styles.container_body}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <div className={styles.container_body_top}>
            <Item
              label="Sklad"
              name="building_id"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                loading={buildingLoading}
                onChange={changeBuilding}
                placeholder="Skladni tanlang"
                suffixIcon={<SelectIcon />}
              >
                {building?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              label="Xodim"
              name="user_id"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                loading={users.isLoading}
                placeholder="Xodimni tanlang"
                suffixIcon={<SelectIcon />}
                notFoundContent={"Ushbu skladga tegishli xodimlar mavjud emas"}
              >
                {users?.data &&
                  users?.data?.data.map((user: any) => (
                    <Option value={user.id} key={user.id}>
                      {user.name}
                    </Option>
                  ))}
              </Select>
            </Item>
            <Item
              label="Sana"
              name="date"
              rules={[{ required: true, message: "" }]}
            >
              <DatePicker placeholder="YIL-OY-SANA VAQT" format={DATE_FORMAT} />
            </Item>
          </div>
          <div className={styles.container_body_bottom}>
            <div className={styles.search}>
              <Item
                name="product_name"
                label={
                  <div className={styles.all}>
                    <span>Mahsulot</span>
                    <Button
                      type="primary"
                      onClick={onClickAllProduct}
                      disabled={disabledAllBtn()}
                    >
                      {allProductState()
                        ? "- Barchasini o'chirish"
                        : "+ Barchasini qo'shish"}
                    </Button>
                  </div>
                }
                className={styles.search_top}
              >
                <Input
                  placeholder="Mahsulot nomi bilan qidirish"
                  onChange={changeProductName}
                />
              </Item>
              <div className={styles.search_bottom}>
                {getWarehouseProducts.data &&
                getWarehouseProducts.data.length > 0 ? (
                  <Item
                    name="products"
                    rules={[
                      () => ({
                        validator(_) {
                          if (productState.length === 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <div className={styles.checkbox}>
                      {searchProduct && searchProduct?.search ? (
                        searchProduct.data.length > 0 ? (
                          searchProduct?.data.map(
                            (
                              item: WareHouseProductDataModel,
                              index: number
                            ) => (
                              <Checkbox
                                key={index}
                                value={item.product_id}
                                onChange={changeCheckboxGroup}
                                checked={productState?.some(
                                  (value) =>
                                    value.product_id === item.product_id
                                )}
                              >
                                {item.product_name}
                              </Checkbox>
                            )
                          )
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Mahsulotlar mavjud emas!"
                          />
                        )
                      ) : (
                        getWarehouseProducts.data.map(
                          (item: WareHouseProductDataModel, index: number) => (
                            <Checkbox
                              key={index}
                              value={item.product_id}
                              onChange={changeCheckboxGroup}
                              checked={productState?.some(
                                (value) => value.product_id === item.product_id
                              )}
                            >
                              {item.product_name}
                            </Checkbox>
                          )
                        )
                      )}
                    </div>
                  </Item>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Mahsulotlar mavjud emas!"
                  />
                )}
              </div>
            </div>
            <div className={styles.table}>
              <Item name="product_table" label="  ">
                <Table
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="Mahsulotlar mavjud emas!"
                      />
                    ),
                  }}
                  pagination={false}
                  columns={column}
                  dataSource={productState}
                  className={styles.content}
                  rowKey={(record) => record.product_id}
                  style={{
                    width: settingsForm?.getFieldValue("comment_product")
                      ? "52.5rem"
                      : "41.25rem",
                  }}
                  scroll={{
                    y: xxl ? 500 : 330,
                  }}
                />
              </Item>
            </div>
          </div>
        </Form>
      </div>
      <SettingsModal
        form={settingsForm}
        visible={visibleSettings}
        setVisible={setVisibleSettings}
      />
    </Spin>
  );
};

export default ExpenseCreate;
