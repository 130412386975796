import { Reducer } from 'redux'
import { UserInterface } from '../models/interfaces/UserInterface'
import { UserService } from '../service/UserService'
import { USER_LOGIN, USER_LOGOUT } from './types'
import { UserActionType } from './userActions'

export interface UserState {
  userData: UserInterface
  token: string
}
const initialUserState = UserService.getUserLocal()
const initialState: UserState = {
  userData: initialUserState,
  token: UserService.getTokenLocal(),
}

export const userReducer: Reducer<UserState, UserActionType> = (
  state: UserState = initialState,
  action
) => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, userData: action.payload.user, token: action.payload.token }

    case USER_LOGOUT:
      return { ...initialState }

    default:
      return state
  }
}
