import { Col, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useOrderProductList, useProductStats } from '../../../../queries/queries'
import { selectFilter } from '../../../../service/Helpers'
import LineGraph from './LineGraph'
import { LoadingOutlined } from '@ant-design/icons'
import ProductStatsDonutChart from './ProductStatsDonutChart'

interface ProductStatsProps {}

const ProductStats: React.FC<ProductStatsProps> = () => {
  const { data: products, isLoading: isProductsLoading } = useOrderProductList()
  const [currentProductId, setCurrentProductId] = useState<number | undefined>(0)
  const elem = useRef<HTMLDivElement>(null)

  const { data, isLoading, isFetching } = useProductStats(currentProductId)

  useEffect(() => {
    if (!isLoading && !isProductsLoading) {
      const objProduct = products?.find((product) => product.name === data?.name)
      setCurrentProductId(objProduct?.id)
    }
    // eslint-disable-next-line
  }, [isLoading, isProductsLoading])

  return (
    <Col span="24" className="cover productStats">
      <div className="productStats_left left">
        <div ref={elem} className="left_head">
          <p>
            Mahsulotlar
            {isFetching && <LoadingOutlined style={{ marginLeft: '10px' }} />}
          </p>
          {isProductsLoading ? (
            <p>Yuklanmoqda...</p>
          ) : (
            <Select
              showSearch
              style={{ width: '15rem' }}
              value={currentProductId}
              filterOption={selectFilter}
              onChange={(value: number) => {
                setCurrentProductId(value)
              }}
            >
              {products?.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
        <LineGraph
          width={elem?.current?.offsetWidth || 500}
          beginData={data?.prices_diagram || []}
          loading={isLoading}
        />
      </div>
      <div className="productStats_right">
        <ProductStatsDonutChart allData={data} isLoading={isLoading} />
      </div>
    </Col>
  )
}

export default ProductStats
