import React from 'react'

const WarningCircle = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 14C11.8137 14 14.5 11.3137 14.5 8C14.5 4.68629 11.8137 2 8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14Z"
        stroke="#ECCA38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 5V8.5"
        stroke="#ECCA38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 11.25C8.77614 11.25 9 11.0261 9 10.75C9 10.4739 8.77614 10.25 8.5 10.25C8.22386 10.25 8 10.4739 8 10.75C8 11.0261 8.22386 11.25 8.5 11.25Z"
        fill="#ECCA38"
      />
    </svg>
  )
}

export default WarningCircle
