import React from "react";
import { Button } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { CheckoutModalEnums } from "../../../../models/checkout/CheckoutModalTypes";

import { CheckoutViewType } from "../../../../models/checkout/CheckoutPaymentModel";
import MinusCircleIcon from "../../../../svg/MinusCircleIcon";
import PlyusCircleIcon from "../../../../svg/PlyusCircleIcon";
import WalletIcon from "../../../../svg/WalletIcon";
import WatchCircleIcon from "../../../../svg/WatchCircleIcon";

import styles from "../checkout.module.scss";
import { useValutaAll } from "../../../../queries/queries";
import { CheckoutSearchKeys } from "../../../../models/checkout/CheckoutSearchKeys";

type Props = {
  data: CheckoutViewType;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      data: CheckoutViewType;
      payment: number;
    }>
  >;
};

const Card: React.FC<Props> = ({ data, setVisible }) => {
  const location = useLocation();
  const history = useHistory();
  const { data: valuta } = useValutaAll();

  const handleOpenModal = (
    type: string,
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setVisible({
      visible: true,
      data,
      payment: 0,
    });
    history.push(`${location.pathname}?${CheckoutSearchKeys.TYPE}=${type}`);
    e.stopPropagation();
  };

  const filterData = () => {
    setVisible({
      visible: false,
      data,
      payment: data.payment_type_id,
    });
  };

  const formatPrice = (price: number) => {
    return String(price).length > 20
      ? `${String(price).slice(0, 16)} ... `
      : Number(price).toLocaleString();
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.icon}>
            <WalletIcon />
          </div>
          <div className={styles.right}>
            <Button
              onClick={(e) => handleOpenModal(CheckoutModalEnums.REVENUE, e)}
            >
              <PlyusCircleIcon />
            </Button>
            <Button
              onClick={(e) => handleOpenModal(CheckoutModalEnums.OUTLAY, e)}
            >
              <MinusCircleIcon />
            </Button>
            <Button onClick={filterData}>
              <WatchCircleIcon />
            </Button>
          </div>
        </div>
        <div className={styles.bottom}>
          <p>{data.payment_type_name}</p>
          <h3>
            {formatPrice(data?.price)}
            <span>{valuta?.filter((item) => item.price === 1)[0].name}</span>
          </h3>
          <h3>
            {formatPrice(data?.price_currency)}
            <span>{valuta?.filter((item) => item.price > 1)[0].name}</span>
          </h3>
        </div>
      </div>
    </>
  );
};

export default Card;
