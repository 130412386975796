import ExpenseCreate from "../../components/main/expense/expense-create/ExpenseCreate";
import Expense from "../../components/main/expense/Expense";
import HistoryPage from "../../components/main/history/HistoryPage";
import CreateOrder from "../../components/main/parts/CreateOrder";
import OrderPage from "../../components/main/parts/OrdersPage";
import Settings from "../../components/main/settings/Settings";
import StatsPage from "../../components/main/statistics/StatsPage";
import SuppliersCont from "../../components/main/supplier/all/SuppliersCont";
import OneSupplierCont from "../../components/main/supplier/one/OneSupplierCont";
import CreateTransfer from "../../components/main/transfer/CreateTransfer";
import TransferPage from "../../components/main/transfer/TransferPage";
import mainPaths from "./mainPaths";
import CheckoutPage from "../../components/main/checkout/CheckoutPage";
import Conversion from "../../components/main/conversion/Conversion";

export const mainRoutes = [
  {
    path: mainPaths.SETTINGS,
    component: Settings,
    exact: true
  },
  {
    path: mainPaths.SUPPLIER,
    component: SuppliersCont,
    exact: true
  },
  {
    path: mainPaths.ONE_SUPPLIER,
    component: OneSupplierCont,
    exact: true
  },
  {
    path: `${mainPaths.ORDERS}/:orderId`,
    component: CreateOrder,
    exact: true
  },
  {
    path: mainPaths.ORDERS,
    component: OrderPage,
    exact: true
  },
  {
    path: mainPaths.TRANSFER,
    component: TransferPage,
    exact: true
  },
  {
    path: `${mainPaths.TRANSFER}/:transferId`,
    component: CreateTransfer,
    exact: true
  },
  {
    path: `${mainPaths.STATISTICS}`,
    component: StatsPage,
    exact: true
  },
  {
    path: `${mainPaths.HISTORY}`,
    component: HistoryPage,
    exact: true
  },
  {
    path: mainPaths.EXPENSE,
    component: Expense,
    exact: true
  },
  {
    path: mainPaths.EXPENSE_CREATE,
    component: ExpenseCreate,
    exact: true
  },
  {
    path: `${mainPaths.EXPENSE}/:id`,
    component: ExpenseCreate,
    exact: true
  },
  {
    path: mainPaths.CHECKOUT,
    component: CheckoutPage,
    exact: true
  },
  {
    path: mainPaths.CONVERSION,
    component: Conversion,
    exact: true
  }
];
