export const queryNames = {
  SETTINGS_PAYMENT: "SETTINGS_PAYMENT",
  SETTINGS_PAYMENTS: "SETTINGS_PAYMENTS",
  SUPPLIER: "SUPPLIER",
  SUPPLIER_DETAILS: "SUPPLIER_DETAILS",
  SUPPLIER_BROKER: "SUPPLIER_BROKER",
  BROKERS_OF_SUPPLIER: "BROKERS_OF_SUPPLIER",
  SETTINGS_USER: "SETTINGS_USER",
  SETTINGS_USERS: "SETTINGS_USERS",
  SETTINGS_EXPENSE: "SETTINGS_EXPENSE",
  SETTINGS_EXPENSES: "SETTINGS_EXPENSES",
  PAGE: "PAGE",
  SETTINGS_CATEGORY: "SETTINGS_CATEGORY",
  SETTINGS_PRODUCT_SI: "SETTINGS_PRODUCT_SI",
  SETTINGS_CATEGORIES: "SETTINGS_CATEGORIES",
  SETTINGS_PRODUCTS_SI: "SETTINGS_PRODUCTS_SI",
  SETTINGS_OBJECT: "SETTINGS_OBJECT",
  SETTINGS_OBJECTS: "SETTINGS_OBJECTS",
  ALL_OBJECTS: "ALL_OBJECTS",
  OBJECT_HISTORY: "OBJECT_HISTORY",
  OBJECT_NAMES_HISTORY: "OBJECT_NAMES_HISTORY",
  SETTINGS_VALUTA_ONE: "SETTINGS_VALUTA_ONE",
  SETTINGS_VALUTA: "SETTINGS_VALUTA",
  SETTINGS_PRODUCT: "SETTINGS_PRODUCT",
  SETTINGS_PRODUCT_DETAILS: "SETTINGS_PRODUCT_DETAILS",
  SETTINGS_PRODUCTS: "SETTINGS_PRODUCTS",
  ALL_CATEGORY: "ALL_CATEGORY",
  ALL_PRODUCT_SI: "ALL_PRODUCT_SI",
  PARTS: "PARTS",
  ORDER_SELECTS: "ORDER_SELECTS",
  ORDER_DETAILS: "ORDER_DETAILS",
  ONE_ORDER_DETAILS: "ONE_ORDER_DETAILS",
  TRANSFERS: "TRANSFERS",
  TRANSFER_DETAILS: "TRANSFER_DETAILS",
  ORDER_PRODUCT_LIST: "ORDER_PRODUCT_LIST",
  HISTORY: "HISTORY",
  STATISTICS_TOP_PRODUCTS: "STATISTICS_TOP_PRODUCTS",
  STATISTICS_KREDIT_DEBIT: "STATISTICS_KREDIT_DEBIT",
  ACT_RECONCILIATION: "ACT_RECONCILIATION",
  PRODUCT_HISTORY: "PRODUCT_HISTORY",
  PRODUCT_STATS: "PRODUCT_STATS",
  EXPENSE_STATS: "EXPENSE_STATS",
  BUILDING_STATS: "BUILDING_STATS",
  CHECK_PRODUCT: "CHECK_PRODUCT",
  EXCEL: "EXCEL",
  WAREHOUSE: "WAREHOUSE",
  WAREHOUSE_PRODUCT: "WAREHOUSE_PRODUCT",
  ALL_EXPENSE: "ALL_EXPENSE",
  GET_ONE_EXPENSE: "GET_ONE_EXPENSE",
  GET_ALL_OBJECTS: "GET_ALL_OBJECTS",
  WAREHOUSE_USED: "WAREHOUSE_USED",
  WAREHOUSE_USED_PRODUCT: "WAREHOUSE_USED_PRODUCT",
  CHECKOUT_PAYMENTS: "CHECKOUT_PAYMENTS",
  CHECKOUT_VIEW: "CHECKOUT_VIEW",
  USER_ROLE_LIST: "USER_ROLE_LIST",
  UYSOT_VIEW: "INTEGRATION_UYSOT",
  KASSA_UYSOT: "KASSA_UYSOT",
  GET_ALL_PAYMENTS: "GET_ALL_PAYMENTS",
  GET_UYSOT_KASSA: "GET_UYSOT_KASSA",
  CONVERSION: "CONVERSION",
  PICTURES_VIEW_FILE: "PICTURES_VIEW_FILE",
  PICTURES_DOWNLOAD_FILE: "PICTURES_DOWNLOAD_FILE",
  USED_PRODUCTS: "USED_PRODUCTS",
  MY_DATA: "my_data",
};
