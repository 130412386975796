export const SETTINGS_OPEN_EDIT_MODAL = "SETTINGS_OPEN_EDIT_MODAL";
export const SEARCH_HEADER_CREATE_MODAL = "SEARCH_HEADER_CREATE_MODAL";
export const SEARCH_HEADER_SEARCH_STRING = "SEARCH_HEADER_SEARCH_STRING";
export const SEARCH_HEADER_TOGGLE_SUPPLIER_MODAL =
  "SEARCH_HEADER_TOGGLE_SUPPLIER_MODAL";

//user types
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
//productList types
export const PRODUCT_LIST_ADD = "PRODUCT_LIST_ADD";
export const PRODUCT_LIST_REMOVE = "PRODUCT_LIST_REMOVE";
export const PRODUCT_LIST_SET_CHECKLIST = "PRODUCT_LIST_SET_CHECKLIST";
export const PRODUCT_LIST_FILTER = "PRODUCT_LIST_FILTER";
export const PRODUCT_LIST_PRICE_CHANGE = "PRODUCT_LIST_PRICE_CHANGE";
export const PRODUCT_LIST_COUNT_CHANGE = "PRODUCT_LIST_COUNT_CHANGE";
export const PRODUCT_LIST_SET_VALUTA = "PRODUCT_LIST_SET_VALUTA";
export const PRODUCT_LIST_SET_PRODUCTS = "PRODUCT_LIST_SET_PRODUCTS";
export const PRODUCT_LIST_ADD_CHECKLIST = "PRODUCT_LIST_ADD_CHECKLIST";
export const PRODUCT_LIST_RESET = "PRODUCT_LIST_RESET";
export const PRODUCT_LIST_NDS = "PRODUCT_LIST_NDS";
export const PRODUCT_LIST_SET_BUILDING_ID = "PRODUCT_LIST_SET_BUILDING_ID";
export const PRODUCT_LIST_SET_BUILDING = "PRODUCT_LIST_SET_BUILDING";
export const PRODUCT_LIST_SET_CHECKED = "PRODUCT_LIST_SET_CHECKED";
export const PRODUCT_LIST_BUILDING_FOR_ALL = "PRODUCT_LIST_BUILDING_FOR_ALL";
export const PRODUCT_LIST_DATE_FOR_ALL = "PRODUCT_LIST_DATE_FOR_ALL";
export const PRODUCT_LIST_SET_COMMENT = "PRODUCT_LIST_SET_COMMENT";
export const PRODUCT_PAYMENT_VALUTA_ID = "PRODUCT_PAYMENT_VALUTA_ID";

//product graph
export const PRODUCT_GRAPH = "PRODUCT_GRAPH";
export const HISTORY_SET_HISTORY = "HISTORY_SET_HISTORY";
export const WAREHOUSE_CARD_ID = "WAREHOUSE_CARD_ID";
export const ALL_SELECET_WAREHOUSES = "ALL_SELECT_WAREHOUSES";
export const PICTURE_VISIBLE = "PICTURE_VISIBLE";
export const ORDER_ID_REDUCER = "order_id_reducer";
export const EXPENCE_COPY = "expence_copy";
