import React, { useRef } from 'react'
import { Modal, Table } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Header from './Header'
import Footer from './Footer'
import { useProductHistory } from '../../../queries/queries'

const { Column } = Table

interface ProductHistoryInterface {
  open: boolean
  close: () => void
  dateArr: string[]
  id: number
  popOverForm: any
  type: string
}

const ProductHistoryCont: React.FC<ProductHistoryInterface> = ({
  open,
  close,
  dateArr,
  id,
  popOverForm,
  type,
}) => {
  const productListNumber = useRef<{
    id: number
    number: number
  }>({
    id: -1,
    number: 0,
  })
  const productLastItem = useRef({
    id: -1,
    firstCount: -1,
    price: 0,
    count: 0,
  })
  const productPrice = useRef({
    price: 0,
    id: -1,
  })

  const productListName = useRef('')

  const { data, isLoading, isFetching } = useProductHistory(id, dateArr)

  const closeFunc = () => {
    close()
    popOverForm.resetFields()
  }
  // handle total price
  const handleTotalPrice = () => {
    let totalPrice = 0
    data?.data?.forEach((item: any) => {
      totalPrice += item.price * item.count
    })
    return totalPrice
  }

  return (
    <Modal
      className={`${type} productHistoryModal`}
      width={1000}
      footer={false}
      visible={open}
      onCancel={closeFunc}
      centered
    >
      <Header dateArr={dateArr} />
      {!isLoading && (
        <Table
          className={'table supplier-table'}
          pagination={false}
          dataSource={data?.data || []}
          loading={isLoading}
          expandable={{
            expandIconColumnIndex: -1,
            defaultExpandAllRows: true,
            expandedRowRender: (record, index) => {
              productLastItem.current = {
                id: record?.product_id,
                price:
                  productLastItem.current.price +
                  record?.count * 1 * record?.price * record?.valuta_history_price,
                firstCount:
                  productLastItem.current.firstCount === -1
                    ? record?.price
                    : productLastItem.current.firstCount,
                count: productLastItem.current.count + record?.count * 1,
              }
              if (
                productLastItem?.current.id !== data?.data[index + 1]?.product_id
              ) {
                // const firstCount = productLastItem.current.firstCount
                const total = productLastItem.current.price
                const count = productLastItem.current.count
                const productType = record.product_type_name
                const currency = record.valuta_name
                productLastItem.current = {
                  id: record?.product_id,
                  price: 0,
                  firstCount: -1,
                  count: 0,
                }
                return (
                  <p style={{ textAlign: 'center' }}>
                    {/* {record.product_name} */}
                    {/* {firstCount} */}
                    Jami: &nbsp;
                    {total.toLocaleString()} {currency} &nbsp;
                    {count} {productType}
                  </p>
                )
              }
              return null
            },
          }}
          scroll={{ y: '70vh' }}
        >
          <Column
            key={'number'}
            title={'#'}
            render={(record, _, index) => {
              if (index === 0) {
                productListNumber.current = {
                  id: -1,
                  number: 0,
                }
              }
              if (record.product_id !== productListNumber.current.id) {
                productListNumber.current = {
                  id: record.product_id,
                  number: productListNumber.current.number + 1,
                }
                const number = productListNumber.current.number
                if (index + 1 === data?.data.length)
                  productListNumber.current = {
                    id: -1,
                    number: 0,
                  }
                return <span>#{number}</span>
              }

              return null
            }}
          />
          <Column
            key={'product_name'}
            title={
              <>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: '1rem',
                  }}
                >
                  MAHSULOT NOMI
                </span>
                {isFetching && <LoadingOutlined />}
              </>
            }
            render={(record, _, index) => {
              if (index === 0) {
                productListName.current = ''
              }
              if (record.product_name !== productListName.current) {
                productListName.current = record.product_name
                const name = productListName.current
                if (index + 1 === data?.data.length) {
                  productListName.current = ''
                }
                return <span>{name}</span>
              }

              return null
            }}
          />
          <Column key={'date'} title={'SANA'} dataIndex={'date'} />
          <Column
            key={'price'}
            title={'SUMMA'}
            render={(record) => {
              if (record?.product_id !== productPrice?.current?.id) {
                productPrice.current.price =
                  record?.price * record?.valuta_history_price
                productPrice.current.id = record?.product_id
              }

              const className = () => {
                if (record?.price > productPrice.current.price) {
                  return 'red'
                }
                if (record?.price < productPrice.current.price) {
                  return 'green'
                }
                if (record?.price === productPrice.current.price) {
                  return ''
                }
              }
              return (
                <span className="price">
                  <span className={`${className()}`}>
                    {record?.price.toLocaleString('ru')}
                    {record?.valuta_name} (x
                    {record?.count + ' ' + record?.product_type_name})
                  </span>
                </span>
              )
            }}
          />
          <Column
            key={'order_id'}
            title={'PARTIYA'}
            dataIndex={'order_id'}
            render={(order_id) => <span>#{order_id}</span>}
          />
          <Column
            key={'supplier_name'}
            title={"TA'MINOTCHI"}
            dataIndex={'supplier_name'}
          />
          <Column key={'user_name'} title={'VOSITACHI'} dataIndex={'user_name'} />
          <Column key="building" title="OBYEKT" dataIndex="building_name" />
        </Table>
      )}
      <Footer
        close={closeFunc}
        price={data?.price || 0}
        totalPrice={handleTotalPrice()}
        type={type}
      />
    </Modal>
  )
}

export default ProductHistoryCont
