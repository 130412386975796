import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchHeaderSearchString } from "../../../redux/actions";
import { RootState } from "../../../redux/rootReducer";

interface MenuInterface {
  title: string;
  number: number;
}

interface SettingsMenuInterface {
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
}

const SettingsMenu: React.FC<SettingsMenuInterface> = ({
  activePage,
  setActivePage,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  // menu
  let menuItems: MenuInterface[] = [
    {
      title: "Obyekt",
      number: 5,
    },
    {
      title: "Foydalanuvchi",
      number: 4,
    },
    {
      title: "Kategoriya",
      number: 1,
    },
    {
      title: "To'lov turi",
      number: 2,
    },
    {
      title: "Xarajat turi",
      number: 3,
    },
    {
      title: "Mahsulotlar",
      number: 6,
    },
    {
      title: "Valyuta",
      number: 7,
    },
    {
      title: "Kassa",
      number: 8,
    },
    {
      title: "Amallar tarixi",
      number: 9,
    },
  ];

  useEffect(() => {
    if (user.userData.role !== "direktor" || "admin") {
      menuItems = menuItems.filter((item) => item.number !== 8);
    }
  }, []);

  return (
    <div className={"menuCont"}>
      {menuItems.map((item) => (
        <div
          onClick={() => {
            dispatch(searchHeaderSearchString(""));
            setActivePage(item.number);
          }}
          key={item.number}
          className={"item"}
        >
          <span
            className={`${
              item.number === activePage ? "activeTitle" : "notActiveTitle"
            }`}
          >
            {item.title}
          </span>
          <div
            className={`${
              item.number === activePage ? "activeDiv" : "notActiveDiv"
            }`}
          />
        </div>
      ))}
    </div>
  );
};

export default SettingsMenu;
