import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import query from "querystring";
import dayjs from "rc-picker/node_modules/dayjs";
import { Button, Input, Modal } from "antd";

import styles from "../conversion.module.scss";
import { paramsValue } from "../../../../helper/paramsValues";
import ViewConversionCard from "./ViewConversionCard";
import ArrowSquareRightIcon from "../../../../svg/ArrowSquareRightIcon";
import { ConversionDataType } from "../../../../models/conversion/ConversionModel";
import { ConversionIncome } from "../../../../models/enums/ConversionEnums";
import Payments from "../payments/Payments";
import { DatePicker } from "../../../../service/DatePicker";

const { TextArea } = Input;

type Props = {
  data: ConversionDataType;
};

const ViewConversion: React.FC<Props> = ({ data }) => {
  const location = useLocation();
  const history = useHistory();

  // visible modal
  const visible = () => {
    const params = query.parse(
      location.search.slice(1, location.search.length)
    ) as {
      page?: string;
      visibleView?: string;
    };
    if (params.visibleView && params.visibleView === paramsValue.TRUE)
      return true;
    return false;
  };

  // close modal
  const handleClose = () => {
    const params = query.parse(
      location.search.slice(1, location.search.length)
    );
    delete params.visibleView;
    history.push(`${location.pathname}?${query.stringify(params)}`);
  };
  return (
    <Modal
      visible={visible()}
      title="Konvertatsiya"
      onCancel={handleClose}
      className={styles.create_conversion}
      centered
    >
      <Payments />
      <div className={styles.content}>
        <div className={styles.left}>
          {data?.elements.map((item) => {
            if (item.kirim === ConversionIncome.CHIQIM) {
              return <ViewConversionCard type={true} key={item.id} {...item} />;
            } else return undefined;
          })}
          <TextArea
            disabled
            placeholder="Izoh mavjud emas"
            value={data?.description! ?? ""}
          />
          <DatePicker
            value={dayjs(data?.created_at!)}
            disabled
            placeholder="YIL-OY-SANA VAQT"
            format="DD.MM.YYYY"
          />
        </div>
        <div className={styles.center}>
          <Button>
            <ArrowSquareRightIcon />
          </Button>
        </div>
        <div className={styles.right}>
          {data?.elements.map((item) => {
            if (item.kirim === ConversionIncome.KIRIM) {
              return (
                <ViewConversionCard type={false} key={item.id} {...item} />
              );
            } else return undefined;
          })}
        </div>
      </div>
      <div className={styles.footer}>
        <Button type="text" onClick={handleClose}>
          Yopish
        </Button>
      </div>
    </Modal>
  );
};

export default ViewConversion;
