import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductInterface } from "../../../../../models/interfaces/ProductInterface";
import { useProductMutation } from "../../../../../queries/mutations";
import {
  useCategoryAll,
  useCurrentProduct,
  useProductSiAll,
} from "../../../../../queries/queries";
import {
  addNewProduct,
  isOpenSettingEditModal,
} from "../../../../../redux/actions";
import { RootState } from "../../../../../redux/rootReducer";

const { Option } = Select;

interface CUInterface {
  currentProductId?: number;
  setProductId?: React.Dispatch<React.SetStateAction<number>>;
  page?: number;
}

const CreateAndEditModal: React.FC<CUInterface> = ({
  setProductId,
  currentProductId,
  page,
}) => {
  const { data, isLoading } = useCurrentProduct(currentProductId);
  const category = useCategoryAll();
  const productSi = useProductSiAll();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isModalOpen = useSelector(
    (state: RootState) => state.settings.openCreateModal
  );
  const cu = useProductMutation(page);

  const cancelFunc = () => {
    dispatch(isOpenSettingEditModal(false));
    if (setProductId) {
      setProductId(0);
    }
    form.resetFields();
  };

  const onFinish = async (values: ProductInterface) => {
    const selectedCategory = category?.data?.find(
      (item) => item.name === values.category_name
    );
    const selectedProductSi = productSi?.data?.find(
      (item) => item.name === values.product_type_name
    );
    // console.log(selectedProductSi, selectedCategory)

    if (currentProductId) {
      await cu.mutateAsync({
        id: currentProductId,
        category_id: selectedCategory?.id as number,
        name: values.name,
        product_type_id: selectedProductSi?.id as number,
        resource: +values.resource!,
      });
    } else {
      const data = await cu.mutateAsync({
        name: values.name,
        category_id: selectedCategory?.id as number,
        product_type_id: selectedProductSi?.id as number,
        resource: values.resource!,
      });
      if (!page) {
        if (data.id) {
          dispatch(
            addNewProduct({
              id: data.id,
              name: values.name,
              type: selectedProductSi!.type,
              checked: false,
              product_type_name: selectedProductSi!.name,
              category_name: selectedCategory!.name,
            })
          );
        }
      }
    }
    cancelFunc();
  };

  useEffect(() => {
    if (currentProductId && currentProductId > 0) {
      form.setFieldsValue({
        name: data?.name,
        product_type_name: data?.product_type_name,
        category_name: data?.category_name,
        resource: data?.resource,
      });
    }
  }, [currentProductId, data, form]);

  const ModalTitle = (
    <>
      <span style={{ display: "inline-block", marginRight: "1rem" }}>
        Mahsulot yaratish
      </span>
      {isLoading && <LoadingOutlined />}
    </>
  );

  return (
    <Modal
      className={"editModal"}
      visible={isModalOpen}
      title={ModalTitle}
      onCancel={cancelFunc}
      footer={false}
    >
      <Form
        form={form}
        name="paymentEdit"
        onFinish={onFinish}
        autoComplete="off"
      >
        <label htmlFor={"name"}>Nomi</label>
        <Form.Item name="name" rules={[{ required: true, message: "" }]}>
          <Input className={"input"} id={"name"} />
        </Form.Item>
        <Row gutter={[20, 8]}>
          <Col span={8}>
            <label htmlFor={"category"}>Kategoriya</label>
            <Form.Item
              name="category_name"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                loading={category.isLoading}
                className={"select"}
                id={"category"}
              >
                {category.data &&
                  category.data.map((item, index) => (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <label htmlFor={"si"}>O’lchov birligi</label>
            <Form.Item
              name="product_type_name"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                loading={productSi.isLoading}
                className={"select"}
                id={"si"}
              >
                {productSi.data &&
                  productSi.data.map((item, index) => (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <label>Turi</label>
            <Form.Item initialValue={0} name="resource">
              <Select className="select">
                <Option value={0}>Mahsulot</Option>
                <Option value={1}>Resurs</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className={"footerCont"}>
          <Button
            onClick={cancelFunc}
            className={"modalFooterBack"}
            type="link"
          >
            Bekor qilish
          </Button>
          <Button
            disabled={isLoading || productSi.isLoading || category.isLoading}
            loading={cu.isLoading}
            type="primary"
            htmlType="submit"
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAndEditModal;
