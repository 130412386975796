import { EyeOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import React, { useState } from 'react'
import greenCard from '../../../../image/greenCard.png'
import redCard from '../../../../image/redCard.png'
import { useCreditDebit } from '../../../../queries/queries'
import { CreditDebitModal } from './CreditDebitModal'

interface CreditDebitStatsProps {}

export const CreditDebitStats: React.FC<CreditDebitStatsProps> = () => {
  const { data, isLoading } = useCreditDebit()
  const [visible, setVisible] = useState(false)
  const [modalData, setModalData] = useState({ title: '', data: {} })
  return (
    <div className="expenseStats_head">
      <div
        className="expenseStats_card green_card"
        onClick={() => {
          setVisible(true)
          setModalData({ data: data?.kredit_data as any, title: 'Kredit' })
        }}
      >
        <div className="expenseStats_dk">
          <Space>
            <p className="text">Jami kredit</p>
            <p
              className="eye"
              onClick={() => {
                setVisible(true)
                setModalData({ data: data?.kredit_data as any, title: 'Kredit' })
              }}
            >
              <EyeOutlined style={{ color: 'white', fontSize: '1rem' }} />
            </p>
          </Space>
          <p>
            <span className="balans">{data?.kredit?.toLocaleString('ru')}</span> so'm
          </p>
        </div>
        <img src={greenCard} alt="card" />
      </div>
      <div
        className="expenseStats_card red_card"
        onClick={() => {
          setVisible(true)
          setModalData({ data: data?.debit_data as any, title: 'Debit' })
        }}
      >
        <div className="expenseStats_dk">
          <Space>
            <p className="text">Jami debit</p>
            <p
              className="eye"
              onClick={() => {
                setVisible(true)
                setModalData({ data: data?.debit_data as any, title: 'Debit' })
              }}
            >
              <EyeOutlined style={{ color: 'white', fontSize: '1rem' }} />
            </p>
          </Space>
          <p>
            <span className="balans">{data?.debit?.toLocaleString('ru')}</span> so'm
          </p>
        </div>
        <img src={redCard} alt="card" />
      </div>
      <CreditDebitModal
        title={modalData.title}
        isLoading={isLoading}
        data={modalData.data as any}
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  )
}
