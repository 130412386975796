import React from 'react'
import { Button } from 'antd'
import DownloadIcon from '../../../svg/DownloadIcon'
import ArrowIconSuccess from '../../../svg/ArrowIconSuccess'
import ArrowIconDanger from '../../../svg/ArrowIconDanger'
import ReactToPrint from 'react-to-print'

interface FooterInterface {
  totalPrice: number
  price: number
  close: () => void
  type: string
}

const Footer: React.FC<FooterInterface> = ({ price, close, totalPrice, type }) => {
  const returnIconFunc = () => {
    if (price >= 0)
      return (
        <span className={'iconSuccess'}>
          <ArrowIconSuccess />
        </span>
      )
    return (
      <span className={'iconDanger'}>
        <ArrowIconDanger />
      </span>
    )
  }

  const handleAfterPrint = () => {
    const titles: any = document.querySelectorAll('.ant-table-thead')
    const tableBody: any = document.querySelectorAll('.ant-table-body')
    titles.forEach((title: any) => {
      title.style.display = 'table-header-group'
    })
    tableBody.forEach((body: any) => {
      body.style.maxHeight = '70vh'
    })
  }

  return (
    <div className={'productHistoryFooter'}>
      <div className={'leftSection'}>
        <span className={'label'}>Tizim hisoboti</span>
        {returnIconFunc()}
        <span className={'sum'}>{price.toLocaleString('ru')} so'm</span>
        &nbsp; &nbsp; &nbsp;
        <span className={'label'}>Jami summa: </span>
        <span className={'sum'}>{totalPrice.toLocaleString()} so'm</span>
      </div>
      <div>
        <Button onClick={close} type={'link'} className={'cancel'}>
          Bekor qilish
        </Button>
        <ReactToPrint
          onBeforeGetContent={() => {
            const titles: any = document.querySelectorAll('.ant-table-thead')
            const tableBody: any = document.querySelectorAll('.ant-table-body')
            titles.forEach((title: any) => {
              title.style.display = 'none'
            })

            tableBody.forEach((body: any) => {
              body.style.maxHeight = 'none'
            })
          }}
          onAfterPrint={handleAfterPrint}
          onPrintError={handleAfterPrint}
          trigger={() => (
            <Button className="pdfBtn">
              <div className={'button'}>
                <DownloadIcon />
                <span>PDF</span>
              </div>
            </Button>
          )}
          content={() => document.querySelector(`.${type}`)}
        />
        {/* <Button className="excelBtn">
          <div className={'button'}>
            <DownloadIcon />
            <span>Excel</span>
          </div>
        </Button> */}
      </div>
    </div>
  )
}

export default Footer
