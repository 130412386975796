import React, {useEffect, useState} from 'react'
import {Button, Popconfirm, Spin, Table} from 'antd'
import {NotePencil} from '../../../../../svg/NotePencil'
import {Garbage} from '../../../../../svg/Garbage'
import CreateAndEditModal from './CreateAndEditModal'
import {isOpenSettingEditModal} from '../../../../../redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import {useProductSi} from '../../../../../queries/queries'
import {useDeleteProductSI} from '../../../../../queries/mutations'
import {LoadingOutlined} from '@ant-design/icons'
import {types} from './types'
import PaginationComp from '../../../universialComponents/PaginationComp'
import {RootState} from "../../../../../redux/rootReducer";

import styles from './productSI.module.scss'
import {Plus} from "../../../../../svg/Plus";

const {Column} = Table

const ProductSI = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [deleteId, setDeleteId] = useState(0)
  const [productSiId, setProductSiId] = useState(0)

  const dispatch = useDispatch()
  const searchString = useSelector((state: RootState) => state.searchHeader.searchString)

  const {data, isLoading, isFetching} = useProductSi(currentPage, searchString)
  const deleteProductSI = useDeleteProductSI(setDeleteId, currentPage)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchString]);

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id)
      await deleteProductSI.mutateAsync(id)
      if (data?.data.length === 1 && data.current_page !== 1)
        setCurrentPage(currentPage - 1)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={styles.productSI}>
      <div className={styles.buttons}>
        <Button className={"buttonCont"} type={"primary"}>
          <div
            className={"button"}
            onClick={() => {
              dispatch(isOpenSettingEditModal(true));
            }}
          >
            <Plus className="icon"/>
            <span>Birlik</span>
          </div>
        </Button>
      </div>
      <Table
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id!}
        dataSource={data?.data || []}
      >
        <Column
          key={'key'}
          width={'10%'}
          title={'#'}
          align={'center'}
          render={(record, _, index) => (
            <span>{(currentPage - 1) * 10 + index + 1}</span>
          )}
        />
        <Column
          key={'name'}
          width={'20%'}
          title={
            <>
              <span style={{marginRight: '10px'}}>NOMI</span>
              {isFetching && <LoadingOutlined/>}
            </>
          }
          dataIndex={'name'}
        />
        <Column
          key={'type'}
          width={'60%'}
          title={"O'LCHOVI"}
          render={(record) => {
            const type = types.find((item) => item.values === record.type)
            return <span>{type?.label}</span>
          }}
        />
        <Column
          key={'action'}
          width={'10%'}
          title={'ACTION'}
          align={'center'}
          render={(record) => (
            <div>
              <span title={"O'zgartirish"}>
                <NotePencil
                  onClick={() => {
                    setProductSiId(record.id)
                    dispatch(isOpenSettingEditModal(true))
                  }}
                  className={'tableIcon'}
                />
              </span>
              {deleteProductSI.isLoading && deleteId === record.id ? (
                <Spin/>
              ) : (
                <Popconfirm
                  placement="left"
                  title="O'chirshni xohlaysizmi?"
                  onConfirm={() => deleteFunc(record.id)}
                  okText="Xa"
                  cancelText="Yoq"
                >
                  <span title={"O'chirish"}>
                    <Garbage className={'tableIcon'}/>
                  </span>
                </Popconfirm>
              )}
            </div>
          )}
        />
      </Table>
      <CreateAndEditModal
        page={currentPage}
        setProductSiId={setProductSiId}
        currentProductSI={productSiId}
      />
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          onChange: (page) => {
            setCurrentPage(page)
          },
        }}
      />
    </div>
  )
}

export default ProductSI
