import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { NotePencil } from "../../../../svg/NotePencil";
import CreateAndEditModal from "./CreateAndEditModal";
import { isOpenSettingEditModal } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useValuta } from "../../../../queries/queries";
import { useDeleteValuta } from "../../../../queries/mutations";
import { LoadingOutlined } from "@ant-design/icons";
import PaginationComp from "../../universialComponents/PaginationComp";
import { RootState } from "../../../../redux/rootReducer";

const { Column } = Table;

const Valuta = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [valutaId, setValutaId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);

  const dispatch = useDispatch();
  const searchString = useSelector(
    (state: RootState) => state.searchHeader.searchString
  );

  const { data, isLoading, isFetching } = useValuta(currentPage, searchString);
  const deleteValuta = useDeleteValuta(setDeleteId, currentPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchString]);

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id);
      await deleteValuta.mutateAsync(id);
      if (data?.data.length === 1 && data.current_page !== 1)
        setCurrentPage(currentPage - 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Table
        pagination={false}
        rowKey={(record) => record.id!}
        loading={isLoading}
        dataSource={data?.data || []}
      >
        <Column
          key={"key"}
          width={"10%"}
          title={"#"}
          align={"center"}
          render={(record, i, index) => (
            <span>{(currentPage - 1) * 10 + index + 1}</span>
          )}
        />
        <Column
          key={"name"}
          width={"30%"}
          title={
            <>
              <span style={{ marginRight: "1rem" }}>NOMI</span>
              {isFetching && <LoadingOutlined />}
            </>
          }
          dataIndex={"name"}
        />
        <Column
          key={"name"}
          width={"50%"}
          title={"QIYMATI"}
          dataIndex={"price"}
          render={(record) => <span>{record.toLocaleString("ru")}</span>}
        />
        <Column
          key={"action"}
          width={"10%"}
          title={"ACTION"}
          align={"center"}
          render={(record) =>
            record.id > 1 && (
              <div>
                <span title="O'zgartirish">
                  <NotePencil
                    onClick={() => {
                      setValutaId(record.id);
                      dispatch(isOpenSettingEditModal(true));
                    }}
                    className="tableIcon"
                  />
                </span>
              </div>
            )
          }
        />
      </Table>
      <CreateAndEditModal
        page={currentPage}
        setValutaId={setValutaId}
        currentValutaId={valutaId}
      />
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          onChange: (page) => {
            setCurrentPage(page);
          }
        }}
      />
    </>
  );
};

export default Valuta;
