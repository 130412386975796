import { Button, Input, message, Modal } from "antd";
import React from "react";
import query from "querystring";
import { useHistory, useLocation } from "react-router-dom";

import { Plus } from "../../../../svg/Plus";
import Payments from "../payments/Payments";
import ConversionCard from "./ConversionCard";
import styles from "../conversion.module.scss";
import dayjs from "rc-picker/node_modules/dayjs";
import { DatePicker } from "../../../../service/DatePicker";
import { paramsValue } from "../../../../helper/paramsValues";
import { useCreateConversion } from "../../../../queries/mutations";
import ArrowSquareRightIcon from "../../../../svg/ArrowSquareRightIcon";
import { validateConverison } from "../../../../helper/ValidateConversion";
import { ConversionIncome } from "../../../../models/enums/ConversionEnums";
import { ConversionStateType } from "../../../../models/conversion/ConversionModel";
import { DATE_FORMAT_TIME } from "../../../../models/settings/dateFormat";
import { Dayjs } from "dayjs";

const { TextArea } = Input;

type Props = {
  setConversions: React.Dispatch<React.SetStateAction<ConversionStateType>>;
  conversions: ConversionStateType;
};

const CreateConversion: React.FC<Props> = ({ conversions, setConversions }) => {
  const location = useLocation();
  const history = useHistory();

  const defaultConversion = {
    description: "",
    created_at: dayjs().format(DATE_FORMAT_TIME),
    formed_list: [
      {
        id: 1,
        amount: undefined,
        expense_type_id: undefined,
        kirim: ConversionIncome.KIRIM,
        payment_type_id: undefined,
        valuta_history_price: 1,
        valuta_id: 1,
      },
    ],
    spent_list: [
      {
        amount: undefined,
        kirim: ConversionIncome.CHIQIM,
        payment_type_id: undefined,
        valuta_history_price: 1,
        valuta_id: 1,
      },
    ],
  };

  // visible modal
  const visible = () => {
    const params = query.parse(
      location.search.slice(1, location.search.length)
    ) as {
      page?: string;
      visible?: string;
    };
    if (params.visible && params.visible === paramsValue.TRUE) return true;
    return false;
  };

  // close modal
  const handleClose = () => {
    const params = query.parse(
      location.search.slice(1, location.search.length)
    );
    delete params.visible;
    history.push(`${location.pathname}?${query.stringify(params)}`);
    setConversions(defaultConversion);
  };

  const createConversion = useCreateConversion(handleClose);

  const addFormedList = () => {
    const length = conversions.formed_list.length;
    setConversions({
      ...conversions!,
      formed_list: [
        ...conversions?.formed_list!,
        {
          id: conversions.formed_list[length - 1].id + 1,
          amount: undefined,
          expense_type_id: undefined,
          kirim: ConversionIncome.KIRIM,
          payment_type_id: undefined,
          valuta_history_price: 1,
          valuta_id: 1,
        },
      ],
    });
  };

  const formedListFilter = () => {
    const length = conversions?.formed_list.length!;
    if (length > 1) return conversions?.formed_list.slice(1, length);
    return [];
  };

  const changeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setConversions((prev) => {
      return {
        ...prev,
        description: e.target.value,
      };
    });
  };

  const finishCreateConversion = () => {
    const data = {
      ...conversions,
      formed_list: conversions.formed_list.map((item) => {
        return {
          amount: item.amount,
          expense_type_id: item.expense_type_id,
          kirim: item.kirim,
          payment_type_id: item.payment_type_id,
          valuta_history_price: item.valuta_history_price,
          valuta_id: item.valuta_id,
        };
      }),
    };

    const totalAmount =
      (conversions.spent_list[0]?.amount! ?? 0) *
      (conversions.spent_list[0]?.valuta_history_price! ?? 1);

    const totalFormedAmount = conversions.formed_list.reduce(
      (first, second) => {
        return (
          first + (second.amount! ?? 0) * (second.valuta_history_price! ?? 1)
        );
      },
      0
    );

    if (!validateConverison(conversions)) {
      if (totalAmount === totalFormedAmount) {
        if (conversions.created_at) {
          createConversion.mutate(data);
        } else {
          message.error("Sanani kiriting");
        }
      } else {
        message.error("Umumiy summalar bir xil emas");
      }
    }
  };

  const changeDate = (e: Dayjs | null) => {
    setConversions({
      ...conversions,
      created_at: e?.format(DATE_FORMAT_TIME),
    });
  };

  return (
    <Modal
      visible={visible()}
      title="Konvertatsiya yaratish"
      onCancel={handleClose}
      className={styles.create_conversion}
      centered
    >
      <Payments />
      <div className={styles.content}>
        <div className={styles.left}>
          <ConversionCard
            setConversions={setConversions}
            data={conversions}
            type={true}
            close={false}
            id={1}
          />
          <TextArea
            placeholder="Izoh kiriting ..."
            onChange={changeDescription}
            value={conversions.description}
          />
          <DatePicker
            defaultValue={dayjs()}
            onChange={changeDate}
            placeholder="YIL-OY-SANA VAQT"
            format="DD.MM.YYYY"
          />
        </div>
        <div className={styles.center}>
          <Button>
            <ArrowSquareRightIcon />
          </Button>
        </div>
        <div className={styles.right}>
          <ConversionCard
            setConversions={setConversions}
            data={conversions}
            type={false}
            close={false}
            id={1}
          />
          {formedListFilter()?.map((item, index) => (
            <ConversionCard
              key={index}
              setConversions={setConversions}
              data={conversions}
              type={false}
              close={true}
              id={item.id}
            />
          ))}
          <Button className={styles.add} onClick={addFormedList}>
            <Plus /> Kassa qo'shish
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <Button type="text" onClick={handleClose}>
          Bekor qilish
        </Button>
        <Button
          type="primary"
          onClick={finishCreateConversion}
          loading={createConversion.isLoading}
        >
          Saqlash
        </Button>
      </div>
    </Modal>
  );
};

export default CreateConversion;
