import { ResponsiveBar } from "@nivo/bar";
import React, { useState } from "react";
import { convertLongMoney } from "../../../../service/Helpers";

interface IProps {
  data?: any;
  isLoading?: boolean;
}

const ResponsiveBarExpense: React.FC<IProps> = (data) => {
  const CustomTooltip = (itemData: any) => {
    return (
      <div className={"tooltip"}>
        <div className={"itemCont"}>
          <div className={"title"}>Sana</div>
          <div className={"data"}>{itemData?.data?.date}</div>
        </div>
        <div className={"itemCont"}>
          <div className={"title"}>Narxi</div>
          <div className={"data"}>
            {itemData?.value.toLocaleString("ru")} so'm
          </div>
        </div>
      </div>
    );
  };
  const [resBarElements, setResBarElements] = useState<{
    keys: string[];
    colors: string[];
  }>({
    keys: ["payment_sum", "no_payment_sum", "expenses_sum", "useds"],
    colors: [
      "rgb(75, 177, 82)",
      "rgb(236, 202, 56)",
      "rgb(247, 71, 83)",
      "rgb(0, 0, 0)"
    ]
  });

  return (
    <ResponsiveBar
      data={data?.data || []}
      keys={resBarElements.keys}
      indexBy="date"
      margin={{ top: 5, right: 0, bottom: 120, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      enableLabel={false}
      colors={resBarElements.colors}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      fill={[
        {
          match: {
            id: "fries"
          },
          id: "dots"
        },
        {
          match: {
            id: "sandwich"
          },
          id: "lines"
        }
      ]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 3,
        tickPadding: 5,
        tickRotation: -90,
        // legend: 'country',
        legendPosition: "middle",
        legendOffset: 32
      }}
      axisLeft={{
        format: (value) => convertLongMoney(value)
      }}
      // axisLeft={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: 'food',
      //   legendPosition: 'middle',
      //   legendOffset: -40,
      // }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          dataFrom: "keys",
          onClick: (e) => {
            const index = resBarElements.colors.findIndex(
              (item) => item === e.fill
            );

            if (index === -1) {
              setResBarElements({
                keys: [...resBarElements.keys, String(e.id)],
                colors: [...resBarElements.colors, String(e.fill)]
              });
            } else {
              setResBarElements({
                keys: resBarElements.keys.filter((item) => item !== e.id),
                colors: resBarElements.colors.filter((item) => item !== e.fill)
              });
            }
          },
          data: [
            // {
            //   label: "To'langan",
            //   fill: "rgb(75, 177, 82)",
            //   id: "payment_sum"
            // },
            // {
            //   label: "To'lanmagan",
            //   fill: "rgb(236, 202, 56)",
            //   id: "no_payment_sum"
            // },
            {
              label: "Xarajat",
              fill: "rgb(247, 71, 83)",
              id: "expenses_sum"
            },
            {
              label: "Chiqim mahsulot",
              fill: "rgb(0, 0, 0)",
              id: "useds"
            }
          ],
          anchor: "bottom",
          direction: "row",
          justify: false,
          // translateX: 120,
          translateY: 90,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role="application"
      // ariaLabel="Nivo bar chart demo"
      // barAriaLabel={function (e) {
      //   return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
      // }}
      tooltip={CustomTooltip}
    />
  );
};

export default ResponsiveBarExpense;
