import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import dayjs, { Dayjs } from "rc-picker/node_modules/dayjs";
import React, { useEffect, useRef, useState } from "react";
import { PaymentCreateInterface } from "../../../../models/interfaces/PaymentCreateInterface";
import { DATE_FORMAT } from "../../../../models/settings/dateFormat";
import { useCreatePaymentMutation } from "../../../../queries/mutations";
import {
  useBrokers,
  usePaymentAll,
  useValutaAll,
} from "../../../../queries/queries";
import { calculateOne } from "../../../../service/calculateResidue";
import { DatePicker } from "../../../../service/DatePicker";
import { moneyToView } from "../../../../service/Helpers";
import "./payModal.scss";

interface PayedModalInterface {
  open: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  supplier_id: string;
}

interface OnFinishInterface {
  price: string;
  description: undefined | string;
  payment_type_id: number;
  valuta_history_price: string;
  valuta_id: number;
  date: Dayjs;
  user_id: string;
}

const { useForm } = Form;
const { Option } = Select;

const PayedModal: React.FC<PayedModalInterface> = ({
  open,
  close,
  title,
  supplier_id,
}) => {
  const [form] = useForm<OnFinishInterface>();
  const [valuta, setValuta] = useState<{
    curs: number;
    name: string | undefined | null;
  }>({
    curs: 1,
    name: "",
  });

  const priceRef = useRef({
    price: "",
    valuta_history_price: "",
  });

  const allValuta = useValutaAll();
  const allPayment = usePaymentAll();

  const paymentCreate = useCreatePaymentMutation(supplier_id);
  const { data: brokers, isLoading: isLoadingBrokers } =
    useBrokers(supplier_id);

  const closeFunc = () => {
    setValuta({
      curs: 1,
      name: "",
    });
    form.resetFields();
    close(false);
  };

  useEffect(() => {
    if (open) {
      const valuta_obj = allValuta.data || [];
      const payment_obj = allPayment.data || [];
      form.setFieldsValue({
        valuta_id: valuta_obj[0]?.id,
        payment_type_id: payment_obj[0]?.id,
        valuta_history_price: moneyToView(valuta_obj[0]?.price),
        date: dayjs(),
      });
      setValuta({
        curs: valuta_obj[0]?.price,
        name: valuta_obj[0]?.name,
      });
    }
    //eslint-disable-next-line
  }, [allPayment.data, allValuta.data, open]);

  const onFinish = async (val: OnFinishInterface) => {
    const price = parseFloat(val.price.replace(/\s/g, ""));
    const valuta_history_price = parseFloat(
      val.valuta_history_price.replace(/\s/g, "")
    );
    const payObj: PaymentCreateInterface = {
      user_id: val.user_id,
      description: val.description,
      price,
      valuta_history_price: valuta_history_price || valuta.curs,
      payment_type_id: val.payment_type_id,
      valuta_id: val.valuta_id,
      expense_type_id: 0,
      supplier_id: supplier_id,
      date: dayjs(val.date).format("YYYY-MM-DD"),
    };
    await paymentCreate.mutateAsync(payObj);
    closeFunc();
  };

  return (
    <Modal
      width={400}
      footer={false}
      className={"payModalCont"}
      visible={open}
      onCancel={closeFunc}
      forceRender={true}
    >
      <Form form={form} name="payModal" onFinish={onFinish} autoComplete="off">
        <div className="header">
          <div className="title">{title}</div>
          <div className={"inputCont"}>
            <span>1 {valuta.name}=</span>
            <Form.Item
              name="valuta_history_price"
              rules={[{ required: true, message: "" }]}
            >
              <Input
                onChange={(e) => {
                  const val = e.target.value.replace(/\s/g, "");
                  if (isNaN(val as any)) {
                    form.setFieldsValue({
                      valuta_history_price:
                        priceRef.current.valuta_history_price,
                    });
                    return;
                  }
                  priceRef.current = {
                    price: priceRef.current.price,
                    valuta_history_price: moneyToView(val),
                  };
                  const price = form.getFieldValue("price");
                  let residue = 0;
                  if (price) {
                    let newPrice = parseFloat(price.replace(/\s/g, ""));
                    residue = calculateOne(
                      newPrice,
                      0,
                      valuta.curs,
                      parseFloat(val)
                    );
                  }
                  if (val)
                    form.setFieldsValue({
                      valuta_history_price: moneyToView(val),
                      price:
                        residue !== 0 ? moneyToView(`${residue}`) : undefined,
                    });
                  setValuta({
                    curs: parseFloat(val),
                    name: valuta.name,
                  });
                }}
                className={"input"}
              />
            </Form.Item>
          </div>
        </div>
        <Row className={"body"}>
          <Col span={10}>
            <label htmlFor={"date"}>Sana</label>
            <Form.Item name="date" rules={[{ required: true, message: "" }]}>
              <DatePicker id={"date"} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <label htmlFor={"user_id"}>Vositachi</label>
            <Form.Item name="user_id">
              <Select
                loading={isLoadingBrokers}
                onChange={(value) => {
                  if (brokers) {
                    form.setFieldsValue({
                      price: brokers
                        ?.find((broker) => broker.user_id === value)
                        ?.debit.toLocaleString("ru")
                        .replace("-", ""),
                    });
                  }
                }}
                placeholder="Vositachini tanlang"
              >
                {brokers?.map((broker) => {
                  return (
                    <Option value={broker.user_id as any} key={broker.user_id}>
                      {broker.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <label htmlFor={"price"}>Summani kiriting</label>
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  const val = e.target.value.replace(/\s/g, "");
                  if (isNaN(val as any)) {
                    form.setFieldsValue({
                      price: priceRef.current.price,
                    });
                    return;
                  }
                  priceRef.current = {
                    price: moneyToView(val),
                    valuta_history_price: priceRef.current.valuta_history_price,
                  };
                  form.setFieldsValue({
                    price: moneyToView(val),
                  });
                }}
                id={"price"}
                placeholder={"so'm"}
                className={"input"}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <label htmlFor={"valuta_id"}>Valyuta</label>
            <Form.Item
              name="valuta_id"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                loading={allValuta.isLoadingError}
                id={"valuta_id"}
                onChange={(value, option) => {
                  let price = form.getFieldValue("price");
                  let residue = 0;
                  if (price) {
                    price = parseFloat(price.replace(/\s/g, ""));
                    //@ts-ignore
                    residue = calculateOne(
                      price,
                      0,
                      valuta.curs,
                      //@ts-ignore
                      parseFloat(option?.data?.price)
                    );
                  }
                  form.setFieldsValue({
                    //@ts-ignore
                    valuta_history_price: moneyToView(`${option?.data?.price}`),
                    price:
                      residue !== 0 ? moneyToView(`${residue}`) : undefined,
                  });
                  setValuta({
                    //@ts-ignore
                    curs: option?.data?.price,
                    //@ts-ignore
                    name: option?.data?.name,
                  });
                }}
                style={{ width: "95%" }}
              >
                {allValuta?.data?.map((val) => (
                  <Option value={val.id as any} key={val.id} data={val}>
                    {val.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <label htmlFor={"payment_type_id"}>To'lov turi</label>
            <Form.Item
              name="payment_type_id"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                loading={allPayment.isLoading}
                id={"payment_type_id"}
                style={{ width: "95%" }}
              >
                {allPayment?.data?.map((val) => (
                  <Option value={val.id as number} key={val.id}>
                    {val.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <label htmlFor={"description"}>Izoh</label>
            <Form.Item name="description">
              <Input.TextArea
                placeholder={"Izohni kiriting"}
                style={{ resize: "none" }}
                id={"description"}
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className={"footer"}>
          <Button onClick={closeFunc} type={"link"} className={"linkButton"}>
            Bekor qilish
          </Button>
          <Button
            loading={paymentCreate.isLoading}
            onClick={() => form.submit()}
            type={"primary"}
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PayedModal;
