import { useQuery } from "react-query";
import $api from "../../../http/http";
import { endpoints } from "../utils/constants/endpoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { errorHandler } from "../utils/helpers/errorHandler";
import { BuildingModel } from "../utils/models/buildingModel";
import { ErrorRes } from "../utils/models/responseType";
import { UserModel } from "../utils/models/userModel";

export function useGetUsers(id?: string[] | null) {
  return useQuery<UserModel, ErrorRes>(
    [queryKeys.USERS, id],
    async () => {
      const res = await $api.get(`${endpoints.USERS}?id=[${id}]`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler
    }
  );
}

export function useGetBuildings() {
    const id = JSON.parse(localStorage.getItem("user") ?? '').building_id
  return useQuery<BuildingModel[]>(
    [queryKeys.BUILDINGS],
    async () => {
      const res = await $api.get(`${endpoints.BUILDING}?all=true&id=[${id}]`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}
