import React, { useEffect, useRef, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Diagram } from "../../../models/settings/ProductTableInterface";
import {
  GraphInterface,
  LineGraphData,
} from "../../../models/settings/Graphinterface";
import { convertLongMoney } from "../../../service/Helpers";

interface GraphPropsInterface {
  beginData: Diagram[];
  width: number;
  height: number | string;
}

const CustomTooltip = (itemData: any) => {
  return (
    <div className={"tooltip"}>
      <div className={"itemCont"}>
        <div className={"title"}>Partya</div>
        <div className={"data"}>#{itemData?.slice?.points[0].data?.id}</div>
      </div>
      <div className={"itemCont"}>
        <div className={"title"}>Narxi</div>
        <div className={"data"}>
          {itemData?.slice?.points[0].data?.y.toLocaleString("ru")} so'm
        </div>
      </div>
      <div className={"itemCont"}>
        <div className={"title"}>Sana</div>
        <div className={"data"}>{itemData?.slice?.points[0].data?.date}</div>
      </div>
      <div className={"itemCont"}>
        <div className={"title"}>Vositachi</div>
        <div className={"data"} title={itemData?.slice?.points[0].data?.broker}>
          {nameString(itemData?.slice?.points[0].data?.broker)}
        </div>
      </div>
      <div className={"itemCont"}>
        <div className={"title"}>Ta'minotchi</div>
        <div
          className={"data"}
          title={itemData?.slice?.points[0].data?.supplier}
        >
          {nameString(itemData?.slice?.points[0].data?.supplier)}
        </div>
      </div>
      <div className={"itemCont"}>
        <div className={"title"}>Obyekt</div>
        <div
          className={"data"}
          title={itemData?.slice?.points[0].data?.building_name}
        >
          {nameString(itemData?.slice?.points[0].data?.building_name)}
        </div>
      </div>
    </div>
  );
};

const nameString = (str: string) => {
  if (str?.length > 13) return str?.slice(0, 13) + "...";
  return str;
};

const Graph: React.FC<GraphPropsInterface> = ({ beginData, width, height }) => {
  const [chartData, setChartData] = useState<GraphInterface[]>([]);
  const chartWidth = useRef(0);

  useEffect(() => {
    const dataArr: LineGraphData[] = [];
    chartWidth.current = 0;
    beginData.forEach((data) => {
      dataArr.push({
        x: `#${data.order_id}`,
        y: data.price,
        date: data.date,
        id: data.order_id,
        broker: data.user_name,
        supplier: data.supplier_name,
        building_name: data.building_name,
      });
      chartWidth.current += 30;
    });
    if (dataArr.length > 0)
      setChartData([
        {
          data: dataArr,
          id: "chart",
          color: "#33D79F",
        },
      ]);
  }, [beginData]);

  // @ts-ignore
  const CustomSymbol = ({ color, borderColor }) => (
    <g>
      <circle fill="#fff" r={8} strokeWidth={0} stroke={borderColor} />
      <circle
        r={1}
        strokeWidth={2}
        stroke={borderColor}
        fill={color}
        fillOpacity={0.35}
      />
    </g>
  );

  const calculateWidth = () => {
    if (width) {
      return chartWidth.current > width ? chartWidth.current : width;
    }
    return "100%";
  };

  return (
    <div style={{ height, width, overflowX: "scroll" }}>
      <div style={{ height, width: calculateWidth() }}>
        <ResponsiveLine
          data={chartData}
          margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          curve="natural"
          axisLeft={{
            format: (value) => convertLongMoney(value),
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 3,
            tickPadding: 0,
            tickRotation: -70,
            legendOffset: 12,
            legendPosition: "middle",
          }}
          enableGridX={false}
          colors={{ datum: "color" }}
          lineWidth={5}
          pointSymbol={CustomSymbol}
          pointColor={{ theme: "background" }}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="x"
          enableArea={true}
          defs={[
            {
              id: "gradientC",
              type: "linearGradient",
              colors: [
                { offset: 0, color: "#33D79F50" },
                { offset: 100, color: "#fff" },
              ],
            },
          ]}
          fill={[{ match: "*", id: "gradientC" }]}
          areaOpacity={0.15}
          enableSlices="x"
          crosshairType="bottom"
          sliceTooltip={CustomTooltip}
          // tooltip={CustomTooltip}
          useMesh={true}
          legends={[]}
        />
      </div>
    </div>
  );
};

export default Graph;
