import { LoadingOutlined } from '@ant-design/icons'
import { Col, Select } from 'antd'
import React, { useState } from 'react'
import { TopStatisticDataType, useTopProducts } from '../../../../queries/queries'
import { TopProductsChart } from './TopProductChart'
const { Option } = Select
interface TopProductsProps {}
interface Top10State {
  type: TopStatisticDataType
  label: string
}
const top10s: Top10State[] = [
  {
    type: 'top10products',
    label: 'Mahsulotlar',
  },
  {
    type: 'top10vositachi',
    label: 'Vositachi',
  },
  {
    type: 'top10qarzdor',
    label: 'Qarzdor',
  },
  {
    type: 'top10supplier',
    label: "Ta'minotchi",
  },
]
const TopProducts: React.FC<TopProductsProps> = () => {
  const [types, setTypes] = useState<Top10State>(top10s[0])
  const { data, isLoading, isFetching } = useTopProducts(types.type)

  return (
    <Col className="cover topProducts" span={24}>
      <div className="cover_head">
        <div>
          <p style={{ display: 'inline-block', marginRight: '1rem' }}>Top-10</p>
          {isFetching && <LoadingOutlined />}
        </div>
        <div style={{ width: '11rem' }}>
          <Select
            value={types.type}
            style={{ width: '100%' }}
            onChange={(_value, props: any) => {
              setTypes({ type: props.value, label: props.children as string })
            }}
          >
            {top10s.map((typeItem) => {
              return (
                <Option key={typeItem.type} value={typeItem.type}>
                  {typeItem.label}
                </Option>
              )
            })}
          </Select>
        </div>
      </div>
      <div className="topProducts_chart">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <TopProductsChart data={data?.sort((a, b) => a.sum_price - b.sum_price)} />
        )}
      </div>
    </Col>
  )
}

export default TopProducts
