const colors:string[] = [
  '#F74753',
  '#ECCA38',
  '#4BB152',
  '#34455A',
  '#2291CC',
  '#9B75EC',
  '#E65C62',
  '#FFB129',
  '#29F2FF'
]
export default colors