export function arrayToString<T>(arr: T) {
  let tags: string | undefined = "";
  if (
    typeof arr === "object" &&
    arr !== null &&
    Array.isArray(arr) &&
    typeof arr[0] === "number"
  ) {
    arr?.forEach((item, index) => {
      if (index + 1 !== arr.length) {
        tags += `${item},`;
      } else {
        tags += `${item}`;
      }
    });
  } else {
    tags = undefined;
  }

  return tags;
}
