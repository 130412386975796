import React from "react";
import { Button, Modal } from "antd";
import DownloadIcon from "../../../svg/DownloadIcon";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  importFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ImportExcelModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  importFile,
}) => {
  const handleDownloadExcelFile = () => {
    const ws = XLSX.utils.json_to_sheet([
      {
        "№": "1",
        "Mahsulot nomi": "",
        Miqdori: "",
        "Birlik narxi": "",
      },
    ]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, "shablon.xlsx");
  };
  return (
    <Modal
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      title="Import qilish"
      className="importModal"
    >
      <p className="importModal_text">
        Exceldagi ma’lumotlarni dasturga kiritish
      </p>
      <input
        id="excel_file"
        type="file"
        placeholder="select file"
        accept=".xls, .xlsx"
        style={{
          display: "none",
        }}
        onChange={importFile}
        multiple
      />
      <div className="importModal_btns">
        <Button
          type="primary"
          className="uploadBtn uploadBtn_active"
          onClick={() => document.getElementById("excel_file")?.click()}
        >
          <DownloadIcon /> Import
        </Button>
        <Button className="uploadBtn" onClick={handleDownloadExcelFile}>
          <DownloadIcon /> Shablon
        </Button>
      </div>
    </Modal>
  );
};

export default ImportExcelModal;
