import { Reducer } from "redux";
import { PRODUCT_PAYMENT_VALUTA_ID } from "./types";

type PaymentAndValutaType = {
  valuta_id: number;
  payment_type_id: number;
};

const initialState: PaymentAndValutaType = {
  valuta_id: 1,
  payment_type_id: 1,
};

export const paymentAndValutaReducer: Reducer<PaymentAndValutaType> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case PRODUCT_PAYMENT_VALUTA_ID:
      return { ...state, ...payload };

    default:
      return state;
  }
};
