export const apis = {
  LOG_IN: "/login",
  SETTINGS_PAYMENT: "/payment-type",
  SUPPLIER: "/supplier",
  SUPPLIER_DETAILS: "/supplier/details",
  SUPPLIER_BROKER: "/supplier/vositachi",
  BROKERS_OF_SUPPLIER: "/supplier/vositachi/",
  SUPPLIERS_OF_BROKER: "/supplier/suppliers/",
  USER: "/user",
  USER_ROLE_LIST: "/user/role-lists",
  EXPENSE: "/expense-type",
  CATEGORY: "/category",
  PRODUCT_SI: "/product-type",
  BUILDING: "/building",
  ALL_OBJECTS: "/building?all=true",
  VALUTA: "/valuta",
  PRODUCT: "/product",
  PRODUCT_DETAILS: "/product/details",
  ORDER: "/order",
  CREATE: "/create",
  ORDER_DETAILS: "/order/details",
  PAYMENT: "/payment",
  TRANSFER: "/transfer",
  TRANSFER_DETAILS: "/transfer/details",
  STATISTICS: "/statistic",
  HISTORY: "/history",
  ACT_RECONCILIATION: "/supplier/aktsverka",
  PRODUCT_HISTORY: "/product/history",
  CHECK_PRODUCT: "/product/check",
  EXCEL: "/export",
  OBJECT_HISTORY: "/building/history",
  OBJECTS_HISTORY_NAME: "/history_only_buildings",
  WAREHOUSE: "/warehouse",
  WAREHOUSE_PRODUCT: "/used/products/",
  VIEW_EXPENSE: "/used/view",
  CREATE_EXPENSE: "/used/create",
  UPDATE_EXPENSE: "/used/update",
  WAREHOUSE_USED: "/warehouse-used",
  CHECKOUT_PATMENTS: "/kassa/payment",
  CHECKOUT_VIEW: "/kassa/view",
  CHECKOUT_DELETE: "/kassa/delete?id=",
  CREATE_CHECKOUT: "/kassa/payment/create",
  CHECKOUT_UYSOT: "/kassa/uysot",
  UYSOT: "/uysot",
  UYSOT_INTEGRATION: "/uysot/integration",
  UYSOT_RETRY: "/uysot/integration/retry",
  UYSOT_PAYMENT: "/uysot/payment",
  VIEW: "/view",
  UPDATE: "/update",
  STATUS_CHANGE: "/status/change",
  UYSOT_KASSA: "/kassa/uysot",
  CONVERSION: "/convertation",
  MY_DATA: "/mydata",
};
