import React from "react";
import { Pagination, PaginationProps, Select } from "antd";

interface PaginationInterface {
  paginationProps?: PaginationProps;
  per_page?: number;
  setPerPage?: React.Dispatch<React.SetStateAction<number>>;
  showSizeChanger?: boolean;
}

const { Option } = Select;

const PaginationComp: React.FC<PaginationInterface> = ({
  paginationProps,
  per_page,
  setPerPage,
  showSizeChanger,
}) => {
  return (
    <div className="paginationCont">
      <Pagination
        showSizeChanger={false}
        {...paginationProps}
        locale={{ items_per_page: "sahifa" }}
      />
      {showSizeChanger && (
        <div className="showChanger">
          <h4>Sahifa hajmi</h4>
          <Select
            value={per_page ? per_page : 10}
            onChange={(e) => {
              if (setPerPage) {
                setPerPage(e);
              }
            }}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </Select>
        </div>
      )}
    </div>
  );
};

export default PaginationComp;
