import { Button, Checkbox } from "antd";
import React from "react";
import ArrowIconDanger from "../../../../../svg/ArrowIconDanger";
import ArrowIconSuccess from "../../../../../svg/ArrowIconSuccess";
import DownloadIcon from "../../../../../svg/DownloadIcon";
import ReactToPrint from "react-to-print";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface FooterInterface {
  closeFunc: () => void;
  debit_sum: number;
  kredit_sum: number;
  viewProducts: boolean;
  setViewProducts: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer: React.FC<FooterInterface> = ({
  closeFunc,
  debit_sum,
  kredit_sum,
  viewProducts,
  setViewProducts,
}) => {
  const returnIconFunc = () => {
    if (debit_sum - kredit_sum >= 0)
      return (
        <span className={"iconSuccess"}>
          <ArrowIconSuccess />
        </span>
      );
    return (
      <span className={"iconDanger"}>
        <ArrowIconDanger />
      </span>
    );
  };

  const handleAfterPrint = () => {
    const titles: any = document.querySelectorAll(".ant-table-thead");
    const tableBody: any = document.querySelectorAll(".ant-table-body");
    const checkboxElem: any = document.querySelector(
      ".actReconciliationFooterTop"
    );
    titles.forEach((title: any) => {
      title.style.display = "table-header-group";
    });
    tableBody.forEach((body: any) => {
      body.style.maxHeight = "70vh";
    });
    checkboxElem.style.display = "block";
  };

  const handleChangeViewProducts = (e: CheckboxChangeEvent) => {
    setViewProducts(e.target.checked);
  };

  return (
    <div className={"actReconciliationFooter"}>
      <div className="actReconciliationFooterTop">
        <Checkbox onChange={handleChangeViewProducts} value={viewProducts}>
          <span className="viewProductsText">Mahsulotlar bilan</span>
        </Checkbox>
      </div>
      <div className="actReconciliationFooterBottom">
        <div className={"leftSection"}>
          <span className={"label"}>debit</span>
          <span className={"sum"}>{debit_sum.toLocaleString("ru")} so'm</span>
          <span className={"label"}>Kredit</span>
          <span className={"sum"}>{kredit_sum.toLocaleString("ru")} so'm</span>
          {returnIconFunc()}
          <span className={"sum"}>
            {(debit_sum - kredit_sum).toLocaleString("ru")} so'm
          </span>
        </div>
        <div>
          <Button onClick={closeFunc} type={"link"} className={"cancel"}>
            Bekor qilish
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button className="pdfBtn">
                <div className={"button"}>
                  <DownloadIcon />
                  <span>PDF</span>
                </div>
              </Button>
            )}
            onBeforeGetContent={() => {
              const checkboxElem: any = document.querySelector(
                ".actReconciliationFooterTop"
              );
              const titles: any = document.querySelectorAll(".ant-table-thead");
              const tableBody: any =
                document.querySelectorAll(".ant-table-body");
              titles.forEach((title: any) => {
                title.style.display = "none";
              });
              tableBody.forEach((body: any) => {
                body.style.maxHeight = "none";
              });
              checkboxElem.style.display = "none";
            }}
            onAfterPrint={handleAfterPrint}
            onPrintError={handleAfterPrint}
            content={() => document.querySelector(".actReconciliationModal")}
          />
          <Button className="excelBtn">
            <div className={"button"}>
              <DownloadIcon />
              <span>Excel</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
