import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  isOpenProductGraph,
  isOpenSettingEditModal,
} from "../../../../../redux/actions";
import { NotePencil } from "../../../../../svg/NotePencil";
import { Garbage } from "../../../../../svg/Garbage";
import CreateAndEditModal from "./CreateAndEditModal";
import PaginationComp from "../../../universialComponents/PaginationComp";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/rootReducer";
import { useProduct } from "../../../../../queries/queries";
import { useDeleteProduct } from "../../../../../queries/mutations";

import styles from "./productsTable.module.scss";
import OpenButtonComp from "../../../productHistory/OpenButtonComp";
import { Plus } from "../../../../../svg/Plus";

const { Column } = Table;

const ProductsTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productId, setProductId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);

  const dispatch = useDispatch();
  const searchString = useSelector(
    (state: RootState) => state.searchHeader.searchString
  );

  const { data, isLoading, isFetching } = useProduct(
    currentPage,
    searchString,
    true
  );
  const deleteProduct = useDeleteProduct(setDeleteId, currentPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchString]);

  const deleteFunc = async (id: number) => {
    try {
      setDeleteId(id);
      await deleteProduct.mutateAsync(id);
      if (data?.data.length === 1 && data.current_page !== 1)
        setCurrentPage(currentPage - 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.table}>
      <div className={styles.buttons}>
        <OpenButtonComp id={0} type="overall" />
        <Button className={"buttonCont"} type={"primary"}>
          <div
            className={"button"}
            onClick={() => {
              dispatch(isOpenSettingEditModal(true));
            }}
          >
            <Plus className="icon" />
            <span>Mahsulot</span>
          </div>
        </Button>
      </div>
      <Table
        rowKey={(record) => record.id!}
        onRow={(record) => {
          return {
            onClick: () => {
              dispatch(isOpenProductGraph(true, record.id));
            },
          };
        }}
        rowClassName={"cursor_pointer"}
        pagination={false}
        loading={isLoading}
        dataSource={data?.data || []}
      >
        <Column
          key={"key"}
          width={"5%"}
          title={"#"}
          align={"center"}
          render={(record, i, index) => (
            <span>{(currentPage - 1) * 10 + index + 1}</span>
          )}
        />
        <Column
          key={"name"}
          title={
            <>
              <span style={{ marginRight: "10px" }}>MAHSULOT NOMI</span>
              {isFetching && <LoadingOutlined />}
            </>
          }
          width={"45%"}
          dataIndex={"name"}
        />
        <Column
          key={"category_name"}
          width={"25%"}
          title={"KATEGORIYA"}
          dataIndex={"category_name"}
        />
        <Column
          key="resource"
          dataIndex="resource"
          title="TURI"
          render={(record) => {
            return <>{record === 0 ? "Mahsulot" : "Resurs"}</>;
          }}
        />
        <Column
          width={"15%"}
          key={"product_type_name"}
          title={"O’lchov birligi"}
          dataIndex={"product_type_name"}
        />
        <Column
          key={"action"}
          width={"10%"}
          title={"ACTION"}
          align={"center"}
          render={(record) => (
            <div>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setProductId(record.id);
                  dispatch(isOpenSettingEditModal(true));
                }}
                className={"tableIcon"}
                title={"O'zgartirish"}
              >
                <NotePencil />
              </span>
              {deleteId === record.id && deleteProduct.isLoading ? (
                <Spin />
              ) : (
                <span
                  title={"O'chirish"}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Popconfirm
                    placement="left"
                    title="O'chirshni xohlaysizmi?"
                    onConfirm={() => deleteFunc(record.id)}
                    okText="Xa"
                    cancelText="Yoq"
                  >
                    <Garbage className={"tableIcon"} />
                  </Popconfirm>
                </span>
              )}
            </div>
          )}
        />
      </Table>
      <CreateAndEditModal
        page={currentPage}
        setProductId={setProductId}
        currentProductId={productId}
      />
      <PaginationComp
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          pageSize: data?.per_page || 10,
          onChange: (page) => {
            setCurrentPage(page);
          },
        }}
      />
    </div>
  );
};

export default ProductsTable;
