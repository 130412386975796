import React, { useEffect, useState } from "react";
import { Button, Modal, Select, Spin, Switch } from "antd";
import { useHistory } from "react-router-dom";

import {
  useGetAllPayments,
  useGetCheckoutView,
  useGetUysotView,
  useKassaUysot,
} from "../../../../queries/queries";
import styles from "./integration.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import SelectIcon from "../../../../svg/SelectIcon";
import PlyusCircleIcon from "../../../../svg/PlyusCircleIcon";
import {
  useCancelUysot,
  useUpdatePaymentUysot,
  useUysotIntegration,
} from "../../../../queries/mutations";
import { ACTIVE, PASSIVE } from "../../../../helper/ExpenseType";
import UysotCard from "../../checkout/cards/UysotCard";
import GeneralCard from "../../checkout/cards/GeneralCard";

const { Option } = Select;

const Integration: React.FC = () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const uysotIntegration = useUysotIntegration();
  const { data: KassaUysot, isLoading: isLoadingKassaUysot } = useKassaUysot();
  const { data: UysotPaymentViewData, isLoading: isLoadingUysotPaymnetView } =
    useGetUysotView();
  const { data: AllPayments, isLoading: isLoadingAllPayments } =
    useGetAllPayments();
  const cancelUysot = useCancelUysot();
  const { data: checkout, isLoading: isLoadingCheckout } = useGetCheckoutView();

  const [visible, setVisible] = useState<boolean>(false);
  const [paymentState, setPaymentState] = useState<
    {
      id: number;
      payment_type_id: number | null;
      status: string;
    }[]
  >([]);

  useEffect(() => {
    if (searchParams.get("key")) {
      uysotIntegration.mutate(searchParams.get("key") ?? "");
    }
  }, []);

  // open or close modal
  const changeModal = (e: boolean) => setVisible(e);

  useEffect(() => {
    if (UysotPaymentViewData) setPaymentState(UysotPaymentViewData);
  }, [UysotPaymentViewData]);

  const updateUysotPayment = useUpdatePaymentUysot(() => changeModal(false));

  // click integration button
  const changeIntegration = () => {
    if (!KassaUysot?.integration) {
      const a = document.createElement("a");
      a.href = `https://app.uysot.uz/integration/uyqur?extra_id=1&redirect_url=${window.location.href}`;
      a.click();
      a.remove();
    }
  };

  // change payment select
  const changePayment = (payment_type_id: number | null, id: number) => {
    setPaymentState((prev) =>
      prev?.map((item) => {
        if (item.id === id) return { ...item, payment_type_id };
        return item;
      })
    );
    disabledSwitch(id);
    paymentSelectValue(id);
  };

  const changePaymentStatus = (e: boolean, id: number) => {
    setPaymentState((prev) =>
      prev.map((item) => {
        if (item.id === id) return { ...item, status: e ? ACTIVE : PASSIVE };
        return item;
      })
    );
    paymentSwitchValue(id);
    disabledSwitch(id);
  };

  const disabledSwitch = (id: number) => {
    const paymentCopyState = paymentState;
    const index = paymentState.findIndex((item) => item.id === id);
    if (paymentCopyState[index]?.payment_type_id) return false;
    else return true;
  };

  const submitPayment = () => {
    const data = paymentState.map((item) => {
      return {
        id: item.id,
        payment_type_id: item.payment_type_id,
        status: item.status,
      };
    });
    updateUysotPayment.mutate({
      payments: data.filter((item) => item.payment_type_id),
    });
  };

  const paymentSelectValue = (id: number) => {
    const index = paymentState.findIndex((item) => item.id === id);
    if (index >= 0) return paymentState[index].payment_type_id ?? undefined;
    else return undefined;
  };

  const paymentSwitchValue = (id: number) => {
    const index = paymentState.findIndex((item) => item.id === id);
    if (index >= 0) return paymentState[index].status === ACTIVE ? true : false;
    else return false;
  };

  const onCancelUysot = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className={styles.integration}>
        <Spin
          spinning={
            uysotIntegration.isLoading ||
            isLoadingKassaUysot ||
            isLoadingUysotPaymnetView ||
            isLoadingAllPayments ||
            cancelUysot.isLoading ||
            isLoadingCheckout
          }
          indicator={<LoadingOutlined />}
        >
          {!KassaUysot?.integration && (
            <div className={styles.add_card} onClick={changeIntegration}>
              <PlyusCircleIcon />
              <p>Uysot dasturi bilan integratsiya qilish</p>
            </div>
          )}
          {KassaUysot?.integration && (
            <div className={styles.cards}>
              <div onClick={() => changeModal(true)}>
                <UysotCard
                  cancel={onCancelUysot}
                  id={1}
                  type="Uysot"
                  sum={KassaUysot?.sum!}
                  updated_at={KassaUysot?.updated_at!}
                  payments={KassaUysot?.uysot_payments!}
                  current_sum={KassaUysot.sum_currency}
                />
              </div>
              <GeneralCard data={AllPayments} title="Uyqur" />
              <GeneralCard
                data={AllPayments}
                uysot={{
                  sum: KassaUysot?.sum!,
                  current_sum: KassaUysot.sum_currency,
                }}
              />
            </div>
          )}
        </Spin>
      </div>
      <Modal
        visible={visible}
        onCancel={() => changeModal(false)}
        title="Kassani integratsiya qilish"
        className={styles.integration_modal}
        centered
      >
        <div className={styles.form}>
          <div className={styles.info}>
            <div className={styles.left}>
              <h4>Hamyonlarga yo’naltirish</h4>
              <div className={styles.content}>
                <div className={styles.left}>
                  {UysotPaymentViewData?.map((item, index) => (
                    <p key={index}>{item.type}</p>
                  ))}
                </div>
                <div className={styles.right}>
                  {paymentState?.map((value, index) => (
                    <Select
                      placeholder="To'lov turini tanlang"
                      key={index}
                      suffixIcon={<SelectIcon />}
                      onChange={(e: number) => changePayment(e, value.id)}
                      value={value.payment_type_id ?? undefined}
                    >
                      {AllPayments?.map((item, index) => (
                        <Option value={item.id} key={index}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.center}>
              <h4>Summa</h4>
              <div className={styles.sum}>
                {UysotPaymentViewData?.map((item, index) => (
                  <div key={index}>
                    <span>{item?.sum?.toLocaleString()} so'm</span>
                    <span>{item?.sum_currency?.toLocaleString()} $</span>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.right}>
              <h4>Kassalarni birlashtirish</h4>
              <div className={styles.switchs}>
                {UysotPaymentViewData?.map((item, index) => (
                  <div key={index} className={styles.item}>
                    <Switch
                      onChange={(e) => changePaymentStatus(e, item.id)}
                      disabled={disabledSwitch(item.id)}
                      checked={paymentSwitchValue(item.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button type="text" onClick={() => changeModal(false)}>
              Chiqish
            </Button>
            <Button
              type="primary"
              onClick={submitPayment}
              loading={updateUysotPayment.isLoading}
            >
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Integration;
