import React from "react";

const PlyusCircleIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.25" cy="12" r="7" fill="white" />
      <path
        d="M12.25 2C6.74 2 2.25 6.49 2.25 12C2.25 17.51 6.74 22 12.25 22C17.76 22 22.25 17.51 22.25 12C22.25 6.49 17.76 2 12.25 2ZM16.25 12.75H13V16C13 16.41 12.66 16.75 12.25 16.75C11.84 16.75 11.5 16.41 11.5 16V12.75H8.25C7.84 12.75 7.5 12.41 7.5 12C7.5 11.59 7.84 11.25 8.25 11.25H11.5V8C11.5 7.59 11.84 7.25 12.25 7.25C12.66 7.25 13 7.59 13 8V11.25H16.25C16.66 11.25 17 11.59 17 12C17 12.41 16.66 12.75 16.25 12.75Z"
        fill="#303541"
      />
    </svg>
  );
};

export default PlyusCircleIcon;
